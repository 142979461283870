import { FC, ReactElement } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './index.scss';
import { AssetPlugin, Props as AssetPluginProps } from './plugins/asset';

const CUSTOM_PLUGINS = [AssetPlugin];
const DEFAULT_TOOLBAR_OPTIONS = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'list',
  'textAlign',
  'link',
  'emoji',
  'history',
];

interface Props extends AssetPluginProps {
  label?: string;
  plugins?: ReactElement<HTMLElement, string | React.JSXElementConstructor<any>>[];
  disabled?: boolean;
}
export const ReactDraft: FC<Props> = ({
  label,
  editorState,
  onEditorStateChange,
  plugins = CUSTOM_PLUGINS,
  disabled = false,
  ...props
}) => (
  <div className={`react-draft-container ${disabled && 'disabled'}`}>
    {label && <div className="react-draft-header">{label}</div>}
    <Editor
      editorState={editorState}
      toolbarClassName="react-draft-toolbar"
      wrapperClassName="react-draft-wrapper"
      editorClassName="react-draft-editor"
      readOnly={disabled}
      onEditorStateChange={onEditorStateChange}
      toolbarCustomButtons={plugins.map((Plugin, key) => (
        <Plugin key={key} />
      ))}
      toolbar={{ options: DEFAULT_TOOLBAR_OPTIONS }}
      {...props}
    />
  </div>
);
