import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { PathParams } from 'global/route';
import { FetchPolicy } from 'graphql/apollo-client';
import { CREATE_LAB_USER, DESTROY_LAB_USER, UPDATE_LAB_USER } from 'graphql/mutations';
import { GET_EXPERIMENTS, GET_LAB_USERS, GET_USER_TYPES } from 'graphql/queries';
import { useItemForm } from 'hooks/useItemForm';
import { ExperimentFormData } from 'pages/experiments/forms';
import { validateEmail } from 'plugins/validator';

import { LAB_USER, LabUser, Key as UserKey, UserTypeData } from '../forms';

interface Props {
  action: PathParams;
  providedFormValues: LabUser;
}

export const useLabUserForm = ({ providedFormValues, action }: Props) => {
  const { id: userId } = useParams();

  const { data: experimentsData, loading: experimentsLoading } = useQuery<{ experiments: ExperimentFormData[] }>(
    GET_EXPERIMENTS,
    {
      fetchPolicy: FetchPolicy.NETWORK_ONLY,
    },
  );
  const { data: userTypesData } = useQuery<{ userTypes: UserTypeData[] }>(GET_USER_TYPES, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: { where: { isExternal: false } },
  });

  const parseData = (values: LabUser) => {
    const { updatedAt, createdAt, __typename, uid, userType, ...formValues } = values;

    formValues.experiments = formValues?.experiments?.map(experiment => experiment.id);

    return formValues;
  };

  const mapFormValue = (key: string, value: any) => {
    switch (key) {
      case UserKey.EXPERIMENTS:
      case UserKey.TYPE:
        return value;
      default:
        return value || '';
    }
  };

  const { formActions, formErrors, formLoading, register, setFormValues, setValue, trigger, watch } =
    useItemForm<LabUser>({
      action,
      defaultValues: LAB_USER,
      itemDisplayName: 'Lab User',
      createMutation: CREATE_LAB_USER,
      updateMutation: UPDATE_LAB_USER,
      destroyMutation: DESTROY_LAB_USER,
      getItemsQuery: GET_LAB_USERS,
      parseData,
      mapFormValue,
      editPathId: userId,
    });

  const formAndExperimentsLoading = useMemo<boolean>(
    () => formLoading || experimentsLoading,
    [formLoading, experimentsLoading],
  );

  const readOnly: boolean = action === PathParams.SHOW;

  useEffect(() => {
    register(UserKey.EMAIL, {
      validate: value => validateEmail(value ?? '', 'Not valid email'),
    });
    register(UserKey.TYPE_ID, {
      validate: value => (value ? true : 'User type required'),
    });
  }, [register]);

  useEffect(() => {
    if (providedFormValues) {
      setFormValues({ ...providedFormValues, typeId: providedFormValues.userType?.id });
    }
  }, []);

  return {
    readOnly,
    formLoading: formAndExperimentsLoading,
    watch,
    setValue,
    trigger,
    errors: formErrors,
    formActions,
    experimentList: experimentsData?.experiments,
    userTypesList: userTypesData?.userTypes,
  };
};
