import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ImageIcon from '@mui/icons-material/Image';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SchoolIcon from '@mui/icons-material/School';
import ScienceIcon from '@mui/icons-material/Science';
import SlideShowIcon from '@mui/icons-material/Slideshow';
import YardIcon from '@mui/icons-material/Yard';

export enum RouteName {
  OVERVIEW = 'Overview',
  CONTEXTS = 'Contexts',
  WIND_DOWNS = 'Wind Downs',
  LEARNS = 'Learns',
  ASSETS = 'Assets',
  MOMENTS = 'Moments',
  QUESTIONS = 'Questions',
  REVIEWS = 'Reviews',
  DAILY_PRACTICES = 'Daily Practices',
  PACKAGES = 'Packages',
  WISDOM_ENGINE_PROMPTS = 'Wisdom Engine Prompts',
  SEQUENCES = 'Sequences',
  LAB_USERS = 'Users',
  // Note - we are changing references to `experiments` to `experiences` in the user interface
  // There will be a follow-up ticket to remove references to the word `experiment` in the codebase
  EXPERIMENTS = 'Experiences',
  CAROUSELS = 'Carousels',
}

export enum Entity {
  POST = 'Post',
  ASSET = 'Asset',
  MOMENT = 'Moment',
  QUESTION = 'Question',
  REVIEW = 'Review',
  DAILY_PRACTICE = 'DailyPractice',
  PACKAGE = 'Package',
  SEQUENCE = 'PackageSequence',
  CAROUSEL = 'Carousel',
  WISDOM_ENGINE_PROMPTS = 'Wisdom',
}

export enum RoutePath {
  OVERVIEW = 'overview',
  CONTEXTS = 'contexts',
  WIND_DOWNS = 'wind-downs',
  LEARNS = 'learns',
  ASSETS = 'assets',
  MOMENTS = 'moments',
  QUESTIONS = 'questions',
  REVIEWS = 'reviews',
  DAILY_PRACTICES = 'daily-practices',
  SEQUENCES = 'sequences',
  WISDOM_ENGINE_PROMPTS = 'wisdom-engine-prompts',
  LAB_USERS = 'users',
  // Note - we are changing references to `experiments` to `experiences` in the user interface
  // There will be a follow-up ticket to remove references to the word `experiment` in the codebase
  EXPERIMENTS = 'experience',
  PACKAGES = 'packages',
  CAROUSELS = 'carousels',
}

enum UidLetters {
  MOMENT = 'M',
  REVIEW = 'R',
  LEARN = 'H',
  WIND_DOWN = 'W',
  DAILY_PRACTICE = 'U',
  CHECK_IN = '',
  CONTEXT = 'K',
  QUESTION = 'C',
  PACKAGE = 'P',
  SEQUENCE = 'S',
}

const letterRouteMap = new Map([
  [UidLetters.MOMENT, RoutePath.MOMENTS],
  [UidLetters.REVIEW, RoutePath.REVIEWS],
  [UidLetters.LEARN, RoutePath.LEARNS],
  [UidLetters.WIND_DOWN, RoutePath.WIND_DOWNS],
  [UidLetters.DAILY_PRACTICE, RoutePath.DAILY_PRACTICES],
  [UidLetters.CONTEXT, RoutePath.CONTEXTS],
  [UidLetters.QUESTION, RoutePath.QUESTIONS],
  [UidLetters.PACKAGE, RoutePath.PACKAGES],
  [UidLetters.SEQUENCE, RoutePath.SEQUENCES],
]);

export const getRouteByUid = (uid: string): RoutePath =>
  letterRouteMap.get(uid.substring(0, 1) as UidLetters) as RoutePath;

export interface NavigationRoute {
  name: RouteName;
  route: RoutePath;
  icon: JSX.Element;
}

export const CMS_ROUTES: NavigationRoute[] = [
  {
    name: RouteName.OVERVIEW,
    route: RoutePath.OVERVIEW,
    icon: <DashboardIcon />,
  },
  {
    name: RouteName.CONTEXTS,
    route: RoutePath.CONTEXTS,
    icon: <ListAltIcon />,
  },
  {
    name: RouteName.WIND_DOWNS,
    route: RoutePath.WIND_DOWNS,
    icon: <YardIcon />,
  },
  {
    name: RouteName.LEARNS,
    route: RoutePath.LEARNS,
    icon: <SchoolIcon />,
  },
  {
    name: RouteName.ASSETS,
    route: RoutePath.ASSETS,
    icon: <ImageIcon />,
  },
  {
    name: RouteName.MOMENTS,
    route: RoutePath.MOMENTS,
    icon: <EventAvailableIcon />,
  },
  {
    name: RouteName.QUESTIONS,
    route: RoutePath.QUESTIONS,
    icon: <HelpCenterIcon />,
  },
  {
    name: RouteName.REVIEWS,
    route: RoutePath.REVIEWS,
    icon: <FactCheckIcon />,
  },
  {
    name: RouteName.DAILY_PRACTICES,
    route: RoutePath.DAILY_PRACTICES,
    icon: <DateRangeIcon />,
  },
  {
    name: RouteName.PACKAGES,
    route: RoutePath.PACKAGES,
    icon: <InventoryIcon />,
  },
  {
    name: RouteName.SEQUENCES,
    route: RoutePath.SEQUENCES,
    icon: <AutoAwesomeMotionIcon />,
  },
  {
    name: RouteName.CAROUSELS,
    route: RoutePath.CAROUSELS,
    icon: <SlideShowIcon />,
  },
  {
    name: RouteName.WISDOM_ENGINE_PROMPTS,
    route: RoutePath.WISDOM_ENGINE_PROMPTS,
    icon: <PsychologyIcon />,
  },
];

export const LAB_ROUTES: NavigationRoute[] = [
  {
    name: RouteName.OVERVIEW,
    route: RoutePath.OVERVIEW,
    icon: <DashboardIcon />,
  },
  {
    name: RouteName.CONTEXTS,
    route: RoutePath.CONTEXTS,
    icon: <ListAltIcon />,
  },
  {
    name: RouteName.WIND_DOWNS,
    route: RoutePath.WIND_DOWNS,
    icon: <YardIcon />,
  },
  {
    name: RouteName.LEARNS,
    route: RoutePath.LEARNS,
    icon: <SchoolIcon />,
  },
  {
    name: RouteName.ASSETS,
    route: RoutePath.ASSETS,
    icon: <ImageIcon />,
  },
  {
    name: RouteName.MOMENTS,
    route: RoutePath.MOMENTS,
    icon: <EventAvailableIcon />,
  },
  {
    name: RouteName.QUESTIONS,
    route: RoutePath.QUESTIONS,
    icon: <HelpCenterIcon />,
  },
  {
    name: RouteName.REVIEWS,
    route: RoutePath.REVIEWS,
    icon: <FactCheckIcon />,
  },
  {
    name: RouteName.DAILY_PRACTICES,
    route: RoutePath.DAILY_PRACTICES,
    icon: <DateRangeIcon />,
  },
  {
    name: RouteName.PACKAGES,
    route: RoutePath.PACKAGES,
    icon: <InventoryIcon />,
  },
  {
    name: RouteName.SEQUENCES,
    route: RoutePath.SEQUENCES,
    icon: <AutoAwesomeMotionIcon />,
  },
  {
    name: RouteName.LAB_USERS,
    route: RoutePath.LAB_USERS,
    icon: <AccountCircleIcon />,
  },
  {
    name: RouteName.EXPERIMENTS,
    route: RoutePath.EXPERIMENTS,
    icon: <ScienceIcon />,
  },
];
