import { FC } from 'react';

import { NotFound } from 'components/NotFound';
import { PathParams } from 'global/route';

import { Review } from './forms/Create';
import { useGetReview } from './hooks/useGetReview';

export const Show: FC = () => {
  const { loading, data } = useGetReview();

  if (loading) return <></>;
  else if (data?.review) return <Review action={PathParams.SHOW} values={data.review} />;
  else return <NotFound />;
};
