import {
  DOMAttributes,
  MouseEvent,
  MouseEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useQuery } from '@apollo/client';

import { FetchPolicy } from 'graphql/apollo-client';
import { GET_ASSETS } from 'graphql/queries';
import { useSnackbar } from 'hooks/useSnackbar';

import { AssetFilter, AssetFormData, DEFAULT_FILTER } from '..';

const SCROLL = 'scroll';

interface UseAssetGridView {
  where: AssetFilter;
  assetsPerPage: number;
}

interface UseAssetGridViewReturn {
  loading: boolean;
  assets: AssetFormData[];
  gridContainerRef: MutableRefObject<HTMLDivElement | null>;
  assetViewClickHandler: MouseEventHandler<HTMLDivElement> | undefined;
}

interface Assets {
  assets: AssetFormData[];
  assetsTotalCount: number;
}

export const useAssetGridView = ({ assetsPerPage, where }: UseAssetGridView): UseAssetGridViewReturn => {
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [assets, setAssets] = useState<AssetFormData[]>([]);
  const [position, setPosition] = useState<number>(0);
  const [whereState, setWhereState] = useState(DEFAULT_FILTER);

  const gridContainerRef = useRef<HTMLDivElement | null>(null);

  const { showErrorToast } = useSnackbar();

  const { loading, error, data, fetchMore } = useQuery<Assets>(GET_ASSETS, {
    variables: { where, offset, limit: assetsPerPage, order: 'reverse:createdAt' },
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
  });

  const assetViewClickHandler: DOMAttributes<HTMLDivElement>['onClick'] = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => e.currentTarget.focus();

  const handleScrollEvent = useCallback(async (): Promise<void> => {
    const element = gridContainerRef?.current;

    try {
      if (!element) {
        throw new Error("Element does't exist");
      }

      const scrollTop = Number(element.scrollTop.toFixed());

      const isScrollAtBottom = element.scrollHeight - scrollTop <= element.clientHeight;
      const hasMoreAssets = offset + assetsPerPage < totalCount;

      if (isScrollAtBottom && hasMoreAssets) {
        const { data } = await fetchMore({ variables: { offset: offset + assetsPerPage } });

        setOffset(prevOffset => prevOffset + assetsPerPage);
        setPosition(scrollTop);

        if (data?.assets?.length) {
          setAssets(prev => [...prev, ...data.assets]);
        }
      }
    } catch (err) {
      const error = err as Error;
      showErrorToast(error.message);
    }
  }, [totalCount, offset]);

  useEffect(() => {
    gridContainerRef?.current?.addEventListener(SCROLL, handleScrollEvent);

    return () => {
      gridContainerRef?.current?.removeEventListener(SCROLL, handleScrollEvent);
    };
  }, [handleScrollEvent]);

  useEffect(() => {
    if (where !== whereState) {
      setOffset(0);
      setWhereState(where);
    }
  }, [where]);

  useEffect(() => {
    if (data?.assetsTotalCount && data.assets?.length && (!assets.length || offset === 0)) {
      setAssets(data.assets);
      setTotalCount(data.assetsTotalCount);
    }
    gridContainerRef?.current?.scrollTo(0, position);
  }, [data]);

  useEffect(() => {
    error && showErrorToast(error as unknown as string);
  }, [error]);

  return {
    loading,
    assets,
    gridContainerRef,
    assetViewClickHandler,
  };
};
