export const API_HOST = String(process.env.REACT_APP_API_HOST);

export enum AuthRoute {
  CMS_LOG_IN_PATH = '/auth/google/login',
  CMS_LOG_OUT_PATH = '/auth/google/logout',
  LAB_LOG_IN_PATH = '/auth/admin/login',
  LAB_LOG_OUT_PATH = '/auth/admin/logout',
}

export enum GoogleUserRole {
  MASTER = 'master',
  CONTENT_EDITOR = 'contentEditor',
  READER = 'reader',
}
