import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { NotFound } from 'components/NotFound';
import { PathParams } from 'global/route';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_CAROUSEL } from 'graphql/queries';

import { CarouselFormData } from './forms';
import { Carousel } from './forms/Create';

export const Edit: FC = () => {
  const { id } = useParams();
  const { data, loading } = useQuery<{ carousel: CarouselFormData }>(GET_CAROUSEL, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: parseInt(id as string),
    },
  });

  if (loading) return <></>;
  if (data?.carousel) return <Carousel action={PathParams.EDIT} values={data.carousel} />;
  return <NotFound />;
};
