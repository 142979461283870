import { FC } from 'react';

import { StandardTextFieldProps } from '@material-ui/core';
import { Autocomplete, Grid, TextField } from '@mui/material';

import { ClassName } from 'components/mui-custom';
import { DEFAULT_GRID_ITEM_PROPS } from 'global/form';
import { PathParams } from 'global/route';

import { UseExperimentForm } from '../hooks/useExperimentForm';

interface Props extends StandardTextFieldProps {
  hook: UseExperimentForm;
  action: PathParams;
}

export const ExperimentSequence: FC<Props> = ({
  hook: { watch, setSequence, readOnly, sequenceIds, getSequenceOption },
  action,
}) => {
  const { packageSequenceId } = watch();

  return (
    <>
      {packageSequenceId || action !== PathParams.SHOW ? (
        <Grid {...DEFAULT_GRID_ITEM_PROPS} md={12}>
          <Autocomplete
            value={packageSequenceId || null}
            className={ClassName.MUI_CUSTOM}
            options={sequenceIds}
            onChange={setSequence}
            getOptionLabel={getSequenceOption}
            renderInput={params => <TextField value={packageSequenceId} {...params} label="Sequence" />}
            disabled={readOnly}
            fullWidth
          />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
