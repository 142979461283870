import { FC } from 'react';

import { RoutePath } from 'app/routes';

import { POST_SECTION_COLUMNS, PostSectionValue } from 'components/post/forms';
import { PostTable } from 'components/post/tables/Post';
import { GET_POSTS } from 'graphql/queries';
import { getShowPagePath } from 'hooks/useTable';

export const List: FC = () => {
  return (
    <PostTable
      query={GET_POSTS}
      where={{ section: PostSectionValue.WIND_DOWN }}
      columns={POST_SECTION_COLUMNS}
      onGetLink={({ row }) => getShowPagePath(RoutePath.WIND_DOWNS, String(row.uid))}
    />
  );
};
