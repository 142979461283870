import { GridColDef } from '@mui/x-data-grid';

import { AssetFormData } from 'components/asset';
import { PackageRow, Tag } from 'global/type';
import { formatDate } from 'global/formater';

export * from './Create';
export * from './Save';

export enum MomentKey {
  ID = 'id',
  TITLE = 'title',
  INTRODUCTION = 'introduction',
  SUGGESTED_LENGTH = 'durationMs',
  PROMPTS = 'prompts',
  TAGS = 'tags',
  PACKAGES = 'packages',
  THUMBNAIL = 'thumbnail',
  THUMBNAIL_ID = 'thumbnailId',
  OPTIONAL_FOR_USER = 'optionalForUser',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PUBLISHED_AT = 'publishedAt',
  UID = 'uid',
  TYPE_NAME = '__typename',
}

enum ContentColumnsFields {
  ID = 'uid',
  TITLE = 'title',
  INTRODUCTION = 'introduction',
  SUGGESTED_LENGTH = 'durationMs',
  PUBLISHED_AT = 'publishedAt',
  CREATED_AT = 'createdAt',
}

export const MOMENT_SECTION_COLUMNS: GridColDef[] = [
  { field: ContentColumnsFields.ID, headerName: 'ID' },
  { field: ContentColumnsFields.TITLE, headerName: 'Title', flex: 2 },
  {
    field: ContentColumnsFields.SUGGESTED_LENGTH,
    headerName: 'Suggested Length, min',
    flex: 1,
    valueGetter: ({ row }) => row.durationMs / 60 / 1000,
  },
  { field: ContentColumnsFields.INTRODUCTION, headerName: 'Introduction', flex: 1 },
  {
    field: ContentColumnsFields.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
  {
    field: ContentColumnsFields.CREATED_AT,
    headerName: 'Created Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.createdAt),
  },
];

export interface MomentFormData {
  [MomentKey.ID]?: string;
  [MomentKey.TITLE]: string;
  [MomentKey.INTRODUCTION]: string;
  [MomentKey.SUGGESTED_LENGTH]: string | number;
  [MomentKey.PROMPTS]?: PromptRow[];
  [MomentKey.TAGS]: Tag[] | number[];
  [MomentKey.PACKAGES]?: PackageRow[];
  [MomentKey.THUMBNAIL]?: AssetFormData | null;
  [MomentKey.THUMBNAIL_ID]: number | null;
  [MomentKey.OPTIONAL_FOR_USER]: boolean | null;
  [MomentKey.CREATED_AT]?: string;
  [MomentKey.UPDATED_AT]?: '';
  [MomentKey.PUBLISHED_AT]?: string | Date | null;
  [MomentKey.UID]?: string;
  [MomentKey.TYPE_NAME]?: string;
}

export const MOMENT_FORM: MomentFormData = {
  [MomentKey.ID]: '',
  [MomentKey.TITLE]: '',
  [MomentKey.INTRODUCTION]: '',
  [MomentKey.SUGGESTED_LENGTH]: '',
  [MomentKey.PROMPTS]: [],
  [MomentKey.TAGS]: [],
  [MomentKey.PACKAGES]: [],
  [MomentKey.THUMBNAIL]: null,
  [MomentKey.OPTIONAL_FOR_USER]: null,
  [MomentKey.THUMBNAIL_ID]: null,
  [MomentKey.CREATED_AT]: '',
  [MomentKey.UPDATED_AT]: '',
  [MomentKey.PUBLISHED_AT]: '',
  [MomentKey.UID]: '',
};

export enum PromptColumnKey {
  CONTENT = 'content',
}

export interface PromptRow {
  id: string;
  content: string;
}
