import { ApolloClient, NormalizedCacheObject, useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UploadIcon from '@mui/icons-material/Upload';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { DocumentNode } from 'graphql';
import { parseError, useForm } from 'plugins/react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { DefaultValues, FieldValues, Path, PathValue } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';

import { PARENT_ROUTE, PathParams, pathJoin } from 'global/route';
import { useSnackbar } from 'hooks/useSnackbar';
import { FormActions } from './useTable';
import { useDispatch } from 'react-redux';
import { setConfirmNavigationModal, clearConfirmNavigationModal } from 'store/confirm-navigation-modal';
import { getConfirmNavigationAction, showConfirmAlert } from 'components/confirm-modal';

export interface ItemType extends FieldValues {
  id?: string;
  uid?: string;
  publishedAt?: string | Date | null;
}

export interface UseItemFormOptions<TData extends ItemType> {
  /** Current form action (CREATE, EDIT, or SHOW). */
  action: PathParams;

  /** Default form values. */
  defaultValues: DefaultValues<TData>;

  /** The user-facing display name for the item type, e.g. "Daily Practice". */
  itemDisplayName: string;

  /**
   * A GraphQL mutation for creating an item, with the following signature:
   * `({ input: Omit<ItemType, 'id' | 'publishedAt'> }) => TData`
   */
  createMutation: DocumentNode;

  /**
   * A GraphQL mutation for updating an item, with the following signature:
   * `({ input: ItemType }) => TData`
   */
  updateMutation: DocumentNode;

  /**
   * A GraphQL mutation for destroying an item, with the following signature:
   * `({ input: { id: string } }) => void`
   */
  destroyMutation: DocumentNode;

  /**
   * A GraphQL mutation for publishing/unpublishing items, with the following signature:
   * `({ ids: string[], publishedAt: string | null }) => void`
   */
  changePublishStatusMutation?: DocumentNode;

  /** A GraphQL query that needs to be re-fetched after creation. */
  getItemsQuery: DocumentNode;

  /** A function that transforms form values. */
  parseData: (data: TData) => Partial<TData>;

  /** An optional function to get data comparable with query response. */
  parseDataComparable?: (data: TData) => Partial<TData>;

  /** An optional function to further transform parsed data, only during creation. */
  parseCreateData?: (data: Partial<TData>) => Partial<TData>;

  /** An optional hook into the submit handler, after passing validation. */
  onWillSubmit?: () => Promise<void>;

  /** An optional function to map form values (default coalesces undefined to the empty string). */
  mapFormValue?: (key: string, value: any) => any;

  /** Optional value to override the default `editPath` id. */
  editPathId?: string;

  /** Optional GraphQL client to use instead of the default. */
  gqlClient?: ApolloClient<NormalizedCacheObject>;
}

export const useItemForm = <TData extends ItemType>(opts: UseItemFormOptions<TData>) => {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useSnackbar();
  const [showConfirm, setShowConfirm] = useState(window.location.href.includes(PathParams.EDIT));
  const [existingFormData, setExistingFormData] = useState<Partial<TData>>();
  const [formDataChanged, setFormDataChanged] = useState(false);

  const {
    formState: { errors: formErrors },
    getValues,
    register,
    setValue,
    trigger,
    watch,
  } = useForm<TData>({
    defaultValues: opts.defaultValues,
  });

  const { id, uid, publishedAt } = watch();

  const editPath = pathJoin(PARENT_ROUTE, opts.editPathId ?? uid ?? '', PathParams.EDIT);

  // HOOKS

  const [create, { loading: createLoading }] = useMutation(opts.createMutation, {
    refetchQueries: [opts.getItemsQuery],
    ...(opts.gqlClient && { client: opts.gqlClient }),
  });
  const [update, { loading: updateLoading }] = useMutation(opts.updateMutation, {
    ...(opts.gqlClient && { client: opts.gqlClient }),
  });
  const [destroy, { loading: destroyLoading }] = useMutation(opts.destroyMutation, {
    ...(opts.gqlClient && { client: opts.gqlClient }),
  });

  // conditionally calling useMutation, but based on an immutable opt, so not a bug
  const [changePublishState, { loading: changePublishStateLoading }] = opts.changePublishStatusMutation
    ? useMutation(opts.changePublishStatusMutation, { ...(opts.gqlClient && { client: opts.gqlClient }) })
    : [null, { loading: false }];

  const formLoading = useMemo(
    () => createLoading || updateLoading || destroyLoading || changePublishStateLoading,
    [createLoading, updateLoading, destroyLoading, changePublishStateLoading],
  );

  // HANDLERS

  const createHandler = async (forcePublish: boolean): Promise<TData | void> => {
    let { id, publishedAt, ...formValues } = opts.parseData(getValues());
    if (opts.parseCreateData) {
      formValues = opts.parseCreateData(formValues as Partial<TData>);
    }

    if (forcePublish) {
      publishedAt = new Date();
    }

    const { data, errors } = await create({
      variables: {
        input: {
          ...formValues,

          // this will filter out falsey values like the empty string
          ...(publishedAt && { publishedAt }),
        },
      },
    });

    if (errors?.length) {
      return;
    }

    showSuccessToast(`${opts.itemDisplayName} successfully created`);
    navigate(-1);

    return data?.data;
  };

  const updateHandler = async (forcePublish: boolean, fromConfirm = false): Promise<TData | void> => {
    setShowConfirm(false);
    if (!formDataChanged) {
      navigate(-1);
      return;
    }
    let { publishedAt, ...formValues } = opts.parseData(getValues());

    if (forcePublish) {
      publishedAt = new Date();
    }

    const { data, errors } = await update({
      variables: {
        input: {
          ...formValues,

          // this will filter out falsey values like the empty string
          ...(publishedAt && { publishedAt }),
        },
      },
    });

    if (errors?.length) {
      return;
    }

    showSuccessToast(`${opts.itemDisplayName} ${uid} successfully updated`);
    if (!fromConfirm) {
      navigate(-1);
    }
    return data?.data;
  };

  const submit = async (forcePublish: boolean, fromConfirm = false): Promise<TData | void> => {
    const isValid = await trigger();
    if (!isValid) {
      showErrorToast(String(parseError(formErrors)));
      return;
    }

    await opts.onWillSubmit?.();

    switch (opts.action) {
      case PathParams.CREATE:
        return createHandler(forcePublish);
      case PathParams.EDIT:
        return updateHandler(forcePublish, fromConfirm);
      default:
        return showErrorToast('No handler provided');
    }
  };

  const destroyHandler = async (): Promise<void> => {
    const { errors } = await destroy({
      variables: {
        input: { id },
      },
    });

    if (errors?.length) {
      return;
    }

    showSuccessToast(`${opts.itemDisplayName} ${uid} successfully deleted`);
    navigate(PARENT_ROUTE);
  };

  const publishHandler = async (): Promise<void> => {
    const newPublishedAt = new Date().toISOString();

    const { errors } = await changePublishState!({
      variables: {
        ids: [id],
        publishedAt: newPublishedAt,
      },
    });

    if (errors?.length) {
      return;
    }

    setValue('publishedAt' as Path<TData>, newPublishedAt as PathValue<TData, Path<TData>>);
    showSuccessToast(`${opts.itemDisplayName} ${uid} successfully published`);
  };

  const unpublishHandler = async (): Promise<void> => {
    const { errors } = await changePublishState!({
      variables: {
        ids: [id],
        publishedAt: null,
      },
    });

    if (errors?.length) {
      return;
    }

    setValue('publishedAt' as Path<TData>, null as PathValue<TData, Path<TData>>);
    showSuccessToast(`${opts.itemDisplayName} ${uid} successfully unpublished`);
  };

  // ACTIONS

  const saveAction = {
    text: 'Save',
    action: () => {
      setShowConfirm(false);
      submit(false);
    },
    Icon: SaveIcon,
    loading: createLoading || updateLoading,
  };

  const cancelAction = {
    text: 'Cancel',
    action: () => {
      setShowConfirm(false);
      navigate(-1);
    },
    Icon: CloseIcon,
    disabled: formLoading,
  };

  const editAction = {
    text: 'Edit',
    action: () => navigate(editPath),
    Icon: EditIcon,
    disabled: destroyLoading,
  };

  const publishAction = {
    text: 'Publish',
    action: publishHandler,
    Icon: UploadIcon,
    disabled: !!publishedAt || changePublishStateLoading,
  };

  const unpublishAction = {
    text: 'Unpublish',
    action: unpublishHandler,
    Icon: VisibilityOffIcon,
    disabled: !publishedAt || changePublishStateLoading,
  };

  const saveAndPublishAction = {
    text: 'Publish',
    action: () => {
      setShowConfirm(false);
      submit(true);
    },
    Icon: UploadIcon,
    disabled: formLoading,
  };

  const destroyAction = {
    text: 'Delete',
    action: destroyHandler,
    Icon: DeleteIcon,
    loading: destroyLoading,
    disabled: formLoading,
  };

  const publishActions = changePublishState ? [publishAction, unpublishAction] : [];
  const saveAndPublishActions = changePublishState ? [saveAndPublishAction] : [];

  const formActions: FormActions =
    opts.action === PathParams.SHOW
      ? { inline: [editAction, ...publishActions], expanded: [destroyAction] }
      : { inline: [saveAction, cancelAction, ...saveAndPublishActions] };

  // UTILS

  const setFormValues = (formValues: Partial<TData>): void => {
    for (const key of Object.keys(formValues)) {
      if (key in opts.defaultValues) {
        const value = opts.mapFormValue ? opts.mapFormValue(key, formValues[key]) : formValues[key] || '';
        setValue(key as Path<TData>, value as PathValue<TData, Path<TData>>);
      }
    }
  };

  //CONFIRM NAVIGATION MODAL

  const dispatch = useDispatch();
  useEffect(() => {
    const confirmNavigationAction = getConfirmNavigationAction(
      showConfirm && formDataChanged,
      async () => {
        await submit(false, true);
      },
      () => dispatch(clearConfirmNavigationModal()),
    );
    dispatch(setConfirmNavigationModal(confirmNavigationAction));
  });

  useEffect(() => {
    return showConfirmAlert();
  }, []);

  useEffect(() => {
    if (window.location.href.includes(PathParams.EDIT)) {
      const formValues = opts.parseDataComparable ? opts.parseDataComparable(getValues()) : opts.parseData(getValues());
      if (!existingFormData && formValues.id) {
        setExistingFormData(formValues);
      }
      if (existingFormData) {
        setFormDataChanged(!_isEqual(existingFormData, formValues));
      }
    }
  });

  // RETURN

  return {
    formActions,
    formErrors,
    formLoading,
    getValues,
    register,
    setFormValues,
    setValue,
    trigger,
    watch,
  };
};
