import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { stringToInt } from 'global/formater';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_QUESTION } from 'graphql/queries';

import { QuestionFormData } from '../forms';

export const useGetQuestion = () => {
  const { id } = useParams();
  const { data, loading } = useQuery<{ question: QuestionFormData }>(GET_QUESTION, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: stringToInt(id as string),
    },
  });

  return {
    data,
    loading,
  };
};
