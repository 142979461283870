import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { AnalyticsService } from '@loticai/lotic-analytics-service';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { APP_CLIENT } from 'app/manager';
import { createRouter } from 'app/router';
import config from 'config';
import 'normalize.css';
import { ApolloProvider } from 'providers/apollo-provider';
import { DateProvider } from 'providers/DateProvider';
import { SnackbarProvider } from 'providers/snackbar';

import { ScrollToTop } from 'components/ScrollToTop';
import { isProduction } from 'global/isProduction';
import { store } from 'store';

import './index.scss';

if (isProduction) {
  const { DSN, REPLAY_ON_ERROR_SAMPLE_RATE, REPLAY_SESSION_SAMPLE_RATE, TRACES_SAMPLE_RATE, PROFILES_SAMPLE_RATE } =
    config.sentry;
  AnalyticsService.initialize({
    serviceTrackerConfig: {
      dsn: DSN,
      environment: config.env.NODE_ENV,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [config.env.API_HOST],
        }),
        new Sentry.Replay(),
        new Sentry.BrowserProfilingIntegration(),
        new CaptureConsole(),
      ],
      profilesSampleRate: PROFILES_SAMPLE_RATE,
      // Performance Monitoring
      tracesSampleRate: TRACES_SAMPLE_RATE, // Sets rate of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: REPLAY_SESSION_SAMPLE_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: REPLAY_ON_ERROR_SAMPLE_RATE, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      beforeSend: event => {
        if (event.exception && !event.extra?.skipErrorReport) {
          AnalyticsService.errorTracker.showReportDialog({ eventId: event.event_id });
        }

        return event;
      },
    },
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const { App } = APP_CLIENT;

const router = createRouter(
  <ScrollToTop>
    <ApolloProvider>
      <DateProvider>
        <App />
      </DateProvider>
    </ApolloProvider>
  </ScrollToTop>,
);

root.render(
  <GoogleOAuthProvider clientId={config.env.GOOGLE_ID}>
    <SnackbarProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </SnackbarProvider>
  </GoogleOAuthProvider>,
);
