import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { AssetFormData } from 'components/asset';
import { NotFound } from 'components/NotFound';
import { PathParams } from 'global/route';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_ASSET } from 'graphql/queries';
import { useSnackbar } from 'hooks/useSnackbar';

import { AssetForm } from './form';

export const Show: FC = () => {
  const { id } = useParams();
  const { data, loading } = useQuery<{ asset: AssetFormData }>(GET_ASSET, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: parseInt(id as string),
    },
  });

  if (loading) return <></>;

  if (data?.asset) return <AssetForm action={PathParams.SHOW} values={data.asset} />;

  return <NotFound />;
};
