const {
  REACT_APP_INSTANCE,
  REACT_APP_API_HOST,
  REACT_APP_LABS_UI_HOST,
  REACT_APP_GOOGLE_ID,
  WDS_SOCKET_PORT,
  REACT_APP_ENVIRONMENT,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  REACT_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  REACT_APP_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
  REACT_APP_SENTRY_PROFILES_SAMPLE_RATE,
  REACT_APP_FEATURE_DOWNLOAD_REPORT,
} = process.env;

export default {
  env: {
    NODE_ENV: REACT_APP_ENVIRONMENT ?? 'development',
    APP_INSTANCE: REACT_APP_INSTANCE ?? '',
    API_HOST: REACT_APP_API_HOST ?? '',
    LABS_UI_HOST: REACT_APP_LABS_UI_HOST ?? '',
    GOOGLE_ID: REACT_APP_GOOGLE_ID ?? '',
    WDS_SOCKET_PORT: Number(WDS_SOCKET_PORT ?? 3003),
  },
  feature: {
    DOWNLOAD_REPORT: REACT_APP_FEATURE_DOWNLOAD_REPORT === 'true',
  },
  sentry: {
    DSN: REACT_APP_SENTRY_DSN ?? '',
    TRACES_SAMPLE_RATE: Number(REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? 0.1),
    REPLAY_SESSION_SAMPLE_RATE: Number(REACT_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE ?? 0.1),
    REPLAY_ON_ERROR_SAMPLE_RATE: Number(REACT_APP_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE ?? 0.1),
    PROFILES_SAMPLE_RATE: Number(REACT_APP_SENTRY_PROFILES_SAMPLE_RATE ?? 0.1),
  },
};
