import { renderToString } from 'react-dom/server';

import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { htmlStringToElement } from 'global/html';

export { htmlToDraft, draftToHtml, EditorState };

export const htmlToEditorContent = (html: string): EditorState => {
  const { contentBlocks, entityMap } = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return EditorState.createWithContent(contentState);
};

export const editorContentToHtml = (content: ContentState): string => {
  const rawContentState = convertToRaw(content);
  //Remove img tags if they are inside pargraph.
  //Due to the bug in react-draft-wysiwyg, img tag can move inside previous paragraph when user deletes image.
  const deletedImageRegExp = /(?<![>\n])<img[A-z0-9 =:"\-/.?;>]+/g;
  return draftToHtml(rawContentState).replace(deletedImageRegExp, '');
};

export type MediaElement = HTMLImageElement | HTMLIFrameElement;
export const htmlStringToMediaElement = (html: string): MediaElement => {
  const element = htmlStringToElement(html);

  if (element?.hasAttribute('src')) {
    return element as MediaElement;
  }

  throw new Error("Can't build media element from: " + html);
};

export const getFragmentBlockKey = (editorState: EditorState, fragment: ContentState): string =>
  Modifier.replaceWithFragment(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    fragment.getBlockMap(),
  ).getLastCreatedEntityKey();

/**
 * Converts JSX.Element to a string wrapped in a siblings tag.
 * ("React Draft editor" - requires wrapping sibling tags when pasted into an editable area)
 * @param { JSX.Element } element to wrapp in siblings
 */
export const JsxToEditorHtmlString = (element: JSX.Element): string =>
  renderToString(
    <>
      <p></p>
      {element}
      <p></p>
    </>,
  );
