import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { store } from 'store';

interface GoogleUser {
  email: string;
  isInitialized: boolean;
}

const newUser = (): GoogleUser => ({
  email: '',
  isInitialized: true,
});

const googleUser = newUser();

export const userSlice = createSlice({
  name: 'googleUser',
  initialState: { ...googleUser, isInitialized: false },
  reducers: {
    setUser: (googleUser, action: PayloadAction<Partial<GoogleUser>>) => {
      return { ...googleUser, ...action.payload };
    },
    clearUser: () => {
      return newUser();
    },
  },
});

export const selectUser = (state: ReturnType<typeof store.getState>) => state.googleUser;

export const userReducer = userSlice.reducer;

export const { setUser, clearUser } = userSlice.actions;
