import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardDownUpIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import { v4 } from 'uuid';

import { ASSET_FORM, AssetFormData, AssetKey } from 'components/asset';
import { editorContentToHtml, htmlToEditorContent } from 'components/react-draft/utils';
import { getFileName } from 'global/formater';
import { PathParams } from 'global/route';
import { useAssetForm } from 'hooks/useAssetForm';
import { FormActions } from 'hooks/useTable';
import { useTextEditor } from 'hooks/useTextEditor';

import { SLIDE_FORM, Slide as SlideForm, SlideKey } from '../forms';

interface Props {
  maxPosition: number;
  action: PathParams;
  providedFormValues: SlideForm;
  submitHandler: (slide: SlideForm) => void;
  deleteHandler: (position: number) => void;
  changePositionHandler: (oldPosition: number, newPosition: number) => void;
}

export const useSlideForm = ({
  maxPosition,
  providedFormValues,
  action,
  submitHandler,
  deleteHandler,
  changePositionHandler,
}: Props) => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const { editorState, onEditorStateChange } = useTextEditor();

  const {
    watch: assetWatch,
    deleteAssetLocally,
    uploadAssetLocally,
    formLoading: assetLoading,
    setValue: setAsset,
    reset: resetAsset,
    createAssetHandler,
    getValues: getAssetValues,
  } = useAssetForm({
    action,
    providedFormValues: providedFormValues.asset ?? ASSET_FORM,
    goBackAfterSubmit: false,
    notifyAfterSubmit: false,
  });

  const {
    watch,
    register,
    setValue,
    trigger,
    getValues,
    formState: { errors },
    reset,
  } = useForm<SlideForm>({
    defaultValues: providedFormValues || SLIDE_FORM,
  });

  const { text } = watch();

  const registerValidation = () => {
    register(SlideKey.TITLE, {
      validate: value => (value.length ? true : 'Title required'),
    });
  };

  const slideTypeChangeHandler = (key: SlideKey, value: string) => {
    setValue(key, value);
  };

  const assetSubmitHandler = (asset: AssetFormData) => {
    setValue(SlideKey.ASSET_ID, asset.id as number);
    setAsset(AssetKey.URL, asset.url);
    setAsset(AssetKey.ASSET_TYPE, asset.assetType);
  };

  const assetDeleteHandler = () => {
    setValue(SlideKey.ASSET_ID, null);
    setValue(SlideKey.ASSET, null);
    setAsset(AssetKey.URL, null);
    deleteAssetLocally();
  };

  const assetChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    uploadAssetLocally(e);
    setAsset(AssetKey.DESCRIPTION, getFileName((e.target.files as FileList)?.[0].name) + '_' + v4());
  };

  const parseData = () => {
    const { title, ...values } = { ...getValues() };

    const contentHtml = editorContentToHtml(editorState.getCurrentContent());

    return { ...values, title: title.trim(), text: contentHtml };
  };

  const saveHandler = async () => {
    const isValid = await trigger();
    const slide = parseData();
    const asset = getAssetValues();

    if (!isValid) return;

    if (!slide.assetId && asset.url) {
      const createdAsset = await createAssetHandler();
      if (createdAsset) {
        slide.assetId = createdAsset.id as number;
      }
    }

    submitHandler(slide);
    setIsEditable(false);
  };

  const cancelHandler = () => {
    setIsEditable(false);
    reset(providedFormValues);
    registerValidation();
    resetAsset(providedFormValues.asset || ASSET_FORM);
  };

  const cancelAction = {
    text: 'Cancel',
    action: cancelHandler,
    disabled: assetLoading,
    Icon: CloseIcon,
  };

  const editAction = {
    text: 'Edit',
    action: () => setIsEditable(true),
    Icon: EditIcon,
  };

  const deleteAction = {
    text: 'Delete',
    action: () => deleteHandler(getValues().position),
    Icon: DeleteIcon,
  };

  const saveAction = {
    text: 'Save',
    action: saveHandler,
    loading: assetLoading,
    Icon: SaveIcon,
  };

  const lowPositionAction = {
    text: '',
    action: () => changePositionHandler(providedFormValues.position, providedFormValues.position + 1),
    Icon: KeyboardDownUpIcon,
    disabled: providedFormValues.position === maxPosition,
  };

  const upPositionAction = {
    text: '',
    action: () => changePositionHandler(providedFormValues.position, providedFormValues.position - 1),
    disabled: providedFormValues.position === 1,
    Icon: KeyboardArrowUpIcon,
  };

  const formActions: FormActions = (() => {
    const leftPanelActions = isEditable ? [saveAction, cancelAction] : [editAction, deleteAction];

    return { inline: leftPanelActions, expanded: [lowPositionAction, upPositionAction] };
  })();

  const setValues = (formValues: SlideForm): void => {
    for (const key in formValues) {
      setValue(key as keyof typeof formValues, formValues[key]);
    }
  };

  // set react-draft state
  useEffect(() => {
    if (providedFormValues) {
      const editorState = htmlToEditorContent(text || '');
      onEditorStateChange(editorState);
    }
  }, [text]);

  useEffect(() => {
    registerValidation();
  }, [register]);

  // set the form values passed from parent
  useEffect(() => {
    if (providedFormValues) {
      setValues(providedFormValues);
    }
  }, [providedFormValues]);

  return {
    errors,
    watch,
    assetWatch,
    setValue,
    trigger,
    formActions,
    isEditable,
    slideTypeChangeHandler,
    assetSubmitHandler,
    assetDeleteHandler,
    assetChangeHandler,
    assetLoading,
    getValues,
    editorState,
    onEditorStateChange,
  };
};
