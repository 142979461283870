import { FC } from 'react';

import { Grid, TextFieldProps } from '@mui/material';

import { TextField } from 'components/mui-custom';

export const ID: FC<TextFieldProps> = ({ ...props }) => (
  <Grid sx={{ mt: 1 }} container>
    <Grid item xs={false} sm={6} md={2}>
      <TextField label="ID" {...props} />
    </Grid>
  </Grid>
);
