import { GridColDef } from '@mui/x-data-grid';

import { formatDate } from 'global/formater';

export interface FormData {
  [Key.ID]?: string;
  [Key.UID]?: string;
  [Key.QUESTION]: string;
  [Key.CREATED_AT]?: string;
  [Key.UPDATED_AT]?: string;
  [Key.PUBLISHED_AT]?: string | Date | null;
  [Key.TYPE_NAME]?: string;
}

export enum Key {
  ID = 'id',
  UID = 'uid',
  QUESTION = 'question',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PUBLISHED_AT = 'publishedAt',
  TYPE_NAME = '__typename',
}

export const FORM: FormData = {
  [Key.ID]: '',
  [Key.UID]: undefined,
  [Key.QUESTION]: '',
  [Key.CREATED_AT]: '',
  [Key.UPDATED_AT]: '',
  [Key.PUBLISHED_AT]: '',
  [Key.TYPE_NAME]: undefined,
};

export const COLUMNS: GridColDef[] = [
  { field: Key.UID, headerName: 'ID', flex: 1 },
  { field: Key.QUESTION, headerName: 'Question', flex: 1 },
  {
    field: Key.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
  {
    field: Key.UPDATED_AT,
    headerName: 'Updated Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.updatedAt),
  },
];
