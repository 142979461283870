import { FC } from 'react';

import { NotFound } from 'components/NotFound';
import { PathParams } from 'global/route';

import { Question } from './forms/Create';
import { useGetQuestion } from './hooks/useGetQeustion';

export const Edit: FC = () => {
  const { loading, data } = useGetQuestion();

  if (loading) return <></>;
  else if (data?.question) return <Question action={PathParams.EDIT} values={data.question} />;
  else return <NotFound />;
};
