import { FC, ImgHTMLAttributes } from 'react';

import PhotoIcon from '@mui/icons-material/Photo';
import { Property } from 'csstype';

import { AssetFormData, MediaTypeValue } from 'components/asset';
import { ASPECT_RATIO_FOUR_TO_FOUR, FULL_HEIGHT, FULL_WIDTH, OBJECT_FIT_CONTAIN } from 'constants/styles';

import { AudioView } from './audio';
import { ImageView } from './image';
import './index.scss';
import { VideoView } from './video';

export const PROGRESS_THICKNESS = 1.6;

const ASSET_PREVIEW_CLASS = 'asset-preview';

interface Props {
  asset: AssetFormData;
  src?: ImgHTMLAttributes<HTMLImageElement>['src'];
  controls?: boolean;
  aspectRatio?: Property.AspectRatio;
  objectFit?: Property.ObjectFit;
  padding?: Property.Padding;
  height?: Property.Height;
  width?: Property.Width;
  maxWidth?: Property.MaxWidth;
  showMetaData?: boolean;
}
export const AssetView: FC<Props> = ({
  asset,
  controls = true,
  aspectRatio = ASPECT_RATIO_FOUR_TO_FOUR,
  objectFit = OBJECT_FIT_CONTAIN,
  height = FULL_HEIGHT,
  width = FULL_WIDTH,
  maxWidth,
  showMetaData = true,
}) => (
  <div className={ASSET_PREVIEW_CLASS} style={{ aspectRatio }}>
    {!asset.url && (
      <div className="viewer-wrapper">
        <PhotoIcon htmlColor="#fff" style={{ height: '50%', width: '50%', objectFit }} />
      </div>
    )}
    {asset.assetType === MediaTypeValue.IMAGE && (
      <ImageView asset={asset} style={{ height, width, maxWidth, objectFit }} showMetaData={showMetaData} />
    )}
    {asset.assetType === MediaTypeValue.AUDIO && (
      <AudioView asset={asset} style={{ width, objectFit }} controls={controls} showMetaData={showMetaData} />
    )}
    {asset.assetType === MediaTypeValue.VIDEO && (
      <VideoView asset={asset} style={{ width, objectFit }} controls={controls} showMetaData={showMetaData} />
    )}
  </div>
);
