import { useState } from 'react';

import { AssetsFromStringResult, parseAssetsFromHtmlString } from 'components/react-draft/plugins/asset/service';
import { editorContentToHtml, EditorState } from 'components/react-draft/utils';

export const useTextEditor = () => {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  return {
    editorState,
    onEditorStateChange,
  };
};

type Result = {
  editorStates: EditorState[];
  getEditorSetter: (index: number) => (editorState: EditorState) => void;
  addEditor: (count?: number) => void;
  removeEditor: (index: number) => void;
  parseAllToHtml: (index: number) => void;
  parseAllContent: () => AssetsFromStringResult[];
};

export const useTextEditors = (): Result => {
  const [editorStates, setEditorStates] = useState<EditorState[]>([]);

  const getEditorSetter = (index: number) => (editorState: EditorState) => {
    setEditorStates(prev => {
      const res = [...prev];
      res[index] = editorState;
      return res;
    });
  };

  const removeEditor = (index: number) => {
    setEditorStates(prev => {
      const res = [...prev];
      res.splice(index, 1);
      return res;
    });
  };

  const addEditor = (count: number = 1) => {
    setEditorStates(prev => {
      const statesToAdd: EditorState[] = Array(count).fill(EditorState.createEmpty());

      return [...prev, ...statesToAdd];
    });
  };

  const parseAllToHtml = (): string[] => editorStates.map(state => editorContentToHtml(state.getCurrentContent()));

  const parseAllContent = (): AssetsFromStringResult[] => parseAllToHtml().map(html => parseAssetsFromHtmlString(html));

  return {
    editorStates,
    getEditorSetter,
    addEditor,
    removeEditor,
    parseAllToHtml,
    parseAllContent,
  };
};
