import { gql } from '@apollo/client/core';

export const GET_POSTS = gql`
  query posts($where: JSON, $order: String, $limit: Int, $offset: Int) {
    posts(where: $where, order: $order, limit: $limit, offset: $offset) {
      id
      uid
      name
      section
      estimatedTime
      publishedAt
    }
    postsTotalCount(where: $where)
  }
`;

export const GET_POST = gql`
  query post($id: Int!) {
    post(id: $id) {
      id
      uid
      name
      section
      estimatedTime
      publishedAt
      thumbnailId
      thumbnail {
        id
        assetType
        url
        description
        createdAt
        updatedAt
      }
      updatedAt
      createdAt
      tags {
        id
        name
      }
      packages {
        id
        uid
        title
        publishedAt
      }
      postPages {
        contentAssets {
          id
          assetType
          url
          description
          createdAt
          updatedAt
        }
        id
        order
        title
        mediaType
        bannerImageId
        bannerImage {
          id
          assetType
          url
          description
          createdAt
          updatedAt
        }
        mediaLink
        content
      }
    }
  }
`;

export const GET_TAGS = gql`
  query tags($order: String, $offset: Int, $limit: Int, $where: JSON) {
    tags(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
      name
    }
  }
`;

export const GET_ASSETS = gql`
  query assets($order: String, $offset: Int, $limit: Int, $where: JSON) {
    assets(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
      assetType
      url
      description
      createdAt
    }
    assetsTotalCount(where: $where)
  }
`;

export const GET_ASSET = gql`
  query asset($id: Int!) {
    asset(id: $id) {
      id
      assetType
      url
      key
      description
      createdAt
      updatedAt
      posts {
        uid
        section
        name
        estimatedTime
        publishedAt
      }
    }
  }
`;

export const GET_MOMENTS = gql`
  query moments($order: String, $offset: Int, $limit: Int, $where: JSON) {
    moments(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
      title
      uid
      durationMs
      introduction
      publishedAt
      createdAt
    }
    momentsTotalCount(where: $where)
  }
`;

export const GET_MOMENT = gql`
  query moment($id: Int!) {
    moment(id: $id) {
      id
      uid
      title
      durationMs
      introduction
      optionalForUser
      thumbnailId
      tags {
        id
        name
      }
      tags {
        id
        name
      }
      packages {
        id
        uid
        title
        publishedAt
      }
      prompts {
        id
        content
      }
      thumbnail {
        id
        assetType
        url
        description
        createdAt
        updatedAt
      }
      publishedAt
      createdAt
      updatedAt
    }
  }
`;

export const GET_QUESTIONS = gql`
  query questions($order: String, $offset: Int, $limit: Int, $where: JSON) {
    questions(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
      uid
      content
      choices
      publishedAt
      createdAt
      updatedAt
    }
    questionsTotalCount(where: $where)
  }
`;

export const GET_QUESTION = gql`
  query question($id: Int!) {
    question(id: $id) {
      id
      uid
      content
      choices
      reviews {
        id
        uid
        title
        publishedAt
        createdAt
        updatedAt
      }
      publishedAt
      createdAt
      updatedAt
    }
  }
`;

export const GET_DAILY_PRACTICES = gql`
  query dailyPractices($order: String, $offset: Int, $limit: Int, $where: JSON) {
    dailyPractices(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
      uid
      action
      publishedAt
      createdAt
      updatedAt
    }
    dailyPracticesTotalCount(where: $where)
  }
`;

export const GET_DAILY_PRACTICE = gql`
  query dailyPractice($id: Int!) {
    dailyPractice(id: $id) {
      id
      uid
      action
      packages {
        id
        uid
        title
        publishedAt
      }
      publishedAt
      createdAt
      updatedAt
    }
  }
`;

export const GET_REVIEWS = gql`
  query reviews($order: String, $offset: Int, $limit: Int, $where: JSON) {
    reviews(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
      uid
      title
      preamble
      durationMs
      publishedAt
      createdAt
      updatedAt
    }
    reviewsTotalCount(where: $where)
  }
`;

export const GET_REVIEW = gql`
  query review($id: Int!) {
    review(id: $id) {
      id
      uid
      title
      preamble
      durationMs
      thumbnailId
      optionalForUser
      questions {
        id
        uid
        content
        choices
        publishedAt
        createdAt
        updatedAt
        position
      }
      tags {
        id
        name
      }
      packages {
        id
        uid
        title
        publishedAt
      }
      thumbnail {
        id
        assetType
        url
        description
        createdAt
        updatedAt
      }
      publishedAt
      createdAt
      updatedAt
    }
  }
`;

export const GET_PACKAGES = gql`
  query packages($order: String, $offset: Int, $limit: Int, $where: JSON) {
    packages(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
      uid
      title
      publishedAt
      createdAt
      updatedAt
    }
    packagesTotalCount(where: $where)
  }
`;

export const GET_PACKAGE = gql`
  query package($id: Int!) {
    package(id: $id) {
      id
      uid
      title
      description
      thumbnailId
      tags {
        id
        name
      }
      packageItems {
        position
        uid
        name
        publishedAt
        itemId
        itemType
        position
        durationMs
      }
      thumbnail {
        id
        assetType
        url
        description
        createdAt
        updatedAt
      }
      publishedAt
      createdAt
      updatedAt
    }
  }
`;

export const GET_CONTENT_LIST = gql`
  query content($where: JSON) {
    posts(where: $where) {
      id
      name
      section
      publishedAt
      uid
    }
    moments(where: $where) {
      id
      prompts {
        content
      }
      publishedAt
      uid
    }
    reviews(where: $where) {
      id
      title
      publishedAt
      uid
    }
    dailyPractices(where: $where) {
      id
      action
      publishedAt
      uid
    }
  }
`;

export const GET_SEQUENCES = gql`
  query packageSequences($order: String, $offset: Int, $limit: Int, $where: JSON) {
    packageSequences(order: $order, offset: $offset, limit: $limit, where: $where) {
      id
      uid
      title
      packageNumber
      publishedAt
      createdAt
      updatedAt
    }
    packageSequencesTotalCount(where: $where)
  }
`;

export const GET_SEQUENCE = gql`
  query packageSequence($id: Int!) {
    packageSequence(id: $id) {
      id
      uid
      title
      description
      nextPackageSequence {
        id
        uid
        title
      }
      packages {
        id
        uid
        title
        packageNumber
        publishedAt
        updatedAt
      }
      tags {
        id
        name
      }
      publishedAt
      createdAt
      updatedAt
    }
  }
`;

export const GET_WISDOM_ENGINE_PROMPTS = gql`
  query wisdomEnginePrompts($where: JSON, $order: String, $limit: Int, $offset: Int) {
    wisdomEnginePrompts(where: $where, order: $order, limit: $limit, offset: $offset) {
      id
      uid
      question
      publishedAt
      updatedAt
    }
    wisdomEnginePromptsTotalCount(where: $where)
  }
`;

export const GET_WISDOM_ENGINE_PROMPT = gql`
  query wisdomEnginePrompt($id: Int!) {
    wisdomEnginePrompt(id: $id) {
      id
      uid
      question
      publishedAt
      createdAt
      updatedAt
    }
  }
`;

export const GET_LAB_USERS = gql`
  query users($where: JSON, $order: String, $limit: Int, $offset: Int) {
    users(where: $where, order: $order, limit: $limit, offset: $offset) {
      id
      uid
      email
      createdAt
      updatedAt
    }
    usersTotalCount(where: $where)
  }
`;

export const GET_LAB_USER = gql`
  query user($id: Int!) {
    user(id: $id) {
      id
      uid
      email
      userType {
        id
        name
      }
      experiments {
        id
        title
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_EXPERIMENT = gql`
  query experiment($id: Int!) {
    experiment(id: $id) {
      id
      uid
      status
      title
      type
      frequency
      packageSequenceId
      showInsights
      showAutoSummaryInsights
      showArtworkInsights
      showMusicInsights
      showAnimationInsights
      showPersonalityInsights
      showSentimentInsights
      showSpeakingInsights
      showWordFrequencyInsights
      showQrCode
      showEmailPage
      showOneVideo
      users {
        id
        uid
        email
        externalUserId
        externalUserType
        createdAt
        updatedAt
      }
      completionCode
      startDate
      endDate
      createdBy {
        email
      }
      publishedAt
      createdAt
      updatedAt
      redirectUrl
      isDisplayBranding
      showThankYouPage
      showMomentSaved
      showThankYouPageNavigation
      isEndSessionFunctionalityEnabled
    }
  }
`;

export const GET_EXPERIMENTS = gql`
  query experiments($where: JSON, $order: String, $limit: Int, $offset: Int) {
    experiments(where: $where, order: $order, limit: $limit, offset: $offset) {
      id
      uid
      status
      title
      type
      frequency
      packageSequenceId
      startDate
      endDate
      createdBy {
        email
      }
      publishedAt
      createdAt
      updatedAt
    }
    experimentsTotalCount(where: $where)
  }
`;

export const GET_CAROUSELS = gql`
  query carousels($where: JSON, $order: String, $limit: Int, $offset: Int) {
    carousels(where: $where, order: $order, limit: $limit, offset: $offset) {
      id
      name
      description
      location {
        id
        name
      }
      createdAt
      updatedAt
      publishedAt
    }
    carouselsTotalCount(where: $where)
  }
`;

export const GET_CAROUSEL = gql`
  query carousel($id: Int!) {
    carousel(id: $id) {
      id
      name
      description
      slides {
        id
        title
        type
        text
        assetId
        position
        asset {
          id
          assetType
          url
        }
        carouselId
      }
      locationId
      publishedAt
      createdAt
      updatedAt
    }
  }
`;

export const GET_CAROUSEL_LOCATIONS = gql`
  query carouselLocations($currentLocationId: Int) {
    carouselLocations(currentLocationId: $currentLocationId) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const GET_EXPERIMENT_TYPES = gql`
  query experimentTypes {
    experimentTypes {
      id
      name
    }
  }
`;

export const GET_USER_TYPES = gql`
  query userTypes($where: JSON, $order: String, $limit: Int, $offset: Int) {
    userTypes(where: $where, order: $order, limit: $limit, offset: $offset) {
      id
      name
    }
  }
`;

export const GET_BREADCRUMBS = gql`
  query breadcrumbs($entitiesList: [BreadcrumbsInput]!) {
    breadcrumbs(entitiesList: $entitiesList) {
      entityName
      labelText
    }
  }
`;
