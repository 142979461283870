import { FC } from 'react';

import { NotFound } from 'components/NotFound';
import { PathParams } from 'global/route';

import { DailyPractice } from './forms/Create';
import { useGetDailyPractice } from './hooks/useGetDailyPractice';

export const Edit: FC = () => {
  const { loading, data } = useGetDailyPractice();

  if (loading) return <></>;
  else if (data?.dailyPractice) return <DailyPractice action={PathParams.EDIT} values={data.dailyPractice} />;
  else return <NotFound />;
};
