import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { NotFound } from 'components/NotFound';
import { stringToInt } from 'global/formater';
import { PathParams } from 'global/route';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_CONTENT_LIST, GET_PACKAGE } from 'graphql/queries';

import { Package } from './Create';

import { PACKAGE_FORM, PackageFormData } from '.';

interface Props {
  action: PathParams;
}
export const SaveForm: FC<Props> = ({ action }) => {
  const { id } = useParams();

  const { data, loading } = useQuery<{ package: PackageFormData }>(GET_PACKAGE, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: id ? stringToInt(id as string) : null,
    },
    skip: action === PathParams.CREATE || !id,
  });
  const { data: contentList, loading: contentLoading } = useQuery(GET_CONTENT_LIST, {
    skip: action === PathParams.SHOW,
  });

  if (loading || contentLoading) return <></>;
  else if (data?.package && action === PathParams.SHOW) return <Package action={action} values={data.package} />;
  else if (action === PathParams.CREATE) return <Package action={action} values={{ ...PACKAGE_FORM, contentList }} />;
  else if (data?.package && action === PathParams.EDIT)
    return <Package action={action} values={{ ...data.package, contentList }} />;
  else return <NotFound />;
};
