import { FC } from 'react';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Button, Typography } from '@mui/material';

import './index.scss';

interface Props {
  signIn: () => void;
  loaded: boolean;
}

export const SignInPage: FC<Props> = ({ signIn, loaded }) => (
  <Box className="sign-in-box">
    <Avatar>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
      Sign in
    </Typography>
    <Box className="action-box">
      <Button
        onClick={signIn}
        disabled={!loaded}
        className="sign-in-button"
        type="submit"
        fullWidth
        variant="contained"
      >
        Sign In With Google
      </Button>
    </Box>
  </Box>
);
