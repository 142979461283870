import { FC } from 'react';

import { Popper, PopperProps } from '@mui/material';

interface TagsPopperProps extends PopperProps {
  className?: string;
}

export const TagsPopper: FC<TagsPopperProps> = ({ className, ...props }) => (
  <Popper
    {...props}
    className={`${className} tags-autocomplete-popper`}
    style={{ width: 'fit-content' }}
    placement="auto"
  />
);

export default TagsPopper;
