import { useEffect, useMemo, useState } from 'react';
import { HelperText } from '../constants';
import { UseFilterValidationProps } from '../types';

export const useFilterValidation = ({
  filterString,
  attributes,
  rowCount,
  isFilterPressed,
}: UseFilterValidationProps) => {
  const [isValid, setIsValid] = useState({
    search: true,
    attributes: true,
    data: true,
  });

  const helperText = useMemo(
    () => ({
      search: isValid.data ? HelperText.SEARCH_EMPTY : HelperText.SEARCH_NOT_FOUND,
      attributes: isValid.data ? HelperText.ATTRIBUTES_EMPTY : HelperText.ATTRIBUTES_NOT_FOUND,
    }),
    [isValid.data],
  );

  const validateFields = () => {
    if (!filterString && !attributes) {
      setIsValid(prev => ({ ...prev, search: true, attributes: true }));
      return;
    }

    if (!attributes) {
      setIsValid(prev => ({ ...prev, attributes: false }));
    }

    if (isValid.search && isValid.attributes && rowCount === 0) {
      setIsValid(() => ({ data: false, search: false, attributes: false }));
    }
  };

  useEffect(() => {
    if (isFilterPressed) {
      validateFields();
    }
  }, [isFilterPressed, rowCount]);

  return {
    helperText,
    isValid,
  };
};
