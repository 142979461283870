import { GraphQLError } from 'graphql';
import { PROVIDER_ID } from 'hooks/useGoogleAuth';

type ApiErrorHandler = (error?: GraphQLError) => void;

const SIGN_OUT_RELOAD_TIMEOUT_MS = 1600;

export const notAuthorizedHandler: ApiErrorHandler = () => {
  setTimeout(() => {
    localStorage.removeItem(PROVIDER_ID);
    location.reload();
  }, SIGN_OUT_RELOAD_TIMEOUT_MS);
};
