import { FC } from 'react';

import { RoutePath } from 'app/routes';

import { GET_REVIEWS } from 'graphql/queries';
import { getShowPagePath } from 'hooks/useTable';

import { REVIEW_SECTION_COLUMNS } from './forms/index';
import { ReviewTable } from './tables/Review';

export const List: FC = () => {
  return (
    <ReviewTable
      query={GET_REVIEWS}
      columns={REVIEW_SECTION_COLUMNS}
      onGetLink={({ row }) => getShowPagePath(RoutePath.REVIEWS, String(row.uid))}
    />
  );
};
