import { FC, useEffect, useState, DragEvent } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DragIndicator from '@mui/icons-material/DragIndicator';
import { Box, Button, Card, CardContent, Tab, Tabs } from '@mui/material';

import { GridItemView } from 'components/common';
import { DateInput, EstimatedTime, ID, Tags, Title } from 'components/inputs';
import { ActionButtons } from 'components/inputs/ActionButtons';
import { ImageInput, Section as SectionSelect } from 'components/post/inputs';
import { ViewTable } from 'components/tables/view-table';
import { MAX_POST_PAGE_COUNT } from 'constants/post';
import { setLabel } from 'global/form';
import { PathParams } from 'global/route';
import { PACKAGE_COLUMNS } from 'global/type';

import { PostPageForm } from './PostPageForm';

import { getEmptyPostPageForm, getPostPageKey, PostFormData, PostKey, PostPageKey, PostSectionValue } from '.';
import { isTagsValid } from 'utils';
import { usePostForm } from './hooks';

interface Props {
  action: PathParams;
  providedFormValues?: PostFormData;
  section?: PostSectionValue;
}

export const Post: FC<Props> = ({ providedFormValues, section: formSection, action }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const postForm = usePostForm({
    providedFormValues: { ...providedFormValues, section: providedFormValues?.section || formSection } as PostFormData,
    action,
    activePostPageIndex: activeTab,
  });
  const {
    textEditors,
    watch,
    setTags,
    formActions,
    setValue,
    trigger,
    errors,
    disabled,
    watchThumbnail,
    thumbnailSubmitHandler,
    thumbnailDeleteHandler,
    thumbnailChangeHandler,
    formLoading,
  } = postForm;

  const { url: thumbnailUrl } = watchThumbnail();

  const { tags, section, name, estimatedTime, thumbnail, uid, packages, updatedAt, createdAt, postPages } = watch();

  const handleTabChange = (_event, newValue: number) => {
    setActiveTab(newValue);
  };

  const [tabsCount, setTabsCount] = useState<number>(postPages?.length ?? 0);

  useEffect(() => setTabsCount(postPages?.length ?? 0), [postPages?.length]);

  const addPageHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    postPages?.push(getEmptyPostPageForm());
    setTabsCount(postPages?.length ?? 0);
    if (!postPages) {
      setActiveTab(-1);
    }
    setActiveTab((postPages && postPages.length - 1) ?? 0);
  };

  const deletePageHandler = () => {
    if (postPages) {
      const newTab = activeTab === 0 ? 0 : activeTab - 1;
      postPages.splice(activeTab, 1);
      textEditors.removeEditor(activeTab);
      setTabsCount(postPages.length);
      setActiveTab(newTab);
    }
  };

  const onDragStart = (e: DragEvent<HTMLDivElement>, startIndex: number) => {
    e.dataTransfer.setData('startIndex', String(startIndex));
  };

  const onDrop = (e: DragEvent<HTMLDivElement>, endIndex: number) => {
    const startIndex = Number(e.dataTransfer.getData('startIndex'));

    if (postPages) {
      const element = postPages[startIndex];

      postPages.splice(startIndex, 1); // remove the element from the array
      postPages.splice(endIndex, 0, element); // insert the element at the new position

      // change order value of the elements
      postPages.forEach((_, index) => {
        setValue(getPostPageKey(PostPageKey.ORDER, index), index as never);
      });

      setActiveTab(endIndex);
    }
  };

  return (
    <Card>
      <CardContent sx={{ mb: '35px' }}>
        {uid && <ID value={uid} disabled />}
        <SectionSelect
          value={section}
          label={setLabel('Section', action)}
          onChange={e => setValue(PostKey.SECTION, e.target.value as PostSectionValue)}
          onBlur={() => trigger(PostKey.SECTION)}
          formHelperTextProps={{ text: errors?.section?.message, error: true }}
          error={Boolean(errors.section)}
          disabled={disabled || Boolean(formSection) || formLoading}
        />
        <Title
          value={name}
          label={setLabel('Name', action)}
          onChange={e => {
            setValue(PostKey.NAME, e.target.value);
          }}
          onBlur={() => trigger(PostKey.NAME)}
          helperText={errors?.name?.message}
          error={Boolean(errors.name)}
          disabled={disabled || formLoading}
        />
        <ImageInput
          onSubmit={thumbnailSubmitHandler}
          onDelete={thumbnailDeleteHandler}
          onChange={thumbnailChangeHandler}
          url={thumbnail?.url || thumbnailUrl}
          imagePreviewProps={{ label: 'Thumbnail' }}
          disabled={disabled || formLoading}
        />
        <GridItemView
          style={{ marginTop: 12 }}
          label="Tag"
          item={<Tags tags={isTagsValid(tags) ? tags : []} setTags={setTags} disabled={disabled} />}
        />
        <EstimatedTime
          value={estimatedTime ?? 0}
          label={setLabel('Estimated time', action)}
          onChange={e => setValue(PostKey.ESTIMATED_TIME, Math.abs(Number(e.target.value)) || '')}
          onBlur={() => trigger(PostKey.ESTIMATED_TIME)}
          helperText={errors.estimatedTime?.message}
          error={Boolean(errors.estimatedTime)}
          disabled={disabled || formLoading}
        />
        {disabled && <DateInput sx={{ mt: 2 }} label="Updated" value={updatedAt} disabled />}
        {disabled && <DateInput sx={{ mt: 2 }} label="Created" value={createdAt} disabled />}
        {disabled && <ViewTable sx={{ mt: 2 }} rows={packages ?? []} columns={PACKAGE_COLUMNS} label={'Packages:'} />}
      </CardContent>

      <Card>
        <CardContent sx={{ mb: '35px' }}>
          <Box sx={{ display: 'flex' }}>
            {!disabled && (
              <Button
                sx={{ width: '120px' }}
                disabled={!!postPages && postPages?.length >= MAX_POST_PAGE_COUNT}
                onClick={addPageHandler}
              >
                <AddIcon /> Add page
              </Button>
            )}
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {postPages?.map((postPage, index) => (
                <Tab
                  icon={disabled ? undefined : <DragIndicator />}
                  iconPosition="start"
                  key={`${postPage.id}${postPage.uid}`}
                  label={`PAGE ${index + 1}`}
                  draggable={!disabled}
                  onDragStart={e => onDragStart(e, index)}
                  onDragOver={e => e.preventDefault()}
                  onDrop={e => onDrop(e, index)}
                />
              ))}
            </Tabs>
          </Box>
          {postPages?.length &&
            postPages.map((postPage, index) => (
              <PostPageForm
                postForm={postForm}
                action={action}
                deletePageHandler={deletePageHandler}
                disabled={disabled}
                display={index === activeTab ? 'inherit' : 'none'}
                index={index}
                postPage={postPage}
                key={`${postPage.id}${postPage.uid}`}
                providedFormValues={providedFormValues}
                isPostPageError={Boolean(errors?.postPages)}
              />
            ))}
        </CardContent>
      </Card>
      <ActionButtons actions={formActions} />
    </Card>
  );
};
