export const htmlStringToElement = (html: string): Element => {
  const template = document.createElement('template');
  template.innerHTML = html.trim();
  const element = template.content.children[0];

  if (element?.nodeType === Node.ELEMENT_NODE) {
    return element;
  }

  throw new Error('Not valid HTML');
};
