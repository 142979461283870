import { capitalize, formatDate } from 'global/formater';

interface Config {
  /**
   * @param capitalizeKey whether to capitalize the first letter of the key (left table column). True by default
   */
  capitalizeKey?: boolean;
  /**
   * @param mapper object with "source object key(s)" and "configuration object" pairs
   */
  mapper?: Mapper;
}

interface Mapper {
  [key: string]: RowConfig;
}

interface Row {
  /**
   * @param key in the left table column
   */
  key: string;
  /**
   * @param value in the right table column
   */
  value: any;
  /**
   * @param id used as a key in "@mui/x-data-grid" table
   */
  id: number;
}

interface RowConfig {
  /**
   * @param exclude whether to exclude the key from rows
   */
  exclude?: boolean;
  /**
   * @param formatKey callback to transform key (left column)
   */
  formatKey?: (key: string) => string;
  /**
   * @param formatValue callback to transform value (right column)
   */
  formatValue?: (value: string | number) => string;
}

const DEFAULT_MAPPER: Mapper = {
  __typename: { exclude: true },
  createdAt: {
    formatKey: () => 'Created date',
    formatValue: date => formatDate(String(date)),
  },
  updatedAt: {
    formatKey: () => 'Updated date',
    formatValue: date => formatDate(String(date)),
  },
  publishedAt: {
    formatKey: () => 'Published date',
    formatValue: date => formatDate(String(date)),
  },
};

/**
 * Build array of rows based on the object structure for "@mui/x-data-grid" table
 * @param object to parse
 * @param config parsing configuration
 * @returns array of rows
 */
export const buildRowsFrom = (object: object, config?: Config): Row[] => {
  const conf: Config = {
    capitalizeKey: config?.capitalizeKey ?? true,
    mapper: {
      ...DEFAULT_MAPPER,
      ...config?.mapper,
    },
  };

  const mapper = conf.mapper;

  return Object.entries(object)
    .filter(([key]) => !mapper?.[key]?.exclude)
    .map(([key, value], id) => {
      const formatKey = mapper?.[key]?.formatKey;
      const formatValue = mapper?.[key]?.formatValue;

      conf.capitalizeKey && (key = capitalize(key));

      formatKey && (key = formatKey(key));
      formatValue && (value = formatValue(String(value)));

      return { key, value, id };
    });
};
