import { GridColDef } from '@mui/x-data-grid';

import { formatDate } from 'global/formater';
import { Tag } from 'global/type';
import { Package } from 'pages/package/forms';

export enum SequenceKey {
  ID = 'id',
  UID = 'uid',
  TAGS = 'tags',
  TITLE = 'title',
  DESCRIPTION = 'description',
  PACKAGE_NUMBER = 'packageNumber',
  PATIENTS_NUMBER = 'patientsNumber',
  NEXT_SEQUENCE = 'nextPackageSequence',
  PACKAGES = 'packages',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PUBLISHED_AT = 'publishedAt',
  TYPE_NAME = '__typename',
}

export interface SequenceFormData {
  [SequenceKey.ID]?: string;
  [SequenceKey.TITLE]: string;
  [SequenceKey.TAGS]: Tag[] | number[];
  [SequenceKey.DESCRIPTION]: string;
  [SequenceKey.PATIENTS_NUMBER]?: number;
  [SequenceKey.PACKAGE_NUMBER]?: number;
  [SequenceKey.NEXT_SEQUENCE]?: Sequence | string | null;
  [SequenceKey.PACKAGES]: Package[] | SequencePackageInput[];
  [SequenceKey.CREATED_AT]?: string;
  [SequenceKey.UPDATED_AT]?: string;
  [SequenceKey.PUBLISHED_AT]?: string | Date | null;
  [SequenceKey.UID]?: string;
  [SequenceKey.TYPE_NAME]?: string;
}

export type Sequence = Omit<SequenceFormData, SequenceKey.PACKAGES | SequenceKey.NEXT_SEQUENCE>;

export const SEQUENCE_FORM = {
  [SequenceKey.ID]: '',
  [SequenceKey.TITLE]: '',
  [SequenceKey.TAGS]: [],
  [SequenceKey.DESCRIPTION]: '',
  [SequenceKey.PACKAGE_NUMBER]: 0,
  [SequenceKey.PATIENTS_NUMBER]: 0,
  [SequenceKey.NEXT_SEQUENCE]: '',
  [SequenceKey.PACKAGES]: [],
  [SequenceKey.CREATED_AT]: '',
  [SequenceKey.UPDATED_AT]: '',
  [SequenceKey.PUBLISHED_AT]: '',
  [SequenceKey.UID]: '',
  [SequenceKey.TYPE_NAME]: undefined,
};

export const SEQUENCE_SECTION_COLUMNS: GridColDef[] = [
  { field: SequenceKey.UID, headerName: 'ID', flex: 1 },
  { field: SequenceKey.TITLE, headerName: 'Title', flex: 1 },
  { field: SequenceKey.PACKAGE_NUMBER, headerName: 'Packages', flex: 1 },
  {
    field: SequenceKey.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
  {
    field: SequenceKey.UPDATED_AT,
    headerName: 'Updated Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.updatedAt),
  },
];

export type SequencePackageInput = {
  id: number;
  packageNumber: number;
};
