import { ChangeEvent, FC, SyntheticEvent, useEffect, useRef, useState } from 'react';

import { Autocomplete, Button, TextField } from '@mui/material';
import { FilterProps } from './types';
import { KeyboardKeys } from './constants';
import { useFilterValidation } from './hooks';

export const Filter: FC<FilterProps> = ({
  filterHandler,
  resetHandler,
  isFiltered,
  setAttributes,
  filterString,
  setFilterString,
  attributes,
  options,
  rowCount,
  isFilterPressed,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [filterInput, setFilterInput] = useState<string>(filterString);

  const { isValid, helperText } = useFilterValidation({
    attributes,
    filterString: filterInput,
    rowCount,
    isFilterPressed,
  });

  const attributeChangeHandler = (_: SyntheticEvent<Element, Event>, value: string | null) => {
    setFilterString(filterInput);
    setAttributes(value ?? '');
  };

  const changeValueHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterInput(e.target.value);
  };

  const filterByFieldHandler = () => {
    filterHandler(filterInput);
  };

  const resetFilterHandler = () => {
    setFilterInput('');
    setFilterString('');
    setAttributes('');
    if (isFiltered) {
      resetHandler();
    }
  };

  const isFilterDisabled = !filterInput || !attributes;
  const isResetFilterDisabled = !filterInput && !attributes && !isFiltered;

  const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === KeyboardKeys.ENTER && !isFilterDisabled) {
      filterByFieldHandler();
    }
  };

  useEffect(() => {
    if (filterInput) {
      const scrollPosition = window.scrollY;

      inputRef.current?.focus();

      window.scrollTo(0, scrollPosition);
    }
  }, []);

  return (
    <div tabIndex={0} className="entity-table_filter-panel">
      <TextField
        inputRef={inputRef}
        value={filterInput}
        error={!isValid.search}
        helperText={!isValid.search ? helperText.search : ' '}
        onChange={changeValueHandler}
        onBlur={changeValueHandler}
        onKeyDown={keyDownHandler}
        style={{ width: '300px' }}
        variant="outlined"
        label="Search"
        InputLabelProps={{
          shrink: true,
        }}
      />
      &nbsp;&nbsp;&nbsp;
      <Autocomplete
        value={attributes || null}
        options={options}
        onChange={attributeChangeHandler}
        onKeyDown={keyDownHandler}
        style={{ width: '320px' }}
        renderInput={params => (
          <TextField
            {...params}
            error={!isValid.attributes}
            helperText={!isValid.attributes ? helperText.attributes : ' '}
            label="Attribute"
            variant="outlined"
          />
        )}
      />
      <Button
        onClick={filterByFieldHandler}
        style={{ marginLeft: 30 }}
        variant="outlined"
        size="small"
        disabled={isFilterDisabled}
      >
        FILTER
      </Button>
      <Button
        onClick={resetFilterHandler}
        style={{ marginLeft: 15 }}
        variant="outlined"
        size="small"
        type="reset"
        disabled={isResetFilterDisabled}
      >
        RESET
      </Button>
    </div>
  );
};

export type { FilterProps } from './types';
