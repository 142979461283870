import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutePath } from 'app/routes';

import { getWebClient } from 'graphql/apollo-client';
import { apiErrorHandler } from 'graphql/error-handler';
import { GET_WISDOM_ENGINE_PROMPTS } from 'graphql/queries';
import { useSnackbar } from 'hooks/useSnackbar';
import { getShowPagePath } from 'hooks/useTable';

import { COLUMNS } from './forms/index';
import { WisdomEnginePromptsTable } from './tables/WisdomEnginePromptsTable';

export const List: FC = () => {
  const navigate = useNavigate();
  const { showErrorToast } = useSnackbar();
  const client = getWebClient({
    errorHandler: errorResponse =>
      apiErrorHandler({
        notify: showErrorToast,
        error: errorResponse,
      }),
  });
  return (
    <WisdomEnginePromptsTable
      query={GET_WISDOM_ENGINE_PROMPTS}
      client={client}
      columns={COLUMNS}
      onRowClick={({ row }) => navigate(getShowPagePath(RoutePath.WISDOM_ENGINE_PROMPTS, String(row.uid)))}
    />
  );
};
