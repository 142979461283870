import { FC } from 'react';

import { RoutePath } from 'app/routes';

import { GET_DAILY_PRACTICES } from 'graphql/queries';
import { getShowPagePath } from 'hooks/useTable';
import { DailyPracticeTable } from 'pages/daily-practice/tables/DailyPractice';

import { DAILY_PRACTICE_SECTION_COLUMNS } from './forms';

export const List: FC = () => {
  return (
    <DailyPracticeTable
      query={GET_DAILY_PRACTICES}
      columns={DAILY_PRACTICE_SECTION_COLUMNS}
      onGetLink={({ row }) => getShowPagePath(RoutePath.DAILY_PRACTICES, String(row.uid))}
    />
  );
};
