import { FC } from 'react';

import { StandardTextFieldProps } from '@material-ui/core';
import { Grid } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';

import { DEFAULT_GRID_ITEM_PROPS } from 'global/form';
import { PathParams } from 'global/route';

import { Key as ExperimentKey } from '../forms';
import { UseExperimentForm } from '../hooks/useExperimentForm';

interface Props extends StandardTextFieldProps {
  hook: UseExperimentForm;
  action: PathParams;
}

export const ExperimentStartDate: FC<Props> = ({
  hook: { watch, setStartDate, errors, readOnly, disableInputs },
  action,
}) => {
  const { startDate, type } = watch();

  return (
    <>
      {startDate || action !== PathParams.SHOW ? (
        <Grid {...DEFAULT_GRID_ITEM_PROPS}>
          <DateTimePicker
            label="Start Date"
            value={startDate ? new Date(startDate) : null}
            onChange={setStartDate}
            disabled={!type || readOnly || disableInputs(ExperimentKey.START_DATE, type)}
            disablePast
            slotProps={{
              textField: {
                error: Boolean(errors.startDate),
                helperText: errors?.startDate?.message,
              },
            }}
          />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
