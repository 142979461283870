import { useEffect } from 'react';

import { v4 } from 'uuid';

import { PathParams } from 'global/route';
import {
  CHANGE_CAROUSELS_PUBLISH_STATUSES,
  CREATE_CAROUSEL,
  DESTROY_CAROUSEL,
  UPDATE_CAROUSEL,
} from 'graphql/mutations';
import { GET_CAROUSELS } from 'graphql/queries';
import { useItemForm } from 'hooks/useItemForm';
import { FormActions } from 'hooks/useTable';
import { validateTextField } from 'plugins/validator';
import { CAROUSEL_FORM, CarouselFormData, CarouselKey, Slide, SLIDE_FORM, SlideKey } from '../forms';

interface Props {
  action: PathParams;
  providedFormValues: CarouselFormData;
}

export const useCarouselForm = ({ providedFormValues, action }: Props) => {
  const parseData = (values: CarouselFormData) => {
    const { updatedAt, createdAt, slides, __typename, ...formValues } = values;

    const parsedSlides = slides.map(slide => {
      const { asset, __typename, virtualKey, ...slideValues } = slide;
      return slideValues;
    });

    return { ...formValues, slides: parsedSlides };
  };

  const { formActions, formErrors, getValues, register, setFormValues, setValue, trigger, watch } =
    useItemForm<CarouselFormData>({
      action,
      defaultValues: CAROUSEL_FORM,
      itemDisplayName: 'Carousel',
      createMutation: CREATE_CAROUSEL,
      updateMutation: UPDATE_CAROUSEL,
      destroyMutation: DESTROY_CAROUSEL,
      changePublishStatusMutation: CHANGE_CAROUSELS_PUBLISH_STATUSES,
      getItemsQuery: GET_CAROUSELS,
      parseData,
    });

  const { slides } = watch();

  const disabled = action === PathParams.SHOW;

  const registerValidation = () => {
    register(CarouselKey.NAME, {
      validate: value => validateTextField(value, 'Name'),
    });
    register(CarouselKey.LOCATION_ID, {
      validate: value => (value ? true : 'Location required'),
    });

    register(CarouselKey.SLIDES, {
      validate: value => (value.length > 0 ? true : 'Carousel should have at least one slide'),
    });
  };

  const sortSlidesByPosition = (slides: Slide[]) => slides.sort((slideA, slideB) => slideA.position - slideB.position);

  const createSlideHandler = () => {
    const { slides } = getValues();
    setValue(CarouselKey.SLIDES, [
      ...slides,
      { ...SLIDE_FORM, [SlideKey.VIRTUAL_KEY]: v4(), position: slides.length + 1 },
    ]);
  };

  const updateSlideHandler = (slide: Slide) => {
    const { slides } = getValues();
    slides[slide.position - 1]! = { ...slide };
    setValue(CarouselKey.SLIDES, slides);
  };

  const changeSlidePositionHandler = (oldPosition: number, newPosition: number) => {
    const { slides } = getValues();
    slides[oldPosition - 1] = { ...slides[oldPosition - 1], position: newPosition };
    slides[newPosition - 1] = { ...slides[newPosition - 1], position: oldPosition };
    setValue(CarouselKey.SLIDES, sortSlidesByPosition(slides));
  };

  const deleteSlideHandler = (position: number) => {
    const slides = getValues().slides.reduce((arr: Slide[], slide) => {
      if (slide.position < position) {
        return [...arr, slide];
      }
      if (slide.position > position) {
        return [...arr, { ...slide, position: slide.position - 1 }];
      }
      return arr;
    }, []);
    setValue(CarouselKey.SLIDES, [...slides]);
  };

  const createSlideAction = {
    text: 'Add slide',
    action: createSlideHandler,
    disabled: slides.length > 9,
  };

  const allFormActions: FormActions =
    action === PathParams.SHOW ? formActions : { inline: [...(formActions.inline ?? []), createSlideAction] };

  useEffect(() => {
    registerValidation();
  }, [register]);

  // set the form values passed from parent
  useEffect(() => {
    if (providedFormValues) {
      setFormValues({
        ...providedFormValues,
        slides: providedFormValues.slides.map(slide => ({ ...slide, virtualKey: v4() })),
      });
    }
  }, []);

  return {
    errors: formErrors,
    watch,
    setValue,
    trigger,
    formActions: allFormActions,
    disabled,
    updateSlideHandler,
    deleteSlideHandler,
    changeSlidePositionHandler,
  };
};
