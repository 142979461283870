import { FC } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider as NativeSnackbarProvider, SnackbarProviderProps } from 'notistack';

import { MAX_STACK_SIZE } from 'hooks/useSnackbar';

import { CustomToast } from './customToast';
import { CustomVariants } from './types';

const snackBarTheme = createTheme({
  palette: { mode: 'dark' },
});

export const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }) => (
  <ThemeProvider theme={snackBarTheme}>
    <NativeSnackbarProvider maxSnack={MAX_STACK_SIZE} Components={{ [CustomVariants.TOAST]: CustomToast }}>
      {children}
    </NativeSnackbarProvider>
  </ThemeProvider>
);
