import { ChangeEvent, FC, useState } from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AssetFormData, MediaTypeValue } from 'components/asset';
import { AssetPicker, AssetPickerTheme } from 'components/asset/asset-picker';
import { AssetView } from 'components/asset/view';
import { Props as ImagePreviewProps } from 'components/image-preview';
import { ALL_MEDIA } from 'constants/input-extension';

const POST_IMAGE_HANDLER_CLASS = 'post-image-handler';
const INPUT_ID = 'input-file';

const Input = styled('input')({
  display: 'none',
});

interface Props {
  disabled: boolean;
  asset: AssetFormData;
  assetType: MediaTypeValue;
  imagePreviewProps: ImagePreviewProps;
  onDelete: () => void;
  onSubmit: (asset: AssetFormData) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
}
export const AssetHandler: FC<Props> = ({
  asset,
  assetType,
  onDelete,
  onChange,
  onSubmit,
  disabled = false,
  id = 'thumbnail',
}) => {
  const [dialogState, setDialogState] = useState<boolean>(false);

  const closeDialog = () => {
    setDialogState(false);
  };

  return (
    <div className={POST_IMAGE_HANDLER_CLASS}>
      <AssetView asset={asset} />
      <br />
      {!disabled &&
        (asset.url ? (
          <Button onClick={onDelete} variant="contained">
            Remove
          </Button>
        ) : (
          <>
            <label htmlFor={`${INPUT_ID}_${id}`}>
              <Input onChange={onChange} accept={ALL_MEDIA} id={`${INPUT_ID}_${id}`} type="file" />
              <Button variant="contained" component="span">
                Upload
              </Button>
            </label>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={() => setDialogState(true)} variant="contained">
              Add existing
            </Button>
          </>
        ))}
      <AssetPicker
        close={closeDialog}
        onSubmit={onSubmit}
        state={dialogState}
        theme={AssetPickerTheme.DARK}
        where={{ assetType, or: { description: '' } }}
      />
    </div>
  );
};
