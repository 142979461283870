import { FC } from 'react';

import { InputLabel } from '@mui/material';

import { PathParams } from 'global/route';

import { Slide } from './slide';

import { Slide as SlideType } from '../forms';

interface Props {
  slides: SlideType[];
  action: PathParams;
  onUpdateSlide: (slide: SlideType) => void;
  onDeleteSlide: (position: number) => void;
  onChangePositionSlide: (oldPosition: number, newPosition: number) => void;
}

export const Slides: FC<Props> = ({
  slides,
  action,
  onUpdateSlide,
  onDeleteSlide,
  onChangePositionSlide = () => {},
}) => (
  <>
    <InputLabel sx={{ mb: 3 }}>Slides</InputLabel>
    {slides.map(slide => (
      <Slide
        onChangePosition={onChangePositionSlide}
        onUpdate={onUpdateSlide}
        onDelete={onDeleteSlide}
        key={slide.virtualKey}
        slide={slide}
        action={action}
        maxPosition={slides.length}
      />
    ))}
  </>
);
