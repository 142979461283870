import { useDispatch } from 'react-redux';

import { DocumentNode, useMutation } from '@apollo/client';

import { getBulkActions } from 'components/tables/grid-table/bulkActions';
import { BULK_DESTROY_MOMENTS, CHANGE_MOMENTS_PUBLISH_STATUSES } from 'graphql/mutations';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTable } from 'hooks/useTable';
import { MomentFormData, MomentKey } from 'pages/moments/forms';

const DATA_KEY = 'moments';
const COUNTER_KEY = 'momentsTotalCount';

interface Props {
  query: DocumentNode;
  where?: object;
}

interface Data {
  momentsTotalCount: number;
  moments: MomentFormData[];
}

interface Search {
  searchString: string;
}

export const useMomentTable = ({ query, where }: Props) => {
  const { showSuccessToast } = useSnackbar();
  const { setOrder, updatePage, bulkObject, page, getFilter, ...args } = useTable<Data['moments']>({
    dataKey: DATA_KEY,
    counterKey: COUNTER_KEY,
    query: {
      query,
      where,
    },
  });

  const bulkDelete = useMutation(BULK_DESTROY_MOMENTS);
  const bulkChangePublishState = useMutation(CHANGE_MOMENTS_PUBLISH_STATUSES);
  const bulkActions = getBulkActions({
    bulkObject,
    updatePage,
    enqueueSnackbar: showSuccessToast,
    bulkDelete,
    bulkChangePublishState,
    entityName: 'moment',
    dispatch: useDispatch(),
  });

  const filterAction = getFilter({ options: [MomentKey.ID, MomentKey.INTRODUCTION, MomentKey.TITLE] });

  return {
    ...args,
    page,
    bulkActions,
    filterAction,
  };
};
