import { ExperimentFormData, Key as ExperimentKey } from 'pages/experiments/forms';

export enum Key {
  ID = 'id',
  UID = 'uid',
  EMAIL = 'email',
  TYPE = 'userType',
  TYPE_ID = 'typeId',
  EXPERIMENTS = 'experiments',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  TYPE_NAME = '__typename',
}
export interface LabUser {
  [Key.ID]?: string;
  [Key.UID]?: string;
  [Key.EMAIL]: string;
  [Key.TYPE]?: UserTypeData;
  [Key.TYPE_ID]?: number;
  [Key.EXPERIMENTS]?: Omit<ExperimentFormData, ExperimentKey.USERS>[] | number[];
  [Key.CREATED_AT]?: string;
  [Key.UPDATED_AT]?: string;
  [Key.TYPE_NAME]?: string;
}

export interface UserTypeData {
  id: number;
  name: string;
}

export const LAB_USER: LabUser = {
  [Key.ID]: '',
  [Key.UID]: undefined,
  [Key.EMAIL]: '',
  [Key.TYPE]: undefined,
  [Key.TYPE_ID]: undefined,
  [Key.EXPERIMENTS]: [],
  [Key.CREATED_AT]: '',
  [Key.UPDATED_AT]: '',
  [Key.TYPE_NAME]: '',
};
