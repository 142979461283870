import { FC, HTMLProps, useState } from 'react';

import { CircularProgress, Grid } from '@material-ui/core';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import { AssetFormData } from 'components/asset';

import './index.scss';

import { PROGRESS_THICKNESS } from '..';

interface Props extends HTMLProps<HTMLAudioElement> {
  asset: AssetFormData;
  showMetaData?: boolean;
}
export const AudioView: FC<Props> = ({ asset, style, controls, src, showMetaData = true, ...props }) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  const media = document.createElement('audio');
  media.src = String(asset.url);
  media.oncanplay = () => setAudio(media);

  if (!audio) return <CircularProgress thickness={PROGRESS_THICKNESS} />;
  if (!controls)
    return (
      <div className="asset-audio-wrapper">
        <div className="icon-wrapper">
          <AudioFileIcon fontSize="large" style={{ color: 'white' }} />
        </div>
        <Grid className="description-wrapper" container spacing={2}>
          <Grid item xs={9}>
            <div className="description">
              <div>{asset.description}</div>
              <div>{audio?.duration?.toFixed(1) + 's'}</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <PlayCircleOutlineIcon style={{ color: 'white' }} />
          </Grid>
        </Grid>
      </div>
    );

  return <audio style={style} controls={controls} src={String(asset.url)} {...props} />;
};
