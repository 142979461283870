import Slide from '@material-ui/core/Slide';
import { AlertColor, AlertProps } from '@mui/material';
import { OptionsObject, useSnackbar as snackBar } from 'notistack';
import { CustomVariants } from 'providers/snackbar';

export enum VariantType {
  SUCCESS = 'success',
  DEFAULT = 'default',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

const DEFAULT_SNACK_CONFIG: OptionsObject = {
  variant: VariantType.SUCCESS,
  preventDuplicate: true,
  autoHideDuration: 4000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  TransitionComponent: Slide,
};

export const MAX_STACK_SIZE = 10;

export interface UseSnackBar {
  showErrorToast: (message: string, alertProps?: AlertProps) => void;
  showSuccessToast: (message: string, alertProps?: AlertProps) => void;
  showWarningToast: (message: string, alertProps?: AlertProps) => void;
  showInfoToast: (message: string, alertProps?: AlertProps) => void;
}

export const useSnackbar = (): UseSnackBar => {
  const { enqueueSnackbar } = snackBar();

  const showToast = (message: string, severity: AlertColor, alertProps?: AlertProps) => {
    enqueueSnackbar(message, {
      ...DEFAULT_SNACK_CONFIG,
      variant: CustomVariants.TOAST,
      severity,
      alertProps,
    });
  };

  return {
    showSuccessToast: (message: string, alertProps?: AlertProps) => {
      showToast(message, VariantType.SUCCESS, alertProps);
    },
    showErrorToast: (message: string, alertProps?: AlertProps) => {
      showToast(message, VariantType.ERROR, alertProps);
    },
    showWarningToast: (message: string, alertProps?: AlertProps) => {
      showToast(message, VariantType.WARNING, alertProps);
    },
    showInfoToast: (message: string, alertProps?: AlertProps) => {
      showToast(message, VariantType.INFO, alertProps);
    },
  };
};
