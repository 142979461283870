import { FC, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { AnalyticsService } from '@loticai/lotic-analytics-service';
import { Transaction } from '@loticai/lotic-analytics-service/dist/types/modules';

import { AssetFormData } from 'components/asset';
import { NotFound } from 'components/NotFound';
import { ALL_MEDIA } from 'constants/input-extension';
import { PathParams } from 'global/route';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_ASSET } from 'graphql/queries';

import { AssetForm } from './form';

export const Edit: FC = () => {
  const { id } = useParams();
  const transactionRef = useRef<Transaction>();
  const { performanceTracker } = AnalyticsService;

  const { data, loading } = useQuery<{ asset: AssetFormData }>(GET_ASSET, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: parseInt(id as string),
    },
  });

  useEffect(() => {
    const transaction = performanceTracker.startTransaction({
      name: 'GET_ASSET',
      description: 'Measuring asset data load',
    });
    if (transaction) {
      transaction.setTag('asset_id', id);
      transactionRef.current = transaction;
    }
    return () => transactionRef.current?.finish();
  }, []);

  useEffect(() => {
    if (data && transactionRef.current) {
      transactionRef.current.finish();
      transactionRef.current = undefined;
    }
  }, [data]);

  if (loading) return <></>;
  else if (data?.asset) return <AssetForm action={PathParams.EDIT} values={data.asset} allowedAssetTypes={ALL_MEDIA} />;
  else return <NotFound />;
};
