import { FC } from 'react';

import { Link } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';

export const Copyright: FC<TypographyProps> = props => (
  <Typography
    className="copyright"
    style={{ marginTop: '35px' }}
    variant="body2"
    color="text.secondary"
    align="center"
    {...props}
  >
    {'Copyright © '}
    <Link target={'_blank'} color="inherit" href="https://lotic.ai/">
      Lotic.ai
    </Link>{' '}
    {new Date().getFullYear()}
  </Typography>
);
