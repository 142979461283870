import { FC, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { MenuItem } from '@material-ui/core';
import { Box, Card, CardContent } from '@mui/material';

import { ActionButtons, ID, TextMultiline, Title } from 'components/inputs';
import { Select } from 'components/mui-custom';
import { setLabel } from 'global/form';
import { PathParams } from 'global/route';
import { GET_CAROUSEL_LOCATIONS } from 'graphql/queries';

import { Slides } from '../components/slides';
import { CAROUSEL_FORM, CarouselFormData, CarouselKey, Location } from '../forms';
import { useCarouselForm } from '../hooks/useCarouselForm';

interface Props {
  action: PathParams;
  values?: CarouselFormData;
}

export const Carousel: FC<Props> = ({ action, values = CAROUSEL_FORM }) => {
  const { data: locationsData } = useQuery<{ carouselLocations: Location[] }>(GET_CAROUSEL_LOCATIONS, {
    variables: {
      currentLocationId: values.locationId,
    },
    fetchPolicy: 'network-only',
  });

  const locations = useMemo(() => (locationsData ? locationsData.carouselLocations : []), [locationsData]);

  const {
    watch,
    formActions,
    setValue,
    trigger,
    errors,
    disabled,
    updateSlideHandler,
    deleteSlideHandler,
    changeSlidePositionHandler,
  } = useCarouselForm({
    providedFormValues: values as CarouselFormData,
    action,
  });

  const { id, name, description, slides, locationId } = watch();

  return (
    <Card>
      <CardContent sx={{ mb: 3 }}>
        {id && <ID sx={{ mb: 3 }} value={id} disabled />}
        <Title
          sx={{ mb: 3 }}
          value={name}
          label={setLabel('Title', action)}
          onChange={event => {
            setValue(CarouselKey.NAME, event.target.value);
          }}
          onBlur={() => trigger(CarouselKey.NAME)}
          helperText={errors?.name?.message}
          error={Boolean(errors.name)}
          disabled={disabled}
          inputProps={{ maxLength: 120 }}
        />

        <Box sx={{ mb: 3 }}>
          <Select
            label={setLabel('Location', action)}
            value={locationId}
            onChange={event => setValue(CarouselKey.LOCATION_ID, event.target.value as number)}
            disabled={disabled}
            formHelperTextProps={{ text: errors.locationId?.message, error: true }}
            error={Boolean(errors.locationId)}
          >
            {locations.length > 0 ? (
              locations.map((item, key) => (
                <MenuItem key={key} value={item.id}>
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled value={0}>
                No locations to select
              </MenuItem>
            )}
          </Select>
        </Box>

        <TextMultiline
          sx={{ mb: 3 }}
          value={description || ' '}
          label={setLabel('Description', action, true)}
          onChange={event => {
            setValue(CarouselKey.DESCRIPTION, event.target.value);
          }}
          onBlur={() => trigger(CarouselKey.DESCRIPTION)}
          helperText={errors?.description?.message}
          error={Boolean(errors.description)}
          disabled={disabled}
          style={{ marginBottom: 40 }}
        />
        <Slides
          onChangePositionSlide={changeSlidePositionHandler}
          onUpdateSlide={updateSlideHandler}
          onDeleteSlide={deleteSlideHandler}
          slides={slides}
          action={action}
        />
      </CardContent>
      <ActionButtons actions={formActions} />
    </Card>
  );
};
