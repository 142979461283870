import { FC } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import {
  Button as BT,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  InputLabel,
  SelectProps,
  Select as SL,
  TextFieldProps,
  TextField as TF,
} from '@mui/material';
import { IconButton } from '@mui/material';
import { DataGridProps, DataGrid as DG } from '@mui/x-data-grid';

import './MUICustom.scss';

export enum ClassName {
  MUI_CUSTOM = 'mui-custom ',
  INPUT = 'input',
  FILLED = 'filled',
  CLEAR_BUTTON = 'clear-button',
  LOADING = 'loading',
  BUTTON_TEXT = 'btn-txt',
  LOADER = 'progress-loader',
  ERROR = 'error',
}

export const TextField: FC<TextFieldProps> = props => (
  <TF className={ClassName.MUI_CUSTOM + ClassName.INPUT} variant="standard" size="small" fullWidth {...props} />
);

export interface CustomFormHelperTextProps extends FormHelperTextProps {
  text: string | undefined;
}

interface SelectExtendedProps extends SelectProps {
  formControlProps?: FormControlProps;
  formHelperTextProps?: CustomFormHelperTextProps;
  clearAction?: () => void;
}

export const Select: FC<SelectExtendedProps> = ({
  formHelperTextProps,
  formControlProps = {},
  clearAction,
  ...props
}) => (
  <FormControl className={ClassName.MUI_CUSTOM + ClassName.INPUT} {...formControlProps} fullWidth>
    {props?.label ? <InputLabel id={props?.labelId}>{props?.label}</InputLabel> : undefined}
    <SL
      MenuProps={{ disablePortal: true }}
      size="small"
      className={props.value ? ClassName.MUI_CUSTOM + ClassName.FILLED : ClassName.MUI_CUSTOM}
      variant="standard"
      fullWidth
      endAdornment={
        clearAction && (
          <IconButton className={ClassName.CLEAR_BUTTON} onClick={clearAction} size="small">
            <ClearIcon fontSize="small" />
          </IconButton>
        )
      }
      {...props}
    />
    <FormHelperText {...(formHelperTextProps || {})}>{formHelperTextProps?.text}</FormHelperText>
  </FormControl>
);

export const DataGrid: FC<DataGridProps> = ({ columns = [], rows = [], ...props }) => (
  <DG
    className="custom-data-grid"
    onRowClick={() => void {}}
    columns={columns}
    rows={rows}
    headerHeight={!rows?.length ? 0 : 56}
    hideFooter={!rows?.length}
    {...props}
  />
);

interface CustomButtonProps extends ButtonProps {
  loading?: boolean;
  text?: string;
  circularProgressSize?: CircularProgressProps['size'];
}

export const Button: FC<CustomButtonProps> = ({ loading, text, circularProgressSize = 16, ...props }) => (
  <BT {...props} className={loading ? ClassName.LOADING : ''} disableRipple>
    <span className={ClassName.BUTTON_TEXT}>{text}</span>
    {loading && <CircularProgress className={ClassName.LOADER} size={circularProgressSize} />}
  </BT>
);
