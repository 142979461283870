import { FC } from 'react';

import { Button, ButtonProps, CircularProgress, IconProps } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const CIRCULAR_PROGRESS_SIZE = 19;
const BUTTON_ICON_SIZE = 'small';
const BUTTON_GENERATOR_CLASS = 'button-generator_wrapper';
const BUTTON_CONTENT_CLASS = 'button-content';
const PROGRESS_LOADER_CLASS = 'progress-loader';
const LOADING_CLASS = 'loading';
const EMPTY = '';

export interface Action {
  text: string;
  action: (any: any) => void;
  Icon?: OverridableComponent<any>;
  buttonProps?: ButtonProps;
  iconProps?: IconProps;
  disabled?: boolean;
  loading?: boolean;
}

interface Props {
  actions: Action[];
}

export const ButtonGenerator: FC<Props> = ({ actions }) => (
  <div className={BUTTON_GENERATOR_CLASS}>
    {actions.map(({ text, action, Icon, buttonProps, iconProps, disabled, loading }, key) => (
      <Button
        variant="contained"
        className={loading ? LOADING_CLASS : EMPTY}
        disabled={disabled || loading}
        key={key}
        onClick={action}
        {...buttonProps}
      >
        <span className={BUTTON_CONTENT_CLASS}>
          {Icon && <Icon fontSize={BUTTON_ICON_SIZE} {...iconProps} />}
          &nbsp;{text}
        </span>
        {loading && <CircularProgress className={PROGRESS_LOADER_CLASS} size={CIRCULAR_PROGRESS_SIZE} />}
      </Button>
    ))}
  </div>
);
