import { GridColDef } from '@mui/x-data-grid';

import { AssetFormData, AssetKey } from 'components/asset';
import { formatDate } from 'global/formater';

export type Location = {
  id: number;
  name: string;
};

export enum CarouselKey {
  ID = 'id',
  NAME = 'name',
  LOCATION = 'location',
  LOCATION_ID = 'locationId',
  DESCRIPTION = 'description',
  SLIDES = 'slides',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PUBLISHED_AT = 'publishedAt',
  TYPE_NAME = '__typename',
}

export enum SlideKey {
  ID = 'id',
  TITLE = 'title',
  TEXT = 'text',
  ASSET_ID = 'assetId',
  ASSET = 'asset',
  POSITION = 'position',
  TYPE = 'type',
  TYPE_NAME = '__typename',
  VIRTUAL_KEY = 'virtualKey',
}

export enum SlideType {
  AUDIO = 'audio',
  VIDEO = 'video',
  IMAGE = 'image',
}

export interface AssetField extends Omit<AssetFormData, AssetKey.POSTS> {}

export interface Slide {
  [SlideKey.ID]?: number;
  [SlideKey.TITLE]: string;
  [SlideKey.TEXT]?: string;
  [SlideKey.ASSET_ID]: number | null;
  [SlideKey.ASSET]?: AssetField | null;
  [SlideKey.TYPE]: SlideType;
  [SlideKey.POSITION]: number;
  [SlideKey.TYPE_NAME]?: string;
  [SlideKey.VIRTUAL_KEY]?: string;
}

export interface CarouselFormData {
  [CarouselKey.ID]?: string;
  [CarouselKey.NAME]: string;
  [CarouselKey.DESCRIPTION]: string;
  [CarouselKey.SLIDES]: Slide[];
  [CarouselKey.LOCATION_ID]: number;
  [CarouselKey.CREATED_AT]?: string;
  [CarouselKey.UPDATED_AT]?: string;
  [CarouselKey.PUBLISHED_AT]?: string | Date | null;
  [SlideKey.TYPE_NAME]?: string;
}

export const SLIDE_FORM = {
  [SlideKey.TITLE]: '',
  [SlideKey.TEXT]: '',
  [SlideKey.ASSET]: null,
  [SlideKey.ASSET_ID]: null,
  [SlideKey.TYPE]: SlideType.IMAGE,
  [SlideKey.POSITION]: 0,
};

export const CAROUSEL_FORM = {
  [CarouselKey.ID]: '',
  [CarouselKey.NAME]: '',
  [CarouselKey.DESCRIPTION]: '',
  [CarouselKey.SLIDES]: [],
  [CarouselKey.LOCATION_ID]: 0,
  [CarouselKey.CREATED_AT]: '',
  [CarouselKey.UPDATED_AT]: '',
  [CarouselKey.PUBLISHED_AT]: '',
  [CarouselKey.TYPE_NAME]: undefined,
};

export const CAROUSEL_SECTION_COLUMNS: GridColDef[] = [
  { field: CarouselKey.ID, headerName: 'ID', flex: 1 },
  { field: CarouselKey.NAME, headerName: 'Name', flex: 1 },
  {
    field: CarouselKey.LOCATION,
    headerName: 'Location',
    flex: 1,
    valueGetter: ({ row }) => (row.location ? row.location.name : 'No location'),
  },
  { field: CarouselKey.DESCRIPTION, headerName: 'Description', flex: 1 },
  {
    field: CarouselKey.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
  {
    field: CarouselKey.UPDATED_AT,
    headerName: 'Updated Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.updatedAt),
  },
];
