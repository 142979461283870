import { GridColDef } from '@mui/x-data-grid';
import { RoutePath } from 'app/routes';

import { renderLinkCell } from 'components/tables/view-table/renderLinkCell';
import { ReviewFormData, ReviewKey } from 'pages/review/forms';
import { formatDate } from 'global/formater';

export interface QuestionFormData {
  [QuestionKey.ID]?: string;
  [QuestionKey.UID]?: string;
  [QuestionKey.CONTENT]: string;
  [QuestionKey.TYPE]?: QuestionType;
  [QuestionKey.CHOICES]?: string[] | null;
  [QuestionKey.REVIEWS]?: ReviewFormData[];
  [QuestionKey.CREATED_AT]?: string;
  [QuestionKey.UPDATED_AT]?: string;
  [QuestionKey.PUBLISHED_AT]?: string | Date | null;
  [QuestionKey.TYPE_NAME]?: string;
}

export type Question = Omit<QuestionFormData, QuestionKey.REVIEWS | QuestionKey.ID> & { [QuestionKey.ID]: number };

export enum QuestionType {
  BASIC_CHOICE = 'basic choice',
  SCALE_1_5 = 'scale 1 - 5',
  SCALE_MINUS_2_2 = 'scale -2 - 2',
  FREE_RESPONSE = 'free response',
}

export enum QuestionKey {
  POSITION = 'position',
  ID = 'id',
  UID = 'uid',
  CONTENT = 'content',
  TYPE = 'type',
  REVIEWS = 'reviews',
  PUBLISHED_AT = 'publishedAt',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  CHOICES = 'choices',
  TYPE_NAME = '__typename',
}

export const QUESTION_FORM: QuestionFormData = {
  [QuestionKey.ID]: '',
  [QuestionKey.UID]: undefined,
  [QuestionKey.CONTENT]: '',
  [QuestionKey.TYPE]: undefined,
  [QuestionKey.REVIEWS]: undefined,
  [QuestionKey.CREATED_AT]: '',
  [QuestionKey.UPDATED_AT]: '',
  [QuestionKey.PUBLISHED_AT]: '',
  [QuestionKey.TYPE_NAME]: undefined,
};

export const typeToChoices = {
  [QuestionType.BASIC_CHOICE]: ['Yes', 'No', 'N/A'],
  [QuestionType.SCALE_1_5]: ['1', '2', '3', '4', '5'],
  [QuestionType.SCALE_MINUS_2_2]: ['-2', '-1', '0', '1', '2'],
  [QuestionType.FREE_RESPONSE]: null,
};

const choicesToType = {
  [typeToChoices[QuestionType.BASIC_CHOICE].join(' ')]: QuestionType.BASIC_CHOICE,
  [typeToChoices[QuestionType.SCALE_1_5].join(' ')]: QuestionType.SCALE_1_5,
  [typeToChoices[QuestionType.SCALE_MINUS_2_2].join(' ')]: QuestionType.SCALE_MINUS_2_2,
  '': QuestionType.FREE_RESPONSE,
};

export const formatChoices = (choices?: string[] | null): QuestionType | null => {
  if (choices === null) return QuestionType.FREE_RESPONSE;
  if (choices === undefined) return null;

  return choicesToType[choices.join(' ')];
};

export const QUESTION_SECTION_COLUMNS: GridColDef[] = [
  {
    field: QuestionKey.UID,
    headerName: 'ID',
    flex: 1,
  },
  { field: QuestionKey.CONTENT, headerName: 'Content', flex: 1 },
  {
    field: QuestionKey.CHOICES,
    headerName: 'Format',
    flex: 1,

    valueGetter: ({ row }) => formatChoices(row.choices),
  },
  {
    field: QuestionKey.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
  {
    field: QuestionKey.UPDATED_AT,
    headerName: 'Updated Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.updatedAt),
  },
];

const getLinkedQuestionsColumns = (): GridColDef[] => [
  { field: QuestionKey.POSITION, headerName: 'Position', align: 'right' },
  ...QUESTION_SECTION_COLUMNS.map(column => {
    if (column.field === QuestionKey.UID) {
      return {
        ...column,
        renderCell: params => renderLinkCell(RoutePath.QUESTIONS, params),
      };
    }
    return column;
  }),
];

export const LINKED_QUESTIONS_COLUMNS = getLinkedQuestionsColumns();

export const LINKED_REVIEW_COLUMNS: GridColDef[] = [
  {
    field: ReviewKey.UID,
    headerName: 'Content ID',
    flex: 1,
    renderCell: params => renderLinkCell(RoutePath.REVIEWS, params),
  },
  { field: ReviewKey.TITLE, headerName: 'Question', flex: 1 },
  {
    field: ReviewKey.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
];

export const QUESTION_PLACEHOLDER = { content: 'empty', id: -1, uid: '' };
