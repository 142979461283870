import { ComponentProps, FC } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { Button } from 'components/mui-custom';
import { CONFIRM_MODAL_ClASS } from './constants';
import { Modal } from 'store/common-modal';

const DEFAULT_MIN_WIDTH = 800;

interface Props extends Modal {
  minWidth?: ComponentProps<typeof DialogContentText>['minWidth'];
}

export const ConfirmModal: FC<Props> = ({ state, title, text, cancel, confirm, minWidth = DEFAULT_MIN_WIDTH }) => (
  <Dialog maxWidth={'lg'} className={CONFIRM_MODAL_ClASS} open={state} onClose={cancel?.action}>
    <DialogTitle mb={1}>{title}</DialogTitle>
    <DialogContent className="confirm-modal-content">
      <DialogContentText minWidth={minWidth}>{text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        text={confirm?.text}
        onClick={confirm?.action}
        loading={confirm?.loading}
        disabled={confirm?.loading}
        autoFocus
      />
      <Button text={cancel?.text} onClick={cancel?.action} disabled={confirm?.loading} />
    </DialogActions>
  </Dialog>
);
