import { Path } from 'react-hook-form';

import { GridColDef } from '@mui/x-data-grid';
import { getRouteByUid } from 'app/routes';

import { AssetFormData } from 'components/asset';
import { renderLinkCell } from 'components/tables/view-table/renderLinkCell';
import { PackageRow, Tag } from 'global/type';
import { formatDate } from 'global/formater';

enum ContentColumnsFields {
  ID = 'uid',
  SECTION = 'section',
  NAME = 'name',
  ESTIMATED_TIME = 'estimatedTime',
  PUBLISHED_AT = 'publishedAt',
}

export enum PostPageType {
  AUDIO = 'Audio',
  VIDEO = 'Video',
  TEXT = 'Text',
  FULL_SCREEN_IMAGE = 'FullScreenImage',
  FULL_SCREEN_VIDEO = 'FullScreenVideo',
  FULL_SCREEN_AUDIO = 'FullScreenAudio',
  MIXED_CONTENT = 'MixedContent',
}

export const POST_MEDIA_TYPES = Object.values(PostPageType);

export enum PostKey {
  ID = 'id',
  UID = 'uid',
  TAGS = 'tags',
  SECTION = 'section',
  NAME = 'name',
  THUMBNAIL = 'thumbnail',
  THUMBNAIL_ID = 'thumbnailId',
  ESTIMATED_TIME = 'estimatedTime',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PUBLISHED_AT = 'publishedAt',
  PACKAGES = 'packages',
  TYPE_NAME = '__typename',
  POST_PAGES = 'postPages',
}

export enum PostSectionValue {
  CONTEXT = 'context',
  WIND_DOWN = 'windDown',
  LEARN = 'learn',
}

export enum PostSectionLabel {
  CONTEXT = 'Context',
  WIND_DOWN = 'Wind down',
  LEARN = 'Learn',
}

export enum SectionFilterTypes {
  LEARN = 'H',
  CONTEXT = 'K',
  WIND_DOWN = 'W',
}

export interface Section {
  text: PostSectionLabel;
  value: PostSectionValue;
}

export interface PostFormData {
  [PostKey.POST_PAGES]?: PostPageFormData[] | null;
  [PostKey.ID]?: string;
  [PostKey.UID]?: string;
  [PostKey.SECTION]: string;
  [PostKey.NAME]: string;
  [PostKey.ESTIMATED_TIME]: string | number;
  [PostKey.PUBLISHED_AT]?: string | Date | null;
  [PostKey.THUMBNAIL_ID]: number | null;
  [PostKey.THUMBNAIL]?: AssetFormData | null;
  [PostKey.TAGS]: Tag[] | number[];
  [PostKey.PACKAGES]?: PackageRow[];
  [PostKey.CREATED_AT]?: string;
  [PostKey.UPDATED_AT]?: string;
  [PostKey.TYPE_NAME]?: string;
}

export const POST_OVERVIEW_COLUMNS: GridColDef[] = [
  { field: ContentColumnsFields.ID, headerName: 'ID', flex: 1 },
  { field: ContentColumnsFields.SECTION, headerName: 'Section', flex: 1 },
  { field: ContentColumnsFields.NAME, headerName: 'Name', flex: 1 },
  { field: ContentColumnsFields.ESTIMATED_TIME, headerName: 'Estimated time min.', flex: 1 },
  {
    field: ContentColumnsFields.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
];

export const POST_SECTION_COLUMNS: GridColDef[] = [
  { field: ContentColumnsFields.ID, headerName: 'ID', flex: 1 },
  { field: ContentColumnsFields.NAME, headerName: 'Name', flex: 1 },
  { field: ContentColumnsFields.ESTIMATED_TIME, headerName: 'Estimated time min.', flex: 1 },
  {
    field: ContentColumnsFields.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
];

const getLinkedPostsColumns = () => {
  const [, ...columns] = POST_SECTION_COLUMNS;
  columns.unshift({
    field: ContentColumnsFields.ID,
    headerName: 'ID',
    flex: 1,
    renderCell: params => renderLinkCell(getRouteByUid(params.row.uid), params),
  });
  return columns;
};

export const LINKED_POSTS_COLUMNS = getLinkedPostsColumns();

export interface OverviewTableRow {
  id: string;
  uid?: string;
  section: PostSectionValue;
  name: string;
  estimatedTime: string;
  publishedAt: string;
}

export interface PostPageFormData {
  [PostPageKey.ID]?: string;
  [PostPageKey.UID]?: number;
  [PostPageKey.MEDIA_LINK]?: string;
  [PostPageKey.TITLE]: string;
  [PostPageKey.ORDER]: number;
  [PostPageKey.MEDIA_TYPE]: string;
  [PostPageKey.BANNER_IMAGE]?: AssetFormData | null;
  [PostPageKey.BANNER_IMAGE_ID]: number | null;
  [PostPageKey.CONTENT]: string;
  [PostPageKey.CONTENT_ASSETS]: AssetFormData[] | number[];
  [PostPageKey.CREATED_AT]?: string;
  [PostPageKey.UPDATED_AT]?: string;
  [PostPageKey.TYPE_NAME]?: string;
}

export const getEmptyPostPageForm = (): PostPageFormData => ({
  [PostPageKey.ID]: '',
  [PostPageKey.UID]: Date.now(),
  [PostPageKey.MEDIA_LINK]: '',
  [PostPageKey.TITLE]: '',
  [PostPageKey.ORDER]: 1,
  [PostPageKey.MEDIA_TYPE]: PostPageType.FULL_SCREEN_IMAGE,
  [PostPageKey.BANNER_IMAGE]: null,
  [PostPageKey.BANNER_IMAGE_ID]: null,
  [PostPageKey.CONTENT]: '',
  [PostPageKey.CONTENT_ASSETS]: [],
  [PostPageKey.UPDATED_AT]: '',
  [PostPageKey.CREATED_AT]: '',
  [PostPageKey.TYPE_NAME]: '',
});

export enum PostPageKey {
  ID = 'id',
  UID = 'uid',
  MEDIA_LINK = 'mediaLink',
  TITLE = 'title',
  MEDIA_TYPE = 'mediaType',
  BANNER_IMAGE = 'bannerImage',
  BANNER_IMAGE_ID = 'bannerImageId',
  CONTENT = 'content',
  CONTENT_ASSETS = 'contentAssets',
  ORDER = 'order',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  TYPE_NAME = '__typename',
}

export const POST_FORM: PostFormData = {
  [PostKey.ID]: '',
  [PostKey.TAGS]: [],
  [PostKey.SECTION]: '',
  [PostKey.NAME]: '',
  [PostKey.THUMBNAIL]: null,
  [PostKey.THUMBNAIL_ID]: null,
  [PostKey.ESTIMATED_TIME]: '',
  [PostKey.PACKAGES]: [],
  [PostKey.UPDATED_AT]: '',
  [PostKey.CREATED_AT]: '',
  [PostKey.UID]: '',
  [PostKey.PUBLISHED_AT]: '',
  [PostKey.TYPE_NAME]: '',
  [PostKey.POST_PAGES]: [getEmptyPostPageForm()],
};

export type LinkedPostData = Pick<PostPageFormData, PostPageKey.ID | PostPageKey.TITLE | PostPageKey.MEDIA_TYPE>;

export const POST_SECTIONS: Section[] = [
  {
    text: PostSectionLabel.CONTEXT,
    value: PostSectionValue.CONTEXT,
  },
  {
    text: PostSectionLabel.WIND_DOWN,
    value: PostSectionValue.WIND_DOWN,
  },
  {
    text: PostSectionLabel.LEARN,
    value: PostSectionValue.LEARN,
  },
];

export const getSection = (str?: string) => {
  if (!str) return;

  switch (str[0]) {
    case SectionFilterTypes.CONTEXT:
      return PostSectionValue.CONTEXT;
    case SectionFilterTypes.LEARN:
      return PostSectionValue.LEARN;
    case SectionFilterTypes.WIND_DOWN:
      return PostSectionValue.WIND_DOWN;
    default:
      return;
  }
};

export const getPostPageKey = (key: PostPageKey, index: number): Path<PostFormData> => {
  return `${PostKey.POST_PAGES}.${index}.${key}`;
};

export const MediaTypeSensitiveValues = [
  PostPageKey.TITLE,
  PostPageKey.CONTENT,
  PostPageKey.CONTENT_ASSETS,
  PostPageKey.BANNER_IMAGE,
  PostPageKey.BANNER_IMAGE_ID,
  PostPageKey.MEDIA_LINK,
];

export const MediaTypeInputs: Record<PostPageType, PostPageKey[]> = {
  [PostPageType.FULL_SCREEN_IMAGE]: [PostPageKey.BANNER_IMAGE, PostPageKey.BANNER_IMAGE_ID],
  [PostPageType.FULL_SCREEN_VIDEO]: [
    PostPageKey.BANNER_IMAGE,
    PostPageKey.BANNER_IMAGE_ID,
    PostPageKey.MEDIA_LINK,
    PostPageKey.TITLE,
  ],
  [PostPageType.FULL_SCREEN_AUDIO]: [
    PostPageKey.BANNER_IMAGE,
    PostPageKey.BANNER_IMAGE_ID,
    PostPageKey.MEDIA_LINK,
    PostPageKey.TITLE,
  ],
  [PostPageType.TEXT]: [PostPageKey.CONTENT, PostPageKey.TITLE],
  [PostPageType.MIXED_CONTENT]: MediaTypeSensitiveValues,
  [PostPageType.AUDIO]: MediaTypeSensitiveValues,
  [PostPageType.VIDEO]: MediaTypeSensitiveValues,
};
