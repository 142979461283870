import { FC, Fragment, useMemo } from 'react';

import { Card, CardContent } from '@mui/material';

import { ActionButtons, DateInput, ID } from 'components/inputs';
import { TextField } from 'components/mui-custom';
import { capitalize } from 'global/formater';
import { PathParams } from 'global/route';

import './index.scss';

import {
  ExperimentEndDate,
  ExperimentFrequency,
  ExperimentSequence,
  ExperimentStartDate,
  ExperimentTitle,
  ExperimentType,
  ExperimentUsers,
} from '../components';
import { ExperimentCompletionCode } from '../components/CompletionCode';
import { ExperimentPackages } from '../components/Packages';
import { ExperimentRedirectUrl } from '../components/RedirectUrl';
import { useExperimentForm, UseExperimentForm } from '../hooks/useExperimentForm';

import {
  EXPERIMENT_FORM,
  ExperimentFormData,
  ExperimentType as ExperimentTypeEnum,
  INSIGHT_LABEL_TEXT,
  Key,
  VirtualKey,
} from '.';
import { YesNoRadioGroup } from 'components/inputs/YesNoRadioGroup';
import { checkStringBool } from 'utils';
import { GridContainerItem } from 'components/common';

interface Props {
  action: PathParams;
  values?: ExperimentFormData;
}
export const Experiment: FC<Props> = ({ action, values = EXPERIMENT_FORM }) => {
  const experimentHookValues: UseExperimentForm = useExperimentForm({
    action,
    providedFormValues: values,
  });

  const {
    id: rawExperimentId,
    uid,
    duration,
    status,
    updatedAt,
    createdAt,
    publishedAt,
    type,
    isDisplayBranding,
    showInsights,
    showAutoSummaryInsights,
    showArtworkInsights,
    showMusicInsights,
    showAnimationInsights,
    showPersonalityInsights,
    showSentimentInsights,
    showSpeakingInsights,
    showWordFrequencyInsights,
    showThankYouPage,
    showMomentSaved,
    showThankYouPageNavigation,
    redirectUrl,
    isEndSessionFunctionalityEnabled,
    users,
    showQrCode,
    showEmailPage,
    showOneVideo,
  } = experimentHookValues.watch();

  const experimentId = useMemo(() => (rawExperimentId ? parseInt(rawExperimentId, 10) : undefined), [rawExperimentId]);

  const showForm = action === PathParams.SHOW;

  const combinedType = String(type) ?? String(values.type);

  const renderCompletionCode = () => {
    return (
      <GridContainerItem>
        <ExperimentCompletionCode hook={experimentHookValues} action={action} />
      </GridContainerItem>
    );
  };

  const renderExperimentPackages = () => {
    return (
      <GridContainerItem>
        <ExperimentPackages experimentId={experimentId} experimentType={combinedType as ExperimentTypeEnum} />
      </GridContainerItem>
    );
  };

  const renderRedirectUrl = () => {
    return (
      <GridContainerItem>
        <ExperimentRedirectUrl hook={experimentHookValues} action={action} />
      </GridContainerItem>
    );
  };

  const EXPERIMENT_TYPE_EXCLUSIVE_FIELDS = {
    [ExperimentTypeEnum.EMAIL]: {
      [PathParams.CREATE]: [],
      [PathParams.SHOW]: [],
      [PathParams.EDIT]: [],
    },
    [ExperimentTypeEnum.PROLIFIC_STUDY]: {
      [PathParams.CREATE]: [Key.COMPLETION_CODE],
      [PathParams.SHOW]: [Key.COMPLETION_CODE, VirtualKey.EXPERIMENT_PACKAGES],
      [PathParams.EDIT]: [Key.COMPLETION_CODE],
    },
    [ExperimentTypeEnum.ONE_LINK_ANON_USER]: {
      [PathParams.CREATE]: [Key.REDIRECT_URL],
      [PathParams.SHOW]: [Key.REDIRECT_URL, VirtualKey.EXPERIMENT_PACKAGES],
      [PathParams.EDIT]: [Key.REDIRECT_URL],
    },
    [ExperimentTypeEnum.RAND_STUDY]: {
      [PathParams.CREATE]: [],
      [PathParams.SHOW]: [VirtualKey.EXPERIMENT_PACKAGES],
      [PathParams.EDIT]: [],
    },
  };

  const EXPERIMENT_FORM_FIELD_RENDER = {
    [Key.COMPLETION_CODE]: renderCompletionCode,
    [VirtualKey.EXPERIMENT_PACKAGES]: renderExperimentPackages,
    [Key.REDIRECT_URL]: renderRedirectUrl,
  };

  const ShowExclusiveFields = (action, experimentType) => {
    return EXPERIMENT_TYPE_EXCLUSIVE_FIELDS[experimentType]?.[action]?.map(field => (
      <Fragment key={field}>{EXPERIMENT_FORM_FIELD_RENDER[field]?.()}</Fragment>
    ));
  };
  const {
    readOnly,
    errors,
    setShowInsights,
    setShowInsightToggle,
    setIsDisplayBranding,
    setShowThankYouPage,
    setShowThankYouPageNavigation,
    setIsEndSessionFunctionalityEnabled,
    setShowMomentSaved,
    setShowEmailPage,
  } = experimentHookValues;

  const isOneLinkType = type === ExperimentTypeEnum.ONE_LINK_ANON_USER;
  const showThankYouPageNavigationToggle = showInsights && (!isOneLinkType || (isOneLinkType && showThankYouPage));

  const widgetHandlers = [
    {
      id: Key.SHOW_AUTO_SUMMARY_INSIGHTS,
      value: showAutoSummaryInsights,
    },
    {
      id: Key.SHOW_ARTWORK_INSIGHTS,
      value: showArtworkInsights,
    },
    {
      id: Key.SHOW_MUSIC_INSIGHTS,
      value: showMusicInsights,
    },
    {
      id: Key.SHOW_ANIMATION_INSIGHTS,
      value: showAnimationInsights,
    },
    {
      id: Key.SHOW_PERSONALITY_INSIGHTS,
      value: showPersonalityInsights,
    },
    {
      id: Key.SHOW_SENTIMENT_INSIGHTS,
      value: showSentimentInsights,
    },
    {
      id: Key.SHOW_SPEAKING_INSIGHTS,
      value: showSpeakingInsights,
    },
    {
      id: Key.SHOW_WORD_FREQUENCY_INSIGHTS,
      value: showWordFrequencyInsights,
    },
    {
      id: Key.SHOW_QR_CODE,
      value: showQrCode,
    },
    { id: Key.SHOW_ONE_VIDEO, value: showOneVideo },
  ];

  const widgetYesNoGroups = () => {
    return widgetHandlers.map(widget => (
      <YesNoRadioGroup
        key={widget.id}
        labelText={INSIGHT_LABEL_TEXT[widget.id]}
        required
        disabled={readOnly}
        onChange={e => setShowInsightToggle(widget.id, checkStringBool(e.target.value))}
        value={widget.value}
        errorMessage={errors?.[widget.id]?.message ?? ''}
      />
    ));
  };

  const hasUsers: boolean = Boolean(users && users.length > 0);

  return (
    <Card>
      <CardContent className="experiment-form-content">
        {uid && <ID value={uid} disabled />}
        {status && <TextField value={capitalize(status)} label="Status" disabled />}
        <ExperimentTitle hook={experimentHookValues} action={action} />

        <GridContainerItem>
          <ExperimentType hook={experimentHookValues} action={action} disabled={hasUsers} />
        </GridContainerItem>
        <YesNoRadioGroup
          value={isDisplayBranding}
          labelText="Display Lotic Branding"
          onChange={e => setIsDisplayBranding(checkStringBool(e.target.value))}
          errorMessage={errors?.isDisplayBranding?.message ?? ''}
          disabled={readOnly}
          required
        />
        {isOneLinkType && (
          <YesNoRadioGroup
            value={isEndSessionFunctionalityEnabled}
            labelText="End Session Functionality"
            onChange={e => setIsEndSessionFunctionalityEnabled(checkStringBool(e.target.value))}
            errorMessage={errors?.isEndSessionFunctionalityEnabled?.message ?? ''}
            disabled={experimentHookValues.readOnly}
            required
          />
        )}

        <YesNoRadioGroup
          value={showMomentSaved}
          labelText="Show 'Moment Saved'"
          onChange={e => setShowMomentSaved(checkStringBool(e.target.value))}
          errorMessage={errors?.showMomentSaved?.message ?? ''}
          disabled={readOnly}
          required
        />
        {ShowExclusiveFields(action, combinedType)}

        <GridContainerItem>
          <ExperimentStartDate hook={experimentHookValues} action={action} />
          <ExperimentEndDate hook={experimentHookValues} action={action} />
          <ExperimentFrequency hook={experimentHookValues} action={action} />
        </GridContainerItem>

        {duration && <TextField value={duration} label="Experiment duration (days)" sx={{ mt: 2 }} disabled />}

        <GridContainerItem>
          <ExperimentUsers hook={experimentHookValues} action={action} />
        </GridContainerItem>

        <GridContainerItem>
          <ExperimentSequence hook={experimentHookValues} action={action} />
        </GridContainerItem>

        {showForm && <DateInput sx={{ mt: 2 }} label="Published" value={publishedAt} disabled />}
        {showForm && <DateInput sx={{ mt: 2 }} label="Updated" value={updatedAt} disabled />}
        {showForm && <DateInput sx={{ mt: 2 }} label="Created" value={createdAt} disabled />}

        <YesNoRadioGroup
          labelText="Show Insights"
          required
          disabled={readOnly}
          onChange={({ target }) => setShowInsights(target.value === 'true')}
          value={showInsights}
          errorMessage={errors?.showInsights?.message ?? ''}
        />
        {showInsights && widgetYesNoGroups()}
        {isOneLinkType && (
          <YesNoRadioGroup
            value={showThankYouPage}
            labelText="Show 'Thank You' Page"
            onChange={e => setShowThankYouPage(checkStringBool(e.target.value))}
            errorMessage={errors?.showThankYouPage?.message ?? ''}
            disabled={readOnly || !redirectUrl}
            required
          />
        )}
        <YesNoRadioGroup
          value={showThankYouPageNavigation}
          labelText="Show 'Thank You' Page Navigation"
          onChange={e => setShowThankYouPageNavigation(checkStringBool(e.target.value))}
          errorMessage={errors?.showThankYouPageNavigation?.message ?? ''}
          disabled={readOnly || !showThankYouPageNavigationToggle}
          required
        />
        <YesNoRadioGroup
          value={showEmailPage}
          labelText="Show Email Page"
          onChange={e => setShowEmailPage(checkStringBool(e.target.value))}
          errorMessage={errors?.showEmailPage?.message ?? ''}
          disabled={readOnly}
          required
        />
      </CardContent>
      <ActionButtons actions={experimentHookValues.formActions} />
    </Card>
  );
};
