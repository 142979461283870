import { Dispatch, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { AnalyticsService } from '@loticai/lotic-analytics-service';
import { useGoogleLogin } from '@react-oauth/google';
import { AnyAction } from '@reduxjs/toolkit';

import { GoogleUserRole } from 'constants/auth';
import { AppPath } from 'global/route';
import { useSnackbar } from 'hooks/useSnackbar';
import { clearUser, setUser } from 'store/google-user';

export const PROVIDER_ID = 'providerId';

interface LoginOptions extends RequestInit {
  token?: string;
  role?: GoogleUserRole;
}

export const logout = (logOutPath: string): Promise<Response> => fetch(logOutPath, { credentials: 'include' });

export const usePromptLogin = (loginPath: string, options: LoginOptions): (() => void) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showSuccessToast, showErrorToast } = useSnackbar();

  return useGoogleLogin({
    onSuccess: tokenResponse => {
      fetch(loginPath, {
        method: 'POST',
        credentials: 'include',
        headers: new Headers({
          ...(tokenResponse.code && { Authorization: tokenResponse.code }),
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({ role: options.role }),
      })
        .then(res => res.json())
        .then(result => {
          localStorage.setItem(PROVIDER_ID, result?.providerId);
          dispatch(setUser({ email: result?.providerId }));
          navigate(AppPath.ADMIN);
          showSuccessToast('success');
        })
        .catch(err => {
          if (err instanceof Error) {
            showErrorToast(err.message);
          }
        });
    },
    flow: 'auth-code',
  });
};

interface UseSignedLoginProps {
  email: string;
  navigate: NavigateFunction;
  dispatch: Dispatch<AnyAction>;
}
const { errorTracker } = AnalyticsService;

export const useSignedLogin = ({ email, navigate, dispatch }: UseSignedLoginProps): void => {
  useEffect(() => {
    if (!email) {
      const providerId = localStorage.getItem(PROVIDER_ID);
      if (providerId) {
        dispatch(setUser({ email: providerId }));
        errorTracker.setUser({ email: providerId });
        errorTracker.setTag('lastUser', providerId);
      } else if (!location.pathname.includes(AppPath.LOGIN)) {
        navigate(AppPath.LOGIN);
      }
      dispatch(setUser({ isInitialized: true }));
    }
  }, []);
};

interface UseGoogleLogoutProps {
  logOutPath: string;
  email: string;
  navigate: NavigateFunction;
  dispatch: Dispatch<AnyAction>;
}
export const useGoogleLogout = async ({ logOutPath, navigate, dispatch, email }: UseGoogleLogoutProps) => {
  await logout(logOutPath);

  if (email) {
    dispatch(clearUser());
    errorTracker.setUser({ email: undefined });
    localStorage.removeItem(PROVIDER_ID);
  }

  navigate(AppPath.LOGIN);
};
