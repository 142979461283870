import { ReactElement } from 'react';
import { Navigate, Params, RouteObject } from 'react-router';

import { BreadcrumbItem } from 'components/common/breadcrumbs/types';

enum EntityOperations {
  CREATE = 'create',
  SHOW = 'show',
  EDIT = 'edit',
}

enum DefaultFieldNames {
  ID = 'id',
  NAME = 'name',
}

const CREATE_LABEL = 'Create';

interface BuildChildrenRoutes {
  (
    routePath: string,
    entityName: string,
    options: {
      elements: {
        list: ReactElement;
        create: ReactElement;
        show: ReactElement;
        edit: ReactElement;
      };
      createTitle?: string;
      idField?: string;
      labelFieldName?: string;
      useIdAsLabelText?: boolean;
      notFoundRedirectTo?: string;
    },
  ): any;
}

export const buildChildrenRoutes: BuildChildrenRoutes = (
  routePath,
  entityName,
  {
    elements,
    createTitle = CREATE_LABEL,
    idField = DefaultFieldNames.ID,
    labelFieldName = DefaultFieldNames.NAME,
    useIdAsLabelText,
    notFoundRedirectTo = '/',
  },
): RouteObject[] => {
  const { list, create, show, edit } = elements;
  return [
    {
      index: true,
      element: list,
    },
    {
      path: EntityOperations.CREATE,
      element: create,
      handle: {
        breadcrumb: (): BreadcrumbItem => ({ link: `${routePath}/${EntityOperations.CREATE}`, labelText: createTitle }),
      },
    },
    {
      path: `:${idField}`,
      element: <Navigate to={`${EntityOperations.SHOW}`} replace />,
    },
    {
      path: `:${idField}/${EntityOperations.SHOW}`,
      element: show,
      handle: {
        breadcrumb: (params: Params<string>): BreadcrumbItem => {
          const link = `${routePath}/${params[idField]}/${EntityOperations.SHOW}`;
          if (!useIdAsLabelText && params[idField]) {
            return {
              link,
              resolveInfo: { entityName, id: params[idField] || '', labelFieldName },
            };
          }
          return {
            link,
            labelText: params[idField] || `<${idField}>`,
          };
        },
      },
    },
    {
      path: `:${idField}/${EntityOperations.EDIT}`,
      element: edit,
      handle: {
        breadcrumb: (params: Params<string>): BreadcrumbItem => {
          const link = `${routePath}/${params[idField]}/${EntityOperations.EDIT}`;
          if (!useIdAsLabelText && params[idField]) {
            return {
              link,
              resolveInfo: { entityName, id: params[idField] || '', labelFieldName },
            };
          }
          return {
            link,
            labelText: params[idField] || `<${idField}>`,
          };
        },
      },
    },
    {
      path: '*',
      element: <Navigate to={notFoundRedirectTo} replace />,
    },
  ];
};
