import { Link } from 'react-router-dom';

import { GridCellParams } from '@mui/x-data-grid';

export const renderFullLinkCell = (path: string, params: GridCellParams<any, any, any>) => (
  <Link
    style={{
      display: 'flex',
      height: '100%',
      width: '100%',
      alignItems: 'center',
      color: 'inherit',
      textDecoration: 'inherit',
      paddingLeft: '10px',
      paddingRight: '10px',
    }}
    to={path}
  >
    {params.value}
  </Link>
);
