import { useDispatch } from 'react-redux';
import { Location, useLocation } from 'react-router';

import { DocumentNode, useMutation } from '@apollo/client';
import { RoutePath } from 'app/routes';

import { getBulkActions } from 'components/tables/grid-table/bulkActions';
import { BULK_DESTROY_POSTS, CHANGE_POST_PUBLISH_STATUSES } from 'graphql/mutations';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTable } from 'hooks/useTable';

import { PostFormData, PostKey } from '../forms';

const DATA_KEY = 'posts';
const COUNTER_KEY = 'postsTotalCount';

interface Props {
  getQuery: DocumentNode;
  where?: object;
}

interface Data {
  postsSequenceTotalCount: number;
  posts: PostFormData[];
}

interface Search {
  uidOrTitle: string;
}

export const usePostTable = ({ getQuery, where }: Props) => {
  const location = useLocation();

  const getPostEntityName = (params: Location): string => {
    const defaultName = 'post';
    // get entity name by route section
    const entityName = params?.pathname.split('/')[1];

    return entityName === RoutePath.OVERVIEW
      ? /* overview is not valid post entity name so return the default name */ defaultName
      : /* make singular from plural (delete last letter - s) */ entityName.slice(0, -1);
  };
  const { showSuccessToast } = useSnackbar();
  const { updatePage, bulkObject, page, getFilter, ...args } = useTable<Data['posts']>({
    dataKey: DATA_KEY,
    counterKey: COUNTER_KEY,
    query: {
      query: getQuery,
      where,
    },
  });

  const bulkDelete = useMutation(BULK_DESTROY_POSTS);
  const bulkChangePublishState = useMutation(CHANGE_POST_PUBLISH_STATUSES);

  const bulkActions = getBulkActions({
    bulkObject,
    updatePage,
    enqueueSnackbar: showSuccessToast,
    bulkDelete,
    bulkChangePublishState,
    entityName: getPostEntityName(location),
    dispatch: useDispatch(),
  });

  const filterAction = getFilter({ options: [PostKey.ID, PostKey.NAME] });

  return {
    ...args,
    page,
    bulkActions,
    filterAction,
  };
};
