import { FC, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Button } from '@mui/material';

import { ViewTable } from 'components/tables/view-table';
import { GET_QUESTIONS } from 'graphql/queries';
import { LINKED_QUESTIONS_COLUMNS, Question, QUESTION_PLACEHOLDER, QuestionKey } from 'pages/question/forms';

import './index.scss';
import { Card } from 'components/common/activity-card';
import { ReviewQuestion } from '../forms';

interface Props {
  questions: ReviewQuestion[];
  setQuestions: Function;
  disabled: boolean;
}

export const Questions: FC<Props> = ({ questions, setQuestions, disabled }) => {
  const [currentQuestions, setCurrentQuestions] = useState(questions);
  const { data } = useQuery<{ questions: Question[] }>(GET_QUESTIONS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setCurrentQuestions(questions);
  }, [questions]);

  const filterQuestions = (options: Question[], uid: string, items: ReviewQuestion[]): Question[] => {
    const idsToExclude = items.reduce<string[]>(
      (questionIds, question) => (question.uid !== uid ? [...questionIds, question.uid] : questionIds),
      [],
    );
    return options.filter(question => !idsToExclude.includes(question.uid ?? ''));
  };

  const setSelected = (
    uid: string | undefined,
    itemId: number | undefined,
    value: ReviewQuestion,
    items: ReviewQuestion[],
  ) => {
    if (uid && itemId) {
      const itemToUpdateIndex = items.findIndex(({ uid }) => uid === value.uid);
      const updatedQuestions = [...items];
      updatedQuestions[itemToUpdateIndex] = {
        ...value,
        uid,
        itemId,
      };
      setCurrentQuestions(updatedQuestions);
      setQuestions(updatedQuestions);
    }
  };

  const addQuestion = () => {
    const newQuestions = [...currentQuestions];
    const newPosition =
      (currentQuestions.toSorted((a, b) => Number(a.position) - Number(b.position))[currentQuestions.length - 1]
        ?.position ?? 0) + 1;
    newQuestions.push({ ...QUESTION_PLACEHOLDER, position: newPosition, itemId: -1 });
    setCurrentQuestions(newQuestions);
    setQuestions(newQuestions);
  };

  const removeQuestion = (uid: string, items: ReviewQuestion[]) => {
    let position = 1;
    const newQuestions = items.reduce<ReviewQuestion[]>((acc, item) => {
      if (item.uid !== uid) {
        const result = [...acc, { ...item, position }];
        position++;
        return result;
      }
      return acc;
    }, []);
    setCurrentQuestions(newQuestions);
    setQuestions(newQuestions);
  };

  const moveCard = (dragIndex: number, hoverIndex: number): void => {
    const sourceItem = currentQuestions.find(item => item.itemId === currentQuestions[dragIndex].itemId);
    const destItem = currentQuestions.find(item => item.itemId === currentQuestions[hoverIndex].itemId);
    if (sourceItem && destItem) {
      const updatedQuestions = [...currentQuestions];
      const sourceIndex = currentQuestions.findIndex(item => item.itemId === sourceItem.itemId);
      const destIndex = currentQuestions.findIndex(item => item.itemId === destItem.itemId);
      updatedQuestions[sourceIndex] = { ...sourceItem, position: destItem.position };
      updatedQuestions[destIndex] = { ...destItem, position: sourceItem.position };
      setQuestions(updatedQuestions);
    }
  };

  const positionItems = useMemo(
    () =>
      currentQuestions.map(({ itemId }, index) => (
        <div key={itemId} className="position-column-item">
          {index + 1}
        </div>
      )),
    [currentQuestions],
  );

  const questionItemsCards = useMemo(
    () =>
      currentQuestions.map((questionItem, key) => (
        <div key={questionItem.position}>
          <Card<ReviewQuestion, Question>
            uid={questionItem.uid}
            index={key}
            fullContent={data?.questions ?? []}
            cardItem={questionItem}
            cardItems={currentQuestions}
            setSelected={setSelected}
            removeItem={removeQuestion}
            labelSource={QuestionKey.CONTENT}
            filterOptions={filterQuestions}
            moveCard={moveCard}
          />
        </div>
      )),
    [currentQuestions, data?.questions, setSelected, removeQuestion, filterQuestions, moveCard],
  );

  return (
    <div className="constructor_questions">
      {!disabled && Boolean(data?.questions?.length) && (
        <>
          <div className="label">Questions:</div>
          <div className="item-columns">
            <div className="position-column">{positionItems}</div>
            <div className="content-column">{questionItemsCards}</div>
          </div>
          <Button variant="contained" onClick={addQuestion} disabled={currentQuestions.some(item => item.uid === '')}>
            Add Question
          </Button>
        </>
      )}
      {disabled && <ViewTable rows={questions ?? []} columns={LINKED_QUESTIONS_COLUMNS} label={''} />}
    </div>
  );
};
