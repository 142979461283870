import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { NotFound } from 'components/NotFound';
import { stringToInt } from 'global/formater';
import { PathParams } from 'global/route';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_CAROUSEL } from 'graphql/queries';

import { Carousel } from './Create';

import { CarouselFormData } from '.';

interface Props {
  action: PathParams;
}
export const SaveForm: FC<Props> = ({ action }) => {
  const { id } = useParams();

  const { data, loading } = useQuery<{ carousel: CarouselFormData }>(GET_CAROUSEL, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: stringToInt(id as string),
    },
  });

  if (loading) return <></>;
  if (data?.carousel) return <Carousel action={action} values={data.carousel} />;
  return <NotFound />;
};
