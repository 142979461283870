import { DetailedHTMLProps, FC, HTMLAttributes, ImgHTMLAttributes, useState } from 'react';

import PhotoIcon from '@mui/icons-material/Photo';
import CloseIcon from '@mui/icons-material/Close';

import './index.scss';
import { Box, Button, Modal } from '@mui/material';

const VIEWER_CLASS = 'image-viewer';
const VIEWER_WRAPPER_CLASS = 'viewer-wrapper';
const LABEL_CLASS = 'label';
const PHOTO_ICON_CLASS = 'photo-icon';
const MODAL_WRAPPER_CLASS = 'modal-wrapper';

export interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  url?: string | null;
  imageHeight?: ImgHTMLAttributes<HTMLImageElement>['height'];
  uploaderMinWidth?: number;
  label?: string;
  htmlColor?: string;
}

export const ImagePreview: FC<Props> = ({
  label,
  htmlColor = '#ffff',
  url,
  imageHeight = 150,
  uploaderMinWidth = 300,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className={MODAL_WRAPPER_CLASS}>
          <Button
            onClick={handleClose}
            style={{ right: 16, top: 16, position: 'fixed', backgroundColor: 'rgb(256, 256, 256, 0.2)' }}
            variant={'outlined'}
          >
            <CloseIcon />
          </Button>
          <img src={url ?? ''} height={'100%'} />
        </Box>
      </Modal>
      <div {...props} className={VIEWER_CLASS}>
        <div className={LABEL_CLASS}>{label}</div>
        <div className={VIEWER_WRAPPER_CLASS} style={{ minWidth: uploaderMinWidth }}>
          {!url && <PhotoIcon htmlColor={htmlColor} className={PHOTO_ICON_CLASS} />}
          <img src={url ?? ''} height={imageHeight} onClick={handleOpen} style={{ cursor: 'zoom-in' }} />
        </div>
      </div>
    </>
  );
};
