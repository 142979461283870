import { FC } from 'react';

import { CssBaseline, Grid, Paper } from '@mui/material';
import { APP_CLIENT } from 'app/manager';

import { Copyright } from 'components/Copyright';

import './index.scss';

export const AuthLayout: FC = () => {
  const { SignIn } = APP_CLIENT;

  return (
    <Grid className="auth-container" container>
      <CssBaseline />
      <Grid className="welcome-grid" item xs={false} sm={4} md={8} />
      <Grid className="sign-in-grid" item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <SignIn />
        <Copyright className="copyright" />
      </Grid>
    </Grid>
  );
};
