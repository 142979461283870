import { FC } from 'react';

import { Card, CardContent } from '@mui/material';

import { GridItemView } from 'components/common';
import { DateInput, ID, Tags, Title } from 'components/inputs';
import { ActionButtons } from 'components/inputs/ActionButtons';
import { SHOW_PATIENT_NUMBER_FIELD } from 'constants/display-content-permissions';
import { setLabel } from 'global/form';
import { PathParams } from 'global/route';
import { Package } from 'pages/package/forms';

import { SequenceFormData, SequenceKey, Sequence as SequenceType } from '../forms';
import { useSequenceForm } from '../hooks/useSequenceForm';
import { NextSequence } from '../inputs/NextSequence';
import { Packages } from '../inputs/Packages';
import { isTagsValid } from 'utils';

interface Props {
  action: PathParams;
  values?: SequenceFormData;
  sequences: SequenceType[];
  allPackages?: Package[];
}

export const Sequence: FC<Props> = ({ action, values, sequences, allPackages }) => {
  const { watch, setTags, formActions, setValue, trigger, setPackages, setNextSequence, errors, disabled } =
    useSequenceForm({
      providedFormValues: values as SequenceFormData,
      action,
    });

  const { tags, uid, title, description, updatedAt, createdAt } = watch();

  return (
    <Card>
      <CardContent sx={{ mb: '35px' }}>
        {uid && <ID value={uid} disabled />}
        <Title
          value={title}
          label={setLabel('Title', action)}
          onChange={e => setValue(SequenceKey.TITLE, e.target.value)}
          onBlur={() => trigger(SequenceKey.TITLE)}
          helperText={errors?.title?.message}
          error={Boolean(errors.title)}
          disabled={disabled}
        />
        <Title
          value={description}
          label={setLabel('Description', action)}
          onChange={e => setValue(SequenceKey.DESCRIPTION, e.target.value)}
          onBlur={() => trigger(SequenceKey.DESCRIPTION)}
          helperText={errors?.description?.message}
          error={Boolean(errors.description)}
          disabled={disabled}
        />
        {disabled && SHOW_PATIENT_NUMBER_FIELD && (
          <>
            <div className="patients-number">Number of users assigned this sequence</div>
            <div>{values?.patientsNumber}</div>
          </>
        )}
        <NextSequence
          nextSequence={values?.nextPackageSequence as SequenceType}
          setNextSequence={setNextSequence}
          sequences={sequences}
          disabled={disabled}
        />
        <GridItemView
          style={{ marginTop: 12 }}
          label="Tag"
          item={<Tags tags={isTagsValid(tags) ? tags : []} setTags={setTags} disabled={disabled} />}
        />
        {values && (
          <Packages
            packages={values.packages}
            allPackages={allPackages}
            setPackages={setPackages}
            disabled={disabled}
          />
        )}
        {disabled && <DateInput sx={{ mt: 2 }} label="Updated" value={updatedAt} disabled />}
        {disabled && <DateInput sx={{ mt: 2 }} label="Created" value={createdAt} disabled />}
      </CardContent>
      <ActionButtons actions={formActions} />
    </Card>
  );
};
