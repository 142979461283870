import { FC } from 'react';

import { RoutePath } from 'app/routes';

import { GET_QUESTIONS } from 'graphql/queries';
import { getShowPagePath } from 'hooks/useTable';

import { QUESTION_SECTION_COLUMNS } from './forms/index';
import { QuestionTable } from './tables/Question';

export const List: FC = () => {
  return (
    <QuestionTable
      query={GET_QUESTIONS}
      columns={QUESTION_SECTION_COLUMNS}
      onGetLink={({ row }) => getShowPagePath(RoutePath.QUESTIONS, String(row.uid))}
    />
  );
};
