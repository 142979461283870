import { FC } from 'react';

import { RoutePath } from 'app/routes';

import { GET_CAROUSELS } from 'graphql/queries';
import { getShowPagePath } from 'hooks/useTable';

import { CAROUSEL_SECTION_COLUMNS } from './forms/index';
import { CarouselTable } from './tables/Carousel';

export const List: FC = () => {
  return (
    <CarouselTable
      query={GET_CAROUSELS}
      columns={CAROUSEL_SECTION_COLUMNS}
      onGetLink={({ row }) => getShowPagePath(RoutePath.CAROUSELS, String(row.id))}
    />
  );
};
