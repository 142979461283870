import { SyntheticEvent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

import { ApolloQueryResult, OperationVariables, useMutation, useQuery } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import * as dateFns from 'date-fns';

import config from 'config';
import { PathParams } from 'global/route';
import { CombinedChangeEvent } from 'global/type';
import {
  CREATE_EXPERIMENT,
  DESTROY_EXPERIMENT,
  UPDATE_EXPERIMENT,
  CREATE_LAB_USER,
  CHANGE_EXPERIMENT_PUBLISH_STATUSES,
} from 'graphql/mutations';
import { GET_EXPERIMENT_TYPES, GET_EXPERIMENTS, GET_LAB_USERS, GET_SEQUENCES } from 'graphql/queries';
import { useItemForm } from 'hooks/useItemForm';
import { useSnackbar } from 'hooks/useSnackbar';
import { FormActions } from 'hooks/useTable';
import { LabUser } from 'pages/lab-users/forms';
import { SequenceFormData } from 'pages/sequence/forms';
import { ResolverError, UseFormTrigger, UseFormWatch } from 'plugins/react-hook-form';
import { validateTextField, validateUrl } from 'plugins/validator';

import {
  EXPERIMENT_DISABLED_FIELDS,
  EXPERIMENT_FORM,
  ExperimentFormData,
  Key as ExperimentKey,
  ExperimentType,
  ExperimentTypeData,
  FIELDS_TO_VALIDATE_BY_TYPE,
  InsightKey,
  UserType,
} from '../forms';

export interface UseExperimentForm {
  readOnly: boolean;
  watch: UseFormWatch<ExperimentFormData>;
  trigger: UseFormTrigger<ExperimentFormData>;
  errors: ResolverError<ExperimentFormData>['errors'];
  formActions: FormActions;
  labUserList: LabUser[] | undefined;
  setTitle: (e: CombinedChangeEvent) => void;
  setShowInsights: (boolean) => void;
  setShowInsightToggle: (experimentKey, checked: boolean) => void;
  setType: (e: CombinedChangeEvent) => void;
  setStartDate: (value: Date | null | undefined) => void;
  setSequence: (_: SyntheticEvent<Element, Event>, value: ExperimentFormData['packageSequenceId'] | null) => void;
  setFrequency: (e: CombinedChangeEvent) => void;
  createLabUserHandler: (userData: LabUser, externalUserType?: UserType) => Promise<LabUser | void>;
  setUsers: (_: SyntheticEvent<Element, Event>, value: LabUser[]) => void;
  setCompletionCode: (e: CombinedChangeEvent) => void;
  setRedirectUrl: (e: CombinedChangeEvent) => void;
  setIsDisplayBranding: (value: boolean) => void;
  getSequenceOption: (option: number | undefined) => string;
  sequenceIds: (string | undefined)[];
  experimentTypesList: ExperimentTypeData[];
  disableInputs: (field: ExperimentKey, type?: ExperimentType | string) => boolean;
  setShowThankYouPage: (value: boolean) => void;
  setShowMomentSaved: (value: boolean) => void;
  setShowThankYouPageNavigation: (value: boolean) => void;
  setShowEmailPage: (value: boolean) => void;
  setIsEndSessionFunctionalityEnabled: (value: boolean) => void;
}

export interface Props {
  action: PathParams;
  providedFormValues: ExperimentFormData;
  refetch?: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<{ experiment: ExperimentFormData }>>;
}

export const useExperimentForm = ({ providedFormValues, action, refetch }: Props): UseExperimentForm => {
  const { showSuccessToast } = useSnackbar();

  const { id: questionId } = useParams();

  const [createLabUser] = useMutation<{
    data: LabUser;
  }>(CREATE_LAB_USER, { refetchQueries: [GET_LAB_USERS] });

  const { data: labUsersData, refetch: refetchUserList } = useQuery<{ users: LabUser[] }>(GET_LAB_USERS);
  const { data: getSequencesResult } = useQuery<{ packageSequences: SequenceFormData[] }>(GET_SEQUENCES);

  const { data: experimentTypesData } = useQuery(GET_EXPERIMENT_TYPES);

  const parseData = (values: ExperimentFormData) => {
    const { updatedAt, duration, createdAt, uid, __typename, createdBy, status, endDate, ...formValues } = values;

    formValues.packageSequenceId = formValues.packageSequenceId ?? undefined;
    formValues.startDate = formValues.startDate || undefined;
    formValues.users = formValues.users?.map(user => user.id) || undefined;
    formValues.showThankYouPage = formValues.showThankYouPage === null ? true : formValues.showThankYouPage;
    formValues.showMomentSaved = formValues.showMomentSaved ?? false;
    formValues.showThankYouPageNavigation =
      formValues.showThankYouPageNavigation === null ? true : formValues.showThankYouPageNavigation;
    formValues.isEndSessionFunctionalityEnabled =
      formValues.type !== ExperimentType.ONE_LINK_ANON_USER ? false : values.isEndSessionFunctionalityEnabled ?? false;
    formValues.showEmailPage = formValues.showEmailPage ?? false;

    // handle insight toggles business logic on save
    const insightFormValues = [
      formValues.showAutoSummaryInsights,
      formValues.showArtworkInsights,
      formValues.showMusicInsights,
      formValues.showAnimationInsights,
      formValues.showPersonalityInsights,
      formValues.showSentimentInsights,
      formValues.showSpeakingInsights,
      formValues.showWordFrequencyInsights,
      formValues.showQrCode,
      formValues.showOneVideo,
    ];

    // if all insight toggles are false, or showInsights is not set, showInsights must also be false.
    // else, if showInsights is false or null, switch all insight toggles to false
    // since showInsights defaults to `false`, we don't have to explicitly set it here.
    if (formValues.showInsights) {
      const allTogglesFalse = insightFormValues.every(value => value === false);
      if (allTogglesFalse) formValues.showInsights = false;
    } else {
      insightFormValues.forEach(value => (value = false));
    }

    return formValues;
  };

  const mapFormValue = (key: string, value: any) => {
    switch (key) {
      case ExperimentKey.USERS:
      case ExperimentKey.SHOW_INSIGHTS:
      case ExperimentKey.SHOW_AUTO_SUMMARY_INSIGHTS:
      case ExperimentKey.SHOW_ARTWORK_INSIGHTS:
      case ExperimentKey.SHOW_MUSIC_INSIGHTS:
      case ExperimentKey.SHOW_ANIMATION_INSIGHTS:
      case ExperimentKey.SHOW_PERSONALITY_INSIGHTS:
      case ExperimentKey.SHOW_SENTIMENT_INSIGHTS:
      case ExperimentKey.SHOW_SPEAKING_INSIGHTS:
      case ExperimentKey.SHOW_WORD_FREQUENCY_INSIGHTS:
      case ExperimentKey.SHOW_QR_CODE:
        return value;
      default:
        return value ?? '';
    }
  };

  const { formActions, formErrors, formLoading, register, setValue, trigger, watch, getValues } =
    useItemForm<ExperimentFormData>({
      action,
      defaultValues: EXPERIMENT_FORM,
      itemDisplayName: 'Experiment',
      createMutation: CREATE_EXPERIMENT,
      updateMutation: UPDATE_EXPERIMENT,
      destroyMutation: DESTROY_EXPERIMENT,
      changePublishStatusMutation: CHANGE_EXPERIMENT_PUBLISH_STATUSES,
      getItemsQuery: GET_EXPERIMENTS,
      parseData,
      mapFormValue,
      editPathId: questionId,
    });

  const { id, users, packageSequenceId, startDate, frequency } = watch();

  const readOnly: boolean = action === PathParams.SHOW || formLoading;

  const createLabUserHandler: UseExperimentForm['createLabUserHandler'] = async (userData, externalUserType) => {
    const { data: user, errors } = await createLabUser({
      variables: {
        input: { ...userData, ...(externalUserType && { externalUserType }) },
      },
    });

    setValue(ExperimentKey.USERS, [...(users as LabUser[]), user?.data as LabUser]);

    if (refetch) {
      const { data } = await refetch();
      setFormValues(data.experiment);
    }

    if (errors?.length) return;

    showSuccessToast(`Lab user successfully created`);
    await refetchUserList();

    return user?.data;
  };

  const setFrequency: UseExperimentForm['setFrequency'] = e => {
    const MIN_VALUE = 1;
    const MAX_VALUE = 10;

    const value = Math.abs(Number(e.target.value)) || MIN_VALUE;

    if (value > MAX_VALUE) return setValue(ExperimentKey.FREQUENCY, MAX_VALUE);
    if (value < MIN_VALUE) return setValue(ExperimentKey.FREQUENCY, MIN_VALUE);

    return setValue(ExperimentKey.FREQUENCY, value);
  };

  const setTitle: UseExperimentForm['setTitle'] = e =>
    setValue(ExperimentKey.TITLE, e?.target?.value as ExperimentType);

  const setShowInsights: UseExperimentForm['setShowInsights'] = checked => {
    // set value for showInsights, and handle form updates triggered by toggling
    // when showInsights is toggled to true, all insights except for Music, QR code, and animation should be true
    // when showInsights is toggled to false, all insights should be false by default
    Object.values(InsightKey)
      .filter(
        insightKey =>
          ![InsightKey.SHOW_MUSIC_INSIGHTS, InsightKey.SHOW_QR_CODE, InsightKey.SHOW_ANIMATION_INSIGHTS].includes(
            insightKey,
          ),
      )
      .forEach(insightKey => setShowInsightToggle(insightKey, checked));

    if (!checked) {
      setValue(ExperimentKey.SHOW_THANK_YOU_PAGE_NAVIGATION, false);
    }

    setValue(ExperimentKey.SHOW_INSIGHTS, checked);
  };

  const setShowInsightToggle: UseExperimentForm['setShowInsightToggle'] = (experimentKey, checked) => {
    setValue(experimentKey, checked);
  };

  const setType: UseExperimentForm['setType'] = e => {
    const newExperimentType = e.target.value as ExperimentType;
    setValue(ExperimentKey.TYPE, newExperimentType);
    if (disableInputs(ExperimentKey.START_DATE, newExperimentType)) {
      setStartDate(null);
    }
  };

  const setCompletionCode: UseExperimentForm['setCompletionCode'] = e =>
    setValue(ExperimentKey.COMPLETION_CODE, (e?.target?.value as ExperimentType) || undefined);

  const setStartDate: UseExperimentForm['setStartDate'] = value =>
    setValue(ExperimentKey.START_DATE, value || undefined);

  const setSequence: UseExperimentForm['setSequence'] = (_, value) => {
    value && setValue(ExperimentKey.PACKAGE_SEQUENCE_ID, value);
  };

  const setRedirectUrl: UseExperimentForm['setRedirectUrl'] = e => {
    const value = e?.target?.value as string;

    if (!value) {
      setValue(ExperimentKey.SHOW_THANK_YOU_PAGE, true);
      setValue(ExperimentKey.SHOW_THANK_YOU_PAGE_NAVIGATION, false);
    }

    setValue(ExperimentKey.REDIRECT_URL, value ?? undefined);
  };

  const setUsers: UseExperimentForm['setUsers'] = (_, value) => setValue(ExperimentKey.USERS, value);

  const setEndDate = (value: Date | null | undefined): void => setValue(ExperimentKey.END_DATE, value || undefined);

  const setDuration = (value: number): void => setValue(ExperimentKey.DURATION, value);

  const setIsDisplayBranding = (value: boolean): void => setValue(ExperimentKey.IS_DISPLAY_BRANDING, value);

  const setShowThankYouPage = (value: boolean): void => {
    if (!value) {
      setValue(ExperimentKey.SHOW_THANK_YOU_PAGE_NAVIGATION, false);
    }

    setValue(ExperimentKey.SHOW_THANK_YOU_PAGE, value);
  };

  const setShowThankYouPageNavigation = (value: boolean): void =>
    setValue(ExperimentKey.SHOW_THANK_YOU_PAGE_NAVIGATION, value);

  const setIsEndSessionFunctionalityEnabled = (value: boolean): void => {
    setValue(ExperimentKey.IS_END_SESSION_FUNCTIONALITY_ENABLED, value);
  };
  const setShowMomentSaved = (value: boolean): void => setValue(ExperimentKey.SHOW_MOMENT_SAVED, value);

  const setShowEmailPage = (value: boolean): void => setValue(ExperimentKey.SHOW_EMAIL_PAGE, value);

  const currentSequence = useMemo<SequenceFormData | undefined>(
    () => getSequencesResult?.packageSequences?.find(sequence => sequence.id === packageSequenceId),
    [packageSequenceId, getSequencesResult?.packageSequences],
  );

  const setExperimentInterval = (): void => {
    if (!startDate || !currentSequence?.packageNumber) return;

    const duration = Math.ceil(currentSequence.packageNumber / frequency);
    const date = dateFns.addDays(new Date(startDate), duration);

    setEndDate(date);
    setDuration(duration);
  };

  const sequenceIds: UseExperimentForm['sequenceIds'] = useMemo(
    () => getSequencesResult?.packageSequences?.map(sequence => sequence?.id) || [],
    [getSequencesResult?.packageSequences],
  );

  const getSequenceOption: UseExperimentForm['getSequenceOption'] = option =>
    getSequencesResult?.packageSequences?.find(sequence => sequence.id === option)?.title || '';

  useEffect(() => setExperimentInterval(), [startDate, currentSequence?.packageNumber, frequency]);

  const setFormValues = (formValues: ExperimentFormData): void => {
    const mapItem = (itemKey: ExperimentKey, value: any) => {
      switch (itemKey) {
        case ExperimentKey.USERS:
        case ExperimentKey.IS_DISPLAY_BRANDING:
        case ExperimentKey.SHOW_INSIGHTS:
        case ExperimentKey.SHOW_AUTO_SUMMARY_INSIGHTS:
        case ExperimentKey.SHOW_ARTWORK_INSIGHTS:
        case ExperimentKey.SHOW_MUSIC_INSIGHTS:
        case ExperimentKey.SHOW_ANIMATION_INSIGHTS:
        case ExperimentKey.SHOW_PERSONALITY_INSIGHTS:
        case ExperimentKey.SHOW_SENTIMENT_INSIGHTS:
        case ExperimentKey.SHOW_SPEAKING_INSIGHTS:
        case ExperimentKey.SHOW_WORD_FREQUENCY_INSIGHTS:
        case ExperimentKey.SHOW_QR_CODE:
        case ExperimentKey.SHOW_THANK_YOU_PAGE:
        case ExperimentKey.SHOW_MOMENT_SAVED:
        case ExperimentKey.SHOW_THANK_YOU_PAGE_NAVIGATION:
        case ExperimentKey.SHOW_EMAIL_PAGE:
        case ExperimentKey.SHOW_ONE_VIDEO:
        case ExperimentKey.IS_END_SESSION_FUNCTIONALITY_ENABLED:
          return { key: itemKey, value: value };
        default:
          return { key: itemKey, value: value || '' };
      }
    };

    for (const key in formValues) {
      const { key: itemKey, value } = mapItem(key as ExperimentKey, formValues[key]);
      setValue(itemKey as keyof typeof formValues, value);
    }
  };

  /**
   * Disable form fields depending on experiment type
   */
  const disableInputs = (field: ExperimentKey, type?: ExperimentType | string) =>
    EXPERIMENT_DISABLED_FIELDS[field].includes(type);

  const validationNeeded = (field: ExperimentKey, type?: ExperimentType | string) =>
    type ? FIELDS_TO_VALIDATE_BY_TYPE[type].includes(field) : false;

  useEffect(() => {
    register(ExperimentKey.TITLE, {
      validate: value => validateTextField(value, 'Title'),
    });

    register(ExperimentKey.TYPE, {
      validate: value => (value?.length ? true : 'Experiment type - required'),
    });

    register(ExperimentKey.SHOW_INSIGHTS, {
      validate: value => (value !== null ? true : 'Show Insights - required'),
    });

    register(ExperimentKey.SHOW_ARTWORK_INSIGHTS, {
      validate: value => (value !== null ? true : 'Show Artwork Insights - required'),
    });

    register(ExperimentKey.IS_DISPLAY_BRANDING, {
      validate: value => (value !== undefined ? true : 'Display Lotic Branding - required'),
    });

    register(ExperimentKey.SHOW_THANK_YOU_PAGE, {
      validate: value => {
        const { redirectUrl, type } = getValues();
        return value === null && redirectUrl && type === ExperimentType.ONE_LINK_ANON_USER
          ? `Show 'Thank You' Page - required`
          : true;
      },
    });

    register(ExperimentKey.SHOW_MOMENT_SAVED, {
      validate: value => (value !== undefined ? true : "Show 'Moment Saved' - required"),
    });

    register(ExperimentKey.SHOW_THANK_YOU_PAGE_NAVIGATION, {
      validate: value => {
        const { showInsights, showThankYouPage, type } = getValues();
        const isOneLinkType = type === ExperimentType.ONE_LINK_ANON_USER;

        return value === null && ((isOneLinkType && showThankYouPage) || (!isOneLinkType && showInsights))
          ? `Show 'Thank You' Page Navigation - required`
          : true;
      },
    });

    register(ExperimentKey.SHOW_EMAIL_PAGE, {
      validate: value => (value !== undefined ? true : "Show 'Email' Page - required"),
    });

    register(ExperimentKey.IS_END_SESSION_FUNCTIONALITY_ENABLED, {
      validate: value => {
        if (validationNeeded(ExperimentKey.IS_END_SESSION_FUNCTIONALITY_ENABLED, watch(ExperimentKey.TYPE))) {
          return value === null ? `End Session Functionality - required` : true;
        }
        return true;
      },
    });

    //Only try to validate if redirectUrl is provided, else return true
    register(ExperimentKey.REDIRECT_URL, {
      validate: value => (value ? validateUrl(value, 'Invalid redirect URL - Please include full URL') : true),
    });

    register(ExperimentKey.COMPLETION_CODE, {
      validate: value => {
        if (validationNeeded(ExperimentKey.COMPLETION_CODE, watch(ExperimentKey.TYPE))) {
          return validateTextField(value ?? '', 'Completion code');
        }
        return true;
      },
    });

    register(ExperimentKey.PACKAGE_SEQUENCE_ID, {
      validate: value => !!value || 'Sequence required',
    });
  }, [register]);

  useEffect(() => {
    if (providedFormValues) {
      setFormValues(providedFormValues);
    }
  }, []);

  const previewAction = {
    text: 'Preview',
    action: () => window.open(config.env.LABS_UI_HOST + '/experience/' + id + '?testUser=true'),
    Icon: OpenInNewIcon,
  };

  const extendedFormActions: FormActions = {
    inline: [...(formActions.inline ?? []), ...(action === PathParams.SHOW ? [previewAction] : [])],
    expanded: formActions.expanded,
  };

  return {
    readOnly,
    watch,
    trigger,
    errors: formErrors,
    formActions: extendedFormActions,
    labUserList: labUsersData?.users,
    setTitle,
    setType,
    setStartDate,
    setSequence,
    setFrequency,
    setUsers,
    setRedirectUrl,
    setShowInsights,
    setShowInsightToggle,
    setIsDisplayBranding,
    sequenceIds,
    getSequenceOption,
    setCompletionCode,
    createLabUserHandler,
    experimentTypesList: experimentTypesData?.experimentTypes,
    disableInputs,
    setShowThankYouPage,
    setShowMomentSaved,
    setShowThankYouPageNavigation,
    setIsEndSessionFunctionalityEnabled,
    setShowEmailPage,
  };
};
