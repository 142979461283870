import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { Button, Card, CardContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { setModal } from 'store/modal';
import { Modal } from 'store/common-modal';

const SELECTION_MESSAGE = ' items selected';
const CARD_ELEVATION = 0;
const CIRCULAR_PROGRESS_SIZE = 16;

interface Props {
  selectedCount: number;
  actions: Modal[];
  progressSize?: number;
  cardElevation?: number;
  selectionMessage?: string;
}

export const Bulk: FC<Props> = ({
  actions,
  selectedCount,
  progressSize = CIRCULAR_PROGRESS_SIZE,
  cardElevation = CARD_ELEVATION,
  selectionMessage = SELECTION_MESSAGE,
  ...props
}) => {
  const dispatch = useDispatch();

  let className = 'bulk-actions-card';
  if (selectedCount) {
    className = className + ' expanded';
  }

  return (
    <Card elevation={cardElevation} className={className}>
      {selectedCount ? (
        <CardContent style={{ fontWeight: 500 }}>{selectedCount + selectionMessage}</CardContent>
      ) : (
        <CircularProgress size={progressSize} />
      )}
      <div>
        {/* generate bulk actions */}
        {actions.map((modal, key) => (
          <Fragment key={key}>
            <AddIcon fontSize="small" color="primary" style={{ verticalAlign: 'middle' }} />
            <Button {...props} onClick={() => dispatch(setModal(modal))}>
              {modal?.confirm?.buttonText || modal?.confirm?.text}
            </Button>
          </Fragment>
        ))}
      </div>
    </Card>
  );
};
