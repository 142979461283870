import { useState } from 'react';
import { EditorProps } from 'react-draft-wysiwyg';

import { AtomicBlockUtils, ContentState, RawDraftContentState } from 'draft-js';

import { AssetFormData } from 'components/asset';
import { getFragmentBlockKey, htmlToDraft } from 'components/react-draft/utils';

import { appendAssetIdParam, buildStringMediaElement } from './service';

export interface UseFilePickerReturnValue {
  galleryState: boolean;
  onSubmit: (asset: AssetFormData) => void;
  onClose: () => void;
  onPluginClick: () => void;
}

export interface Props extends EditorProps {}
/**
 * useFilePicker - implements the ability to add assets with a unique identifier to the content of the editor
 * @param { editorState } EditorProps.editorState - current editor state
 * @param { onChange } EditorProps.onChange - callback passed from "react-draft-wysiwyg" to change the state of the editor
 */

export const useFilePicker = ({ editorState, onChange }: Props): UseFilePickerReturnValue => {
  const [galleryState, setGalleryState] = useState<boolean>(false);

  const onSubmit = ({ url: assetUrl, id, assetType }: AssetFormData): void => {
    setGalleryState(false);

    const isFilledAsset = id && assetUrl && assetType;

    if (editorState && onChange && isFilledAsset) {
      // wait for the gallery to close
      setTimeout(() => {
        const url = appendAssetIdParam(assetUrl, id);
        const mediaElement = buildStringMediaElement({ assetType, url });
        const { contentBlocks, entityMap } = htmlToDraft(mediaElement);
        const fragment = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const fragmentKey = getFragmentBlockKey(editorState, fragment);
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
          editorState,
          fragmentKey,
          ' ',
        ) as unknown as RawDraftContentState;

        // wait for focus on editor content and run onchange
        setTimeout(() => onChange(newEditorState));
      });
    }
  };

  const onPluginClick = (): void => setGalleryState(true);
  const onClose = (): void => setGalleryState(false);

  return { galleryState, onSubmit, onClose, onPluginClick };
};
