import { Modal } from 'store/common-modal';

export const getConfirmNavigationAction = (
  state: boolean,
  confirmAction: () => Promise<void>,
  cancelAction: () => void,
): Modal => ({
  title: 'Save the page?',
  text: 'This page is not saved yet. Do you want to save this page and proceed to another page?',
  state,
  confirm: {
    action: confirmAction,
    text: 'Yes',
  },
  cancel: {
    action: cancelAction,
    text: 'No',
  },
});
