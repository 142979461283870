import { useEffect } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, Typography } from '@mui/material';

import { Title } from 'components/inputs';
import { PostPageType } from 'components/post/forms';
import { MediaTypeInputs } from 'components/post/forms';
import { ImageInput, MediaLink, MediaType } from 'components/post/inputs';
import { ReactDraft } from 'components/react-draft';
import { editorContentToHtml, EditorState, htmlToEditorContent } from 'components/react-draft/utils';
import { setLabel } from 'global/form';
import { validateUrl } from 'plugins/validator';

import { getPostPageKey, PostPageKey } from '.';

export const PostPageForm = ({
  postPage,
  index,
  display,
  action,
  postForm,
  disabled,
  deletePageHandler,
  providedFormValues,
  isPostPageError,
}) => {
  const { setValue, formLoading, textEditors, getBannerChangeHandler, getBannerDeleteHandler, getBannerSubmitHandler } =
    postForm;

  const { getEditorSetter, editorStates } = textEditors;

  const setEditorState = getEditorSetter(index);

  const onEditorStateChange = (editorState: EditorState) => {
    const editorHtmlContent = editorContentToHtml(editorState.getCurrentContent());
    postPage.content = editorHtmlContent;

    setEditorState(editorState);
  };

  useEffect(() => {
    if (providedFormValues) {
      const parsedState = htmlToEditorContent(postPage.content);
      setEditorState(parsedState);
    }
  }, [providedFormValues, postPage.order]);

  const isVideo = postPage.mediaType === PostPageType.VIDEO;

  const showInput = (inputKey: PostPageKey) => MediaTypeInputs[postPage.mediaType].includes(inputKey);

  return (
    <Box style={{ display }} key={`${postPage.id}${postPage.uid}`}>
      <MediaType
        value={postPage.mediaType}
        label={setLabel('Media Type', action)}
        onChange={e => setValue(getPostPageKey(PostPageKey.MEDIA_TYPE, index), e.target.value as PostPageType)}
        disabled={Boolean(postPage.id) || disabled || formLoading}
        required={true}
      />

      {showInput(PostPageKey.TITLE) && (
        <Title
          value={postPage.title ?? ''}
          label={'Title'}
          onChange={e => setValue(getPostPageKey(PostPageKey.TITLE, index), e.target.value as string)}
          disabled={disabled || formLoading}
        />
      )}
      {showInput(PostPageKey.BANNER_IMAGE) && (
        <ImageInput
          url={(postPage.bannerImage?.url || '') as string}
          onSubmit={getBannerSubmitHandler(index)}
          onDelete={getBannerDeleteHandler(index)}
          onChange={getBannerChangeHandler(index)}
          imagePreviewProps={{
            uploaderMinWidth: 500,
            label: isVideo ? 'Video preview' : 'Banner image',
          }}
          disabled={disabled || formLoading}
        />
      )}

      {showInput(PostPageKey.MEDIA_LINK) && (
        <MediaLink
          value={postPage.mediaLink ?? ''}
          onChange={e => setValue(getPostPageKey(PostPageKey.MEDIA_LINK, index), e.target.value as string)}
          error={isPostPageError && !validateUrl(postPage.mediaLink, '')}
          disabled={disabled || formLoading}
        />
      )}
      {showInput(PostPageKey.CONTENT) && (
        <ReactDraft
          disabled={disabled}
          label="Content"
          editorState={editorStates[index]}
          onEditorStateChange={onEditorStateChange}
        />
      )}
      {!disabled && editorStates.length && (
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end', my: 2 }}>
          <Button sx={{ color: 'red' }} onClick={deletePageHandler}>
            <DeleteForeverIcon />
            <Typography variant="button"> Remove current page </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};
