import { Entity, RoutePath } from 'app/routes';

import { buildChildrenRoutes } from 'global/buildChildrenRoutes';

import { Create } from './Create';
import { Edit } from './Edit';
import { List } from './List';
import { Show } from './Show';

const routes = buildChildrenRoutes(RoutePath.CAROUSELS, Entity.CAROUSEL, {
  elements: { list: <List />, create: <Create />, show: <Show />, edit: <Edit /> },
});

export default routes;
