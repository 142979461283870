import { FC } from 'react';

import { RoutePath } from 'app/routes';

import { GET_MOMENTS } from 'graphql/queries';
import { getShowPagePath } from 'hooks/useTable';
import { MomentTable } from 'pages/moments/tables/Moment';

import { MOMENT_SECTION_COLUMNS } from './forms';

export const List: FC = () => {
  return (
    <MomentTable
      query={GET_MOMENTS}
      columns={MOMENT_SECTION_COLUMNS}
      onGetLink={({ row }) => getShowPagePath(RoutePath.MOMENTS, String(row.uid))}
    />
  );
};
