import { useDispatch } from 'react-redux';

import { ApolloClient, DocumentNode, NormalizedCacheObject, useMutation } from '@apollo/client';

import { getBulkActions } from 'components/tables/grid-table/bulkActions';
import { BULK_DESTROY_WISDOM_ENGINE_PROMPTS, CHANGE_WISDOM_ENGINE_PROMPTS_PUBLISH_STATUSES } from 'graphql/mutations';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTable } from 'hooks/useTable';

import { FormData, Key } from '../forms';

const DATA_KEY = 'wisdomEnginePrompts';
const COUNTER_KEY = 'wisdomEnginePromptsTotalCount';

export interface Props {
  query: DocumentNode;
  client?: ApolloClient<NormalizedCacheObject>;
  where?: object;
}

interface Data {
  wisdomEnginePrompts: FormData[];
  wisdomEnginePromptsTotalCount: number;
}

interface Search {
  idOrQuestion: string;
}

export const useWisdomEnginePromptsTable = ({ query, where, client }: Props) => {
  const { showSuccessToast } = useSnackbar();
  const { updatePage, bulkObject, page, getFilter, ...args } = useTable<Data['wisdomEnginePrompts']>({
    dataKey: DATA_KEY,
    counterKey: COUNTER_KEY,
    query: {
      query: query,
      where,
      client,
    },
  });

  const bulkDelete = useMutation(BULK_DESTROY_WISDOM_ENGINE_PROMPTS, { client });
  const bulkChangePublishState = useMutation(CHANGE_WISDOM_ENGINE_PROMPTS_PUBLISH_STATUSES, { client });

  const bulkActions = getBulkActions({
    bulkObject,
    updatePage,
    enqueueSnackbar: showSuccessToast,
    bulkDelete,
    bulkChangePublishState,
    entityName: 'wisdom engine prompt',
    dispatch: useDispatch(),
  });

  const filterAction = getFilter({
    options: Object.values(Key),
    excludeOptions: [Key.PUBLISHED_AT, Key.CREATED_AT, Key.UPDATED_AT],
  });

  return {
    ...args,
    page,
    bulkActions,
    filterAction,
  };
};
