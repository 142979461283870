import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { RouteAction } from 'global/route';

import { Create } from './Create';
import { Edit } from './Edit';
import { List } from './List';
import { Show } from './Show';

export const ExperimentsPage: FC = () => (
  <Routes>
    <Route index element={<List />} />
    <Route path={RouteAction.CREATE} element={<Create />} />
    <Route path={RouteAction.SHOW} element={<Show />} />
    <Route path={RouteAction.EDIT} element={<Edit />} />
  </Routes>
);
