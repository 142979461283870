import { FC } from 'react';

import { RoutePath } from 'app/routes';

import { GET_EXPERIMENTS } from 'graphql/queries';
import { getShowPagePath } from 'hooks/useTable';

import { EXPERIMENT_TABLE_COLUMNS } from './forms/index';
import { ExperimentTable } from './tables/Experiment';

export const List: FC = () => {
  return (
    <ExperimentTable
      query={GET_EXPERIMENTS}
      columns={EXPERIMENT_TABLE_COLUMNS}
      onGetLink={({ row }) => getShowPagePath(RoutePath.EXPERIMENTS, String(row.uid))}
    />
  );
};
