import { FC, MouseEventHandler, useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { Fade, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from '@mui/material';

interface Props {
  signOut: () => void;
  email: string;
}

export const UserCard: FC<Props> = ({ email, signOut }) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);

  const clickHandler: MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeHandler: MenuProps['onClose'] = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span className="app-bar_email">{email}</span>
      <IconButton onClick={clickHandler} color="inherit">
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{ 'aria-labelledby': 'fade-button' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeHandler}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className="app-bar_menu-item">Log out</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};
