import { FC } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, AppBar as MuiAppBar, Theme, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { APP_CLIENT } from 'app/manager';

interface Props {
  config: {
    theme: Theme;
    toggleDrawer: () => void;
  };
}

export const AppBar: FC<Props> = ({ config: { theme, toggleDrawer } }) => {
  const { AdminCard, title } = APP_CLIENT;

  return (
    <MuiAppBar sx={{ 'z-index': theme.zIndex.drawer + 1 }} position="absolute">
      <Toolbar variant="dense">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{ marginRight: '36px' }}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <AdminCard />
      </Toolbar>
    </MuiAppBar>
  );
};
