import { FC } from 'react';

import { Card, CardContent } from '@mui/material';

import { DateInput, ID, Tags, Title } from 'components/inputs';
import { ActionButtons } from 'components/inputs/ActionButtons';
import { ImageInput } from 'components/post/inputs';
import { ViewTable } from 'components/tables/view-table';
import { PathParams } from 'global/route';

import './index.scss';

import { Group } from '../components/Group';
import { usePackageForm } from '../hooks/usePackageForm';

import { PACKAGE_FORM, PACKAGE_ITEMS_COLUMNS, PackageFormData, PackageKey } from '.';
import { GridItemView } from 'components/common';
import { isTagsValid } from 'utils';

interface Props {
  action: PathParams;
  values?: PackageFormData;
}

export const Package: FC<Props> = ({ action, values = PACKAGE_FORM }) => {
  const {
    watch,
    formActions,
    setValue,
    trigger,
    errors,
    filterOptions,
    setSelected,
    addItem,
    removeItem,
    setPackageItems,
    mappedContent,
    disabled,
    watchThumbnail,
    thumbnailSubmitHandler,
    thumbnailDeleteHandler,
    thumbnailChangeHandler,
    formLoading,
    setTags,
  } = usePackageForm({
    providedFormValues: values as PackageFormData,
    action,
  });

  const { uid, title, description, packageItems, thumbnail, tags, updatedAt, createdAt } = watch();
  const { url: thumbnailUrl } = watchThumbnail();

  return (
    <Card>
      <CardContent sx={{ mb: '35px' }}>
        {uid && <ID value={uid} disabled />}
        <Title
          value={title}
          label={'Title'}
          onChange={e => setValue(PackageKey.TITLE, e.target.value)}
          onBlur={() => trigger(PackageKey.TITLE)}
          helperText={errors?.title?.message}
          error={Boolean(errors.title)}
          disabled={disabled}
        />
        <Title
          value={description}
          label={'Description'}
          onChange={e => setValue(PackageKey.DESCRIPTION, e.target.value)}
          onBlur={() => trigger(PackageKey.DESCRIPTION)}
          helperText={errors?.description?.message}
          error={Boolean(errors.description)}
          disabled={disabled}
        />
        <ImageInput
          onSubmit={thumbnailSubmitHandler}
          onDelete={thumbnailDeleteHandler}
          onChange={thumbnailChangeHandler}
          url={thumbnail?.url || thumbnailUrl}
          imagePreviewProps={{ label: 'Thumbnail' }}
          disabled={disabled || formLoading}
        />
        <GridItemView
          style={{ marginTop: 12 }}
          label="Tag"
          item={<Tags tags={isTagsValid(tags) ? tags : []} setTags={setTags} disabled={disabled} />}
        />
        {!disabled && mappedContent && (
          <Group
            mappedContent={mappedContent}
            packageItems={packageItems}
            title={'Package Items'}
            filterOptions={filterOptions}
            setSelected={setSelected}
            removeItem={removeItem}
            addItem={addItem}
            setPackageItems={setPackageItems}
          />
        )}
        {disabled && <ViewTable rows={packageItems ?? []} columns={PACKAGE_ITEMS_COLUMNS} label={'Package Items:'} />}
        {disabled && <DateInput sx={{ mt: 2 }} label="Updated" value={updatedAt} disabled />}
        {disabled && <DateInput sx={{ mt: 2 }} label="Created" value={createdAt} disabled />}
      </CardContent>
      <ActionButtons actions={formActions} />
    </Card>
  );
};
