import { FC, useMemo } from 'react';

import { Button } from '@mui/material';

import { ContentItem, PackageItem } from '../forms';
import { Card } from 'components/common/activity-card';

interface Props {
  mappedContent: ContentItem[];
  packageItems: PackageItem[];
  title: string;
  filterOptions: (options: ContentItem[], uid: string, packageItems: PackageItem[]) => ContentItem[];
  setSelected: (
    uid: string | undefined,
    itemId: number | undefined,
    updatedPackageItem: PackageItem,
    packageItems: PackageItem[],
  ) => void;
  removeItem: (uid: string, packageItems: PackageItem[]) => void;
  addItem: (packageItems: PackageItem[]) => void;
  setPackageItems: (packageItems: PackageItem[]) => void;
}

export const Group: FC<Props> = ({
  mappedContent,
  packageItems,
  title,
  filterOptions,
  setSelected,
  removeItem,
  addItem,
  setPackageItems,
}) => {
  const moveCard = (dragIndex: number, hoverIndex: number): void => {
    const sourceItem = packageItems.find(item => item.uid === packageItems[dragIndex].uid);
    const destItem = packageItems.find(item => item.uid === packageItems[hoverIndex].uid);
    if (sourceItem && destItem) {
      const updatedPackageItems = [...packageItems];
      const item1Index = packageItems.findIndex(item => item.uid === sourceItem.uid);
      const item2Index = packageItems.findIndex(item => item.uid === destItem.uid);
      updatedPackageItems[item1Index] = { ...sourceItem, position: destItem.position };
      updatedPackageItems[item2Index] = { ...destItem, position: sourceItem.position };
      setPackageItems(updatedPackageItems);
    }
  };

  const positionItems = useMemo(
    () =>
      packageItems.map((packageItem, index) => (
        <div key={packageItem.itemId} className="position-column-item">
          {index + 1}
        </div>
      )),
    [packageItems],
  );

  const packageItemsCards = useMemo(
    () =>
      packageItems.map((packageItem, key) => (
        <div key={packageItem.position}>
          <Card<PackageItem, ContentItem>
            key={packageItem.uid}
            uid={packageItem.uid}
            index={key}
            fullContent={mappedContent}
            cardItem={packageItem}
            cardItems={packageItems}
            setSelected={setSelected}
            removeItem={removeItem}
            labelSource={'title'}
            filterOptions={filterOptions}
            moveCard={moveCard}
          />
        </div>
      )),
    [packageItems, mappedContent, setSelected, removeItem, filterOptions, moveCard],
  );

  return (
    <div className="constructor-items">
      <div className="category">{title}</div>
      <div className="item-columns">
        <div className="position-column">{positionItems}</div>
        <div className="content-column">{packageItemsCards}</div>
      </div>
      <Button
        variant="contained"
        disabled={packageItems.some(item => item.uid === '')}
        onClick={() => addItem(packageItems)}
      >
        Add Activity
      </Button>
    </div>
  );
};
