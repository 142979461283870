import Joi from 'joi';

export const validateEmail = (email: string, errorMessage: string, options?: Joi.EmailOptions): string | true => {
  try {
    const OPTIONS: Joi.EmailOptions = {
      tlds: { allow: false },
      ...options,
    };

    Joi.attempt(email, Joi.string().email(OPTIONS));

    return true;
  } catch (_) {
    return errorMessage;
  }
};

export const validateUrl = (url: string, errorMessage: string): string | true => {
  try {
    new URL(url);
  } catch (_) {
    return errorMessage;
  }
  return true;
};

export const validateTextField = (text: string, fieldName: string): string | true => {
  if (/^(?!^\s*$).+$/.test(text)) {
    return true;
  }
  return fieldName + ' required';
};
