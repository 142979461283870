import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { NotFound } from 'components/NotFound';
import { stringToInt } from 'global/formater';
import { PathParams } from 'global/route';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_MOMENT } from 'graphql/queries';

import { Moment, MomentFormData } from '.';

interface Props {
  action: PathParams;
}
export const SaveForm: FC<Props> = ({ action }) => {
  const { id } = useParams();

  const { data, loading } = useQuery<{ moment: MomentFormData }>(GET_MOMENT, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: stringToInt(id as string),
    },
  });

  if (loading) return <></>;
  else if (data?.moment) return <Moment action={action} providedFormValues={data.moment} />;
  else return <NotFound />;
};
