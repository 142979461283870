import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { API_HOST, AuthRoute } from 'constants/auth';
import { useGoogleLogout } from 'hooks/useGoogleAuth';
import { selectUser } from 'store/google-user';

import './index.scss';
import { UserCard } from './UserCard';

export const CmsUserCard: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email } = useSelector(selectUser);

  const signOut = () =>
    useGoogleLogout({ logOutPath: API_HOST + AuthRoute.CMS_LOG_OUT_PATH, dispatch, navigate, email });

  return <UserCard email={email} signOut={signOut} />;
};

export const LabUserCard: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email } = useSelector(selectUser);

  const signOut = () =>
    useGoogleLogout({ logOutPath: API_HOST + AuthRoute.LAB_LOG_OUT_PATH, dispatch, navigate, email });

  return <UserCard email={email} signOut={signOut} />;
};
