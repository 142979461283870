import { FC, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Autocomplete, Popper, TextField } from '@mui/material';

import { Tag } from 'global/type';
import { GET_TAGS } from 'graphql/queries';

import TagsPopper from './TagsPopper';

interface Props {
  tags: Tag[];
  setTags: Function;
  disabled: boolean;
  readonly?: boolean;
}

interface GroupedTag extends Tag {
  group: string;
}

const groupTags = (tags: Tag[]): GroupedTag[] =>
  tags
    .map(tag => {
      const prefixIndex = tag.name.lastIndexOf('__');
      const transformString = (string: string) => string.replace(/_+/g, ' ');

      const name = prefixIndex !== -1 ? tag.name.substring(prefixIndex + 2) : tag.name;
      const groupName = tag.name.substring(0, prefixIndex);

      return {
        ...tag,
        name: transformString(name),
        group: transformString(groupName),
      };
    })
    .sort((left, right) => left.group.localeCompare(right.group));

export const Tags: FC<Props> = ({ tags, setTags, disabled }) => {
  const { data } = useQuery<{ tags: Tag[] }>(GET_TAGS, {
    variables: {
      order: 'name',
    },
  });
  const [allGroupedTags, setAllGroupedTags] = useState<GroupedTag[]>([]);
  const placeholder = disabled ? '' : '+';

  useEffect(() => {
    if (data?.tags) {
      setAllGroupedTags(groupTags(data.tags));
    }
  }, [data]);

  return (
    <>
      {disabled && !tags.length && '---'}
      {!!allGroupedTags.length && (
        <Autocomplete
          className="tags-autocomplete"
          defaultValue={groupTags(tags)}
          options={allGroupedTags}
          groupBy={option => option.group}
          onChange={(_, value) => setTags(value)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField
              placeholder={placeholder}
              {...params}
              inputProps={{
                ...params.inputProps,
                style: { ...params.inputProps.style, width: '200px' },
              }}
              variant="standard"
            />
          )}
          readOnly={disabled}
          disableClearable
          multiple
          size="small"
          PopperComponent={TagsPopper}
        />
      )}
    </>
  );
};
