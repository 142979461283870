export const NESTED_ROUTES = '/*';
export const PARENT_ROUTE = '..';
export const PARENT_ROUTE_SLASH = '../';
export const BASE_PATH = '/';

export enum RouteAction {
  BASE = '/',
  CREATE = 'create',
  EDIT = ':id/edit',
  SHOW = ':id/show',
}

export enum PathParams {
  SHOW = 'show',
  CREATE = 'create',
  EDIT = 'edit',
}

export enum AppPath {
  LOGIN = 'login',
  ADMIN = '/*',
}

export const BaseURL = new URL(location.origin).href;

export const pathJoin = (...args: string[]) => args.join('/');

export const buildQueryURL = (params: Record<string, string>, baseURL?: string | URL) => {
  const url = new URL(baseURL || BaseURL);

  Object.entries(params).forEach(([key, value]) => value && url.searchParams.append(key, value));

  return url;
};
