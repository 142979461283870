import { FC } from 'react';

import { ApolloProvider as NativeApolloProvider } from '@apollo/client';
import { ApolloProviderProps } from '@apollo/client/react/context';
import { APP_CLIENT } from 'app/manager';

import { apiErrorHandler } from 'graphql/error-handler';
import { useSnackbar } from 'hooks/useSnackbar';

const { getApiClient } = APP_CLIENT;

export const ApolloProvider: FC<Omit<ApolloProviderProps<any>, 'client'>> = props => {
  const { showErrorToast } = useSnackbar();

  const client = getApiClient({
    errorHandler: errorResponse =>
      apiErrorHandler({
        notify: showErrorToast,
        error: errorResponse,
      }),
  });

  return (
    <NativeApolloProvider {...props} client={client}>
      {props.children}
    </NativeApolloProvider>
  );
};
