import { FC, HTMLProps } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AdminLayout } from 'layouts/admin';
import { AuthLayout } from 'layouts/auth';

interface Props extends HTMLProps<HTMLDivElement> {
  isLoaded: boolean;
  isSignedIn: boolean;
}
export const AppLayout: FC<Props> = ({ isLoaded, isSignedIn }) => (
  <div className="app">{isLoaded && <>{isSignedIn ? <AdminLayout /> : <AuthLayout />}</>}</div>
);
