import { FC } from 'react';

import { GridToolbarContainer } from '@mui/x-data-grid';

import { PathParams } from 'global/route';

import { CreateButton } from './CreateButton';
import './index.scss';

import { Filter, FilterProps } from '../filter';

export interface Props extends FilterProps {}

export const Toolbar: FC<Props> = ({ ...props }) => (
  <GridToolbarContainer className="grid-custom-toolbar">
    <Filter {...props} />
    <CreateButton createPath={PathParams.CREATE} />
  </GridToolbarContainer>
);
