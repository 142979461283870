import { GridColDef } from '@mui/x-data-grid';

import { AssetFormData } from 'components/asset';
import { OrderedActivityItem, PackageRow, Tag } from 'global/type';
import { Question, QuestionKey } from 'pages/question/forms';
import { formatDate } from 'global/formater';

export enum ReviewKey {
  ID = 'id',
  UID = 'uid',
  TITLE = 'title',
  PREAMBLE = 'preamble',
  SUGGESTED_LENGTH = 'durationMs',
  OWNER_ID = 'ownerId',
  IS_ONBOARDING = 'isOnboarding',
  QUESTIONS = 'questions',
  TAGS = 'tags',
  PACKAGES = 'packages',
  THUMBNAIL = 'thumbnail',
  THUMBNAIL_ID = 'thumbnailId',
  OPTIONAL_FOR_USER = 'optionalForUser',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PUBLISHED_AT = 'publishedAt',
  TYPE_NAME = '__typename',
}

export interface ReviewFormData {
  [ReviewKey.ID]?: string;
  [ReviewKey.TITLE]: string;
  [ReviewKey.PREAMBLE]: string;
  [ReviewKey.SUGGESTED_LENGTH]: string | number;
  [ReviewKey.QUESTIONS]: Question[] | number[];
  [ReviewKey.TAGS]: Tag[] | number[];
  [ReviewKey.PACKAGES]?: PackageRow[];
  [ReviewKey.THUMBNAIL]?: AssetFormData | null;
  [ReviewKey.THUMBNAIL_ID]: number | null;
  [ReviewKey.OPTIONAL_FOR_USER]: boolean | null;
  [ReviewKey.CREATED_AT]?: string;
  [ReviewKey.UPDATED_AT]?: string;
  [ReviewKey.PUBLISHED_AT]?: string | Date | null;
  [ReviewKey.UID]?: string;
  [ReviewKey.TYPE_NAME]?: string;
}

export const REVIEW_FORM = {
  [ReviewKey.ID]: '',
  [ReviewKey.TITLE]: '',
  [ReviewKey.PREAMBLE]: '',
  [ReviewKey.SUGGESTED_LENGTH]: '',
  [ReviewKey.QUESTIONS]: [],
  [ReviewKey.TAGS]: [],
  [ReviewKey.PACKAGES]: [],
  [ReviewKey.THUMBNAIL]: null,
  [ReviewKey.THUMBNAIL_ID]: null,
  [ReviewKey.OPTIONAL_FOR_USER]: null,
  [ReviewKey.CREATED_AT]: '',
  [ReviewKey.UPDATED_AT]: '',
  [ReviewKey.PUBLISHED_AT]: '',
  [ReviewKey.UID]: '',
  [ReviewKey.TYPE_NAME]: undefined,
};

export const REVIEW_SECTION_COLUMNS: GridColDef[] = [
  { field: ReviewKey.UID, headerName: 'ID', flex: 1 },
  { field: ReviewKey.TITLE, headerName: 'Title', flex: 1 },
  { field: ReviewKey.PREAMBLE, headerName: 'Preamble', flex: 1 },
  {
    field: ReviewKey.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
  {
    field: ReviewKey.UPDATED_AT,
    headerName: 'Updated Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.updatedAt),
  },
];

export type ReviewQuestion = Omit<Question, QuestionKey.ID | QuestionKey.UID> & OrderedActivityItem;
