import { ErrorResponse } from '@apollo/client/link/error';
import { AnalyticsService } from '@loticai/lotic-analytics-service';

import { log } from 'plugins/logger';

import { notAuthorizedHandler } from './handlers';

export type GetErrorLinkCallback = (err: ErrorResponse) => void;

enum ErrorMessage {
  NOT_AUTHORIZED = 'Not authorized',
}

interface ApiErrorHandlerProps {
  error: ErrorResponse;
  notify: (title: string, extra?: any) => void;
}

type ApiErrorHandler = (props: ApiErrorHandlerProps) => void;

export const apiErrorHandler: ApiErrorHandler = ({ error: { operation, graphQLErrors, response }, notify }) => {
  const errors = response?.errors || graphQLErrors;
  const { errorTracker } = AnalyticsService;

  if (errors?.length) {
    errors.forEach(error => {
      log(error);

      switch (error.message) {
        case ErrorMessage.NOT_AUTHORIZED:
          notAuthorizedHandler();
          errorTracker.addBreadcrumb({ data: { apiErrorHandler: 'NotAuthorized' } });
          errorTracker.setTag('cause', 'Not authorized');
          errorTracker.captureError(JSON.stringify(error) as unknown as Error, {
            level: 'info',
            extra: { operation, graphQLErrors, skipErrorReport: true },
          });
          break;
        default:
          break;
      }

      if (notify) {
        notify(error.message);
      }
    });
  }
};
