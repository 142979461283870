import { ApolloCache, DefaultContext, MutationTuple as MT, OperationVariables } from '@apollo/client';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import { capitalize } from 'global/formater';
import { UseSnackBar } from 'hooks/useSnackbar';
import { BulkObject } from 'hooks/useTable';
import { clearModal, setModal } from 'store/modal';
import { Modal, Key as ModalKey } from 'store/common-modal';

type MutationTuple = MT<any, OperationVariables, DefaultContext, ApolloCache<any>>;
interface BulkActionsConfig {
  entityName: string;
  bulkObject: BulkObject;
  updatePage: () => void;
  enqueueSnackbar: UseSnackBar['showSuccessToast'];
  dispatch: Dispatch<AnyAction>;
  bulkDelete?: MutationTuple;
  bulkChangePublishState?: MutationTuple;
}

const SUCCESS_PUBLISH_MSG = 'Successfully published';
const SUCCESS_UNPUBLISH_MSG = 'Successfully unpublished';
const SUCCESS_DELETE_MSG = 'Successfully deleted';
const DELETE_TXT = 'Delete';
const PUBLISH_TXT = 'Publish';
const UNPUBLISH_TXT = 'Unpublish';

interface GenerateMessage {
  data: object;
  entityName: BulkActionsConfig['entityName'];
  isDelete?: boolean;
}

const generateMessage = ({ data, entityName }: GenerateMessage): string => {
  const mutationName = Object.keys(data)[0];
  const count = data[mutationName]?.length;

  return ` ${count || data[mutationName]} ${entityName}(s)`;
};

type GetModalDefaults = (
  entityName: string,
  actionName: string,
  dispatch: BulkActionsConfig['dispatch'],
) => Omit<Modal, ModalKey.CONFIRM>;

const getModalDefaults: GetModalDefaults = (entityName, actionName, dispatch) => ({
  state: true,
  title: capitalize(actionName) + ' ' + capitalize(entityName) + 's',
  text: `Are you sure you want to ${actionName.toLowerCase()} these ${entityName.toLowerCase()}(s) ?`,
  cancel: {
    action: () => dispatch(clearModal()),
    text: 'No',
  },
});

export const getBulkActions = ({
  bulkObject,
  bulkDelete,
  bulkChangePublishState,
  updatePage,
  enqueueSnackbar,
  entityName,
  dispatch,
}: BulkActionsConfig): Modal[] => {
  const actions: Modal[] = [];

  const onSuccessHandler = (msg: string, data: object, isDelete = false) => {
    updatePage();
    enqueueSnackbar(msg + generateMessage({ data, entityName, isDelete }));
  };

  if (bulkDelete) {
    const modalDefaults = getModalDefaults(entityName, DELETE_TXT, dispatch);

    actions.push({
      confirm: {
        action: async () => {
          dispatch(setModal({ ...modalDefaults, confirm: { loading: true } }));

          const [runBulkDelete] = bulkDelete;
          const { data, errors } = await runBulkDelete({ variables: bulkObject });

          dispatch(clearModal());

          if (errors?.length) return;

          onSuccessHandler(SUCCESS_DELETE_MSG, data);
        },
        text: 'Yes',
        buttonText: DELETE_TXT,
      },
      ...modalDefaults,
    });
  }

  if (bulkChangePublishState) {
    const publishModalDefaults = getModalDefaults(entityName, PUBLISH_TXT, dispatch);
    const unpublishModalDefaults = getModalDefaults(entityName, UNPUBLISH_TXT, dispatch);

    actions.push(
      {
        confirm: {
          action: async () => {
            dispatch(setModal({ ...publishModalDefaults, confirm: { loading: true } }));

            const [changePublishStatuses] = bulkChangePublishState;
            const variables = { ...bulkObject, publishedAt: new Date() };
            const { data, errors } = await changePublishStatuses({ variables });

            dispatch(clearModal());

            if (errors?.length) return;

            onSuccessHandler(SUCCESS_PUBLISH_MSG, data);
          },
          text: PUBLISH_TXT,
        },
        ...publishModalDefaults,
      },
      {
        confirm: {
          action: async () => {
            dispatch(setModal({ ...unpublishModalDefaults, confirm: { loading: true } }));

            const [changePublishStatuses] = bulkChangePublishState;
            const variables = { ...bulkObject, publishedAt: null };
            const { data, errors } = await changePublishStatuses({ variables });

            dispatch(clearModal());

            if (errors?.length) return;

            onSuccessHandler(SUCCESS_UNPUBLISH_MSG, data);
          },
          text: UNPUBLISH_TXT,
        },
        ...unpublishModalDefaults,
      },
    );
  }

  return actions;
};
