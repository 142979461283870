import { FC, HTMLProps, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { AssetFormData } from 'components/asset';

import { PROGRESS_THICKNESS } from '..';

interface Props extends HTMLProps<HTMLImageElement> {
  asset: AssetFormData;
  showMetaData?: boolean;
}

export const ImageView: FC<Props> = ({ asset, style, className, showMetaData = true }) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  const img = new Image();
  img.src = String(asset.url);
  img.onload = () => setImage(img);

  if (!image) return <CircularProgress thickness={PROGRESS_THICKNESS} />;

  return (
    <>
      <img className={className} style={style} src={image?.src} />
      {showMetaData && (
        <div className="description" style={{ position: 'absolute', bottom: 0 }}>
          <div>{asset.description}</div>
          <div>{img.height + ' * ' + img.width}</div>
        </div>
      )}
    </>
  );
};
