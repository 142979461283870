import { FC, ReactNode } from 'react';

import { CardContent, Card as MUICard } from '@mui/material';

import { Margins } from 'global/type';

interface Props extends Margins {
  children?: ReactNode;
}

const BORDER_RADIUS = 13;
const ELEVATION = 4;
const PADDING = 7;

export const Card: FC<Props> = ({ children, marginTop, marginRight, marginBottom, marginLeft }) => (
  <MUICard
    elevation={ELEVATION}
    style={{ borderRadius: BORDER_RADIUS, padding: PADDING, marginTop, marginRight, marginBottom, marginLeft }}
  >
    <CardContent>{children}</CardContent>
  </MUICard>
);
