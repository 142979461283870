import { FC, HTMLProps, useState } from 'react';

import { CircularProgress, Grid } from '@material-ui/core';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import { AssetFormData } from 'components/asset';

import { PROGRESS_THICKNESS } from '..';

interface Props extends HTMLProps<HTMLVideoElement> {
  asset: AssetFormData;
  showMetaData?: boolean;
}

export const VideoView: FC<Props> = ({ asset, style, onCanPlay, controls, showMetaData = true, ...props }) => {
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);

  const media = document.createElement('video');
  media.src = String(asset.url);
  media.oncanplay = () => setVideo(media);

  if (!video) return <CircularProgress thickness={PROGRESS_THICKNESS} />;

  return (
    <>
      <video onCanPlay={onCanPlay} style={style} controls={controls} src={String(asset.url)} {...props} />
      {showMetaData && (
        <Grid container spacing={2} style={{ position: 'absolute', bottom: 0 }}>
          <Grid item xs={9}>
            <div className={'description'}>
              <div>{asset.description}</div>
              <div>{video?.videoHeight + ' * ' + video?.videoWidth + '  ' + video?.duration?.toFixed(1) + 's'}</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <PlayCircleOutlineIcon style={{ color: 'white' }} />
          </Grid>
        </Grid>
      )}
    </>
  );
};
