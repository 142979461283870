import { format, parseISO } from 'date-fns';

import { DATE_FORMAT, NO_DATE } from 'constants/date-time';

import { isPrimitive } from './type';

export const stringifyPretty = (value: any) =>
  isPrimitive(value) ? value : JSON.stringify(value).split('"').join(' ');

export const replaceNotNumeric = (str: string) => str.replace(/[^\d.-]/g, '');

export const stringToFloat = (str: string) => parseFloat(replaceNotNumeric(str));

export const stringToInt = (str: string) => parseInt(replaceNotNumeric(str));

export const joinParts = (...args: string[]) => args.join(' ');

export const getFileName = (str: string): string => str.substring(0, str.lastIndexOf('.')).split('/').pop() ?? '';

export const getFileExtension = (str: string): string => str.substring(str.lastIndexOf('.') + 1, str.length);

export const replaceCharacters = (str: string, replace: string, replacer: string): string =>
  str.split(replace).join(replacer);

export const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1).toLowerCase();

export const formatDate = (str: string) => (str ? format(parseISO(str), DATE_FORMAT) : NO_DATE);
