import { FC } from 'react';

import { AssetPickerComponent } from './component';
import { useFilePicker, Props as UseFilePickerProps } from './hook';

export interface Props extends UseFilePickerProps {}
export const AssetPlugin: FC<Props> = ({ editorState, onChange }) => {
  const props = useFilePicker({
    editorState,
    onChange,
  });

  return <AssetPickerComponent {...props} />;
};
