import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { FetchPolicy } from 'graphql/apollo-client';
import { GET_SEQUENCE } from 'graphql/queries';
import { SequenceFormData } from 'pages/sequence/forms';

import { useGetExperiment } from './useGetExperiment';

export const useGetExperimentPackages = () => {
  const { data: experimentData } = useGetExperiment();

  const [getQuery, { data }] = useLazyQuery<{ packageSequence: SequenceFormData }>(GET_SEQUENCE, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: experimentData?.experiment?.packageSequenceId,
    },
  });

  useEffect(() => {
    if (experimentData?.experiment?.packageSequenceId) {
      getQuery();
    }
  }, [experimentData]);

  return {
    data,
  };
};
