import { gql } from '@apollo/client';

export const DEFAULT_EXPERIMENT_FIELDS = gql`
  fragment ExperimentFields on Experiment {
    id
    uid
    title
    type
    frequency
    packageSequenceId
    showInsights
    showAutoSummaryInsights
    showArtworkInsights
    showMusicInsights
    showAnimationInsights
    showPersonalityInsights
    showSentimentInsights
    showSpeakingInsights
    showWordFrequencyInsights
    showEmailPage
    showOneVideo
    users {
      id
    }
    completionCode
    startDate
    publishedAt
    createdAt
    updatedAt
    redirectUrl
  }
`;
