import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { TextField } from '@mui/material';
import { v4 } from 'uuid';

import 'components/inputs/index.scss';

import { PromptRow } from '../forms';

interface MultilinePromptProps {
  label: string;
  prompts: PromptRow[];
  setCurrentPrompts: Dispatch<SetStateAction<PromptRow[]>>;
  disabled: boolean;
  error?: boolean;
  helperText?: string;
  onBlur?: () => void;
}

const emptyPrompt: PromptRow = { id: '', content: '' };

const isPromptsEqual = (prompt1: PromptRow, prompt2: PromptRow): boolean => {
  return prompt1?.id === prompt2?.id && prompt1?.content === prompt2?.content;
};

export const MultilinePrompt: FC<MultilinePromptProps> = ({
  label,
  prompts,
  setCurrentPrompts,
  disabled,
  error,
  helperText,
  onBlur,
}) => {
  const [prompt, setPrompt] = useState<PromptRow>(emptyPrompt);

  useEffect(() => {
    if (prompts[0]) {
      setPrompt(prompts[0]);
    }
  }, [prompts]);

  const changeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const content = event.currentTarget.value;
    setPrompt(({ id }) => ({ id: id || v4(), content }));
  };

  const blurHandler = () => {
    if (!isPromptsEqual(prompts[0], prompt)) {
      if (prompt.id) {
        setCurrentPrompts([prompt]);
      } else {
        setCurrentPrompts([]);
      }
    }

    onBlur?.();
  };

  return (
    <div className="linked-table_wrapper">
      <div className="label">{label}</div>
      <TextField
        fullWidth
        multiline
        value={prompt.content || ''}
        disabled={disabled}
        onChange={changeHandler}
        onBlur={blurHandler}
        error={error}
        helperText={helperText}
      />
    </div>
  );
};
