import { useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import type { Identifier, XYCoord } from 'dnd-core';
import { OrderedActivityItem } from 'global/type';
import './index.scss';

export interface CardProps<Item, ContentItem> {
  uid: string;
  index: number;
  cardItem: Item;
  cardItems: Item[];
  labelSource: keyof ContentItem;
  fullContent: ContentItem[];
  filterOptions: (options: ContentItem[], uid: string, items: Item[]) => ContentItem[];
  setSelected: (uid: string | undefined, itemId: number | undefined, value: Item, items: Item[]) => void;
  removeItem: (uid: string, items: Item[]) => void;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}
interface DragItem {
  index: number;
  uid: string;
  type: string;
}

export const Card = <
  Item extends OrderedActivityItem,
  ContentItem extends Partial<OrderedActivityItem> & { id?: number },
>({
  uid,
  index,
  filterOptions,
  setSelected,
  removeItem,
  labelSource,
  fullContent,
  cardItem,
  cardItems,
  moveCard,
}: CardProps<Item, ContentItem>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { uid, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const className = isDragging ? 'activity-item-transparent' : 'activity-item';
  drag(drop(ref));

  const autoCompleteValue = useMemo(
    () => fullContent.find(item => item.uid === cardItem.uid) ?? null,
    [fullContent, cardItem],
  );

  const autocompleteChangeHandler = (_event: React.SyntheticEvent<Element, Event>, value: ContentItem | null) =>
    setSelected(value?.uid, value?.id, cardItem, cardItems);

  return (
    <div className={className} ref={ref} data-handler-id={handlerId}>
      <DragIndicatorIcon className="drag-indicator" />
      <Autocomplete<ContentItem>
        value={autoCompleteValue}
        options={filterOptions(fullContent, cardItem.uid, cardItems)}
        isOptionEqualToValue={(option, value) => option.uid === value.uid}
        onChange={autocompleteChangeHandler}
        getOptionLabel={option => `${option.uid} : ${option[labelSource]}`}
        renderInput={params => <TextField {...params} variant="standard" />}
        fullWidth
      />
      <IconButton aria-label="delete" onClick={() => removeItem(uid, cardItems)}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
