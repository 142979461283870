import { FC } from 'react';

import { Card, CardContent } from '@mui/material';

import { ActionButtons, DateInput, ID } from 'components/inputs';
import { TextField } from 'components/mui-custom';
import { ViewTable } from 'components/tables/view-table';
import { setLabel } from 'global/form';
import { PathParams } from 'global/route';
import { PACKAGE_COLUMNS } from 'global/type';

import { useDailyPracticeForm } from '../hooks/useDailyPracticeForm';

import { DAILY_PRACTICE_FORM, DailyPracticeFormData, DailyPracticeKey } from '.';

interface Props {
  action: PathParams;
  values?: DailyPracticeFormData;
}

export const DailyPractice: FC<Props> = ({ action: formAction, values = DAILY_PRACTICE_FORM }) => {
  const { formLoading, readOnly, watch, trigger, setValue, formActions, errors } = useDailyPracticeForm({
    action: formAction,
    providedFormValues: values,
  });

  const { uid, action, packages, updatedAt, createdAt, publishedAt } = watch();

  return (
    <Card>
      <CardContent className="question-form-content">
        {uid && <ID value={uid} disabled />}
        <TextField
          value={action}
          label={setLabel('Action', formAction)}
          onChange={e => setValue(DailyPracticeKey.ACTION, e.target.value)}
          onBlur={() => trigger(DailyPracticeKey.ACTION)}
          helperText={errors?.action?.message}
          error={Boolean(errors.action)}
          disabled={readOnly || formLoading}
        />
        {readOnly && <DateInput label="Published" value={publishedAt} disabled />}
        {readOnly && <DateInput label="Updated" value={updatedAt} disabled />}
        {readOnly && <DateInput label="Created" value={createdAt} disabled />}
        {readOnly && <ViewTable rows={packages ?? []} columns={PACKAGE_COLUMNS} label={'Packages:'} />}
      </CardContent>
      <ActionButtons actions={formActions} />
    </Card>
  );
};
