import { useEffect } from 'react';

import { PathParams } from 'global/route';
import {
  CHANGE_DAILY_PRACTICES_PUBLISH_STATUSES,
  CREATE_DAILY_PRACTICE,
  DESTROY_DAILY_PRACTICE,
  UPDATE_DAILY_PRACTICE,
} from 'graphql/mutations';
import { GET_DAILY_PRACTICES } from 'graphql/queries';
import { validateTextField } from 'plugins/validator';

import { DAILY_PRACTICE_FORM, DailyPracticeFormData, DailyPracticeKey } from '../forms';
import { useItemForm } from 'hooks/useItemForm';

interface Props {
  action: PathParams;
  providedFormValues: DailyPracticeFormData;
}

export const useDailyPracticeForm = ({ providedFormValues, action }: Props) => {
  const readOnly: boolean = action === PathParams.SHOW;

  const parseData = (data: DailyPracticeFormData) => {
    const { updatedAt, createdAt, uid, packages, __typename, ...formValues } = data;
    return formValues;
  };

  const { formActions, formErrors, formLoading, register, setFormValues, setValue, trigger, watch } =
    useItemForm<DailyPracticeFormData>({
      action,
      defaultValues: DAILY_PRACTICE_FORM,
      itemDisplayName: 'Daily Practice',
      createMutation: CREATE_DAILY_PRACTICE,
      updateMutation: UPDATE_DAILY_PRACTICE,
      destroyMutation: DESTROY_DAILY_PRACTICE,
      changePublishStatusMutation: CHANGE_DAILY_PRACTICES_PUBLISH_STATUSES,
      getItemsQuery: GET_DAILY_PRACTICES,
      parseData,
    });

  useEffect(() => {
    register(DailyPracticeKey.ACTION, {
      validate: value => validateTextField(value, 'Action'),
    });
  }, [register]);

  useEffect(() => {
    if (providedFormValues) {
      setFormValues(providedFormValues);
    }
  }, []);

  return {
    readOnly,
    formLoading,
    watch,
    setValue,
    trigger,
    errors: formErrors,
    formActions,
  };
};
