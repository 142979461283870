import { FC } from 'react';

import { Card } from '@mui/material';
import { GridEventListener } from '@mui/x-data-grid';

import { Table } from 'components/tables/grid-table';
import { CustomTableProps } from 'components/tables/grid-table/types';

import { usePackageTable } from '../hooks/usePackageTable';

export const PackageTable: FC<CustomTableProps> = ({ columns, query, where, onRowClick, onGetLink }) => {
  const {
    cellClickHandler,
    content,
    setSelected,
    selectAll,
    unselectAll,
    selectedCount,
    sortContent,
    rowsPerPage,
    loading,
    page,
    selected,
    filterAction,
    bulkActions,
    rowCount,
    paginationModelChangeHandler,
    sortModel,
    selectAllChecked,
    onSelectAllChange,
    selectPageHandler,
    selectAllIndeterminate,
  } = usePackageTable({
    query,
    where,
  });

  return (
    <Card>
      <Table
        title="Package"
        onCellClick={cellClickHandler as unknown as GridEventListener<'cellClick'>}
        rows={content}
        columns={columns}
        onRowClick={onRowClick}
        onGetLink={onGetLink}
        onRowSelectionModelChange={setSelected}
        paginationModel={{ page, pageSize: rowsPerPage }}
        onPaginationModelChange={paginationModelChangeHandler}
        onSelectAll={selectAll}
        onUnselectAll={unselectAll}
        onSortModelChange={sortContent}
        rowCount={rowCount}
        loading={loading}
        selected={selected}
        selectedCount={selectedCount}
        keepNonExistentRowsSelected
        filter={filterAction}
        bulk={{
          actions: bulkActions,
        }}
        sortModel={sortModel}
        selectAllChecked={selectAllChecked}
        onSelectAllChange={onSelectAllChange}
        selectPageHandler={selectPageHandler}
        selectAllIndeterminate={selectAllIndeterminate}
        sortingMode="server"
      />
    </Card>
  );
};
