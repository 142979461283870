import { FC } from 'react';

import { GridCellParams } from '@mui/x-data-grid';
import { RoutePath } from 'app/routes';

import { POST_OVERVIEW_COLUMNS, PostSectionValue } from 'components/post/forms';
import { PostTable } from 'components/post/tables/Post';
import { GET_POSTS } from 'graphql/queries';
import { getShowPagePath } from 'hooks/useTable';

export const List: FC = () => {
  const getRouteBySection = ({ row }: GridCellParams<any, any, any>) => {
    switch (row.section) {
      case PostSectionValue.CONTEXT:
        return getShowPagePath(RoutePath.CONTEXTS, String(row.uid));
      case PostSectionValue.LEARN:
        return getShowPagePath(RoutePath.LEARNS, String(row.uid));
      case PostSectionValue.WIND_DOWN:
        return getShowPagePath(RoutePath.WIND_DOWNS, String(row.uid));
      default:
        return '';
    }
  };

  return (
    <PostTable query={GET_POSTS} columns={POST_OVERVIEW_COLUMNS} onGetLink={params => getRouteBySection(params)} />
  );
};
