import { FC, PropsWithChildren } from 'react';

import {
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  InputLabel,
  RadioGroup as MUIRadioGroup,
  Radio,
  RadioGroupProps,
} from '@mui/material';

interface Props extends Pick<RadioGroupProps, 'onChange'>, Pick<FormControlLabelProps, 'disabled'> {
  value: boolean | null;
}

enum OptionalForUserValue {
  YES = 'Yes',
  NO = 'No',
}

export const OptionalForUser: FC<PropsWithChildren<Props>> = ({ value, onChange, disabled }) => (
  <Grid sx={{ mt: 2 }} container>
    <Grid item xs={false} sm={6} md={6}>
      <InputLabel>Optional for user (Required)</InputLabel>
      <MUIRadioGroup row value={value} onChange={onChange}>
        {Object.values(OptionalForUserValue).map(value => (
          <FormControlLabel
            key={value}
            value={value === OptionalForUserValue.YES}
            control={<Radio />}
            label={value}
            disabled={disabled}
          />
        ))}
      </MUIRadioGroup>
    </Grid>
  </Grid>
);
