export enum HelperText {
  SEARCH_EMPTY = 'Please enter any word to start searching',
  SEARCH_NOT_FOUND = 'No search result is found, try other keywords',
  ATTRIBUTES_EMPTY = 'Select Attribute to search',
  ATTRIBUTES_NOT_FOUND = 'or try change attributes',
}

export enum KeyboardKeys {
  ENTER = 'Enter',
}
