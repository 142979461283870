import { FC } from 'react';

import { Button, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ASSET_FORM, AssetFormData, AssetKey } from 'components/asset';
import { AssetView } from 'components/asset/view';
import { ActionButtons } from 'components/inputs/ActionButtons';
import { TextField } from 'components/mui-custom';
import { LINKED_POSTS_COLUMNS } from 'components/post/forms';
import { ViewTable } from 'components/tables/view-table';
import { ALL_IMAGES, ALL_MEDIA } from 'constants/input-extension';
import { setLabel } from 'global/form';
import { PathParams } from 'global/route';
import { useAssetForm } from 'hooks/useAssetForm';
import { Property } from 'csstype';

import './index.scss';

const ASSET_TABLE_CLASS_NAME = 'asset-description';
type AllowedAssetTypes = typeof ALL_IMAGES | typeof ALL_MEDIA;

const Input = styled('input')({
  display: 'none',
});
interface Props {
  action: PathParams;
  values?: AssetFormData;
  allowedAssetTypes?: AllowedAssetTypes;
  maxWidth?: Property.MaxWidth;
  onCancel?: () => void;
  onAssetUploaded?: (asset: AssetFormData) => void;
  goBackAfterSubmit?: boolean;
  notifyAfterSubmit?: boolean;
}
export const AssetForm: FC<Props> = ({
  action,
  values = ASSET_FORM,
  allowedAssetTypes = ALL_IMAGES,
  maxWidth,
  goBackAfterSubmit = true,
  notifyAfterSubmit = true,
  onAssetUploaded,
  onCancel,
}) => {
  const {
    rows,
    columns,
    aspectRatio,
    formLoading,
    readOnly,
    watch,
    trigger,
    setValue,
    formActions,
    errors,
    deleteAssetLocally,
    uploadAssetLocally,
  } = useAssetForm({
    action,
    onCancel,
    providedFormValues: values,
    goBackAfterSubmit,
    notifyAfterSubmit,
    onAssetUploaded,
  });

  const asset = watch();

  return (
    <Card>
      <CardContent className="asset-content">
        <TextField
          value={asset.description}
          label={setLabel('Description', action)}
          onChange={e => {
            setValue(AssetKey.DESCRIPTION, e.target.value);
          }}
          onBlur={() => trigger(AssetKey.DESCRIPTION)}
          helperText={errors?.description?.message}
          error={Boolean(errors.description)}
          sx={{ mt: 1, mb: 2 }}
          disabled={readOnly}
        />
        <div className="asset-wrapper">
          <AssetView aspectRatio={aspectRatio} key={asset.url} asset={asset} showMetaData={false} maxWidth={maxWidth} />
          {!readOnly && (
            <div>
              {asset.url ? (
                <Button
                  onClick={deleteAssetLocally}
                  style={{ marginTop: 15 }}
                  disabled={formLoading}
                  type="submit"
                  variant="outlined"
                >
                  Remove
                </Button>
              ) : (
                <label htmlFor="contained-button-file">
                  <Input
                    onChange={uploadAssetLocally}
                    accept={allowedAssetTypes}
                    id="contained-button-file"
                    type="file"
                  />
                  <Button
                    variant="contained"
                    component="span"
                    style={{ marginTop: 20 }}
                    color={errors.url ? 'error' : 'primary'}
                  >
                    Upload asset
                  </Button>
                </label>
              )}
            </div>
          )}
        </div>
        {readOnly && <ViewTable className={ASSET_TABLE_CLASS_NAME} columns={columns} rows={rows} />}
        {readOnly && <ViewTable rows={asset.posts ?? []} columns={LINKED_POSTS_COLUMNS} label={'Posts: '} />}
      </CardContent>
      <ActionButtons actions={formActions} />
    </Card>
  );
};
