import { indigo } from '@material-ui/core/colors';
import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: indigo['A200'],
      contrastText: '#ffff',
    },
  },
  typography: {
    fontFamily: `'Inter', sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
});
