import { ComponentProps, FC, useEffect, useState } from 'react';

import { DialogContentText } from '@mui/material';

import { PathParams } from 'global/route';
import { Modal } from 'store/common-modal';
import { ConfirmModal } from './ConfirmModal';
import { useBlocker } from 'react-router-dom';

interface Props extends Modal {
  minWidth?: ComponentProps<typeof DialogContentText>['minWidth'];
}

export const ConfirmNavigationModal: FC<Props> = ({ state, title, text, cancel, confirm, minWidth }) => {
  const [nextLocation, setNextLocation] = useState('');
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    setNextLocation(nextLocation.pathname);
    return state;
  });

  useEffect(() => {
    if (!window.location.href.includes(PathParams.EDIT) && !window.location.href.includes(PathParams.SHOW)) {
      blocker.proceed?.();
    }
  });

  return blocker.state === 'blocked' ? (
    <ConfirmModal
      state={state}
      title={title}
      text={text}
      confirm={{
        ...confirm,
        action: async () => {
          await confirm?.action?.();
          blocker.proceed?.();
        },
      }}
      cancel={{
        ...cancel,
        action: () => {
          cancel?.action?.();
          nextLocation.includes(PathParams.SHOW) ? blocker.proceed?.() : blocker.reset?.();
        },
      }}
      minWidth={minWidth}
    />
  ) : null;
};
