import { FC } from 'react';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Card, CardContent } from '@mui/material';

import { DateInput, ID, Title } from 'components/inputs';
import { ActionButtons } from 'components/inputs/ActionButtons';
import { setLabel } from 'global/form';
import { PathParams } from 'global/route';

import { FormData, Key } from '../forms';
import { useWisdomEnginePromptForm } from '../hooks/useWisdomEnginePromptForm';

interface Props {
  action: PathParams;
  values?: FormData;
  client: ApolloClient<NormalizedCacheObject>;
}

export const WisdomEnginePrompt: FC<Props> = ({ action, values, client }) => {
  const { watch, formActions, setValue, trigger, errors, disabled } = useWisdomEnginePromptForm({
    providedFormValues: values as FormData,
    action,
    client,
  });

  const { uid, question, updatedAt, createdAt } = watch();

  return (
    <Card>
      <CardContent sx={{ mb: '35px' }}>
        {uid && <ID value={uid} disabled />}
        <Title
          value={question}
          label={setLabel('Question', action)}
          onChange={e => setValue(Key.QUESTION, e.target.value)}
          onBlur={() => trigger(Key.QUESTION)}
          helperText={errors?.question?.message}
          error={Boolean(errors.question)}
          disabled={disabled}
        />
        {disabled && <DateInput sx={{ mt: 2 }} label="Updated" value={updatedAt} disabled />}
        {disabled && <DateInput sx={{ mt: 2 }} label="Created" value={createdAt} disabled />}
      </CardContent>
      <ActionButtons actions={formActions} />
    </Card>
  );
};
