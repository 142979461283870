import { FC } from 'react';
import { To, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

interface Props {
  createPath: To;
}

export const CreateButton: FC<Props> = ({ createPath }) => {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate(createPath)} variant="contained">
      Create
    </Button>
  );
};
