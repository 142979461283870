import { FC } from 'react';

import { RoutePath } from 'app/routes';

import { GET_PACKAGES } from 'graphql/queries';
import { getShowPagePath } from 'hooks/useTable';

import { PACKAGE_SECTION_COLUMNS } from './forms/index';
import { PackageTable } from './tables/Package';

export const List: FC = () => {
  return (
    <PackageTable
      query={GET_PACKAGES}
      columns={PACKAGE_SECTION_COLUMNS}
      onGetLink={({ row }) => getShowPagePath(RoutePath.PACKAGES, String(row.uid))}
    />
  );
};
