import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { NotFound } from 'components/NotFound';
import { stringToInt } from 'global/formater';
import { PathParams } from 'global/route';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_POST } from 'graphql/queries';

import { Post } from './Create';

import { PostFormData, PostSectionValue } from '.';

interface Props {
  section?: PostSectionValue;
  action: PathParams;
}

export const SaveForm: FC<Props> = ({ section, action }) => {
  const { id } = useParams();

  const { data, loading, error } = useQuery<{ post: PostFormData }>(GET_POST, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: stringToInt(id as string),
    },
  });

  if (loading) return null;

  if (data?.post) {
    return <Post action={action} providedFormValues={data.post} section={section} />;
  }

  return <NotFound />;
};
