import { GridColDef } from '@mui/x-data-grid';
import { getRouteByUid, RoutePath } from 'app/routes';

import { AssetFormData } from 'components/asset';
import { renderLinkCell } from 'components/tables/view-table/renderLinkCell';
import { formatDate } from 'global/formater';
import { OrderedActivityItem, Tag } from 'global/type';

export enum PackageKey {
  ID = 'id',
  UID = 'uid',
  TITLE = 'title',
  DESCRIPTION = 'description',
  PACKAGE_ITEMS = 'packageItems',
  PACKAGE_NUMBER = 'packageNumber',
  THUMBNAIL = 'thumbnail',
  THUMBNAIL_ID = 'thumbnailId',
  TAGS = 'tags',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PUBLISHED_AT = 'publishedAt',
  CONTENT_LIST = 'contentList',
  TYPE_NAME = '__typename',
}

export enum PackageItemTypes {
  CONTEXT = 'context',
  CHECK_IN = 'checkIn',
  MOMENT = 'moment',
  REVIEW = 'review',
  DAILY_PRACTICE = 'dailyPractice',
  LEARN = 'learn',
  WIND_DOWN = 'windDown',
}

export interface PackageItem extends OrderedActivityItem {
  name?: string;
  itemType?: PackageItemTypes;
  publishedAt?: Date;
}

export interface PackageFormData {
  [PackageKey.ID]?: string;
  [PackageKey.TITLE]: string;
  [PackageKey.DESCRIPTION]: string;
  [PackageKey.PACKAGE_ITEMS]: PackageItem[];
  [PackageKey.THUMBNAIL]?: AssetFormData | null;
  [PackageKey.THUMBNAIL_ID]?: number | null;
  [PackageKey.TAGS]: Tag[] | number[];
  [PackageKey.CREATED_AT]?: string;
  [PackageKey.UPDATED_AT]?: string;
  [PackageKey.PUBLISHED_AT]?: string | Date | null;
  [PackageKey.UID]?: string;
  [PackageKey.CONTENT_LIST]?: any;
  [PackageKey.TYPE_NAME]?: string;
}

export type Package = {
  id: number;
  uid?: string;
  title?: string;
  packageNumber?: number;
};

export const PACKAGE_FORM = {
  [PackageKey.ID]: '',
  [PackageKey.TITLE]: '',
  [PackageKey.DESCRIPTION]: '',
  [PackageKey.PACKAGE_ITEMS]: [],
  [PackageKey.THUMBNAIL]: null,
  [PackageKey.THUMBNAIL_ID]: undefined,
  [PackageKey.TAGS]: [],
  [PackageKey.CREATED_AT]: '',
  [PackageKey.UPDATED_AT]: '',
  [PackageKey.PUBLISHED_AT]: '',
  [PackageKey.UID]: '',
  [PackageKey.CONTENT_LIST]: undefined,
  [PackageKey.TYPE_NAME]: undefined,
};

export const PACKAGE_SECTION_COLUMNS: GridColDef[] = [
  { field: PackageKey.UID, headerName: 'ID', flex: 1 },
  { field: PackageKey.TITLE, headerName: 'Title', flex: 1 },
  {
    field: PackageKey.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
  {
    field: PackageKey.UPDATED_AT,
    headerName: 'Updated Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.updatedAt),
  },
];

const getLinkedPackagesColumns = () => {
  const columns = [...PACKAGE_SECTION_COLUMNS];
  columns.splice(0, 1, {
    field: PackageKey.UID,
    headerName: 'ID',
    flex: 1,
    renderCell: params => renderLinkCell(RoutePath.PACKAGES, params),
  });

  return columns;
};

export const SEQUENCE_PACKAGES_COLUMNS = [
  { field: PackageKey.PACKAGE_NUMBER, headerName: 'Day', flex: 1 },
  ...getLinkedPackagesColumns(),
];

enum PackageItemKey {
  POSITION = 'position',
  UID = 'uid',
  NAME = 'name',
  PUBLISHED_AT = 'publishedAt',
}

export const PACKAGE_ITEMS_COLUMNS: GridColDef[] = [
  { field: PackageItemKey.POSITION, headerName: 'Position', flex: 1 },
  {
    field: PackageItemKey.UID,
    headerName: 'ID',
    flex: 1,
    renderCell: params => {
      if (params.row.uid === 'Check-in') {
        return params.row.uid;
      } else {
        return renderLinkCell(getRouteByUid(params.row.uid), params);
      }
    },
  },
  { field: PackageItemKey.NAME, headerName: 'Name', flex: 2 },
  {
    field: PackageKey.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
];

export interface ContentItem {
  id: number;
  uid: string;
  title: string;
}

export const PACKAGE_PLACEHOLDER: Package = { id: -1, uid: 'empty', title: 'empty' };
