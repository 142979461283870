import { FC, useState } from 'react';

import { Button, Dialog } from '@mui/material';

import { AssetFormData } from 'components/asset';
import { AssetPicker, AssetPickerTheme } from 'components/asset/asset-picker';
import { ImagePreview, Props as ImagePreviewProps } from 'components/image-preview';
import { PathParams } from 'global/route';
import { AssetForm } from 'pages/assets/form';

const POST_IMAGE_HANDLER_CLASS = 'post-image-handler';

interface Props {
  disabled: boolean;
  url: string | null;
  imagePreviewProps: ImagePreviewProps;
  onDelete: () => void;
  onSubmit: (asset: AssetFormData) => void;
  onChange: (asset: AssetFormData) => void;
  notifyAfterSubmit?: boolean;
  goBackAfterSubmit?: boolean;
}
export const ImageInput: FC<Props> = ({
  imagePreviewProps,
  url,
  onDelete,
  onChange,
  onSubmit,
  disabled = false,
  notifyAfterSubmit = true,
  goBackAfterSubmit = false,
}) => {
  const [assetPickerDialogOpen, setAssetPickerDialogOpen] = useState<boolean>(false);
  const [assetUploadDialogOpen, setAssetUploadDialogOpen] = useState(false);

  const closeDialog = () => setAssetPickerDialogOpen(false);
  const openDialog = () => setAssetPickerDialogOpen(true);

  const openAssetUploadDialog = () => setAssetUploadDialogOpen(true);
  const closeAssetUploadDialog = () => setAssetUploadDialogOpen(false);

  return (
    <div className={POST_IMAGE_HANDLER_CLASS}>
      <ImagePreview url={url} {...imagePreviewProps} />
      <br />
      {!disabled &&
        (url ? (
          <Button onClick={onDelete} variant="contained">
            Remove
          </Button>
        ) : (
          <>
            <Button variant="contained" onClick={openAssetUploadDialog} component="span">
              Upload
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={openDialog} variant="contained">
              Add existing
            </Button>
          </>
        ))}
      <AssetPicker
        close={closeDialog}
        onSubmit={onSubmit}
        state={assetPickerDialogOpen}
        theme={AssetPickerTheme.DARK}
      />
      <Dialog open={assetUploadDialogOpen} onClose={closeAssetUploadDialog}>
        <AssetForm
          action={PathParams.CREATE}
          maxWidth={'95%'}
          onAssetUploaded={asset => {
            onChange(asset);
            closeAssetUploadDialog();
          }}
          onCancel={closeAssetUploadDialog}
          goBackAfterSubmit={goBackAfterSubmit}
          notifyAfterSubmit={notifyAfterSubmit}
        />
      </Dialog>
    </div>
  );
};
