import { FC } from 'react';

import { Autocomplete, TextField } from '@mui/material';

import { Sequence } from '../forms';
import '../index.scss';

interface Props {
  nextSequence?: Sequence;
  sequences: Sequence[];
  setNextSequence: (sequence: Sequence | null) => void;
  disabled: boolean;
}

export const NextSequence: FC<Props> = ({ nextSequence, sequences, setNextSequence, disabled }) => {
  return (
    <div className="constructor_tags">
      <div className="label">Upon completion start sequence:</div>
      {!disabled ? (
        <Autocomplete
          defaultValue={nextSequence}
          options={sequences}
          onChange={(e, value) => setNextSequence(value)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={option => option.title}
          renderInput={params => <TextField {...params} variant="standard" />}
          disabled={disabled}
          fullWidth
        />
      ) : nextSequence ? (
        <>
          {nextSequence.uid} : {nextSequence.title}
        </>
      ) : (
        <>---</>
      )}
    </div>
  );
};
