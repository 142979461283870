import { useEffect } from 'react';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { PathParams } from 'global/route';
import {
  CHANGE_WISDOM_ENGINE_PROMPTS_PUBLISH_STATUSES,
  CREATE_WISDOM_ENGINE_PROMPT,
  DESTROY_WISDOM_ENGINE_PROMPT,
  UPDATE_WISDOM_ENGINE_PROMPT,
} from 'graphql/mutations';
import { validateTextField } from 'plugins/validator';

import { FORM, FormData, Key } from '../forms';
import { useItemForm } from 'hooks/useItemForm';
import { GET_WISDOM_ENGINE_PROMPTS } from 'graphql/queries';

interface Props {
  action: PathParams;
  providedFormValues: FormData;
  client: ApolloClient<NormalizedCacheObject>;
}

export const useWisdomEnginePromptForm = ({ providedFormValues, action, client }: Props) => {
  const parseData = (values: FormData) => {
    const { updatedAt, createdAt, uid, __typename, ...formValues } = values;
    return formValues;
  };

  const { formActions, formErrors, register, setFormValues, setValue, trigger, watch } = useItemForm<FormData>({
    action,
    defaultValues: FORM,
    itemDisplayName: 'Wisdom Engine Prompt',
    createMutation: CREATE_WISDOM_ENGINE_PROMPT,
    updateMutation: UPDATE_WISDOM_ENGINE_PROMPT,
    destroyMutation: DESTROY_WISDOM_ENGINE_PROMPT,
    changePublishStatusMutation: CHANGE_WISDOM_ENGINE_PROMPTS_PUBLISH_STATUSES,
    getItemsQuery: GET_WISDOM_ENGINE_PROMPTS,
    parseData,
    gqlClient: client,
  });

  const disabled = action === PathParams.SHOW;

  useEffect(() => {
    register(Key.QUESTION, {
      validate: value => validateTextField(value, 'Question'),
    });
  }, [register]);

  // set the form values passed from parent
  useEffect(() => {
    if (providedFormValues) {
      setFormValues(providedFormValues);
    }
  }, []);

  return {
    errors: formErrors,
    watch,
    setValue,
    trigger,
    formActions,
    disabled,
  };
};
