import { ChangeEvent, FC, useCallback, useMemo } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { DataGridProps, GridEventListener, GridRowSelectionModel } from '@mui/x-data-grid';

import { DataGrid } from 'components/mui-custom';
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'hooks/useTable';

import { Bulk } from './Bulk';
import { FilterProps } from './filter';
import './index.scss';
import { SelectAll } from './SelectAll';
import { Title } from './Title';
import { Toolbar } from './toolbar';
import { GetCellLinkCallback } from './types';

import { renderFullLinkCell } from '../view-table/renderFullLinkCell';
import { renderOverlay } from '../renderOverlay';
import { Modal } from 'store/common-modal';

interface Props extends DataGridProps {
  filter: FilterProps;
  bulk: {
    actions: Modal[];
  };
  selected?: GridRowSelectionModel;
  selectedCount?: number;
  onSelectAll?: () => void;
  onUnselectAll?: () => void;
  title: string;
  onGetLink?: GetCellLinkCallback;
  selectPageHandler: GridEventListener<'columnHeaderClick'>;
  selectAllChecked: boolean;
  onSelectAllChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  selectAllIndeterminate: boolean;
}

export const Table: FC<Props> = ({
  filter,
  bulk: { actions: bulkActions },
  onRowClick,
  onGetLink,
  columns,
  rows,
  rowSelectionModel,
  selected,
  selectedCount = 0,
  onSelectAll,
  onUnselectAll,
  rowCount,
  title,
  selectPageHandler,
  onSelectAllChange,
  selectAllChecked,
  selectAllIndeterminate,
  ...props
}) => {
  const hasRows = Boolean(rows.length);

  const columnsWithLinks = useMemo(() => {
    if (!onGetLink) {
      return columns;
    }
    return columns.map(column => {
      if (!column.renderCell) {
        const renderCellWithLink = params => renderFullLinkCell(onGetLink(params), params);
        return { ...column, renderCell: renderCellWithLink };
      }
      return column;
    });
  }, []);

  const GridToolbar = useCallback(
    () => (
      <>
        <Toolbar {...filter} />
        <SelectAll
          checked={selectAllChecked}
          onChange={onSelectAllChange}
          indeterminate={selectAllIndeterminate}
          sx={{ marginLeft: '4px' }}
        />
        {hasRows && <Bulk actions={bulkActions} selectedCount={selectedCount} />}
      </>
    ),
    [hasRows, bulkActions, selectedCount, filter],
  );

  return (
    <div className="entity-table">
      <Title>{title}</Title>
      <DataGrid
        style={{ border: 'none' }}
        rowCount={rowCount || 0}
        onColumnHeaderClick={selectPageHandler}
        rows={rows}
        columns={columnsWithLinks}
        checkboxSelection
        autoHeight
        pageSizeOptions={TABLE_ROWS_PER_PAGE_OPTIONS}
        paginationMode="server"
        rowSelectionModel={selected}
        slots={{
          toolbar: GridToolbar,
          noResultsOverlay: () => renderOverlay('entity-table_no-results'),
          noRowsOverlay: () => renderOverlay('entity-table_not-found'),
          columnSortedAscendingIcon: ArrowDropDownIcon,
          columnSortedDescendingIcon: ArrowDropUpIcon,
          columnUnsortedIcon: null,
        }}
        hideFooterSelectedRowCount
        onRowClick={onRowClick}
        {...props}
      />
    </div>
  );
};
