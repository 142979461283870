import { FC } from 'react';

import { DataGridProps, GridColDef, GridRowsProp } from '@mui/x-data-grid';

import { DataGrid } from 'components/mui-custom';
import { renderOverlay } from '../renderOverlay';

const NO_RESULTS_OVERLAY = 'No results Found';
const NO_DATA = 'No data Found';

interface Props extends DataGridProps {
  rows: GridRowsProp;
  columns: GridColDef[];
  label?: string;
  noResultsText?: string;
  noDataText?: string;
}

export const ViewTable: FC<Props> = ({
  rows,
  columns,
  label,
  noResultsText = NO_RESULTS_OVERLAY,
  noDataText = NO_DATA,
  ...props
}) => {
  return (
    <div className="view-table">
      <div className="--label">{label}</div>
      <DataGrid
        rows={rows ?? []}
        columns={columns}
        autoHeight
        hideFooter
        getRowId={row => row.id ?? Math.random()}
        hideFooterSelectedRowCount
        slots={{
          noResultsOverlay: () => renderOverlay('entity-table_no-results', noResultsText),
          noRowsOverlay: () => renderOverlay('entity-table_not-found', noDataText),
        }}
        {...props}
      />
    </div>
  );
};
