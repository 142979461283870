import { FC } from 'react';

import { API_HOST, AuthRoute, GoogleUserRole } from 'constants/auth';
import { usePromptLogin } from 'hooks/useGoogleAuth';

import { SignInPage } from './SignIn';

export const CmsSigninPage: FC = () => {
  const signIn = usePromptLogin(API_HOST + AuthRoute.CMS_LOG_IN_PATH, {
    role: GoogleUserRole.CONTENT_EDITOR,
  });

  return <SignInPage signIn={signIn} loaded={true} />;
};

export const LabSigninPage: FC = () => {
  const signIn = usePromptLogin(API_HOST + AuthRoute.LAB_LOG_IN_PATH, {
    role: GoogleUserRole.CONTENT_EDITOR,
  });

  return <SignInPage signIn={signIn} loaded={true} />;
};
