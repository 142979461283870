import { PathParams } from 'global/route';

export const showConfirmAlert = (): (() => void) | undefined => {
  if (window.location.href.includes(PathParams.EDIT)) {
    const unloadCallback = event => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }
};
