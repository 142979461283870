import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

import { ButtonGenerator, MenuItemGenerator } from 'components/generators';
import { FormActions } from 'hooks/useTable';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  actions: FormActions;
  background?: string;
}

export const ActionButtons: FC<Props> = ({ actions }) => (
  <div className="action-buttons">
    <ButtonGenerator actions={actions.inline || []} />
    <MenuItemGenerator actions={actions.expanded || []} />
  </div>
);
