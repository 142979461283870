import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSignedLogin } from 'hooks/useGoogleAuth';
import { AppLayout } from 'layouts';
import { selectUser } from 'store/google-user';

export const CmsApp: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email, isInitialized } = useSelector(selectUser);
  useSignedLogin({ dispatch, navigate, email });

  return <AppLayout className="app" isLoaded={isInitialized} isSignedIn={Boolean(email)} />;
};

export const LabApp: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email, isInitialized } = useSelector(selectUser);
  useSignedLogin({ dispatch, navigate, email });

  return <AppLayout className="app" isLoaded={isInitialized} isSignedIn={Boolean(email)} />;
};
