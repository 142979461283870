import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { GridColDef } from '@mui/x-data-grid';
import _isEqual from 'lodash/isEqual';

import { ASSET_FORM, AssetFormData, AssetKey, MediaTypeValue } from 'components/asset';
import { buildRowsFrom } from 'components/tables/service';
import { ASPECT_RATIO_FOUR_TO_ONE, ASPECT_RATIO_FOUR_TO_THREE, ASPECT_RATIO_FOUR_TO_ZERO } from 'constants/styles';
import { capitalize, getFileExtension } from 'global/formater';
import { BASE_PATH, PARENT_ROUTE, pathJoin, PathParams } from 'global/route';
import { CREATE_ASSET, DESTROY_ASSET, GENERATE_ASSET_PUT_SIGNED_URL, UPDATE_ASSET } from 'graphql/mutations';
import { GET_ASSETS } from 'graphql/queries';
import { useSnackbar } from 'hooks/useSnackbar';
import { FormActions } from 'hooks/useTable';
import { parseError, useForm } from 'plugins/react-hook-form';
import { validateTextField } from 'plugins/validator';
import { useDispatch } from 'react-redux';
import { clearConfirmNavigationModal, setConfirmNavigationModal } from 'store/confirm-navigation-modal';
import { getConfirmNavigationAction, showConfirmAlert } from 'components/confirm-modal';

export const ASSET_COLUMNS: GridColDef[] = [
  { field: 'key', flex: 1 },
  { field: 'value', flex: 3 },
];

interface Props {
  action: PathParams;
  providedFormValues: AssetFormData;
  goBackAfterSubmit?: boolean;
  notifyAfterSubmit?: boolean;
  onAssetUploaded?: (asset: AssetFormData) => void;
  onCancel?: () => void;
}
export const useAssetForm = ({
  providedFormValues,
  action,
  goBackAfterSubmit = true,
  notifyAfterSubmit = true,
  onAssetUploaded,
  onCancel,
}: Props) => {
  const navigate = useNavigate();
  const { id: assetId } = useParams();
  const { showErrorToast, showSuccessToast } = useSnackbar();

  const [file, setFile] = useState<File | null>(null);
  const [cachedUrl, setCachedUrl] = useState<string>('');
  const [putUrlLoading, setPutUrlLoading] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState(window.location.href.includes(PathParams.EDIT));
  const [existingFormData, setExistingFormData] = useState<AssetFormData>();
  const [formDataChanged, setFormDataChanged] = useState(false);

  const [createAsset, { loading: createLoading }] = useMutation<{
    createAsset: {
      record: AssetFormData;
      signedUrl: string;
    };
  }>(CREATE_ASSET, { refetchQueries: [GET_ASSETS] });
  const [generatePutUrl, { loading: generateUrlLoading }] = useMutation<{
    assetPutSignedUrlResponse: {
      key: string;
      signedUrl: string;
    };
  }>(GENERATE_ASSET_PUT_SIGNED_URL);
  const [updateAsset, { loading: updateLoading }] = useMutation<{
    asset: AssetFormData;
  }>(UPDATE_ASSET);
  const [destroyAsset, { loading: destroyLoading }] = useMutation<{
    asset: number;
  }>(DESTROY_ASSET);

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors },
    reset,
    getValues,
  } = useForm<AssetFormData>({
    defaultValues: ASSET_FORM,
  });

  const formLoading = useMemo<boolean>(
    () => createLoading || generateUrlLoading || updateLoading || destroyLoading || putUrlLoading,
    [createLoading, generateUrlLoading, updateLoading, destroyLoading, putUrlLoading],
  );
  const asset = watch();
  const { id, url, assetType, description } = asset;
  const fileType = useMemo<string>(() => getFileExtension(file?.name || url || ''), [file?.type, url]);
  const fileName = useMemo<string>(() => description + '.' + fileType, [description, file?.type]);
  const readOnly: boolean = action === PathParams.SHOW;
  const aspectRatio =
    assetType === MediaTypeValue.AUDIO
      ? ASPECT_RATIO_FOUR_TO_ONE
      : !assetType
      ? ASPECT_RATIO_FOUR_TO_ZERO
      : ASPECT_RATIO_FOUR_TO_THREE;
  const rows = buildRowsFrom(asset, {
    mapper: {
      id: { exclude: true },
      assetType: { formatKey: () => 'Asset type' },
    },
  }).filter(row => row.key !== capitalize(AssetKey.POSTS));
  const columns = ASSET_COLUMNS;

  const putAsset = async (signedUrl: string, mimeType: string, file: File): Promise<Response> => {
    setPutUrlLoading(true);
    const request = await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': mimeType,
      },
      body: file,
    });

    setPutUrlLoading(false);
    return request;
  };

  const validateFormHandler = async (): Promise<boolean | void> => {
    const isValid = await trigger();

    if (!isValid) {
      showErrorToast(String(parseError(errors)));
    }

    return isValid;
  };

  const editHandler = (): void => navigate(pathJoin(PARENT_ROUTE, assetId as string, PathParams.EDIT));
  const deleteHandler = async (): Promise<void> => {
    const { errors } = await destroyAsset({
      variables: {
        id,
      },
    });

    if (errors?.length) return;

    if (notifyAfterSubmit) {
      showSuccessToast(`Asset ${id} successfully deleted`);
    }
    if (goBackAfterSubmit) {
      navigate(PARENT_ROUTE);
    }
  };
  const updateAssetHandler = async (fromConfirm = false): Promise<AssetFormData | void> => {
    setShowConfirm(false);
    if (!formDataChanged) {
      navigate(-1);
      return;
    }
    let asset: AssetFormData | void = undefined;

    if (file && url !== cachedUrl) {
      const { data, errors } = await generatePutUrl({
        variables: {
          mimeType: file.type,
          fileName,
        },
      });

      if (errors?.length) return;

      if (data) {
        const { key, signedUrl } = data.assetPutSignedUrlResponse;
        await putAsset(signedUrl, file.type, file);
        let { data: updatedAsset, errors } = await updateAsset({
          variables: {
            asset: {
              id,
              key,
              assetType,
              description,
            },
          },
        });

        if (errors?.length) return;

        asset = updatedAsset?.asset;
      }
    } else {
      let { data: updatedAsset, errors } = await updateAsset({
        variables: {
          asset: {
            id,
            description,
          },
        },
      });

      if (errors?.length) return;

      asset = updatedAsset?.asset;
    }

    if (notifyAfterSubmit) {
      showSuccessToast(`Asset ${id} successfully updated`);
    }

    if (goBackAfterSubmit && !fromConfirm) {
      navigate(-1);
    }

    return asset;
  };

  const createAssetHandler = async (): Promise<AssetFormData | void> => {
    let asset: AssetFormData | void = undefined;

    if (file) {
      const { data, errors } = await createAsset({
        variables: {
          asset: {
            assetType,
            mimeType: file.type,
            fileName,
          },
        },
      });

      if (errors?.length) return;

      if (data) {
        asset = data?.createAsset.record;
        await putAsset(data?.createAsset.signedUrl, file.type, file);
        if (onAssetUploaded) {
          onAssetUploaded(asset);
        }
      }
    }

    if (notifyAfterSubmit) {
      setShowConfirm(false);
      showSuccessToast(`Asset successfully created`);
    }
    if (goBackAfterSubmit) {
      navigate(-1);
    }

    return asset;
  };
  const saveHandler = async (fromConfirm = false): Promise<AssetFormData | void> => {
    const isValid = await validateFormHandler();
    if (!isValid) return;

    return (() => {
      switch (action) {
        case PathParams.EDIT:
          return updateAssetHandler(fromConfirm);
        case PathParams.CREATE:
          return createAssetHandler();
      }
    })();
  };
  const cancelHandler = (): void => {
    if (onCancel) {
      onCancel();
    }
    if (goBackAfterSubmit) {
      navigate(-1);
    }
    setShowConfirm(false);
  };
  const deleteAssetLocally = (): void => {
    setValue(AssetKey.URL, null);
    setValue(AssetKey.ASSET_TYPE, null);
    setFile(null);
  };
  const uploadAssetLocally = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = (e.target.files as FileList)?.[0];

    setFile(file);
    setValue(AssetKey.URL, URL.createObjectURL(file));
    setValue(AssetKey.DESCRIPTION, file.name);
    setValue(AssetKey.ASSET_TYPE, file.type.split(BASE_PATH)[0] as MediaTypeValue);
  };

  const editAction = {
    text: 'Edit',
    action: editHandler,
    Icon: EditIcon,
    disabled: destroyLoading,
  };
  const deleteAction = {
    text: 'Delete',
    action: deleteHandler,
    Icon: DeleteIcon,
    loading: formLoading,
  };
  const saveAction = {
    text: 'Save',
    action: () => {
      saveHandler(false);
    },
    Icon: SaveIcon,
    loading: formLoading,
  };
  const cancelAction = {
    text: 'Cancel',
    action: cancelHandler,
    Icon: CloseIcon,
    disabled: formLoading,
  };

  const formActions: FormActions = (() => {
    switch (action) {
      case PathParams.CREATE:
        return { inline: [saveAction, cancelAction] };
      case PathParams.EDIT:
        return { inline: [saveAction, cancelAction] };
      case PathParams.SHOW:
        return { inline: [editAction], expanded: [deleteAction] };
    }
  })();

  const setValues = (formValues: AssetFormData): void => {
    for (const key in formValues) {
      if (key in ASSET_FORM) {
        setValue(key as keyof typeof formValues, formValues[key] || '');
      }
    }
  };

  useEffect(() => {
    register(AssetKey.DESCRIPTION, {
      validate: value => validateTextField(value, 'Description'),
    });
    register(AssetKey.URL, {
      validate: value => (value ? true : 'Asset - required'),
    });
  }, [register]);

  useEffect(() => {
    if (providedFormValues) {
      setValues(providedFormValues);
      setCachedUrl(providedFormValues.url ?? '');
    }
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.href.includes('assets')) {
      const confirmNavigationAction = getConfirmNavigationAction(
        showConfirm && formDataChanged,
        async () => {
          await saveHandler(true);
        },
        () => dispatch(clearConfirmNavigationModal()),
      );
      dispatch(setConfirmNavigationModal(confirmNavigationAction));
    }
  });

  useEffect(() => {
    return showConfirmAlert();
  }, []);

  useEffect(() => {
    if (!existingFormData && id) {
      setExistingFormData(asset);
    }
    if (existingFormData) {
      setFormDataChanged(!_isEqual(existingFormData, asset));
    }
  });

  return {
    reset,
    rows,
    columns,
    aspectRatio,
    readOnly,
    formLoading,
    watch,
    setValue,
    trigger,
    errors,
    formActions,
    deleteAssetLocally,
    uploadAssetLocally,
    saveHandler,
    createAssetHandler,
    getValues,
  };
};
