import { gql } from '@apollo/client';

import { DEFAULT_EXPERIMENT_FIELDS } from './fragments';

export const CREATE_POST = gql`
  mutation createPost($input: PostCreate!) {
    createPost(post: $input) {
      id
    }
  }
`;

export const UPDATE_POST = gql`
  mutation updatePost($input: PostUpdate!) {
    updatePost(post: $input) {
      id
    }
  }
`;

export const DESTROY_POST = gql`
  mutation destroyPost($input: PostDestroy!) {
    destroyPost(post: $input) {
      id
    }
  }
`;

export const BULK_DESTROY_POSTS = gql`
  mutation bulkDestroyPosts($where: JSON, $ids: [Int], $publishedAt: String, $isExclude: Boolean) {
    bulkDestroyPosts(where: $where, ids: $ids, publishedAt: $publishedAt, isExclude: $isExclude)
  }
`;

export const CHANGE_POST_PUBLISH_STATUSES = gql`
  mutation changePostPublishStatuses($where: JSON, $ids: [Int], $publishedAt: String, $isExclude: Boolean) {
    changePostPublishStatuses(where: $where, ids: $ids, publishedAt: $publishedAt, isExclude: $isExclude)
  }
`;

export const CREATE_MOMENT = gql`
  mutation createMoment($input: MomentCreate!) {
    createMoment(moment: $input) {
      id
    }
  }
`;

export const UPDATE_MOMENT = gql`
  mutation updateMoment($input: MomentUpdate!) {
    updateMoment(moment: $input) {
      id
    }
  }
`;

export const DESTROY_MOMENT = gql`
  mutation destroyMoment($input: MomentDestroy!) {
    destroyMoment(moment: $input) {
      id
    }
  }
`;

export const BULK_DESTROY_MOMENTS = gql`
  mutation bulkDestroyMoments($where: JSON, $ids: [Int], $publishedAt: String, $isExclude: Boolean) {
    bulkDestroyMoments(where: $where, ids: $ids, publishedAt: $publishedAt, isExclude: $isExclude)
  }
`;

export const CHANGE_MOMENTS_PUBLISH_STATUSES = gql`
  mutation changeMomentsPublishStatuses($where: JSON, $ids: [Int], $publishedAt: String, $isExclude: Boolean) {
    changeMomentsPublishStatuses(where: $where, ids: $ids, publishedAt: $publishedAt, isExclude: $isExclude)
  }
`;

export const BULK_DESTROY_ASSETS = gql`
  mutation bulkDestroyAssets($where: JSON, $ids: [Int], $isExclude: Boolean) {
    bulkDestroyAssets(where: $where, ids: $ids, isExclude: $isExclude)
  }
`;

export const CREATE_ASSET = gql`
  mutation createAsset($asset: AssetCreate!) {
    createAsset(asset: $asset) {
      record {
        id
        assetType
        url
        description
        createdAt
        updatedAt
      }
      signedUrl
    }
  }
`;

export const DESTROY_ASSET = gql`
  mutation destroyAsset($id: Int!) {
    destroyAsset: destroyAsset(id: $id)
  }
`;

export const GENERATE_ASSET_PUT_SIGNED_URL = gql`
  mutation generateAssetPutSignedUrl($mimeType: String!, $fileName: String!) {
    assetPutSignedUrlResponse: generateAssetPutSignedUrl(mimeType: $mimeType, fileName: $fileName) {
      key
      signedUrl
    }
  }
`;

export const UPDATE_ASSET = gql`
  mutation updateAsset($asset: AssetUpdate!) {
    asset: updateAsset(asset: $asset) {
      id
      assetType
      url
      description
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation createQuestion($input: QuestionCreate!) {
    data: createQuestion(question: $input) {
      content
      choices
    }
  }
`;

export const DESTROY_QUESTION = gql`
  mutation destroyQuestion($input: QuestionDestroy!) {
    data: destroyQuestion(question: $input) {
      id
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation updateQuestion($input: QuestionUpdate!) {
    data: updateQuestion(question: $input) {
      id
      content
      choices
      publishedAt
    }
  }
`;

export const BULK_DESTROY_QUESTIONS = gql`
  mutation bulkDestroyQuestions($where: JSON, $ids: [Int], $isExclude: Boolean) {
    bulkDestroyQuestions(where: $where, ids: $ids, isExclude: $isExclude)
  }
`;

export const CHANGE_QUESTIONS_PUBLISH_STATUSES = gql`
  mutation changeQuestionPublishStatuses($publishedAt: DateTime, $ids: [Int], $isExclude: Boolean, $where: JSON) {
    changeQuestionPublishStatuses(publishedAt: $publishedAt, ids: $ids, isExclude: $isExclude, where: $where)
  }
`;

export const CREATE_DAILY_PRACTICE = gql`
  mutation createDailyPractice($input: DailyPracticeCreate!) {
    data: createDailyPractice(dailyPractice: $input) {
      id
    }
  }
`;

export const UPDATE_DAILY_PRACTICE = gql`
  mutation updateDailyPractice($input: DailyPracticeUpdate!) {
    data: updateDailyPractice(dailyPractice: $input) {
      id
      action
      publishedAt
    }
  }
`;

export const DESTROY_DAILY_PRACTICE = gql`
  mutation destroyDailyPractice($input: DailyPracticeDestroy!) {
    data: destroyDailyPractice(dailyPractice: $input) {
      id
    }
  }
`;

export const BULK_DESTROY_DAILY_PRACTICES = gql`
  mutation bulkDestroyDailyPractices($where: JSON, $ids: [Int], $isExclude: Boolean) {
    bulkDestroyDailyPractices(where: $where, ids: $ids, isExclude: $isExclude)
  }
`;

export const CHANGE_DAILY_PRACTICES_PUBLISH_STATUSES = gql`
  mutation changeDailyPracticesPublishStatuses($publishedAt: DateTime, $ids: [Int], $isExclude: Boolean, $where: JSON) {
    changeDailyPracticesPublishStatuses(publishedAt: $publishedAt, ids: $ids, isExclude: $isExclude, where: $where)
  }
`;

export const CREATE_REVIEW = gql`
  mutation createReview($input: ReviewCreate!) {
    data: createReview(review: $input) {
      id
    }
  }
`;

export const UPDATE_REVIEW = gql`
  mutation updateReview($input: ReviewUpdate!) {
    data: updateReview(review: $input) {
      id
    }
  }
`;

export const DESTROY_REVIEW = gql`
  mutation destroyReview($input: ReviewDestroy!) {
    data: destroyReview(review: $input) {
      id
    }
  }
`;

export const BULK_DESTROY_REVIEWS = gql`
  mutation bulkDestroyReviews($where: JSON, $ids: [Int], $isExclude: Boolean) {
    bulkDestroyReviews(where: $where, ids: $ids, isExclude: $isExclude)
  }
`;

export const CHANGE_REVIEWS_PUBLISH_STATUSES = gql`
  mutation changeReviewPublishStatuses($publishedAt: DateTime, $ids: [Int], $isExclude: Boolean) {
    changeReviewPublishStatuses(publishedAt: $publishedAt, ids: $ids, isExclude: $isExclude)
  }
`;

export const CREATE_PACKAGE = gql`
  mutation createPackage($input: PackageCreate!) {
    data: createPackage(packageData: $input) {
      id
    }
  }
`;

export const UPDATE_PACKAGE = gql`
  mutation updatePackage($input: PackageUpdate!) {
    data: updatePackage(packageData: $input) {
      id
    }
  }
`;

export const DESTROY_PACKAGE = gql`
  mutation destroyPackage($input: PackageDestroy!) {
    data: destroyPackage(packageData: $input) {
      id
    }
  }
`;

export const BULK_DESTROY_PACKAGES = gql`
  mutation bulkDestroyPackages($where: JSON, $ids: [Int!], $isExclude: Boolean) {
    bulkDestroyPackages(where: $where, ids: $ids, isExclude: $isExclude)
  }
`;

export const CHANGE_PACKAGES_PUBLISH_STATUSES = gql`
  mutation changePackagePublishStatuses($publishedAt: DateTime, $ids: [Int!], $isExclude: Boolean, $where: JSON) {
    changePackagePublishStatuses(publishedAt: $publishedAt, ids: $ids, isExclude: $isExclude, where: $where)
  }
`;

export const CREATE_SEQUENCE = gql`
  mutation createPackageSequence($input: PackageSequenceCreate!) {
    data: createPackageSequence(packageSequence: $input) {
      id
    }
  }
`;

export const UPDATE_SEQUENCE = gql`
  mutation updatePackageSequence($input: PackageSequenceUpdate!) {
    data: updatePackageSequence(packageSequence: $input) {
      id
    }
  }
`;

export const DESTROY_SEQUENCE = gql`
  mutation destroyPackageSequence($input: PackageSequenceDestroy!) {
    data: destroyPackageSequence(packageSequence: $input) {
      id
    }
  }
`;

export const BULK_DESTROY_SEQUENCES = gql`
  mutation bulkDestroyPackageSequences($where: JSON, $ids: [Int]!, $isExclude: Boolean) {
    bulkDestroyPackageSequences(where: $where, ids: $ids, isExclude: $isExclude)
  }
`;

export const CHANGE_SEQUENCE_PUBLISH_STATUSES = gql`
  mutation changePackageSequencePublishStatuses(
    $publishedAt: DateTime
    $ids: [Int]!
    $isExclude: Boolean
    $where: JSON
  ) {
    changePackageSequencePublishStatuses(publishedAt: $publishedAt, ids: $ids, isExclude: $isExclude, where: $where)
  }
`;

export const CREATE_WISDOM_ENGINE_PROMPT = gql`
  mutation createWisdomEnginePrompt($input: WisdomEnginePromptCreate!) {
    data: createWisdomEnginePrompt(wisdomEnginePrompt: $input) {
      id
    }
  }
`;

export const UPDATE_WISDOM_ENGINE_PROMPT = gql`
  mutation updateWisdomEnginePrompt($input: WisdomEnginePromptUpdate!) {
    data: updateWisdomEnginePrompt(wisdomEnginePrompt: $input) {
      id
    }
  }
`;

export const DESTROY_WISDOM_ENGINE_PROMPT = gql`
  mutation destroyWisdomEnginePrompt($input: WisdomEnginePromptDestroy!) {
    data: destroyWisdomEnginePrompt(wisdomEnginePrompt: $input) {
      id
    }
  }
`;

export const BULK_DESTROY_WISDOM_ENGINE_PROMPTS = gql`
  mutation bulkDestroyWisdomEnginePrompts($where: JSON, $ids: [Int!], $isExclude: Boolean) {
    bulkDestroyWisdomEnginePrompts(where: $where, ids: $ids, isExclude: $isExclude)
  }
`;

export const CHANGE_WISDOM_ENGINE_PROMPTS_PUBLISH_STATUSES = gql`
  mutation changeWisdomEnginePromptsPublishStatuses(
    $publishedAt: DateTime
    $ids: [Int!]
    $isExclude: Boolean
    $where: JSON
  ) {
    changeWisdomEnginePromptsPublishStatuses(publishedAt: $publishedAt, ids: $ids, isExclude: $isExclude, where: $where)
  }
`;

export const CREATE_LAB_USER = gql`
  mutation createUser($input: UserCreate!) {
    data: createUser(user: $input) {
      id
      email
      typeId
      experiments {
        id
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_LAB_USER = gql`
  mutation updateUser($input: UserUpdate!) {
    data: updateUser(user: $input) {
      id
      email
      typeId
      experiments {
        id
        title
      }
      createdAt
      updatedAt
    }
  }
`;

export const DESTROY_LAB_USER = gql`
  mutation destroyUser($input: UserDestroy!) {
    data: destroyUser(user: $input) {
      id
    }
  }
`;

export const BULK_DESTROY_LAB_USERS = gql`
  mutation bulkDestroyUsers($where: JSON, $ids: [Int!]!, $isExclude: Boolean) {
    bulkDestroyUsers(where: $where, ids: $ids, isExclude: $isExclude)
  }
`;

export const CREATE_EXPERIMENT = gql`
  ${DEFAULT_EXPERIMENT_FIELDS}
  mutation createExperiment($input: ExperimentCreate!) {
    data: createExperiment(experiment: $input) {
      ...ExperimentFields
    }
  }
`;

export const UPDATE_EXPERIMENT = gql`
  ${DEFAULT_EXPERIMENT_FIELDS}
  mutation updateExperiment($input: ExperimentUpdate!) {
    data: updateExperiment(experiment: $input) {
      ...ExperimentFields
    }
  }
`;

export const CHANGE_EXPERIMENT_SHOW_INSIGHTS_STATUS = gql`
  mutation changeShowInsightsStatus($experiment: ExperimentChangeShowInsightsStatus!) {
    showInsights: changeShowInsightsStatus(experiment: $experiment)
  }
`;

export const DESTROY_EXPERIMENT = gql`
  mutation destroyExperiment($input: ExperimentDestroy!) {
    data: destroyExperiment(experiment: $input)
  }
`;

export const BULK_DESTROY_EXPERIMENTS = gql`
  mutation bulkDestroyExperiments($ids: [Int!]!, $where: JSON, $isExclude: Boolean) {
    bulkDestroyExperiments(where: $where, ids: $ids, isExclude: $isExclude)
  }
`;

export const CHANGE_EXPERIMENT_PUBLISH_STATUSES = gql`
  mutation changeExperimentPublishStatuses($ids: [Int!]!, $where: JSON, $publishedAt: String, $isExclude: Boolean) {
    changeExperimentPublishStatuses(ids: $ids, where: $where, publishedAt: $publishedAt, isExclude: $isExclude)
  }
`;

export const BULK_DESTROY_CAROUSELS = gql`
  mutation bulkDestroyCarousels($where: JSON, $ids: [Int!]!, $isExclude: Boolean) {
    bulkDestroyCarousels(where: $where, ids: $ids, isExclude: $isExclude)
  }
`;

export const CHANGE_CAROUSELS_PUBLISH_STATUSES = gql`
  mutation changeCarouselPublishStatuses($ids: [Int!]!, $where: JSON, $publishedAt: DateTime, $isExclude: Boolean) {
    changeCarouselPublishStatuses(ids: $ids, where: $where, publishedAt: $publishedAt, isExclude: $isExclude)
  }
`;

export const CREATE_CAROUSEL = gql`
  mutation createCarousel($input: CarouselCreate!) {
    data: createCarousel(carousel: $input) {
      id
      name
      description
      locationId
      location {
        id
        name
      }
      slides {
        id
        type
        text
        position
        title
        assetId
        asset {
          id
          assetType
          url
        }
      }
    }
  }
`;

export const UPDATE_CAROUSEL = gql`
  mutation updateCarousel($input: CarouselUpdate!) {
    data: updateCarousel(carousel: $input) {
      id
      name
      description
      locationId
      location {
        id
        name
      }
      slides {
        id
        type
        text
        position
        title
        assetId
        asset {
          id
          assetType
          url
        }
      }
    }
  }
`;

export const DESTROY_CAROUSEL = gql`
  mutation destroyCarousel($input: CarouselDestroy!) {
    data: destroyCarousel(carousel: $input) {
      id
    }
  }
`;
