export * from 'react-hook-form';

export const parseError = (error: Object) => {
  if (!error) return;

  const message = 'Errors: ';

  const errors = Object.values(error).map(({ message }) => message);
  return message + errors.join(', ');
};
