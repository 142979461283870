import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, TextField } from '@mui/material';
import _debounce from 'lodash/debounce';

import { AssetFilter, AssetFormData, DEFAULT_FILTER } from 'components/asset';
import { AssetGridView } from 'components/asset/grid';

import './index.scss';

const DEFAULT_ASSETS_PER_PAGE = 30;
const DEFAULT_DIALOG_MAX_WIDTH = 'sm';
const DIALOG_CLASS = 'asset-dialog ';
const DIALOG_ACTIONS_CLASS = 'asset-picker-actions';

export enum AssetPickerTheme {
  WHITE = 'white',
  DARK = 'dark',
}

interface Props {
  state: boolean;
  onSubmit: (asset: AssetFormData) => void;
  close: () => void;
  clickHandler?: (e: MouseEvent, asset: AssetFormData) => void;
  where?: AssetFilter;
  theme?: AssetPickerTheme;
  assetsPerPage?: number;
  dialogHeight?: string;
  dialogMaxWidth?: DialogProps['maxWidth'];
}
export const AssetPicker: FC<Props> = ({
  state,
  onSubmit,
  clickHandler,
  close,
  where = DEFAULT_FILTER,
  theme = AssetPickerTheme.WHITE,
  assetsPerPage = DEFAULT_ASSETS_PER_PAGE,
  dialogMaxWidth = DEFAULT_DIALOG_MAX_WIDTH,
}) => {
  const [selectedAsset, setSelectedAsset] = useState<AssetFormData | null>(null);
  const [filter, setFilter] = useState(where);
  useEffect(() => {
    setFilter(where);
  }, [where]);

  const closeDialog = (): void => {
    setSelectedAsset(null);
    close();
  };

  const selectAsset = (): void => {
    onSubmit(selectedAsset as AssetFormData);
    closeDialog();
  };

  const updateFilter = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilter({
      ...filter,
      or: {
        description: e.target.value,
      },
    });
  };

  return (
    <Dialog className={DIALOG_CLASS + theme} maxWidth={dialogMaxWidth} onClose={closeDialog} open={state}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DialogTitle>Add from Library</DialogTitle>
        </Grid>
        <Grid item xs={6}>
          <DialogActions className={DIALOG_ACTIONS_CLASS} sx={{ padding: 2 }}>
            <Button onClick={closeDialog} variant="text">
              CANCEL
            </Button>
            <Button disabled={!selectedAsset} variant="contained" onClick={selectAsset}>
              NEXT
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
      <Grid>
        <TextField
          fullWidth
          sx={{ paddingLeft: 2, paddingRight: 2 }}
          placeholder="Search"
          onChange={_debounce(updateFilter, 500)}
          autoFocus
        />
      </Grid>
      <DialogContent style={{ marginLeft: 40, marginRight: 40 }}>
        <AssetGridView
          where={filter}
          assetsPerPage={assetsPerPage}
          theme={theme}
          onClick={(e, asset) => {
            setSelectedAsset(asset);
            clickHandler?.(e, asset);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
