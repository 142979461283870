import { configureStore } from '@reduxjs/toolkit';

import { userReducer } from './google-user';
import { modalReducer } from './modal';
import { confirmNavigationModalReducer } from './confirm-navigation-modal';

export const store = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    googleUser: userReducer,
    modal: modalReducer,
    confirmNavigationModal: confirmNavigationModalReducer,
  },
});
