import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { GridColDef } from '@mui/x-data-grid';
import { RoutePath } from 'app/routes';
import { format, parseISO } from 'date-fns';

import { AssetFormData, AssetKey } from 'components/asset';
import { getBulkActions } from 'components/tables/grid-table/bulkActions';
import { DATE_FORMAT, NO_DATE } from 'constants/date-time';
import { BULK_DESTROY_ASSETS } from 'graphql/mutations';
import { GET_ASSETS } from 'graphql/queries';
import { useSnackbar } from 'hooks/useSnackbar';
import { getShowPagePath, useTable } from 'hooks/useTable';

const DATA_KEY = 'assets';
const COUNTER_KEY = 'assetsTotalCount';

interface Data {
  postsTotalCount: number;
  assets: AssetFormData[];
}

interface Search {
  description: string;
  assetType: string;
}

const ASSET_SECTION_COLUMNS: GridColDef[] = [
  { field: AssetKey.ID, headerName: 'ID' },
  { field: AssetKey.DESCRIPTION, headerName: 'Description', flex: 1 },
  { field: AssetKey.URL, headerName: 'url', flex: 1 },
  { field: AssetKey.ASSET_TYPE, headerName: 'Type', flex: 1 },
  {
    field: AssetKey.CREATED_AT,
    headerName: 'Created date',
    flex: 1,
    valueGetter: ({ row }) => (row.createdAt ? format(parseISO(row.createdAt), DATE_FORMAT) : NO_DATE),
  },
];

export const useAssetTable = () => {
  const navigate = useNavigate();
  const { showSuccessToast } = useSnackbar();

  const { updatePage, bulkObject, page, getFilter, ...args } = useTable<Data['assets']>({
    dataKey: DATA_KEY,
    counterKey: COUNTER_KEY,
    query: {
      query: GET_ASSETS,
    },
  });

  const bulkDelete = useMutation(BULK_DESTROY_ASSETS);

  const bulkActions = getBulkActions({
    bulkObject,
    updatePage,
    enqueueSnackbar: showSuccessToast,
    bulkDelete,
    entityName: 'asset',
    dispatch: useDispatch(),
  });

  const filterAction = getFilter({ options: [AssetKey.ID, AssetKey.DESCRIPTION] });

  return {
    ...args,
    page,
    bulkActions,
    filterAction,
    columns: ASSET_SECTION_COLUMNS,
    onRowClick: ({ row }) => navigate(getShowPagePath(RoutePath.ASSETS, String(row.id))),
  };
};
