import { FC } from 'react';

import { Typography } from '@mui/material';

interface Props {
  message?: string;
}
export const NotFound: FC<Props> = ({ message = 'No data Found', ...props }) => (
  <Typography variant="body1" component="div" align="center" gutterBottom {...props}>
    {message}
  </Typography>
);
