import { FC } from 'react';

import { Box, Checkbox, SxProps, Theme, Typography } from '@mui/material';

interface Props {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  indeterminate?: boolean;
  sx?: SxProps<Theme>;
}

export const SelectAll: FC<Props> = ({ onChange, checked, indeterminate = false, sx }) => (
  <Box sx={sx}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox onChange={onChange} checked={checked} indeterminate={indeterminate} />
      <Typography>Select all</Typography>
    </Box>
  </Box>
);
