import { useEffect } from 'react';

import { PathParams } from 'global/route';
import { Tag } from 'global/type';
import {
  CHANGE_SEQUENCE_PUBLISH_STATUSES,
  CREATE_SEQUENCE,
  DESTROY_SEQUENCE,
  UPDATE_SEQUENCE,
} from 'graphql/mutations';
import { GET_SEQUENCES } from 'graphql/queries';
import { Package } from 'pages/package/forms';
import { validateTextField } from 'plugins/validator';

import { Sequence, SEQUENCE_FORM, SequenceFormData, SequenceKey } from '../forms';
import { useItemForm } from 'hooks/useItemForm';

interface Props {
  action: PathParams;
  providedFormValues: SequenceFormData;
}

export const useSequenceForm = ({ providedFormValues, action }: Props) => {
  const parseData = (values: SequenceFormData) => {
    values.tags = values.tags?.map(tag => tag.id);
    values.nextPackageSequence = values.nextPackageSequence ? (values.nextPackageSequence as Sequence).id : null;
    values.packages = values.packages.map(_package => ({ id: _package.id, packageNumber: _package.packageNumber }));

    const { updatedAt, createdAt, uid, __typename, packageNumber, patientsNumber, ...formValues } = values;
    return formValues;
  };

  const { formActions, formErrors, register, setFormValues, setValue, trigger, watch } = useItemForm<SequenceFormData>({
    action,
    defaultValues: SEQUENCE_FORM,
    itemDisplayName: 'Sequence',
    createMutation: CREATE_SEQUENCE,
    updateMutation: UPDATE_SEQUENCE,
    destroyMutation: DESTROY_SEQUENCE,
    changePublishStatusMutation: CHANGE_SEQUENCE_PUBLISH_STATUSES,
    getItemsQuery: GET_SEQUENCES,
    parseData,
  });

  const disabled = action === PathParams.SHOW;

  const setTags = (tags: Tag[]) => {
    setValue(SequenceKey.TAGS, tags);
  };

  const setPackages = (packages: Package[]) => {
    setValue(SequenceKey.PACKAGES, packages);
  };

  const setNextSequence = (sequence: Sequence | null) => {
    setValue(SequenceKey.NEXT_SEQUENCE, sequence);
  };

  useEffect(() => {
    register(SequenceKey.TITLE, {
      validate: value => validateTextField(value, 'Title'),
    });
    register(SequenceKey.DESCRIPTION, {
      validate: value => validateTextField(value, 'Description'),
    });
    register(SequenceKey.PACKAGES, {
      validate: value => {
        if (value.map(_package => _package.id).some(id => id < 0)) {
          return 'Package can not be empty';
        }
      },
    });
  }, [register]);

  // set the form values passed from parent
  useEffect(() => {
    if (providedFormValues) {
      setFormValues(providedFormValues);
    }
  }, []);

  return {
    errors: formErrors,
    watch,
    setTags,
    setValue,
    trigger,
    formActions,
    setPackages,
    setNextSequence,
    disabled,
  };
};
