import { FC } from 'react';

import { StandardTextFieldProps } from '@material-ui/core';

import { TextField } from 'components/mui-custom';
import { setLabel } from 'global/form';
import { PathParams } from 'global/route';

import { Key as ExperimentKey } from '../forms';
import { UseExperimentForm } from '../hooks/useExperimentForm';

interface Props extends StandardTextFieldProps {
  hook: UseExperimentForm;
  action: PathParams;
}

export const ExperimentTitle: FC<Props> = ({ hook: { watch, setTitle, trigger, errors, readOnly }, action }) => {
  const { title } = watch();

  return (
    <TextField
      value={title}
      label={setLabel('Title', action)}
      onChange={setTitle}
      onBlur={() => trigger(ExperimentKey.TITLE)}
      helperText={errors?.title?.message}
      error={Boolean(errors.title)}
      disabled={readOnly}
    />
  );
};
