import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { ApolloClient, NormalizedCacheObject, useQuery } from '@apollo/client';

import { NotFound } from 'components/NotFound';
import { stringToInt } from 'global/formater';
import { PathParams } from 'global/route';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_WISDOM_ENGINE_PROMPT } from 'graphql/queries';

import { WisdomEnginePrompt } from './Create';

import { FORM, FormData } from '.';

interface Props {
  action: PathParams;
  client: ApolloClient<NormalizedCacheObject>;
}
export const SaveForm: FC<Props> = ({ action, client }) => {
  const { id } = useParams();

  const { data, loading } = useQuery<{ wisdomEnginePrompt: FormData }>(GET_WISDOM_ENGINE_PROMPT, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: id ? stringToInt(id as string) : null,
    },
    skip: action === PathParams.CREATE || !id,
    client,
  });

  if (loading) return <></>;
  else if (data?.wisdomEnginePrompt)
    return <WisdomEnginePrompt action={action} values={data.wisdomEnginePrompt} client={client} />;
  else if (action === PathParams.CREATE) return <WisdomEnginePrompt action={action} values={FORM} client={client} />;
  else return <NotFound />;
};
