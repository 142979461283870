import { FC } from 'react';

import { PathParams } from 'global/route';
import { getWebClient } from 'graphql/apollo-client';
import { apiErrorHandler } from 'graphql/error-handler';
import { useSnackbar } from 'hooks/useSnackbar';

import { SaveForm } from './forms/Save';

export const Create: FC = () => {
  const { showErrorToast } = useSnackbar();
  const client = getWebClient({
    errorHandler: errorResponse =>
      apiErrorHandler({
        notify: showErrorToast,
        error: errorResponse,
      }),
  });
  return <SaveForm action={PathParams.CREATE} client={client} />;
};
