import { MediaTypeInputs, PostPageFormData, PostPageKey } from 'components/post/forms';
import { validateUrl } from 'plugins/validator';

export const validatePostPagesMediaLinks = (postPages: PostPageFormData[]): string | true => {
  const hasInvalidMediaLink = postPages.some(({ mediaLink, mediaType }) => {
    const hasMediaLink = MediaTypeInputs[mediaType].includes(PostPageKey.MEDIA_LINK) && Boolean(mediaLink);

    if (typeof mediaLink === 'string' && hasMediaLink) {
      return Boolean(mediaLink.trim()) && !validateUrl(mediaLink, '');
    }

    return false;
  });

  return hasInvalidMediaLink ? 'Invalid media link' : true;
};
