import { CSSProperties, FC, MouseEvent, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export interface MenuItemAction {
  action: () => void;
  text: string;
}

interface Props {
  actions: MenuItemAction[];
  style?: CSSProperties;
}

export const MenuItemGenerator: FC<Props> = ({ actions, style }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const clickHandler = (e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);
  const closeHandler = () => setAnchorEl(null);

  if (!actions.length) return null;

  return (
    <div style={style} className="menu-item-generator">
      <Button variant="contained" onClick={clickHandler}>
        <MoreVertIcon />
      </Button>
      <Menu className="menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeHandler}>
        {actions.map((item, index) => (
          <MenuItem key={index} onClick={item.action}>
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
