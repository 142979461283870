import { FC } from 'react';

import { Card, CardContent, Grid, InputLabel, MenuItem } from '@mui/material';

import { ActionButtons, Title } from 'components/inputs';
import { Select } from 'components/mui-custom';
import { AssetHandler } from 'components/post/inputs/AssetHandler';
import { ReactDraft } from 'components/react-draft';
import { setLabel } from 'global/form';
import { PathParams } from 'global/route';

import { Slide as SlideForm, SlideKey, Slide as SlideProps, SlideType } from '../forms';
import { useSlideForm } from '../hooks/useSlideForm';
import { MediaTypeValue } from 'components/asset';

interface Props {
  slide: SlideProps;
  action: PathParams;
  onUpdate: (slide: SlideForm) => void;
  onDelete: (position: number) => void;
  onChangePosition: (oldPosition: number, newPosition: number) => void;
  maxPosition: number;
}

export const Slide: FC<Props> = ({ slide, action, maxPosition, onUpdate, onDelete, onChangePosition }) => {
  const showActions = action !== PathParams.SHOW;

  const {
    trigger,
    watch,
    assetSubmitHandler,
    assetDeleteHandler,
    assetChangeHandler,
    errors,
    isEditable,
    assetLoading,
    setValue,
    assetWatch,
    formActions,
    editorState,
    onEditorStateChange,
  } = useSlideForm({
    maxPosition,
    providedFormValues: slide,
    action,
    submitHandler: onUpdate,
    deleteHandler: onDelete,
    changePositionHandler: onChangePosition,
  });

  const { title, type, position } = watch();
  const asset = assetWatch();

  return (
    <Card sx={{ mb: 5 }}>
      <CardContent>
        <Grid gap={5} container>
          <Grid item xs={3}>
            <AssetHandler
              onSubmit={assetSubmitHandler}
              onDelete={assetDeleteHandler}
              onChange={assetChangeHandler}
              asset={asset}
              assetType={type as unknown as MediaTypeValue}
              imagePreviewProps={{ label: 'Asset' }}
              disabled={!isEditable || assetLoading}
              id={`id-${slide.virtualKey}`}
            />
          </Grid>
          <Grid item xs={7}>
            <Title
              sx={{ mb: 1 }}
              margin="normal"
              value={title}
              label={setLabel('Title', action)}
              onChange={e => {
                setValue(SlideKey.TITLE, e.target.value);
              }}
              onBlur={() => {
                trigger(SlideKey.TITLE);
              }}
              helperText={errors?.title?.message}
              error={Boolean(errors.title)}
              disabled={!isEditable}
              inputProps={{ maxLength: 120 }}
            />
            <Select
              sx={{ mb: 1 }}
              margin="dense"
              label={setLabel('Type', action)}
              value={type}
              onChange={e => setValue(SlideKey.TYPE, e.target.value as SlideType)}
              disabled={!isEditable}
              error={Boolean(errors.type)}
            >
              {Object.values(SlideType).map((item, key) => (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            {type === SlideType.IMAGE && (
              <ReactDraft
                disabled={!isEditable}
                label="Text"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbarCustomButtons={[]}
              />
            )}
          </Grid>
          <Grid item xs={1}>
            <InputLabel style={{ fontSize: '0.8rem', marginTop: 10 }}>Position</InputLabel>
            {position}
          </Grid>
        </Grid>
      </CardContent>
      {showActions && <ActionButtons actions={formActions} />}
    </Card>
  );
};
