import { FC } from 'react';

import { Card } from '@mui/material';
import { GridEventListener } from '@mui/x-data-grid';
import { RoutePath } from 'app/routes';

import { Table } from 'components/tables/grid-table';
import { getShowPagePath } from 'hooks/useTable';

import { useAssetTable } from '../useAssetTable';

export const AssetTable: FC = () => {
  const {
    columns,
    content,
    setSelected,
    selectAll,
    unselectAll,
    sortContent,
    selectedCount,
    rowsPerPage,
    rowCount,
    loading,
    paginationModelChangeHandler,
    page,
    selected,
    bulkActions,
    filterAction,
    cellClickHandler,
    sortModel,
    selectAllChecked,
    onSelectAllChange,
    selectPageHandler,
    selectAllIndeterminate,
  } = useAssetTable();

  return (
    <Card>
      <Table
        title="Asset"
        onCellClick={cellClickHandler as unknown as GridEventListener<'cellClick'>}
        rows={content}
        columns={columns}
        onGetLink={({ row }) => getShowPagePath(RoutePath.ASSETS, String(row.id))}
        onRowSelectionModelChange={setSelected}
        onSelectAll={selectAll}
        onUnselectAll={unselectAll}
        onSortModelChange={sortContent}
        rowCount={rowCount}
        loading={loading}
        selected={selected}
        paginationModel={{ page, pageSize: rowsPerPage }}
        onPaginationModelChange={paginationModelChangeHandler}
        selectedCount={selectedCount}
        keepNonExistentRowsSelected
        filter={filterAction}
        bulk={{
          actions: bulkActions,
        }}
        sortModel={sortModel}
        selectAllChecked={selectAllChecked}
        onSelectAllChange={onSelectAllChange}
        selectPageHandler={selectPageHandler}
        selectAllIndeterminate={selectAllIndeterminate}
        sortingMode="server"
      />
    </Card>
  );
};
