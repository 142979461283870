import { FC } from 'react';

import { StandardTextFieldProps } from '@material-ui/core';
import { Grid } from '@mui/material';

import { ClassName, TextField } from 'components/mui-custom';
import { DEFAULT_GRID_ITEM_PROPS, setLabel } from 'global/form';
import { PathParams } from 'global/route';

import { Key as ExperimentKey } from '../forms';
import { UseExperimentForm } from '../hooks/useExperimentForm';

interface Props extends StandardTextFieldProps {
  hook: UseExperimentForm;
  action: PathParams;
}

export const ExperimentCompletionCode: FC<Props> = ({
  hook: { watch, setCompletionCode, trigger, errors, readOnly },
  action,
}) => {
  const { completionCode } = watch();

  return (
    <Grid {...DEFAULT_GRID_ITEM_PROPS} className={errors.type?.message ? ClassName.ERROR : ''}>
      <TextField
        value={completionCode}
        label={setLabel('Completion Code', action)}
        onChange={setCompletionCode}
        onBlur={() => trigger(ExperimentKey.COMPLETION_CODE)}
        helperText={errors?.completionCode?.message}
        error={Boolean(errors.completionCode)}
        disabled={readOnly}
      />
    </Grid>
  );
};
