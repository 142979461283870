import { forwardRef } from 'react';

import { Alert } from '@mui/material';
import { CustomContentProps } from 'notistack';

import { VariantType } from 'hooks/useSnackbar';

interface CustomToastProps extends CustomContentProps {
  severity: Exclude<VariantType, 'default'>;
  alertProps: object;
}

export const CustomToast = forwardRef<HTMLDivElement, CustomToastProps>((props, ref) => {
  const { message, severity, alertProps } = props;

  return (
    <Alert ref={ref} {...alertProps} severity={severity}>
      {message}
    </Alert>
  );
});
CustomToast.displayName = 'CustomToast';
