import { FC } from 'react';

import { Divider, List, Toolbar } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import { MainNavigation } from './AppNav';

const drawerWidth: number = 240;
const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface Props {
  state: boolean;
}

export const AppDrawer: FC<Props> = props => (
  <Drawer variant="permanent" open={props.state}>
    <Toolbar
      variant="dense"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
      }}
    />
    <Divider />
    <List sx={{ paddingTop: '0px' }} component="nav">
      <MainNavigation />
    </List>
  </Drawer>
);
