import { FC, FormEvent } from 'react';

import { Grid, TextFieldProps } from '@mui/material';

import { TextField } from 'components/mui-custom';
import { GRAPHQL_MAX_INT } from 'graphql';

export const EstimatedTime: FC<TextFieldProps> = ({ ...props }) => {
  const onInputHandler = (e: FormEvent<HTMLInputElement>) => {
    const MAX_MINUTES_NUM = GRAPHQL_MAX_INT / 1000 / 60;

    const inputValue = +(e.target as HTMLInputElement).value;
    if (inputValue > MAX_MINUTES_NUM) {
      (e.target as HTMLInputElement).value = String(Math.trunc(MAX_MINUTES_NUM));
    }
  };

  return (
    <Grid sx={{ mt: 1 }} container>
      <Grid item xs={false} sm={6} md={6}>
        <TextField
          placeholder="min"
          label="Estimation time (Required)"
          onInput={onInputHandler}
          inputProps={{ min: 0, inputMode: 'numeric' }}
          {...props}
        />
      </Grid>
    </Grid>
  );
};
