import { useEffect, useMemo } from 'react';

import { ASSET_FORM, AssetFormData, AssetKey } from 'components/asset';
import { PathParams } from 'global/route';
import { Tag } from 'global/type';
import { CHANGE_REVIEWS_PUBLISH_STATUSES, CREATE_REVIEW, DESTROY_REVIEW, UPDATE_REVIEW } from 'graphql/mutations';
import { GET_REVIEWS } from 'graphql/queries';
import { useAssetForm } from 'hooks/useAssetForm';
import { QUESTION_PLACEHOLDER } from 'pages/question/forms';
import { validateTextField } from 'plugins/validator';

import { REVIEW_FORM, ReviewFormData, ReviewKey, ReviewQuestion } from '../forms';
import { useItemForm } from 'hooks/useItemForm';

interface Props {
  action: PathParams;
  providedFormValues: ReviewFormData;
}

export const useReviewForm = ({ providedFormValues, action }: Props) => {
  const parseData = (values: ReviewFormData) => {
    values.durationMs = Number(values.durationMs) * 60 * 1000;
    values.questions = values.questions.map(question => question.itemId);
    values.tags = values.tags.map(tag => tag.id);

    const { updatedAt, createdAt, uid, packages, thumbnail, __typename, ...formValues } = values;
    return formValues;
  };

  const onWillSubmit = async () => {
    if (await isThumbnailValid()) {
      const thumbnail = await saveThumbnail();

      if (thumbnail?.id) {
        setValue(ReviewKey.THUMBNAIL_ID, thumbnail.id);
      }
    }
  };

  const mapFormValue = (key: string, value: any) => {
    switch (key) {
      case ReviewKey.SUGGESTED_LENGTH:
        return value / 60 / 1000;
      case ReviewKey.QUESTIONS:
        if (!value.length) {
          value.push(QUESTION_PLACEHOLDER);
        }
        return value.map(question => ({ ...question, itemId: question.id }));
      default:
        return value;
    }
  };

  const { formActions, formErrors, formLoading, register, setFormValues, setValue, trigger, watch } =
    useItemForm<ReviewFormData>({
      action,
      defaultValues: REVIEW_FORM,
      itemDisplayName: 'Review',
      createMutation: CREATE_REVIEW,
      updateMutation: UPDATE_REVIEW,
      destroyMutation: DESTROY_REVIEW,
      changePublishStatusMutation: CHANGE_REVIEWS_PUBLISH_STATUSES,
      getItemsQuery: GET_REVIEWS,
      parseData,
      onWillSubmit,
      mapFormValue,
    });

  const { thumbnail } = watch();

  const {
    watch: watchThumbnail,
    saveHandler: saveThumbnail,
    setValue: setThumbnail,
    trigger: isThumbnailValid,
    deleteAssetLocally: deleteThumbnailLocally,
    formLoading: thumbnailLoading,
  } = useAssetForm({
    action: PathParams.CREATE,
    providedFormValues: thumbnail ?? ASSET_FORM,
    notifyAfterSubmit: false,
    goBackAfterSubmit: false,
  });

  const disabled = action === PathParams.SHOW;

  const formAndThumbnailLoading = useMemo<boolean>(
    () => formLoading || thumbnailLoading,
    [formLoading, thumbnailLoading],
  );

  const setTags = (tags: Tag[]) => {
    setValue(ReviewKey.TAGS, tags);
  };

  const setQuestions = (questions: ReviewQuestion[]) => {
    setValue(
      ReviewKey.QUESTIONS,
      questions.toSorted((a, b) => Number(a.position) - Number(b.position)),
    );
  };

  const thumbnailSubmitHandler = (asset: AssetFormData) => {
    setValue(ReviewKey.THUMBNAIL_ID, asset.id as number);
    setThumbnail(AssetKey.URL, asset.url);
  };

  const thumbnailDeleteHandler = () => {
    setValue(ReviewKey.THUMBNAIL_ID, null);
    setValue(ReviewKey.THUMBNAIL, null);
    setThumbnail(AssetKey.URL, null);
    deleteThumbnailLocally();
  };

  const thumbnailChangeHandler = (asset: AssetFormData) => {
    setValue(ReviewKey.THUMBNAIL, asset);
    setValue(ReviewKey.THUMBNAIL_ID, asset.id ?? null);
  };

  useEffect(() => {
    register(ReviewKey.TITLE, {
      validate: value => validateTextField(value, 'Title'),
    });
    register(ReviewKey.SUGGESTED_LENGTH, {
      validate: value => (value ? true : 'Estimated time required'),
    });
    register(ReviewKey.OPTIONAL_FOR_USER, {
      validate: value => (value !== null ? true : 'Optional for user field required'),
    });
    register(ReviewKey.QUESTIONS, {
      validate: value => {
        if (!value.length) {
          return 'At least one question required';
        }
        if (value.map(question => question.itemId).some(id => !id || id < 0)) {
          return 'Question can not be empty';
        }
      },
    });
  }, [register]);

  // set the form values passed from parent
  useEffect(() => {
    if (providedFormValues) {
      setFormValues(providedFormValues);
    }
  }, []);

  return {
    errors: formErrors,
    watch,
    setValue,
    trigger,
    setTags,
    setQuestions,
    formActions,
    disabled,
    watchThumbnail,
    thumbnailSubmitHandler,
    thumbnailDeleteHandler,
    thumbnailChangeHandler,
    formLoading: formAndThumbnailLoading,
  };
};
