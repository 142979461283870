import { FC, useMemo, useState } from 'react';

import { StandardTextFieldProps } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import { Autocomplete, Box, Grid, IconButton, TextField } from '@mui/material';

import { DEFAULT_GRID_ITEM_PROPS } from 'global/form';
import { capitalize } from 'global/formater';
import { PathParams } from 'global/route';
import { LabUser } from 'pages/lab-users/forms';
import { validateEmail } from 'plugins/validator';

import { EXPERIMENT_TYPE_TO_USER_TYPE_MAPPING, Key as ExperimentKey, ExperimentType, UserType } from '../forms';
import { UseExperimentForm } from '../hooks/useExperimentForm';

interface Props extends StandardTextFieldProps {
  hook: UseExperimentForm;
  action: PathParams;
}

export const ExperimentUsers: FC<Props> = ({
  hook: { watch, setUsers, readOnly, labUserList, createLabUserHandler, disableInputs },
  action,
}) => {
  const USERS_LABEL = capitalize(ExperimentKey.USERS);

  const { users, id, type } = watch();
  const [newUserEmail, setUserEmail] = useState('');
  const existedExperimentEmails = useMemo(() => (users as LabUser[])?.map(user => user.email), [users]);

  const isEmailUnique = useMemo(() => {
    if (!validateEmail(newUserEmail, '')) return false;
    if (existedExperimentEmails.includes(newUserEmail)) return false;

    return true;
  }, [newUserEmail, users]);

  const TOTAL_IDENTIFIABLE_USERS_LABEL = ['Total Identifiable', USERS_LABEL].join(' ');
  const TOTAL_ANONYMOUS_USERS_LABEL = ['Total Anonymous', USERS_LABEL].join(' ');
  const TOTAL_USERS_LABEL = ['Total', USERS_LABEL].join(' ');

  const { identifiableUsersCount, anonymousUsersCount, emailList } = useMemo(() => {
    let anonymousUsersCount = 0;
    const emails: string[] = [];

    users?.forEach(user => {
      if (user.email) {
        emails.push(user.email);
      } else if (user?.externalUserId) {
        anonymousUsersCount = anonymousUsersCount + 1;
      }
    });

    return { identifiableUsersCount: emails.length, anonymousUsersCount, emailList: emails.join(', ') || '---' };
  }, [users]);

  const userTypeName: UserType = type && EXPERIMENT_TYPE_TO_USER_TYPE_MAPPING[type];

  return (
    <>
      <Grid {...DEFAULT_GRID_ITEM_PROPS} md={12}>
        {action !== PathParams.SHOW && type !== ExperimentType.ONE_LINK_ANON_USER ? (
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Autocomplete
              value={(users as LabUser[]) || []}
              options={labUserList || []}
              onInputChange={(_, value) => setUserEmail(value)}
              onChange={setUsers}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.email}
              renderInput={params => <TextField {...params} label={USERS_LABEL} variant="standard" />}
              disabled={readOnly || disableInputs(ExperimentKey.USERS, type)}
              fullWidth
              multiple
            />
            {isEmailUnique && userTypeName && (
              <IconButton
                onMouseDown={() =>
                  createLabUserHandler(
                    {
                      email: newUserEmail,
                      ...(id && { experiments: [parseInt(id, 10)] }),
                    },
                    userTypeName,
                  )
                }
                size="small"
                edge="end"
              >
                <DoneIcon />
              </IconButton>
            )}
          </Box>
        ) : (
          <>
            <div className="--label">{USERS_LABEL}</div>
            <span className="--readonly-value">{emailList}</span>

            <div className="--label">{TOTAL_IDENTIFIABLE_USERS_LABEL}</div>
            <span className="--readonly-value">{identifiableUsersCount}</span>

            <div className="--label">{TOTAL_ANONYMOUS_USERS_LABEL}</div>
            <span className="--readonly-value">{anonymousUsersCount}</span>

            <div className="--label">{TOTAL_USERS_LABEL}</div>
            <span className="--readonly-value">{identifiableUsersCount + anonymousUsersCount}</span>
          </>
        )}
      </Grid>
    </>
  );
};
