export interface Modal {
  [Key.STATE]: boolean;
  [Key.TITLE]: string;
  [Key.TEXT]: string;
  [Key.CONFIRM]?: Action;
  [Key.CANCEL]?: Action;
}

export enum Key {
  STATE = 'state',
  TITLE = 'title',
  TEXT = 'text',
  CANCEL = 'cancel',
  CONFIRM = 'confirm',
}

interface Action {
  text?: string;
  action?: () => Promise<void> | void;
  loading?: boolean;
  buttonText?: string;
}

export const MODAL: Modal = {
  [Key.STATE]: false,
  [Key.TITLE]: '',
  [Key.TEXT]: '',
};
