import { ChangeEvent, CSSProperties } from 'react';

import { SelectChangeEvent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { RoutePath } from 'app/routes';
import { format, parseISO } from 'date-fns';

import { renderLinkCell } from 'components/tables/view-table/renderLinkCell';
import { DATE_FORMAT, NO_DATE } from 'constants/date-time';

export const isPrimitive = (value: any): boolean => value !== Object(value);

export interface Tag {
  id: number;
  name: string;
}

export interface PackageRow {
  id: string;
  title: string;
  published: string;
}

enum PackageColumnKey {
  ID = 'id',
  TITLE = 'title',
  PUBLISHED_AT = 'publishedAt',
  UID = 'uid',
}

export const PACKAGE_COLUMNS: GridColDef[] = [
  {
    field: PackageColumnKey.UID,
    headerName: 'ID',
    flex: 1,
    renderCell: params => renderLinkCell(RoutePath.PACKAGES, params),
  },
  { field: PackageColumnKey.TITLE, headerName: 'Title', flex: 1 },
  {
    field: PackageColumnKey.PUBLISHED_AT,
    headerName: 'Published',
    flex: 1,
    valueGetter: ({ row }) => (row.publishedAt ? format(parseISO(row.publishedAt), DATE_FORMAT) : NO_DATE),
  },
];

export type CombinedChangeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>;

export interface Margins {
  marginTop?: CSSProperties['marginTop'];
  marginRight?: CSSProperties['marginRight'];
  marginBottom?: CSSProperties['marginBottom'];
  marginLeft?: CSSProperties['marginLeft'];
}

export interface OrderedActivityItem {
  itemId: number;
  uid: string;
  position: number;
}
