import { useDispatch } from 'react-redux';

import { DocumentNode, useMutation } from '@apollo/client';

import { getBulkActions } from 'components/tables/grid-table/bulkActions';
import { BULK_DESTROY_CAROUSELS, CHANGE_CAROUSELS_PUBLISH_STATUSES } from 'graphql/mutations';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTable } from 'hooks/useTable';

import { CarouselFormData, CarouselKey } from '../forms';

const DATA_KEY = 'carousels';
const COUNTER_KEY = 'carouselsTotalCount';

interface Props {
  query: DocumentNode;
  where?: object;
}

interface Data {
  carousels: CarouselFormData[];
  carouselsTotalCount: number;
}

interface Search {
  searchString: string;
}

export const useCarouselTable = ({ query, where }: Props) => {
  const { showSuccessToast } = useSnackbar();
  const { updatePage, bulkObject, page, getFilter, ...args } = useTable<Data['carousels']>({
    dataKey: DATA_KEY,
    counterKey: COUNTER_KEY,
    query: {
      query,
      where,
    },
  });

  const bulkDelete = useMutation(BULK_DESTROY_CAROUSELS);
  const bulkChangePublishState = useMutation(CHANGE_CAROUSELS_PUBLISH_STATUSES);

  const bulkActions = getBulkActions({
    bulkObject,
    updatePage,
    enqueueSnackbar: showSuccessToast,
    bulkDelete,
    bulkChangePublishState,
    entityName: 'carousel',
    dispatch: useDispatch(),
  });

  const filterAction = getFilter({ options: [CarouselKey.ID, CarouselKey.NAME] });

  return {
    ...args,
    page,
    bulkActions,
    filterAction,
  };
};
