import { FC } from 'react';

import { StandardTextFieldProps } from '@material-ui/core';
import { Grid } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';

import { ClassName } from 'components/mui-custom';
import { DEFAULT_GRID_ITEM_PROPS } from 'global/form';
import { PathParams } from 'global/route';

import { UseExperimentForm } from '../hooks/useExperimentForm';

interface Props extends StandardTextFieldProps {
  hook: UseExperimentForm;
  action: PathParams;
}

export const ExperimentEndDate: FC<Props> = ({ hook: { watch, errors }, action }) => {
  const { endDate } = watch();

  return (
    <>
      {endDate || action !== PathParams.SHOW ? (
        <Grid {...DEFAULT_GRID_ITEM_PROPS}>
          <DateTimePicker
            className={action === PathParams.SHOW ? ClassName.MUI_CUSTOM : ''}
            label="End Date"
            value={endDate ? new Date(endDate) : null}
            onChange={() => false}
            readOnly
            disabled
            slotProps={{ textField: { error: Boolean(errors.startDate) } }}
          />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
