import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { stringToInt } from 'global/formater';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_DAILY_PRACTICE } from 'graphql/queries';

import { DailyPracticeFormData } from '../forms';

export const useGetDailyPractice = () => {
  const { id } = useParams();
  const { data, loading } = useQuery<{ dailyPractice: DailyPracticeFormData }>(GET_DAILY_PRACTICE, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: stringToInt(id as string),
    },
  });

  return {
    data,
    loading,
  };
};
