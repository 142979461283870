import { GridColDef } from '@mui/x-data-grid';

import { PackageRow } from 'global/type';
import { formatDate } from 'global/formater';

export enum DailyPracticeKey {
  ID = 'id',
  UID = 'uid',
  ACTION = 'action',
  PACKAGES = 'packages',
  PUBLISHED_AT = 'publishedAt',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  TYPE_NAME = '__typename',
}

export const DAILY_PRACTICE_SECTION_COLUMNS: GridColDef[] = [
  { field: DailyPracticeKey.UID, headerName: 'ID', flex: 1 },
  { field: DailyPracticeKey.ACTION, headerName: 'Action', flex: 1 },
  {
    field: DailyPracticeKey.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
  {
    field: DailyPracticeKey.UPDATED_AT,
    headerName: 'Updated Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.updatedAt),
  },
];

export interface DailyPracticeFormData {
  [DailyPracticeKey.ID]?: string;
  [DailyPracticeKey.ACTION]: string;
  [DailyPracticeKey.PACKAGES]?: PackageRow[];
  [DailyPracticeKey.CREATED_AT]?: string;
  [DailyPracticeKey.UPDATED_AT]?: '';
  [DailyPracticeKey.PUBLISHED_AT]?: string | Date | null;
  [DailyPracticeKey.UID]?: string;
  [DailyPracticeKey.TYPE_NAME]?: string;
}

export const DAILY_PRACTICE_FORM: DailyPracticeFormData = {
  [DailyPracticeKey.ID]: '',
  [DailyPracticeKey.ACTION]: '',
  [DailyPracticeKey.PACKAGES]: [],
  [DailyPracticeKey.CREATED_AT]: '',
  [DailyPracticeKey.UPDATED_AT]: '',
  [DailyPracticeKey.PUBLISHED_AT]: '',
  [DailyPracticeKey.UID]: '',
};
