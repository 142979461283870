import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { stringToInt } from 'global/formater';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_LAB_USER } from 'graphql/queries';

import { LabUser } from '../forms';

export const useGetLabUser = () => {
  const { id } = useParams();
  const { data, loading } = useQuery<{ user: LabUser }>(GET_LAB_USER, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: stringToInt(id as string),
    },
  });

  return {
    data,
    loading,
  };
};
