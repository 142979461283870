import { useEffect, useMemo, useState } from 'react';

import { ASSET_FORM, AssetFormData, AssetKey } from 'components/asset';
import { PathParams } from 'global/route';
import { CHANGE_PACKAGES_PUBLISH_STATUSES, CREATE_PACKAGE, DESTROY_PACKAGE, UPDATE_PACKAGE } from 'graphql/mutations';
import { GET_PACKAGES } from 'graphql/queries';
import { useAssetForm } from 'hooks/useAssetForm';
import { validateTextField } from 'plugins/validator';
import { ContentItem, PACKAGE_FORM, PackageFormData, PackageItem, PackageKey } from '../forms';
import { Tag } from 'global/type';
import { useItemForm } from 'hooks/useItemForm';

interface Props {
  action: PathParams;
  providedFormValues: PackageFormData;
}

export const usePackageForm = ({ providedFormValues, action }: Props) => {
  const parseData = (values: PackageFormData) => {
    values.packageItems = values.packageItems.map((item, index) => ({
      itemId: item.itemId,
      itemType: item.itemType,
      position: index + 1,
      uid: item.uid,
    }));
    values.tags = values.tags.map(tag => tag.id);
    const { updatedAt, createdAt, uid, thumbnail, __typename, ...formValues } = values;
    return formValues;
  };

  const onWillSubmit = async () => {
    if (await isThumbnailValid()) {
      const thumbnail = await saveThumbnail();

      if (thumbnail?.id) {
        setValue(PackageKey.THUMBNAIL_ID, thumbnail.id);
      }
    }
  };

  const mapContent = (responseList: any): void => {
    if (!disabled) {
      const mappedContent = [
        ...responseList.posts.reduce(
          (acc, item) => (item.publishedAt ? [...acc, { title: item.name, ...item }] : acc),
          [],
        ),
        ...responseList.dailyPractices.reduce(
          (acc, item) => (item.publishedAt ? [...acc, { title: item.action, ...item }] : acc),
          [],
        ),
        ...responseList.moments.reduce(
          (acc, item) => (item.publishedAt ? [...acc, { title: item.prompts[0]?.content, ...item }] : acc),
          [],
        ),
        ...responseList.reviews.filter(item => item.publishedAt),
      ];
      setMappedContent(mappedContent);
    }
  };

  const mapFormValue = (key: string, value: any) => {
    switch (key) {
      case PackageKey.CONTENT_LIST:
        return mapContent(value);
      case PackageKey.PACKAGE_ITEMS:
        setPackageItems(value);
        return value;
      default:
        return value;
    }
  };

  const { formActions, formErrors, formLoading, register, setFormValues, setValue, trigger, watch } =
    useItemForm<PackageFormData>({
      action,
      defaultValues: PACKAGE_FORM,
      itemDisplayName: 'Package',
      createMutation: CREATE_PACKAGE,
      updateMutation: UPDATE_PACKAGE,
      destroyMutation: DESTROY_PACKAGE,
      changePublishStatusMutation: CHANGE_PACKAGES_PUBLISH_STATUSES,
      getItemsQuery: GET_PACKAGES,
      parseData,
      onWillSubmit,
      mapFormValue,
    });

  const { thumbnail } = watch();

  const {
    watch: watchThumbnail,
    saveHandler: saveThumbnail,
    setValue: setThumbnail,
    trigger: isThumbnailValid,
    deleteAssetLocally: deleteThumbnailLocally,
    formLoading: thumbnailLoading,
  } = useAssetForm({
    action: PathParams.CREATE,
    providedFormValues: thumbnail ?? ASSET_FORM,
    goBackAfterSubmit: false,
    notifyAfterSubmit: false,
  });

  const disabled = action === PathParams.SHOW;

  const formAndThumbnailLoading = useMemo<boolean>(
    () => formLoading || thumbnailLoading,
    [formLoading, thumbnailLoading],
  );
  const [mappedContent, setMappedContent] = useState<ContentItem[]>();

  const setTags = (tags: Tag[]) => {
    setValue(PackageKey.TAGS, tags);
  };

  const setPackageItems = (packageItems: PackageItem[]) => {
    setValue(
      PackageKey.PACKAGE_ITEMS,
      packageItems.toSorted((a, b) => a.position - b.position),
    );
  };

  const filterOptions = (options: ContentItem[], uid: string, packageItems: PackageItem[]): ContentItem[] => {
    const uidsToExclude = packageItems.reduce((uids: string[], item: PackageItem) => {
      if (item.uid !== uid) {
        uids.push(item.uid);
      }
      return uids;
    }, []);
    return options.filter(item => !uidsToExclude.includes(item.uid));
  };

  const setSelected = (
    uid: string | undefined,
    itemId: number | undefined,
    updatedPackageItem: PackageItem,
    packageItems: PackageItem[],
  ) => {
    if (uid && itemId) {
      const itemToUpdateIndex = packageItems.findIndex(item => item.uid === updatedPackageItem.uid);
      const updatedPackageItems = [...packageItems];
      updatedPackageItems[itemToUpdateIndex] = {
        itemId,
        itemType: updatedPackageItem.itemType,
        position: updatedPackageItem.position,
        uid,
      };
      setPackageItems(updatedPackageItems);
    }
  };

  const addItem = (packageItems: PackageItem[]) => {
    setPackageItems([...packageItems, { itemId: -1, uid: '', position: packageItems.length + 1 }]);
  };

  const removeItem = (uid: string, packageItems: any[]) => {
    let index = 0;
    const items = packageItems.reduce((acc: any[], item) => {
      if (item.uid !== uid) {
        const result = [...acc, { ...item, position: index + 1 }];
        index++;
        return result;
      }
      return acc;
    }, []);
    setPackageItems(items);
  };

  const thumbnailSubmitHandler = (asset: AssetFormData) => {
    setValue(PackageKey.THUMBNAIL_ID, asset.id as number);
    setThumbnail(AssetKey.URL, asset.url);
  };

  const thumbnailDeleteHandler = () => {
    setValue(PackageKey.THUMBNAIL_ID, null);
    setValue(PackageKey.THUMBNAIL, null);
    setThumbnail(AssetKey.URL, null);
    deleteThumbnailLocally();
  };

  const thumbnailChangeHandler = (asset: AssetFormData) => {
    setValue(PackageKey.THUMBNAIL, asset);
    setValue(PackageKey.THUMBNAIL_ID, asset.id ?? null);
  };

  useEffect(() => {
    register(PackageKey.TITLE, {
      validate: value => validateTextField(value, 'Title'),
    });
    register(PackageKey.DESCRIPTION, {
      validate: value => validateTextField(value, 'Description'),
    });
    register(PackageKey.PACKAGE_ITEMS, {
      validate: value => {
        if (value.map(item => item.itemId).some(id => id === -1)) {
          return 'Package item can not be empty';
        }
      },
    });
  }, [register]);

  // set the form values passed from parent
  useEffect(() => {
    if (providedFormValues) {
      setFormValues(providedFormValues);
    }
  }, []);

  return {
    errors: formErrors,
    watch,
    setValue,
    trigger,
    formActions,
    filterOptions,
    setSelected,
    addItem,
    removeItem,
    setPackageItems,
    mappedContent,
    disabled,
    watchThumbnail,
    thumbnailSubmitHandler,
    thumbnailDeleteHandler,
    thumbnailChangeHandler,
    formLoading: formAndThumbnailLoading,
    setTags,
  };
};
