import { useEffect, useMemo } from 'react';

import { ASSET_FORM, AssetFormData, AssetKey } from 'components/asset';
import { PathParams } from 'global/route';
import { Tag } from 'global/type';
import { CHANGE_MOMENTS_PUBLISH_STATUSES, CREATE_MOMENT, DESTROY_MOMENT, UPDATE_MOMENT } from 'graphql/mutations';
import { GET_MOMENTS } from 'graphql/queries';
import { useAssetForm } from 'hooks/useAssetForm';

import { MOMENT_FORM, MomentFormData, MomentKey } from '../forms';
import { useItemForm } from 'hooks/useItemForm';

interface Props {
  action: PathParams;
  providedFormValues: MomentFormData;
}

export const useMomentForm = ({ providedFormValues, action }: Props) => {
  const parseData = (values: MomentFormData) => {
    values.durationMs = Number(values.durationMs) * 60 * 1000;
    values.prompts = values.prompts?.map(prompt => ({ id: prompt.id, content: prompt.content }));
    values.tags = values.tags.map(tag => tag.id);

    const { updatedAt, createdAt, uid, packages, thumbnail, __typename, ...formValues } = values;
    return formValues;
  };

  const onWillSubmit = async () => {
    if (await isThumbnailValid()) {
      const thumbnail = await saveThumbnail();
      if (thumbnail?.id) {
        setValue(MomentKey.THUMBNAIL_ID, thumbnail.id);
      }
    }
  };

  const mapFormValue = (key: string, value: any) => {
    switch (key) {
      case MomentKey.SUGGESTED_LENGTH:
        return value / 60 / 1000;
      default:
        return value;
    }
  };

  const { formActions, formErrors, formLoading, register, setFormValues, setValue, trigger, watch } =
    useItemForm<MomentFormData>({
      action,
      defaultValues: MOMENT_FORM,
      itemDisplayName: 'Moment',
      createMutation: CREATE_MOMENT,
      updateMutation: UPDATE_MOMENT,
      destroyMutation: DESTROY_MOMENT,
      changePublishStatusMutation: CHANGE_MOMENTS_PUBLISH_STATUSES,
      getItemsQuery: GET_MOMENTS,
      parseData,
      onWillSubmit,
      mapFormValue,
    });

  const { thumbnail } = watch();

  const {
    watch: watchThumbnail,
    saveHandler: saveThumbnail,
    setValue: setThumbnail,
    trigger: isThumbnailValid,
    deleteAssetLocally: deleteThumbnailLocally,
    formLoading: thumbnailLoading,
  } = useAssetForm({
    action: PathParams.CREATE,
    providedFormValues: thumbnail ?? ASSET_FORM,
    goBackAfterSubmit: false,
    notifyAfterSubmit: false,
  });

  const setTags = (tags: Tag[]) => {
    setValue(MomentKey.TAGS, tags);
  };

  const setPrompts = (currentPrompts: any) => {
    setValue(MomentKey.PROMPTS, currentPrompts);
  };

  const formAndThumbnailLoading = useMemo<boolean>(
    () => action === PathParams.SHOW || formLoading || thumbnailLoading,
    [action, formLoading, thumbnailLoading],
  );

  useEffect(() => {
    register(MomentKey.TITLE, {
      validate: value => (value ? true : 'Title should not be empty'),
    });

    register(MomentKey.SUGGESTED_LENGTH, {
      validate: value => (value ? true : 'Estimated time required'),
    });

    register(MomentKey.OPTIONAL_FOR_USER, {
      validate: value => (value !== null ? true : 'Optional for user field required'),
    });

    register(MomentKey.PROMPTS, {
      validate: value =>
        value?.length && !value.some(prompt => !prompt.content) ? true : 'Prompts should not be empty',
    });
  }, [register]);

  const thumbnailSubmitHandler = (asset: AssetFormData) => {
    setValue(MomentKey.THUMBNAIL_ID, asset.id as number);
    setThumbnail(AssetKey.URL, asset.url);
  };

  const thumbnailDeleteHandler = () => {
    setValue(MomentKey.THUMBNAIL_ID, null);
    setValue(MomentKey.THUMBNAIL, null);
    setThumbnail(AssetKey.URL, null);
    deleteThumbnailLocally();
  };

  const thumbnailChangeHandler = (asset: AssetFormData) => {
    setValue(MomentKey.THUMBNAIL, asset);
    setValue(MomentKey.THUMBNAIL_ID, asset.id as number);
  };

  // set the form values passed from parent
  useEffect(() => {
    if (providedFormValues) {
      setFormValues(providedFormValues);
    }
  }, []);

  return {
    errors: formErrors,
    watch,
    setValue,
    trigger,
    setTags,
    setPrompts,
    formActions,
    disabled: formAndThumbnailLoading,
    watchThumbnail,
    thumbnailSubmitHandler,
    thumbnailDeleteHandler,
    thumbnailChangeHandler,
  };
};
