import { GridColDef } from '@mui/x-data-grid';

import { capitalize, formatDate } from 'global/formater';
import { LabUser } from 'pages/lab-users/forms';

export enum Key {
  ID = 'id',
  UID = 'uid',
  TYPE = 'type',
  STATUS = 'status',
  TITLE = 'title',
  DURATION = 'duration',
  FREQUENCY = 'frequency',
  PACKAGE_SEQUENCE_ID = 'packageSequenceId',
  USERS = 'users',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  CREATED_BY = 'createdBy',
  PUBLISHED_AT = 'publishedAt',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  COMPLETION_CODE = 'completionCode',
  TYPE_NAME = '__typename',
  EXPERIMENT_TYPE = 'experimentType',
  EXPERIMENT_TYPE_NAME = 'name',
  REDIRECT_URL = 'redirectUrl',
  SHOW_INSIGHTS = 'showInsights',
  SHOW_AUTO_SUMMARY_INSIGHTS = 'showAutoSummaryInsights',
  SHOW_ARTWORK_INSIGHTS = 'showArtworkInsights',
  SHOW_MUSIC_INSIGHTS = 'showMusicInsights',
  SHOW_ANIMATION_INSIGHTS = 'showAnimationInsights',
  SHOW_PERSONALITY_INSIGHTS = 'showPersonalityInsights',
  SHOW_SENTIMENT_INSIGHTS = 'showSentimentInsights',
  SHOW_SPEAKING_INSIGHTS = 'showSpeakingInsights',
  SHOW_WORD_FREQUENCY_INSIGHTS = 'showWordFrequencyInsights',
  SHOW_QR_CODE = 'showQrCode',
  IS_DISPLAY_BRANDING = 'isDisplayBranding',
  SHOW_THANK_YOU_PAGE = 'showThankYouPage',
  SHOW_MOMENT_SAVED = 'showMomentSaved',
  SHOW_THANK_YOU_PAGE_NAVIGATION = 'showThankYouPageNavigation',
  IS_END_SESSION_FUNCTIONALITY_ENABLED = 'isEndSessionFunctionalityEnabled',
  SHOW_EMAIL_PAGE = 'showEmailPage',
  SHOW_ONE_VIDEO = 'showOneVideo',
}

export enum InsightKey {
  SHOW_AUTO_SUMMARY_INSIGHTS = 'showAutoSummaryInsights',
  SHOW_ARTWORK_INSIGHTS = 'showArtworkInsights',
  SHOW_MUSIC_INSIGHTS = 'showMusicInsights',
  SHOW_ANIMATION_INSIGHTS = 'showAnimationInsights',
  SHOW_PERSONALITY_INSIGHTS = 'showPersonalityInsights',
  SHOW_SENTIMENT_INSIGHTS = 'showSentimentInsights',
  SHOW_SPEAKING_INSIGHTS = 'showSpeakingInsights',
  SHOW_WORD_FREQUENCY_INSIGHTS = 'showWordFrequencyInsights',
  SHOW_QR_CODE = 'showQrCode',
  SHOW_EMAIL_PAGE = 'showEmailPage',
  SHOW_ONE_VIDEO = 'showOneVideo',
}

export const INSIGHT_LABEL_TEXT = {
  [InsightKey.SHOW_AUTO_SUMMARY_INSIGHTS]: 'Show Auto-Summary',
  [InsightKey.SHOW_ARTWORK_INSIGHTS]: 'Show Artwork Insights',
  [InsightKey.SHOW_MUSIC_INSIGHTS]: 'Show Music Insights',
  [InsightKey.SHOW_ANIMATION_INSIGHTS]: 'Show Animation Insights',
  [InsightKey.SHOW_PERSONALITY_INSIGHTS]: 'Show Personality Insights',
  [InsightKey.SHOW_SENTIMENT_INSIGHTS]: 'Show Sentiment Insights',
  [InsightKey.SHOW_SPEAKING_INSIGHTS]: 'Show Speaking Insights',
  [InsightKey.SHOW_WORD_FREQUENCY_INSIGHTS]: 'Show Word Frequency Insights',
  [InsightKey.SHOW_QR_CODE]: 'Show QR Code',
  [InsightKey.SHOW_EMAIL_PAGE]: 'Show Email Page',
  [InsightKey.SHOW_ONE_VIDEO]: 'Show Only One Video',
};

export enum VirtualKey {
  EXPERIMENT_PACKAGES = 'experimentPackages',
}

export enum ExperimentType {
  EMAIL = 'email',
  ONE_LINK_ANON_USER = 'oneLinkAnonUser',
  PROLIFIC_STUDY = 'prolificStudy',
  RAND_STUDY = 'randStudy',
}

export const EXPERIMENT_TYPE_TEXT = {
  [ExperimentType.EMAIL]: 'Email',
  [ExperimentType.PROLIFIC_STUDY]: 'Prolific Study',
  [ExperimentType.ONE_LINK_ANON_USER]: 'One Link, Anonymous User',
  [ExperimentType.RAND_STUDY]: 'RAND',
};

export interface ExperimentTypeData {
  [Key.ID]: number;
  [Key.EXPERIMENT_TYPE_NAME]: string;
}

export interface ExperimentFormData {
  [Key.ID]?: string;
  [Key.UID]?: string;
  [Key.TYPE]?: ExperimentType | '';
  [Key.STATUS]?: string;
  [Key.TITLE]: string;
  [Key.DURATION]?: number | '';
  [Key.FREQUENCY]: number;
  [Key.PACKAGE_SEQUENCE_ID]?: number | '';
  [Key.USERS]?: LabUser[] | number[];
  [Key.START_DATE]?: Date | '';
  [Key.END_DATE]?: Date | '';
  [Key.CREATED_BY]?: string;
  [Key.CREATED_AT]?: string;
  [Key.UPDATED_AT]?: string;
  [Key.PUBLISHED_AT]?: string | Date | null;
  [Key.COMPLETION_CODE]?: string | null;
  [Key.TYPE_NAME]?: string;
  [Key.EXPERIMENT_TYPE]?: string;
  [Key.REDIRECT_URL]?: string | null;
  [Key.SHOW_INSIGHTS]: boolean | null;
  [Key.SHOW_AUTO_SUMMARY_INSIGHTS]: boolean | null;
  [Key.SHOW_ARTWORK_INSIGHTS]: boolean | null;
  [Key.SHOW_MUSIC_INSIGHTS]: boolean | null;
  [Key.SHOW_ANIMATION_INSIGHTS]: boolean | null;
  [Key.SHOW_PERSONALITY_INSIGHTS]: boolean | null;
  [Key.SHOW_SENTIMENT_INSIGHTS]: boolean | null;
  [Key.SHOW_SPEAKING_INSIGHTS]: boolean | null;
  [Key.SHOW_WORD_FREQUENCY_INSIGHTS]: boolean | null;
  [Key.SHOW_QR_CODE]?: boolean | null;
  [Key.IS_DISPLAY_BRANDING]?: boolean;
  [Key.SHOW_THANK_YOU_PAGE]?: boolean | null;
  [Key.SHOW_MOMENT_SAVED]?: boolean | null;
  [Key.SHOW_THANK_YOU_PAGE_NAVIGATION]?: boolean | null;
  [Key.IS_END_SESSION_FUNCTIONALITY_ENABLED]?: boolean | null;
  [Key.SHOW_EMAIL_PAGE]?: boolean | null;
  [Key.SHOW_ONE_VIDEO]?: boolean | null;
}

export const EXPERIMENT_FORM: ExperimentFormData = {
  [Key.ID]: '',
  [Key.UID]: undefined,
  [Key.TYPE]: '',
  [Key.STATUS]: '',
  [Key.TITLE]: '',
  [Key.DURATION]: '',
  [Key.FREQUENCY]: 1,
  [Key.PACKAGE_SEQUENCE_ID]: '',
  [Key.USERS]: [],
  [Key.START_DATE]: '',
  [Key.END_DATE]: '',
  [Key.CREATED_BY]: '',
  [Key.CREATED_AT]: '',
  [Key.UPDATED_AT]: '',
  [Key.PUBLISHED_AT]: '',
  [Key.COMPLETION_CODE]: '',
  [Key.TYPE_NAME]: undefined,
  [Key.REDIRECT_URL]: null,
  [Key.SHOW_INSIGHTS]: false,
  [Key.SHOW_AUTO_SUMMARY_INSIGHTS]: false,
  [Key.SHOW_ARTWORK_INSIGHTS]: false,
  [Key.SHOW_MUSIC_INSIGHTS]: false,
  [Key.SHOW_ANIMATION_INSIGHTS]: false,
  [Key.SHOW_PERSONALITY_INSIGHTS]: false,
  [Key.SHOW_SENTIMENT_INSIGHTS]: false,
  [Key.SHOW_SPEAKING_INSIGHTS]: false,
  [Key.SHOW_WORD_FREQUENCY_INSIGHTS]: false,
  [Key.SHOW_QR_CODE]: false,
  [Key.IS_DISPLAY_BRANDING]: undefined,
  [Key.SHOW_MOMENT_SAVED]: undefined,
  [Key.SHOW_THANK_YOU_PAGE]: true,
  [Key.SHOW_THANK_YOU_PAGE_NAVIGATION]: false,
  [Key.IS_END_SESSION_FUNCTIONALITY_ENABLED]: null,
  [Key.SHOW_EMAIL_PAGE]: false,
  [Key.SHOW_ONE_VIDEO]: false,
};

export const EXPERIMENT_TABLE_COLUMNS: GridColDef[] = [
  { field: Key.UID, headerName: 'ID', flex: 1 },
  { field: Key.TITLE, headerName: 'Title', flex: 2 },
  {
    field: Key.CREATED_BY,
    headerName: 'Created By',
    flex: 2,
    valueGetter: ({ row }) => row.createdBy.email,
  },
  {
    field: Key.STATUS,
    headerName: 'Status',
    sortable: false,
    flex: 1,
    valueGetter: ({ row }) => capitalize(row.status),
  },
  {
    field: Key.EXPERIMENT_TYPE,
    headerName: 'Type',
    sortable: false,
    flex: 2,
    valueGetter: ({ row }) => EXPERIMENT_TYPE_TEXT[row.type] ?? '',
  },
  {
    field: Key.PUBLISHED_AT,
    headerName: 'Published Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.publishedAt),
  },
  {
    field: Key.CREATED_AT,
    headerName: 'Created Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.createdAt),
  },
];

/**
 * Certain experiment types require specific forms to be disabled.
 * Key: Form field to disable
 * Value: array of ExperimentType for which field should be disabled.
 */
export const EXPERIMENT_DISABLED_FIELDS = {
  [Key.START_DATE]: [ExperimentType.PROLIFIC_STUDY, ExperimentType.ONE_LINK_ANON_USER, ExperimentType.RAND_STUDY],
  [Key.FREQUENCY]: [ExperimentType.PROLIFIC_STUDY, ExperimentType.ONE_LINK_ANON_USER, ExperimentType.RAND_STUDY],
  [Key.USERS]: [ExperimentType.PROLIFIC_STUDY, ExperimentType.ONE_LINK_ANON_USER, ExperimentType.RAND_STUDY],
};

export const FIELDS_TO_VALIDATE_BY_TYPE: Record<ExperimentType, Key[]> = {
  [ExperimentType.EMAIL]: [],
  [ExperimentType.PROLIFIC_STUDY]: [Key.COMPLETION_CODE],
  [ExperimentType.ONE_LINK_ANON_USER]: [Key.REDIRECT_URL, Key.IS_END_SESSION_FUNCTIONALITY_ENABLED],
  [ExperimentType.RAND_STUDY]: [],
};

export enum UserType {
  ANONYMOUS = 'anonymous',
  PROLIFIC = 'prolific',
  RAND = 'rand',
  EMAIL = 'email',
}

export const EXPERIMENT_TYPE_TO_USER_TYPE_MAPPING = {
  [ExperimentType.EMAIL]: UserType.EMAIL,
};
