import { useDispatch } from 'react-redux';

import { DocumentNode, useMutation } from '@apollo/client';

import { getBulkActions } from 'components/tables/grid-table/bulkActions';
import { BULK_DESTROY_EXPERIMENTS, CHANGE_EXPERIMENT_PUBLISH_STATUSES } from 'graphql/mutations';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTable } from 'hooks/useTable';

import { ExperimentFormData, Key as ExperimentKey } from '../forms';
import { clearModal, setModal } from 'store/modal';
import { API_HOST } from 'constants/auth';
import config from 'config';
import { Modal } from 'store/common-modal';

const DATA_KEY = 'experiments';
const COUNTER_KEY = 'experimentsTotalCount';

interface Props {
  query: DocumentNode;
  where?: object;
}

interface Data {
  experiments: ExperimentFormData[];
  experimentsTotalCount: number;
}

export const EXPERIENCE_REPORT_ROUTE = API_HOST + '/experiments/report/';

export const useExperimentTable = ({ query, where }: Props) => {
  const { showSuccessToast, showErrorToast } = useSnackbar();
  const { updatePage, bulkObject, getFilter, page, ...args } = useTable<Data['experiments']>({
    dataKey: DATA_KEY,
    counterKey: COUNTER_KEY,
    query: {
      query,
      where,
    },
  });

  const bulkDelete = useMutation(BULK_DESTROY_EXPERIMENTS);
  const bulkChangePublishState = useMutation(CHANGE_EXPERIMENT_PUBLISH_STATUSES);

  const dispatch = useDispatch();

  const bulkActions = getBulkActions({
    bulkObject,
    updatePage,
    enqueueSnackbar: showSuccessToast,
    bulkDelete,
    bulkChangePublishState,
    entityName: 'experiment',
    dispatch,
  });

  const downloadReportAction: Modal = {
    title: 'Download CSV',
    text: 'You are going to download report of selected experiences',
    state: true,
    confirm: {
      action: async (): Promise<void> => {
        dispatch(setModal({ ...downloadReportAction, confirm: { loading: true } }));
        try {
          const { ids, isExclude, where } = bulkObject;

          const res = await fetch(EXPERIENCE_REPORT_ROUTE, {
            method: 'POST',
            credentials: 'include',
            headers: { 'x-origin-site': 'Admin', 'Content-Type': 'application/json' },
            body: JSON.stringify({ ids, isExclude, where }),
          });

          if (!res.ok) {
            if (res.status === 404) {
              throw new Error('Error: Cannot find report for selected experiences');
            }
            throw new Error(`Error: Cannot download report. Status code: ${res.status}`);
          }

          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;
          a.download = `experience-report.csv`;
          a.click();
        } catch (err: any) {
          console.log(err);
          showErrorToast(err.message);
        } finally {
          dispatch(clearModal());
        }
      },
      text: 'Download',
      buttonText: 'Download report',
    },
    cancel: {
      action: () => dispatch(clearModal()),
      text: 'Cancel',
    },
  };

  const filterAction = getFilter({ options: [ExperimentKey.ID, ExperimentKey.TITLE] });

  const downloadReportActionEnabled = config.feature.DOWNLOAD_REPORT;

  return {
    ...args,
    page,
    bulkActions: downloadReportActionEnabled ? [downloadReportAction, ...bulkActions] : bulkActions,
    filterAction,
  };
};
