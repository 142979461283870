import { useEffect, useMemo } from 'react';

import { ASSET_FORM, AssetFormData, AssetKey } from 'components/asset';
import { parseAssetsToHtmlString } from 'components/react-draft/plugins/asset/service';
import { htmlToEditorContent } from 'components/react-draft/utils';
import { PathParams } from 'global/route';
import { Tag } from 'global/type';
import { CHANGE_POST_PUBLISH_STATUSES, CREATE_POST, DESTROY_POST, UPDATE_POST } from 'graphql/mutations';
import { GET_POSTS } from 'graphql/queries';
import { useAssetForm } from 'hooks/useAssetForm';
import { useItemForm } from 'hooks/useItemForm';
import { useTextEditors } from 'hooks/useTextEditor';
import { validateTextField } from 'plugins/validator';
import {
  getEmptyPostPageForm,
  getPostPageKey,
  MediaTypeInputs,
  MediaTypeSensitiveValues,
  POST_FORM,
  PostFormData,
  PostKey,
  PostPageFormData,
  PostPageKey,
} from '../..';
import { validatePostPagesMediaLinks } from './validators';

interface Props {
  action: PathParams;
  providedFormValues: PostFormData;
  activePostPageIndex: number;
}

export const usePostForm = ({ providedFormValues, action, activePostPageIndex }: Props) => {
  const textEditors = useTextEditors();

  const parseData = (values: PostFormData) => {
    values = parseDataComparable(values);

    const allContent = textEditors.parseAllContent();
    allContent.forEach((data, index) => {
      if (values.postPages?.length) {
        values.postPages[index].contentAssets = data.assetsIds;
      }
    });

    return values;
  };

  const parseDataComparable = (values: PostFormData) => {
    values.tags = values.tags?.map(tag => tag.id);

    values.estimatedTime = parseInt(values.estimatedTime as string);

    const allContent = textEditors.parseAllContent();
    allContent.forEach((data, index) => {
      if (values.postPages?.length) {
        values.postPages[index].content = data.content;
        values.postPages[index].order = index;
      }
    });

    const { createdAt, updatedAt, uid, packages, __typename, thumbnail, ...formValues } = values;

    const postPages = formValues[PostKey.POST_PAGES]?.map(postPage => {
      const mappedPostPage = deleteUnusedFormValues(postPage);
      const { __typename, bannerImage, id, uid, createdAt, updatedAt, ...postValues } = mappedPostPage;

      return { ...postValues, id: postPage.id === '' ? undefined : postPage.id };
    });

    return { ...formValues, postPages };
  };

  const parseCreateData = ({ postPages, ...formValues }: Partial<PostFormData>) => ({
    ...formValues,
    postPages: postPages?.map(({ id, ...postPage }) => postPage),
  });

  const onWillSubmit = async () => {
    if (await isThumbnailValid()) {
      const thumbnail = await saveThumbnail();

      if (thumbnail?.id) {
        setValue(PostKey.THUMBNAIL_ID, thumbnail.id);
      }
    }

    if (await isBannerValid()) {
      const banner = await saveBanner();

      if (banner?.id) {
        setValue(getPostPageKey(PostPageKey.BANNER_IMAGE_ID, activePostPageIndex), banner.id as never);
      }
    }
  };

  const { formActions, formErrors, formLoading, register, setValue, trigger, watch } = useItemForm<PostFormData>({
    action,
    defaultValues: action === PathParams.CREATE ? POST_FORM : providedFormValues,
    itemDisplayName: 'Post',
    createMutation: CREATE_POST,
    updateMutation: UPDATE_POST,
    destroyMutation: DESTROY_POST,
    changePublishStatusMutation: CHANGE_POST_PUBLISH_STATUSES,
    getItemsQuery: GET_POSTS,
    parseData,
    parseDataComparable,
    parseCreateData,
    onWillSubmit,
  });

  const { thumbnail } = watch();

  const {
    watch: watchThumbnail,
    saveHandler: saveThumbnail,
    setValue: setThumbnail,
    trigger: isThumbnailValid,
    formLoading: thumbnailLoading,
  } = useAssetForm({
    action: PathParams.CREATE,
    providedFormValues: thumbnail ?? ASSET_FORM,
    goBackAfterSubmit: false,
    notifyAfterSubmit: false,
  });

  const {
    saveHandler: saveBanner,
    trigger: isBannerValid,
    formLoading: bannerLoading,
  } = useAssetForm({
    action: PathParams.CREATE,
    providedFormValues: ASSET_FORM,
    goBackAfterSubmit: false,
    notifyAfterSubmit: false,
  });

  const disabled = action === PathParams.SHOW;

  const formAndAssetLoading = useMemo(
    () => thumbnailLoading || bannerLoading || formLoading,
    [thumbnailLoading, bannerLoading, formLoading],
  );

  const setTags = (tags: Tag[]) => {
    setValue(PostKey.TAGS, tags);
  };

  const deleteUnusedFormValues = (postPage: PostPageFormData) => {
    const emptyPostPageForm = getEmptyPostPageForm();

    const postPageEntries = Object.entries(postPage) as [PostPageKey, PostPageFormData][];

    return Object.fromEntries(
      postPageEntries.map(postPageEntry => {
        const [key] = postPageEntry;
        const isSensitiveType = MediaTypeSensitiveValues.includes(key);
        const notInMediaInputs = !MediaTypeInputs[postPage.mediaType].includes(key);

        if (isSensitiveType && notInMediaInputs) {
          return [key, emptyPostPageForm[key]];
        }

        return postPageEntry;
      }),
    );
  };

  const thumbnailSubmitHandler = (asset: AssetFormData) => {
    setValue(PostKey.THUMBNAIL_ID, asset.id as number);
    setValue(PostKey.THUMBNAIL, asset);
    setThumbnail(AssetKey.URL, asset.url);
  };

  const getBannerSubmitHandler = (index: number) => (asset: AssetFormData) => {
    setValue(getPostPageKey(PostPageKey.BANNER_IMAGE, index), asset as never);
    setValue(getPostPageKey(PostPageKey.BANNER_IMAGE_ID, index), asset.id as never);
  };

  const thumbnailDeleteHandler = () => {
    setValue(PostKey.THUMBNAIL_ID, null);
    setValue(PostKey.THUMBNAIL, null);
    setThumbnail(AssetKey.URL, null);
  };

  const getBannerDeleteHandler = (index: number) => () => {
    setValue(getPostPageKey(PostPageKey.BANNER_IMAGE, index), null as never);
    setValue(getPostPageKey(PostPageKey.BANNER_IMAGE_ID, index), null as never);
  };

  const thumbnailChangeHandler = (asset: AssetFormData) => {
    setValue(PostKey.THUMBNAIL, asset);
    setValue(PostKey.THUMBNAIL_ID, asset.id ?? null);
  };

  const getBannerChangeHandler = (index: number) => (asset: AssetFormData) => {
    setValue(getPostPageKey(PostPageKey.BANNER_IMAGE, index), asset as never);
    setValue(getPostPageKey(PostPageKey.BANNER_IMAGE_ID, index), asset.id as never);
  };

  const setValues = (formValues: Partial<PostFormData>): void => {
    for (const key in formValues) {
      const value = formValues[key];

      if (key === PostKey.POST_PAGES) {
        formValues[PostKey.POST_PAGES]?.map((postPage, index) => {
          const parsedContent = parseAssetsToHtmlString(
            postPage.content || '',
            postPage.contentAssets as AssetFormData[],
          );

          textEditors.getEditorSetter(index)(htmlToEditorContent(parsedContent));
        });

        continue;
      }

      setValue(key as keyof typeof formValues, value);
    }
  };

  useEffect(() => {
    register(PostKey.SECTION, {
      validate: value => (value.length ? true : 'Section type required'),
    });
    register(PostKey.NAME, {
      validate: value => validateTextField(value, 'Name'),
    });
    register(PostKey.ESTIMATED_TIME, {
      validate: value => (value ? true : 'Estimated time required'),
    });
    register(PostKey.POST_PAGES, {
      validate: values => {
        if (values?.length) {
          return validatePostPagesMediaLinks(values);
        }

        return true;
      },
    });
  }, [register]);

  useEffect(() => {
    if (providedFormValues) {
      setValues(providedFormValues);
    }
  }, []);

  return {
    errors: formErrors,
    watch,
    setValue,
    trigger,
    setTags,
    formActions,
    disabled,
    watchThumbnail,
    thumbnailSubmitHandler,
    thumbnailDeleteHandler,
    thumbnailChangeHandler,
    formLoading: formAndAssetLoading,
    textEditors,
    getBannerChangeHandler,
    getBannerDeleteHandler,
    getBannerSubmitHandler,
  };
};
