import { useDispatch } from 'react-redux';

import { DocumentNode, useMutation } from '@apollo/client';

import { getBulkActions } from 'components/tables/grid-table/bulkActions';
import { BULK_DESTROY_QUESTIONS, CHANGE_QUESTIONS_PUBLISH_STATUSES } from 'graphql/mutations';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTable } from 'hooks/useTable';

import { QuestionFormData, QuestionKey } from '../forms';

const DATA_KEY = 'questions';
const COUNTER_KEY = 'questionsTotalCount';

interface Props {
  query: DocumentNode;
  where?: object;
}

interface Data {
  questions: QuestionFormData[];
  questionsTotalCount: number;
}

interface Search {
  string: string;
}

export const useQuestionTable = ({ query, where }: Props) => {
  const { showSuccessToast } = useSnackbar();

  const { updatePage, bulkObject, attributes, page, getFilter, ...args } = useTable<Data['questions']>({
    dataKey: DATA_KEY,
    counterKey: COUNTER_KEY,
    query: {
      query,
      where,
    },
  });

  const bulkDelete = useMutation(BULK_DESTROY_QUESTIONS);
  const bulkChangePublishState = useMutation(CHANGE_QUESTIONS_PUBLISH_STATUSES);

  const bulkActions = getBulkActions({
    bulkObject,
    updatePage,
    enqueueSnackbar: showSuccessToast,
    bulkDelete,
    bulkChangePublishState,
    entityName: 'question',
    dispatch: useDispatch(),
  });

  const filterAction = getFilter({ options: [QuestionKey.ID, QuestionKey.CONTENT] });

  return {
    ...args,
    page,
    bulkActions,
    filterAction,
  };
};
