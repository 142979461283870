import { FC } from 'react';

import {
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Card as MUICard,
  Radio,
  RadioGroup,
} from '@mui/material';

import { Card } from 'components/common';
import { ActionButtons, DateInput, ID } from 'components/inputs';
import { TextField } from 'components/mui-custom';
import { ViewTable } from 'components/tables/view-table';
import { setLabel } from 'global/form';
import { PathParams } from 'global/route';

import './index.scss';

import { useQuestionForm } from '../hooks/useQuestionForm';

import { LINKED_REVIEW_COLUMNS, QUESTION_FORM, QuestionFormData, QuestionKey, QuestionType } from '.';

interface Props {
  action: PathParams;
  values?: QuestionFormData;
}
export const Question: FC<Props> = ({ action, values = QUESTION_FORM }) => {
  const { formLoading, readOnly, watch, trigger, setValue, formActions, errors } = useQuestionForm({
    action,
    providedFormValues: values,
  });

  const { uid, content, reviews, updatedAt, createdAt, publishedAt, type = '' } = watch();

  return (
    <MUICard>
      <CardContent className="question-form-content">
        {uid && <ID value={uid} disabled />}
        <Card>
          <TextField
            value={content}
            label={setLabel('Question', action)}
            onChange={e => setValue(QuestionKey.CONTENT, e.target.value)}
            onBlur={() => trigger(QuestionKey.CONTENT)}
            helperText={errors?.content?.message}
            error={Boolean(errors.content)}
            disabled={readOnly || formLoading}
          />
          <FormControl error={Boolean(errors.type)}>
            <FormLabel className="--label" id={QuestionKey.TYPE}>
              Format
            </FormLabel>
            <RadioGroup
              row
              id={QuestionKey.TYPE}
              value={type}
              onChange={e => setValue(QuestionKey.TYPE, e.target.value as QuestionType)}
            >
              {Object.values(QuestionType).map(value => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={value === QuestionType.BASIC_CHOICE ? 'yes/no' : value}
                  disabled={readOnly || formLoading}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Card>
        {readOnly && <DateInput key={1} label="Published at" value={publishedAt} disabled />}
        {readOnly && <DateInput key={2} label="Updated" value={updatedAt} disabled />}
        {readOnly && <DateInput key={3} label="Created" value={createdAt} disabled />}
        {readOnly && <ViewTable columns={LINKED_REVIEW_COLUMNS} rows={reviews ?? []} label="Linked reviews" />}
      </CardContent>
      <ActionButtons actions={formActions} />
    </MUICard>
  );
};
