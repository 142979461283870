import { useDispatch } from 'react-redux';

import { DocumentNode, useMutation } from '@apollo/client';

import { getBulkActions } from 'components/tables/grid-table/bulkActions';
import { BULK_DESTROY_REVIEWS, CHANGE_REVIEWS_PUBLISH_STATUSES } from 'graphql/mutations';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTable } from 'hooks/useTable';

import { ReviewFormData, ReviewKey } from '../forms';

const DATA_KEY = 'reviews';
const COUNTER_KEY = 'reviewsTotalCount';

interface Props {
  query: DocumentNode;
  where?: object;
}

interface Data {
  reviews: ReviewFormData[];
  reviewsTotalCount: number;
}

interface Search {
  searchString: string;
}

export const useReviewTable = ({ query, where }: Props) => {
  const { showSuccessToast } = useSnackbar();
  const { updatePage, bulkObject, page, getFilter, ...args } = useTable<Data['reviews']>({
    dataKey: DATA_KEY,
    counterKey: COUNTER_KEY,
    query: {
      query,
      where,
    },
  });

  const bulkDelete = useMutation(BULK_DESTROY_REVIEWS);
  const bulkChangePublishState = useMutation(CHANGE_REVIEWS_PUBLISH_STATUSES);

  const bulkActions = getBulkActions({
    bulkObject,
    updatePage,
    enqueueSnackbar: showSuccessToast,
    bulkDelete,
    bulkChangePublishState,
    entityName: 'review',
    dispatch: useDispatch(),
  });

  const filterAction = getFilter({ options: [ReviewKey.ID, ReviewKey.TITLE, ReviewKey.PREAMBLE] });

  return {
    ...args,
    page,
    bulkActions,
    filterAction,
  };
};
