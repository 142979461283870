import { Link } from 'react-router-dom';

import { GridCellParams } from '@mui/x-data-grid';
import { RoutePath } from 'app/routes';

import { pathJoin, PathParams } from 'global/route';

export const renderLinkCell = (routePath: RoutePath, params: GridCellParams<any, any, any>) => (
  <Link to={'/' + pathJoin(routePath, params.value, PathParams.SHOW)} target="_blank">
    {params.value}
  </Link>
);
