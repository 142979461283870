import { FC, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { Box, Container, CssBaseline, useMediaQuery, ThemeProvider } from '@mui/material';

import { Breadcrumbs } from 'components/common/breadcrumbs';
import { selectModal } from 'store/modal';

import { AppBar } from './AppBar';
import { AppDrawer } from './AppDrawer';
import { AppFooter } from './AppFooter';
import './AppLayout.scss';
import { theme } from './theme';
import { selectConfirmNavigationModal } from 'store/confirm-navigation-modal';
import { ConfirmModal, ConfirmNavigationModal } from 'components/confirm-modal';

export const AdminLayout: FC = () => {
  const [open, setDrawer] = useState(true);
  const modal = useSelector(selectModal);
  const confirmNavigationModal = useSelector(selectConfirmNavigationModal);
  const screenLessMd = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = () => setDrawer(!open);

  useEffect(() => {
    screenLessMd ? setDrawer(false) : setDrawer(true);
  }, [screenLessMd]);

  return (
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: 'flex',
            minHeight: '100vh',
          }}
        >
          <ConfirmModal {...modal} />
          <ConfirmNavigationModal {...confirmNavigationModal} />
          <CssBaseline />
          <AppBar config={{ toggleDrawer, theme }} />
          <AppDrawer state={open} />
          <Box
            component="main"
            mt={6}
            sx={{
              backgroundColor: theme =>
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              overflow: 'auto',
            }}
          >
            <Container maxWidth={false} sx={{ pt: 3, pb: 0, minHeight: '85vh' }}>
              <Breadcrumbs />
              <Outlet />
            </Container>
            <AppFooter></AppFooter>
          </Box>
        </Box>
      </ThemeProvider>
    </DndProvider>
  );
};
