import { FC } from 'react';

import { Grid, GridSize, StandardTextFieldProps } from '@mui/material';
import { format, parseISO } from 'date-fns';

import { TextField } from 'components/mui-custom';
import { DATE_FORMAT, NO_DATE } from 'constants/date-time';

const XS = false;
const SM = 6;
const MD = 3;

interface Props extends StandardTextFieldProps {
  sm?: boolean | GridSize;
  md?: boolean | GridSize;
  xs?: boolean | GridSize;
  value?: string | Date | null;
}
export const DateInput: FC<Props> = ({ value, label, sm = SM, md = MD, xs = XS, ...props }) => (
  <Grid container>
    <Grid item xs={xs} sm={sm} md={md}>
      <TextField value={value ? format(parseISO(String(value)), DATE_FORMAT) : NO_DATE} label={label} {...props} />
    </Grid>
  </Grid>
);
