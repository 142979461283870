import { useDispatch } from 'react-redux';

import { DocumentNode, useMutation } from '@apollo/client';

import { getBulkActions } from 'components/tables/grid-table/bulkActions';
import { BULK_DESTROY_DAILY_PRACTICES, CHANGE_DAILY_PRACTICES_PUBLISH_STATUSES } from 'graphql/mutations';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTable } from 'hooks/useTable';
import { DailyPracticeFormData, DailyPracticeKey } from 'pages/daily-practice/forms';

const DATA_KEY = 'dailyPractices';
const COUNTER_KEY = 'dailyPracticesTotalCount';

interface Props {
  query: DocumentNode;
  where?: object;
}

interface Data {
  dailyPracticesTotalCount: number;
  dailyPractices: DailyPracticeFormData[];
}

interface Search {
  searchString: string;
}

export const useDailyPracticeTable = ({ query, where }: Props) => {
  const { showSuccessToast } = useSnackbar();
  const { setOrder, updatePage, bulkObject, page, getFilter, ...args } = useTable<Data['dailyPractices']>({
    dataKey: DATA_KEY,
    counterKey: COUNTER_KEY,
    query: {
      query,
      where,
    },
  });

  const bulkDelete = useMutation(BULK_DESTROY_DAILY_PRACTICES);
  const bulkChangePublishState = useMutation(CHANGE_DAILY_PRACTICES_PUBLISH_STATUSES);

  const bulkActions = getBulkActions({
    bulkObject,
    updatePage,
    enqueueSnackbar: showSuccessToast,
    bulkDelete,
    bulkChangePublishState,
    entityName: 'dailyPractice',
    dispatch: useDispatch(),
  });

  const filterAction = getFilter({ options: [DailyPracticeKey.ID, DailyPracticeKey.ACTION] });

  return {
    ...args,
    page,
    bulkActions,
    filterAction,
  };
};
