import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PathParams } from 'global/route';

import {
  CHANGE_QUESTIONS_PUBLISH_STATUSES,
  CREATE_QUESTION,
  DESTROY_QUESTION,
  UPDATE_QUESTION,
} from 'graphql/mutations';
import { GET_QUESTIONS } from 'graphql/queries';
import { validateTextField } from 'plugins/validator';
import { QUESTION_FORM, QuestionFormData, QuestionKey, formatChoices, typeToChoices } from '../forms';
import { useItemForm } from 'hooks/useItemForm';

interface Props {
  action: PathParams;
  providedFormValues: QuestionFormData;
}
export const useQuestionForm = ({ providedFormValues, action }: Props) => {
  const { id: questionId } = useParams();

  const parseData = (values: QuestionFormData) => {
    const { updatedAt, createdAt, uid, reviews, __typename, type, ...formValues } = values;

    return { ...formValues, choices: type && typeToChoices[type] };
  };

  const { formActions, formErrors, formLoading, register, setFormValues, setValue, trigger, watch } =
    useItemForm<QuestionFormData>({
      action,
      defaultValues: QUESTION_FORM,
      itemDisplayName: 'Question',
      createMutation: CREATE_QUESTION,
      updateMutation: UPDATE_QUESTION,
      destroyMutation: DESTROY_QUESTION,
      changePublishStatusMutation: CHANGE_QUESTIONS_PUBLISH_STATUSES,
      getItemsQuery: GET_QUESTIONS,
      parseData,
      editPathId: questionId,
    });

  const readOnly: boolean = action === PathParams.SHOW;

  useEffect(() => {
    register(QuestionKey.CONTENT, {
      validate: value => validateTextField(value, 'Question'),
    });
    register(QuestionKey.TYPE, {
      validate: value => (value ? true : 'Type - required'),
    });
  }, [register]);

  useEffect(() => {
    if (providedFormValues) {
      setFormValues({
        ...providedFormValues,
        type: formatChoices(providedFormValues.choices) ?? undefined,
      });
    }
  }, []);

  return {
    readOnly,
    formLoading,
    watch,
    setValue,
    trigger,
    errors: formErrors,
    formActions,
  };
};
