import { FC } from 'react';

import { FormControlProps, Grid, MenuItem, SelectProps } from '@mui/material';

import { CustomFormHelperTextProps, Select } from 'components/mui-custom';
import { POST_SECTIONS } from 'components/post/forms';

interface Props extends SelectProps {
  formControlProps?: FormControlProps;
  formHelperTextProps?: CustomFormHelperTextProps;
}

export const Section: FC<Props> = ({ formHelperTextProps, value, ...props }) => (
  <Grid sx={{ mt: 1 }} container>
    <Grid item xs={false} sm={6} md={4}>
      <Select value={value} label="Section (Required)" formHelperTextProps={formHelperTextProps} {...props}>
        {POST_SECTIONS.map(({ text, value }, key) => (
          <MenuItem key={key} value={value}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  </Grid>
);
