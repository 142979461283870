import { FC } from 'react';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { CmsApp, LabApp } from 'app';

import { CmsUserCard, LabUserCard } from 'components/user-card';
import { getCMSClient } from 'graphql/apollo-client';
import { GetErrorLinkCallback } from 'graphql/error-handler';
import { CmsSigninPage, LabSigninPage } from 'pages/login';

import { CMS_ROUTES, LAB_ROUTES, NavigationRoute } from './routes';

export const LOTIC_LAB = 'Lab';
export const LOTIC_CMS = 'CMS';

interface GetApiClientProps {
  errorHandler: GetErrorLinkCallback;
}

export type GetApiClient = (props: GetApiClientProps) => ApolloClient<NormalizedCacheObject>;
interface AppClient {
  App: FC<any>;
  SignIn: FC<any>;
  AdminCard: FC<any>;
  routes: NavigationRoute[];
  getApiClient: GetApiClient;
  title: string;
}

const CMS_APP_CLIENT: AppClient = Object.freeze({
  App: CmsApp,
  SignIn: CmsSigninPage,
  AdminCard: CmsUserCard,
  routes: CMS_ROUTES,
  getApiClient: getCMSClient,
  title: 'Lotic admin CMS',
});

const LAB_APP_CLIENT: AppClient = Object.freeze({
  App: LabApp,
  SignIn: LabSigninPage,
  AdminCard: LabUserCard,
  routes: LAB_ROUTES,
  getApiClient: getCMSClient,
  title: 'Lab Admin',
});

export const APP_CLIENT: AppClient = (() => {
  switch (process.env.REACT_APP_INSTANCE) {
    case LOTIC_LAB:
      return LAB_APP_CLIENT;
    case LOTIC_CMS:
      return CMS_APP_CLIENT;
    default:
      throw new Error('Application client not defined');
  }
})();
