import { FC } from 'react';

import { FormHelperTextProps, Grid, GridProps, MenuItem, SelectProps } from '@mui/material';

import { ClassName as MuiCustom } from 'components/mui-custom';
import { Select } from 'components/mui-custom';
import { POST_MEDIA_TYPES } from 'components/post/forms';

interface CustomFormHelperTextProps extends FormHelperTextProps {
  text: string | undefined;
}

interface Props extends SelectProps {
  formHelperTextProps?: CustomFormHelperTextProps;
  gridContainerProps?: GridProps;
  gridWrapperProps?: GridProps;
}

const DEFAULT_CONTAINER_PROPS = {
  sx: { mt: 1 },
};

const DEFAULT_WRAPPER_PROPS = {
  xs: false,
  sm: 6,
  md: 4,
};

export const MediaType: FC<Props> = ({
  formHelperTextProps,
  gridContainerProps = DEFAULT_CONTAINER_PROPS,
  gridWrapperProps = DEFAULT_WRAPPER_PROPS,
  ...props
}) => (
  <Grid {...gridContainerProps} container>
    <Grid {...gridWrapperProps} className={props.error ? MuiCustom.ERROR : ''} item>
      <Select label="Media type (Required)" formHelperTextProps={formHelperTextProps} {...props}>
        {POST_MEDIA_TYPES.map((text, key) => (
          <MenuItem key={key} value={text}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  </Grid>
);
