import { useDispatch } from 'react-redux';

import { DocumentNode, useMutation } from '@apollo/client';

import { getBulkActions } from 'components/tables/grid-table/bulkActions';
import { BULK_DESTROY_SEQUENCES, CHANGE_SEQUENCE_PUBLISH_STATUSES } from 'graphql/mutations';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTable } from 'hooks/useTable';

import { SequenceFormData, SequenceKey } from '../forms';

const DATA_KEY = 'packageSequences';
const COUNTER_KEY = 'packageSequencesTotalCount';

interface Props {
  query: DocumentNode;
  where?: object;
}

interface Data {
  sequences: SequenceFormData[];
  sequencesTotalCount: number;
}

interface Search {
  searchString: string;
}

export const useSequenceTable = ({ query, where }: Props) => {
  const { showSuccessToast } = useSnackbar();
  const { updatePage, bulkObject, page, getFilter, ...args } = useTable<Data['sequences']>({
    dataKey: DATA_KEY,
    counterKey: COUNTER_KEY,
    query: {
      query,
      where,
    },
  });

  const bulkDelete = useMutation(BULK_DESTROY_SEQUENCES);
  const bulkChangePublishState = useMutation(CHANGE_SEQUENCE_PUBLISH_STATUSES);

  const bulkActions = getBulkActions({
    bulkObject,
    updatePage,
    enqueueSnackbar: showSuccessToast,
    bulkDelete,
    bulkChangePublishState,
    entityName: 'sequence',
    dispatch: useDispatch(),
  });

  const filterAction = getFilter({ options: [SequenceKey.ID, SequenceKey.TITLE] });

  return {
    ...args,
    page,
    bulkActions,
    filterAction,
  };
};
