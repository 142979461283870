import { FC } from 'react';

import { NotFound } from 'components/NotFound';
import { PathParams } from 'global/route';

import { Experiment } from './forms/Create';
import { useGetExperiment } from './hooks/useGetExperiment';

export const Edit: FC = () => {
  const { loading, data } = useGetExperiment();

  if (loading) return <></>;
  if (data?.experiment) return <Experiment action={PathParams.EDIT} values={data.experiment} />;
  return <NotFound />;
};
