import { FC } from 'react';

import { StandardTextFieldProps } from '@material-ui/core';
import { Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import config from 'config';

import { ViewTable } from 'components/tables/view-table';
import { DEFAULT_GRID_ITEM_PROPS } from 'global/form';
import { PackageKey } from 'pages/package/forms';

import { ExperimentType } from '../forms';
import { useGetExperimentPackages } from '../hooks/useGetExperimentPackages';

interface Props extends StandardTextFieldProps {
  experimentId?: number;
  experimentType: ExperimentType;
}

const buildColumns = (experimentId?: number, experimentType?: ExperimentType): GridColDef[] => {
  return [
    {
      field: PackageKey.PACKAGE_NUMBER,
      headerName: 'Production URLs',
      flex: 1,
      valueGetter: ({ row }) => {
        const baseURL = `${config.env.LABS_UI_HOST}/experience/${experimentId}/packages/${row.packageNumber}`;
        if (experimentType === ExperimentType.PROLIFIC_STUDY) {
          const prolificParams = 'PROLIFIC_PID={{%PROLIFIC_PID%}}&STUDY_ID={{%STUDY_ID%}}&SESSION_ID={{%SESSION_ID%}}';
          return `${baseURL}?${prolificParams}`;
        }
        if (experimentType === ExperimentType.RAND_STUDY) {
          const randParams = 'userId={{RAND_USER_ID}}&sid={{RAND_SESSION_ID}}';
          return `${baseURL}?${randParams}`;
        }
        if (experimentType === ExperimentType.ONE_LINK_ANON_USER) {
          return `${baseURL}`;
        }
      },
    },
  ];
};

export const ExperimentPackages: FC<Props> = ({ experimentId, experimentType }) => {
  const columns = buildColumns(experimentId, experimentType);
  const packages = useGetExperimentPackages()?.data?.packageSequence?.packages;
  return packages ? (
    <Grid {...DEFAULT_GRID_ITEM_PROPS} md={12}>
      <ViewTable rows={packages ?? []} columns={columns} label={'Packages:'} />
    </Grid>
  ) : (
    <></>
  );
};
