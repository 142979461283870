import { FC } from 'react';

import { StandardTextFieldProps } from '@material-ui/core';
import { Grid } from '@mui/material';

import { ClassName, TextField } from 'components/mui-custom';
import { DEFAULT_GRID_ITEM_PROPS, setLabel } from 'global/form';
import { PathParams } from 'global/route';

import { Key as ExperimentKey } from '../forms';
import { UseExperimentForm } from '../hooks/useExperimentForm';

interface Props extends StandardTextFieldProps {
  hook: UseExperimentForm;
  action: PathParams;
}

export const ExperimentRedirectUrl: FC<Props> = ({
  hook: { watch, setRedirectUrl, trigger, errors, readOnly },
  action,
}) => {
  const { redirectUrl } = watch();

  return (
    <Grid {...DEFAULT_GRID_ITEM_PROPS} className={errors.type?.message ? ClassName.ERROR : ''}>
      <TextField
        value={redirectUrl}
        label={setLabel('Redirect URL', action, true)}
        onChange={setRedirectUrl}
        onBlur={() => trigger(ExperimentKey.REDIRECT_URL)}
        helperText={'Example: https://www.google.com'}
        error={Boolean(errors.redirectUrl)}
        disabled={readOnly}
      />
    </Grid>
  );
};
