import { PathParams } from './route';

export const setLabel = (label: string, action: PathParams, optional: boolean = false): string =>
  [PathParams.CREATE, PathParams.EDIT].includes(action) && !optional ? label + ' (Required)' : label;

export const DEFAULT_GRID_CONTAINER_PROPS = {
  sx: { mt: 1 },
  container: true,
  spacing: 5,
};

export const DEFAULT_GRID_ITEM_PROPS = {
  xs: false,
  sm: 12,
  md: 4,
  item: true,
};
