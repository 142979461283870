import { FC } from 'react';

import { Card } from '@mui/material';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { RoutePath } from 'app/routes';

import { Table as TB } from 'components/tables/grid-table';
import { formatDate } from 'global/formater';
import { getShowPagePath } from 'hooks/useTable';

import { Key } from '../forms';
import { useUserTable } from '../hooks/useUserTable';

export const Table: FC = () => {
  const {
    cellClickHandler,
    content,
    setSelected,
    selectAll,
    unselectAll,
    selectedCount,
    sortContent,
    rowsPerPage,
    loading,
    page,
    selected,
    filterAction,
    bulkActions,
    rowCount,
    paginationModelChangeHandler,
    sortModel,
    selectAllChecked,
    onSelectAllChange,
    selectPageHandler,
    selectAllIndeterminate,
  } = useUserTable();

  return (
    <Card>
      <TB
        title="Lab user"
        onCellClick={cellClickHandler as unknown as GridEventListener<'cellClick'>}
        rows={content}
        columns={TABLE_COLUMNS}
        onGetLink={({ row }) => getShowPagePath(RoutePath.LAB_USERS, String(row.uid))}
        onRowSelectionModelChange={setSelected}
        onSelectAll={selectAll}
        onUnselectAll={unselectAll}
        onSortModelChange={sortContent}
        rowCount={rowCount}
        paginationModel={{ page, pageSize: rowsPerPage }}
        onPaginationModelChange={paginationModelChangeHandler}
        loading={loading}
        selected={selected}
        selectedCount={selectedCount}
        keepNonExistentRowsSelected
        filter={filterAction}
        bulk={{
          actions: bulkActions,
        }}
        sortModel={sortModel}
        selectAllChecked={selectAllChecked}
        onSelectAllChange={onSelectAllChange}
        selectPageHandler={selectPageHandler}
        selectAllIndeterminate={selectAllIndeterminate}
        sortingMode="server"
      />
    </Card>
  );
};

const TABLE_COLUMNS: GridColDef[] = [
  { field: Key.UID, headerName: 'ID', flex: 1 },
  { field: Key.EMAIL, headerName: 'Email', flex: 1 },
  {
    field: Key.CREATED_AT,
    headerName: 'Created Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.createdAt),
  },
  {
    field: Key.UPDATED_AT,
    headerName: 'Updated Date',
    flex: 1,
    valueGetter: ({ row }) => formatDate(row.updatedAt),
  },
];
