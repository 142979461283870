import { renderToString } from 'react-dom/server';

import { AssetFormData, MediaTypeValue } from 'components/asset';
import { htmlStringToMediaElement, JsxToEditorHtmlString } from 'components/react-draft/utils';

export const ASSET_ID_PARAM = 'assetId';

export const ASSET_REGEXP = /<editorasset(.*?)*><\/editorasset>/g;
export const IFRAME_REGEXP = /<iframe(.*?)*><\/iframe>/g;
export const IMAGE_REGEXP = /<img(.*?)*>/g;

/**
 * Appends an "assetId" query parameter to the asset source link
 * @param src source link
 * @param assetId asset ID
 */
export const appendAssetIdParam = (src: string, assetId: number): URL => {
  const url = new URL(src);
  url.searchParams.append(ASSET_ID_PARAM, String(assetId));
  return url;
};

/**
 * Gets the asset id query parameter from the asset source link
 * @param src source link
 */
const getAssetIdParam = (src: string): string | null => {
  const url = new URL(src);
  return url.searchParams.get(ASSET_ID_PARAM);
};

/**
 * Generates a template based on an asset ID parameter
 * @param assetId asset ID
 * @returns string of asset template with id attribute
 */
const getAssetTemplate = (assetId: number | string): string => `<editorasset id="${assetId}"></editorasset>`;

export interface AssetsFromStringResult {
  /**
   * @param assetsIds assets ids from passed html string
   */
  assetsIds: number[];
  /**
   * @param content new html with real assets in it
   */
  content: string;
}

/**
 * Parses the html string and return asset ids and a new html with the real assets in it
 * @param str html string
 */
export const parseAssetsFromHtmlString = (str: string): AssetsFromStringResult => {
  let content = str;

  // put all "iframes" and "images" in one array
  const stringElements = [...(content.match(IFRAME_REGEXP) ?? []), ...(content.match(IMAGE_REGEXP) ?? [])];

  const assetsIds = stringElements.map(stringElement => {
    const mediaElement = htmlStringToMediaElement(stringElement);
    const assetId = getAssetIdParam(mediaElement.src);

    if (assetId) {
      // replace an element with the asset template with ID
      content = content.replace(stringElement, getAssetTemplate(assetId));
      return parseInt(assetId);
    }

    throw new Error("Can't get " + ASSET_ID_PARAM + ' from: ' + stringElement);
  });

  return { assetsIds, content };
};

/**
 * Parses the html string and replaces all asset templates with the tag from the "buildStringMediaElement" method
 * @param str html string
 * @param contentAssets assets used in content
 * @returns html string with the tags from "buildStringMediaElement" method
 */
export const parseAssetsToHtmlString = (str: string, contentAssets: AssetFormData[]): string => {
  let content = str;

  contentAssets?.forEach(({ id, url: assetUrl, assetType }) => {
    if (!id || !assetUrl || !assetType) throw new Error('Invalid asset parameters');

    const assetTemplate = new RegExp(getAssetTemplate(id), 'g');
    const url = appendAssetIdParam(assetUrl, id);
    const mediaElement = buildStringMediaElement({ assetType, url, withWrapper: false });

    // replace asset template with the tag from "buildStringMediaElement" method
    content = content.replace(assetTemplate, mediaElement);
  });

  return content;
};

export interface BuildMediaElement {
  url: URL;
  assetType: MediaTypeValue;
  withWrapper?: boolean;
}
/**
 * Creates an 'iframe' or 'image' tag in string format with the src attribute based on the assetsType parameter.
 * @param { assetType } BuildMediaElement.assetType - asset type
 * @param { url } BuildMediaElement.url - URL instance
 * @param { withWrapper } BuildMediaElement.withWrapper - whether the element should be wrapped in siblings tag
 * @return html element string
 */
export const buildStringMediaElement = ({ assetType, url, withWrapper = true }: BuildMediaElement): string => {
  const createStringElement = withWrapper ? JsxToEditorHtmlString : renderToString;

  switch (assetType) {
    case MediaTypeValue.IMAGE:
      return createStringElement(<img src={url.href} />);
    case MediaTypeValue.VIDEO:
    case MediaTypeValue.AUDIO:
      return createStringElement(<iframe src={url.href} />);
    default:
      throw new Error('Not valid media type');
  }
};
