import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { store } from 'store';
import { Modal, MODAL } from './common-modal';

export const confirmNavigationModalSlice = createSlice({
  name: 'confirmNavigationModal',
  initialState: MODAL,
  reducers: {
    setConfirmNavigationModal: (MODAL, action: PayloadAction<Modal>) => ({ ...MODAL, ...action.payload }),
    clearConfirmNavigationModal: () => MODAL,
  },
});

export const selectConfirmNavigationModal = (state: ReturnType<typeof store.getState>) => state.confirmNavigationModal;

export const confirmNavigationModalReducer = confirmNavigationModalSlice.reducer;

export const { setConfirmNavigationModal, clearConfirmNavigationModal } = confirmNavigationModalSlice.actions;
