import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { NotFound } from 'components/NotFound';
import { stringToInt } from 'global/formater';
import { PathParams } from 'global/route';
import { FetchPolicy } from 'graphql/apollo-client';
import { GET_PACKAGES, GET_SEQUENCE, GET_SEQUENCES } from 'graphql/queries';

import { Sequence } from './Create';

import { SEQUENCE_FORM, SequenceFormData } from '.';

interface Props {
  action: PathParams;
}
export const SaveForm: FC<Props> = ({ action }) => {
  const { id } = useParams();

  const { data, loading } = useQuery<{ packageSequence: SequenceFormData }>(GET_SEQUENCE, {
    fetchPolicy: FetchPolicy.NETWORK_ONLY,
    variables: {
      id: id ? stringToInt(id as string) : null,
    },
    skip: action === PathParams.CREATE || !id,
  });
  const { data: packagesData, loading: packagesLoading } = useQuery(GET_PACKAGES, { skip: action === PathParams.SHOW });
  const { data: sequencesData, loading: sequencesLoading } = useQuery(GET_SEQUENCES);

  if (loading || packagesLoading || sequencesLoading) return <></>;
  else if (data?.packageSequence && action === PathParams.SHOW)
    return <Sequence action={action} values={data.packageSequence} sequences={sequencesData.packageSequences} />;
  else if (action === PathParams.CREATE)
    return (
      <Sequence
        action={action}
        values={SEQUENCE_FORM}
        allPackages={packagesData.packages}
        sequences={sequencesData.packageSequences}
      />
    );
  else if (data?.packageSequence && action === PathParams.EDIT)
    return (
      <Sequence
        action={action}
        values={data.packageSequence}
        allPackages={packagesData.packages}
        sequences={sequencesData.packageSequences}
      />
    );
  else return <NotFound />;
};
