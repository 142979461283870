import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { useMutation } from '@apollo/client';
import { DataGridProps } from '@mui/x-data-grid';
import { RoutePath } from 'app/routes';

import { getBulkActions } from 'components/tables/grid-table/bulkActions';
import { BULK_DESTROY_LAB_USERS } from 'graphql/mutations';
import { GET_LAB_USERS } from 'graphql/queries';
import { useSnackbar } from 'hooks/useSnackbar';
import { getShowPagePath, useTable } from 'hooks/useTable';

import { LabUser, Key as UserKey } from '../forms';

const DATA_KEY = 'users';
const COUNTER_KEY = 'usersTotalCount';

interface Data {
  users: LabUser[];
  usersTotalCount: number;
}

interface Search {
  idEmail: string;
}

export const useUserTable = () => {
  const navigate = useNavigate();
  const { showSuccessToast } = useSnackbar();
  const { updatePage, bulkObject, page, getFilter, ...args } = useTable<Data['users']>({
    dataKey: DATA_KEY,
    counterKey: COUNTER_KEY,
    query: {
      query: GET_LAB_USERS,
    },
  });

  const bulkDelete = useMutation(BULK_DESTROY_LAB_USERS);

  const bulkActions = getBulkActions({
    bulkObject,
    updatePage,
    enqueueSnackbar: showSuccessToast,
    bulkDelete,
    entityName: 'lab user',
    dispatch: useDispatch(),
  });

  const filterAction = getFilter({ options: [UserKey.ID, UserKey.EMAIL] });

  const onRowClick: DataGridProps['onRowClick'] = ({ row }) =>
    navigate(getShowPagePath(RoutePath.LAB_USERS, String(row.uid)));

  return {
    ...args,
    page,
    bulkActions,
    filterAction,
    onRowClick,
  };
};
