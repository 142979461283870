import { FC, MouseEvent, useMemo } from 'react';

import { Box, Grid, GridSize, LinearProgress } from '@mui/material';

import { AssetFilter, AssetFormData } from 'components/asset';

import { useAssetGridView } from './hook';
import './index.scss';

import { AssetPickerTheme } from '../asset-picker';
import { AssetView } from '../view';

const DEFAULT_GRID_SIZE = 2.4;
const GRID_CONTAINER_CLASS = 'asset-grid-container ';
const GRID_ITEM_CLASS = 'grid-item';
const ASSET_WRAPPER_CLASS = 'asset-wrapper';
const PROGRESS_WRAPPER_CLASS = 'progress-wrapper';
const DEFAULT_ASSETS_PER_PAGE = 30;

interface Props {
  onClick: (e: MouseEvent, asset: AssetFormData) => void;
  gridSize?: GridSize;
  theme?: AssetPickerTheme;
  where: AssetFilter;
  assetsPerPage?: number;
}
export const AssetGridView: FC<Props> = ({
  onClick,
  gridSize = DEFAULT_GRID_SIZE,
  theme = AssetPickerTheme.DARK,
  where,
  assetsPerPage = DEFAULT_ASSETS_PER_PAGE,
}) => {
  const { assets, loading, gridContainerRef, assetViewClickHandler } = useAssetGridView({
    where,
    assetsPerPage,
  });

  const assetsGrid = useMemo(
    () =>
      assets.map(asset => (
        <Grid key={asset.url} className={GRID_ITEM_CLASS} onClick={event => onClick(event, asset)} item md={gridSize}>
          <div onClick={assetViewClickHandler} className={ASSET_WRAPPER_CLASS} tabIndex={asset.id}>
            <AssetView asset={asset} controls={false} />
          </div>
        </Grid>
      )),
    [assets],
  );

  return (
    <Grid className={GRID_CONTAINER_CLASS + theme} container ref={gridContainerRef}>
      {assetsGrid}
      {loading && (
        <Box className={PROGRESS_WRAPPER_CLASS}>
          <LinearProgress />
        </Box>
      )}
    </Grid>
  );
};
