import { CSSProperties, FC, ReactNode } from 'react';

import { Grid } from '@material-ui/core';
import ListIcon from '@mui/icons-material/List';

import './index.scss';

interface Props {
  label: string;
  item: ReactNode | string;
  style?: CSSProperties;
}

const LABEL_CONTAINER_SIZE = 3;
const ITEM_CONTAINER_SIZE = 9;

export const GridItemView: FC<Props> = ({ label, item, style }) => (
  <Grid style={style} className="grid-item-view" container>
    <Grid className="grid-column" item xs={LABEL_CONTAINER_SIZE}>
      <ListIcon className="icon" />
      <span>{label}</span>
    </Grid>
    <Grid className="grid-column" item xs={ITEM_CONTAINER_SIZE}>
      {item}
    </Grid>
  </Grid>
);
