import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { List, ListItem } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { APP_CLIENT } from 'app/manager';

export const MainNavigation: FC = () => {
  const { routes } = APP_CLIENT;

  return (
    <List className="app-navigation">
      {routes.map(({ name, icon, route }, key) => (
        <div key={key}>
          <ListItem key={key} component={NavLink} button to={route} dense>
            {icon}
            <ListItemText primary={name} />
          </ListItem>
          {key === 0 && <Divider />}
        </div>
      ))}
    </List>
  );
};
