import { FC } from 'react';

import { StandardTextFieldProps } from '@material-ui/core';
import { Grid } from '@mui/material';

import { TextField } from 'components/mui-custom';
import { DEFAULT_GRID_ITEM_PROPS } from 'global/form';
import { PathParams } from 'global/route';

import { Key as ExperimentKey } from '../forms';
import { UseExperimentForm } from '../hooks/useExperimentForm';

interface Props extends StandardTextFieldProps {
  hook: UseExperimentForm;
  action: PathParams;
  min?: number;
  max?: number;
}

const FREQUENCY_MIN_VALUE = 1;
const FREQUENCY_MAX_VALUE = 10;

export const ExperimentFrequency: FC<Props> = ({
  min = FREQUENCY_MIN_VALUE,
  max = FREQUENCY_MAX_VALUE,
  hook: { watch, setFrequency, readOnly, disableInputs },
  action,
}) => {
  const { frequency, type } = watch();

  return (
    <>
      {frequency || action !== PathParams.SHOW ? (
        <Grid {...DEFAULT_GRID_ITEM_PROPS}>
          <TextField
            value={frequency}
            label="Frequency"
            onChange={setFrequency}
            disabled={readOnly || disableInputs(ExperimentKey.FREQUENCY, type)}
            type="number"
            inputProps={{ min, max }}
          />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
