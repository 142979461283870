import { createBrowserRouter, createRoutesFromElements, Link, Navigate, Route, Routes } from 'react-router-dom';

import { RouteName, RoutePath } from 'app/routes';

import { BreadcrumbItem } from 'components/common/breadcrumbs/types';
import { NESTED_ROUTES } from 'global/route';
import assetsChildrenRoutes from 'pages/assets/routes';
import carouselsChildrenRoutes from 'pages/carousel/routes';
import contextsChildrenRoutes from 'pages/context/routes';
import dailyPracticesChildrenRoutes from 'pages/daily-practice/routes';
import { ExperimentsPage } from 'pages/experiments';
import { LabUsersPage } from 'pages/lab-users';
import learnChildrenRoutes from 'pages/learn/routes';
import momentsChildrenRoutes from 'pages/moments/routes';
import { OverviewPage } from 'pages/overview';
import packagesChildrenRoutes from 'pages/package/routes';
import questionsChildrenRoutes from 'pages/question/routes';
import reviewsChildrenRoutes from 'pages/review/routes';
import sequencesChildrenRoutes from 'pages/sequence/routes';
import windDownsChildrenRoutes from 'pages/wind-down/routes';
import wisdomEnginePromptsChildrenRoutes from 'pages/wisdom-engine-prompts/routes';

export const createRouter = (wrapper: React.ReactNode) =>
  createBrowserRouter([
    {
      element: wrapper,
      children: [
        {
          path: RoutePath.OVERVIEW + NESTED_ROUTES,
          element: <OverviewPage />,
          handle: { breadcrumb: (): BreadcrumbItem => ({ link: RoutePath.OVERVIEW, labelText: RouteName.OVERVIEW }) },
        },
        {
          path: RoutePath.CONTEXTS + NESTED_ROUTES,
          handle: { breadcrumb: (): BreadcrumbItem => ({ link: RoutePath.CONTEXTS, labelText: RouteName.CONTEXTS }) },
          children: contextsChildrenRoutes,
        },
        {
          path: RoutePath.LEARNS + NESTED_ROUTES,
          handle: { breadcrumb: (): BreadcrumbItem => ({ link: RoutePath.LEARNS, labelText: RouteName.LEARNS }) },
          children: learnChildrenRoutes,
        },
        {
          path: RoutePath.WIND_DOWNS + NESTED_ROUTES,
          handle: {
            breadcrumb: (): BreadcrumbItem => ({ link: RoutePath.WIND_DOWNS, labelText: RouteName.WIND_DOWNS }),
          },
          children: windDownsChildrenRoutes,
        },
        {
          path: RoutePath.ASSETS + NESTED_ROUTES,
          handle: { breadcrumb: (): BreadcrumbItem => ({ link: RoutePath.ASSETS, labelText: RouteName.ASSETS }) },
          children: assetsChildrenRoutes,
        },
        {
          path: RoutePath.MOMENTS + NESTED_ROUTES,
          handle: { breadcrumb: (): BreadcrumbItem => ({ link: RoutePath.MOMENTS, labelText: RouteName.MOMENTS }) },
          children: momentsChildrenRoutes,
        },
        {
          path: RoutePath.QUESTIONS + NESTED_ROUTES,
          handle: { breadcrumb: (): BreadcrumbItem => ({ link: RoutePath.QUESTIONS, labelText: RouteName.QUESTIONS }) },
          children: questionsChildrenRoutes,
        },
        {
          path: RoutePath.REVIEWS + NESTED_ROUTES,
          handle: { breadcrumb: (): BreadcrumbItem => ({ link: RoutePath.REVIEWS, labelText: RouteName.REVIEWS }) },
          children: reviewsChildrenRoutes,
        },
        {
          path: RoutePath.DAILY_PRACTICES + NESTED_ROUTES,
          handle: {
            breadcrumb: (): BreadcrumbItem => ({
              link: RoutePath.DAILY_PRACTICES,
              labelText: RouteName.DAILY_PRACTICES,
            }),
          },
          children: dailyPracticesChildrenRoutes,
        },
        {
          path: RoutePath.SEQUENCES + NESTED_ROUTES,
          handle: { breadcrumb: (): BreadcrumbItem => ({ link: RoutePath.SEQUENCES, labelText: RouteName.SEQUENCES }) },
          children: sequencesChildrenRoutes,
        },
        {
          path: RoutePath.CAROUSELS + NESTED_ROUTES,
          handle: { breadcrumb: (): BreadcrumbItem => ({ link: RoutePath.CAROUSELS, labelText: RouteName.CAROUSELS }) },
          children: carouselsChildrenRoutes,
        },
        {
          path: RoutePath.WISDOM_ENGINE_PROMPTS + NESTED_ROUTES,
          handle: {
            breadcrumb: (): BreadcrumbItem => ({
              link: RoutePath.CAROUSELS,
              labelText: RouteName.WISDOM_ENGINE_PROMPTS,
            }),
          },
          children: wisdomEnginePromptsChildrenRoutes,
        },
        { path: RoutePath.EXPERIMENTS + NESTED_ROUTES, element: <ExperimentsPage /> },
        { path: RoutePath.LAB_USERS + NESTED_ROUTES, element: <LabUsersPage /> },
        {
          path: RoutePath.PACKAGES + NESTED_ROUTES,
          handle: {
            breadcrumb: (): BreadcrumbItem => ({ link: RoutePath.PACKAGES, labelText: RouteName.PACKAGES }),
          },
          children: packagesChildrenRoutes,
        },
        { path: '*', element: <Navigate to={RoutePath.OVERVIEW} replace /> },
      ],
    },
  ]);
