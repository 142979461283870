import { FC } from 'react';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import { AssetPicker, AssetPickerTheme } from 'components/asset/asset-picker';

import { UseFilePickerReturnValue } from './hook';

export const AssetPickerComponent: FC<UseFilePickerReturnValue> = ({
  galleryState,
  onPluginClick,
  onClose,
  onSubmit,
}) => (
  <>
    <AddPhotoAlternateIcon onClick={onPluginClick} />
    {galleryState && (
      <AssetPicker state={galleryState} onSubmit={onSubmit} close={onClose} theme={AssetPickerTheme.DARK} />
    )}
  </>
);
