import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { RouteAction } from 'global/route';

import { Create } from './Create';
import { List } from './List';

export const OverviewPage: FC = () => (
  <Routes>
    <Route index element={<List />} />
    <Route path={RouteAction.CREATE} element={<Create />} />
  </Routes>
);
