import { FC } from 'react';

import { Autocomplete, Card, CardContent, MenuItem, TextField as TF } from '@mui/material';

import { ActionButtons, DateInput, ID } from 'components/inputs';
import { Select, TextField } from 'components/mui-custom';
import { setLabel } from 'global/form';
import { PathParams } from 'global/route';
import { ExperimentFormData } from 'pages/experiments/forms';

import './index.scss';

import { useLabUserForm } from '../hooks/useUserForm';

import { LAB_USER, LabUser, Key as UserKey, UserTypeData } from '.';

interface Props {
  action: PathParams;
  values?: LabUser;
}
export const LabUserForm: FC<Props> = ({ action, values = LAB_USER }) => {
  const { formLoading, readOnly, watch, trigger, setValue, formActions, errors, experimentList, userTypesList } =
    useLabUserForm({
      action,
      providedFormValues: values,
    });

  const { uid, email, userType, updatedAt, createdAt, experiments, typeId } = watch();

  const USER_EXPERIMENTS_LABEL = 'User experiments';
  const USER_TYPE_LABEL = 'User type';

  return (
    <Card>
      <CardContent className="lab-user-form-content">
        {uid && <ID value={uid} disabled />}
        <TextField
          value={email}
          label={setLabel('Email', action)}
          onChange={e => setValue(UserKey.EMAIL, e.target.value)}
          onBlur={() => trigger(UserKey.EMAIL)}
          helperText={errors?.email?.message}
          error={Boolean(errors.email)}
          disabled={readOnly || formLoading}
        />
        {action !== PathParams.SHOW && userTypesList && (
          <Select
            value={typeId}
            label={setLabel(USER_TYPE_LABEL, action)}
            onChange={e => setValue(UserKey.TYPE_ID, e.target.value as number)}
            onBlur={() => trigger(UserKey.TYPE_ID)}
            formHelperTextProps={{ text: errors?.typeId?.message, error: true }}
            error={Boolean(errors.typeId)}
          >
            {userTypesList.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        )}
        {action === PathParams.SHOW && userType && (
          <>
            <div className="--label">{USER_TYPE_LABEL}</div>
            <span className="--readonly-value">{(userType as UserTypeData).name}</span>
          </>
        )}
        {action !== PathParams.SHOW ? (
          <Autocomplete
            value={(experiments as ExperimentFormData[]) || []}
            options={experimentList || []}
            onChange={(_, value) => setValue(UserKey.EXPERIMENTS, value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => option.title}
            renderInput={params => <TF {...params} label={USER_EXPERIMENTS_LABEL} variant="standard" />}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.title}
              </li>
            )}
            disabled={readOnly}
            fullWidth
            multiple
          />
        ) : (
          <>
            <div className="--label">{USER_EXPERIMENTS_LABEL}</div>
            <span className="--readonly-value">
              {experiments?.map(experiment => experiment.title).join(', ') || '---'}
            </span>
          </>
        )}
        {readOnly && <DateInput label="Updated" value={updatedAt} disabled />}
        {readOnly && <DateInput label="Created" value={createdAt} disabled />}
      </CardContent>
      <ActionButtons actions={formActions} />
    </Card>
  );
};
