import { LinkedPostData } from 'components/post/forms';

export enum AssetKey {
  ID = 'id',
  URL = 'url',
  KEY = 'key',
  DESCRIPTION = 'description',
  ASSET_TYPE = 'assetType',
  POSTS = 'posts',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  TYPE_NAME = '__typename',
}

export enum MediaTypeLabel {
  AUDIO = 'Audio',
  VIDEO = 'Video',
  IMAGE = 'Image',
  FULL_SCREEN_IMAGE = 'Full Screen Image',
  FULL_SCREEN_VIDEO = 'Full Screen Video',
  FULL_SCREEN_AUDIO = 'Full Screen Audio',
  MIXED_CONTENT = 'Mixed Content',
}

export enum MediaTypeValue {
  AUDIO = 'audio',
  VIDEO = 'video',
  IMAGE = 'image',
  FULL_SCREEN_IMAGE = 'FullScreenImage',
  FULL_SCREEN_VIDEO = 'FullScreenVideo',
  FULL_SCREEN_AUDIO = 'FullScreenAudio',
  MIXED_CONTENT = 'MixedContent',
}

export interface AssetFormData {
  [AssetKey.ID]?: number;
  [AssetKey.URL]: string | null;
  [AssetKey.DESCRIPTION]: string;
  [AssetKey.ASSET_TYPE]: MediaTypeValue | null;
  [AssetKey.POSTS]?: LinkedPostData[];
  [AssetKey.CREATED_AT]: string;
  [AssetKey.UPDATED_AT]: string;
  [AssetKey.TYPE_NAME]: string;
}

export const MEDIA_TYPES = [
  {
    text: MediaTypeLabel.IMAGE,
    value: MediaTypeValue.IMAGE,
  },
  {
    text: MediaTypeLabel.AUDIO,
    value: MediaTypeValue.AUDIO,
  },
  {
    text: MediaTypeLabel.VIDEO,
    value: MediaTypeValue.VIDEO,
  },
  {
    text: MediaTypeLabel.FULL_SCREEN_AUDIO,
    value: MediaTypeValue.FULL_SCREEN_AUDIO,
  },
  {
    text: MediaTypeLabel.FULL_SCREEN_IMAGE,
    value: MediaTypeValue.FULL_SCREEN_IMAGE,
  },
  {
    text: MediaTypeLabel.FULL_SCREEN_VIDEO,
    value: MediaTypeValue.FULL_SCREEN_VIDEO,
  },
  {
    text: MediaTypeLabel.MIXED_CONTENT,
    value: MediaTypeValue.MIXED_CONTENT,
  },
];

export const ASSET_FORM: AssetFormData = {
  [AssetKey.ID]: undefined,
  [AssetKey.URL]: '',
  [AssetKey.DESCRIPTION]: '',
  [AssetKey.ASSET_TYPE]: null,
  [AssetKey.POSTS]: [],
  [AssetKey.CREATED_AT]: '',
  [AssetKey.UPDATED_AT]: '',
  [AssetKey.TYPE_NAME]: '',
};

export type AssetFilter = { assetType: MediaTypeValue; or: { description: string } };

export const DEFAULT_FILTER = { assetType: MediaTypeValue.IMAGE, or: { description: '' } };
