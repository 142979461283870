import { FC, ReactNode } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

interface Props {
  children: ReactNode;
}

export const DateProvider: FC<Props> = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
);
