import { ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { ApolloClient, DocumentNode, FetchPolicy, NormalizedCacheObject, useLazyQuery } from '@apollo/client';
import {
  GridPaginationModel,
  GridSortItem,
  GridSortModel,
  GridCallbackDetails,
  GridCellParams,
  GridEventListener,
  GridRowSelectionModel,
  MuiEvent,
} from '@mui/x-data-grid';
import { RoutePath } from 'app/routes';

import { Action, MenuItemAction } from 'components/generators';
import { PARENT_ROUTE_SLASH, pathJoin, PathParams } from 'global/route';
import { FetchPolicy as Policy } from 'graphql/apollo-client';
import { FilterProps } from 'components/tables/grid-table/filter/types';

interface SearchOption {
  uppercased?: boolean;
  toNumber?: boolean;
}

interface SearchOptions {
  [key: string]: SearchOption;
}

export interface BulkObject {
  ids: GridRowSelectionModel;
  isExclude: boolean;
  where?: object;
}

export interface FormActions {
  inline?: Action[];
  expanded?: MenuItemAction[];
}

interface CellClick {
  cellParams: GridCellParams<any, any, any>;
  cellClickEvent: MuiEvent<MouseEvent<HTMLElement, MouseEvent>>;
  cellDetails: GridCallbackDetails;
  cellClickHandler: GridEventListener<'cellClick'>;
}

interface Query {
  query: DocumentNode;
  filterArguments?: string[];
  fetchPolicy?: FetchPolicy;
  client?: ApolloClient<NormalizedCacheObject>;
  where?: object;
}

interface Props {
  query: Query;
  dataKey: string;
  counterKey: string;
  defaultRowsPerPage?: number;
  defaultOrder?: string;
  defaultAttributes?: string;
  searchOptions?: SearchOptions;
  extendedCellClickHandler?: CellClick['cellClickHandler'];
}

type CellClickHandler = (
  params: CellClick['cellParams'],
  event: CellClick['cellClickEvent'],
  details: CellClick['cellDetails'],
  extendedCellClickHandler?: CellClick['cellClickHandler'],
) => void;

interface SearchParams extends Record<string, string> {
  attributes: string;
  filterString: string;
  order: string;
  page: string;
  rowsPerPage: string;
}

interface GetFilterParams {
  options: string[];
  excludeOptions?: string[];
}

const ID_FIELD = 'id';
const UID_FIELD = 'uid';
const ASC = 'asc';
const DESC = 'desc';
const DEFAULT_ORDER_FIELD = ID_FIELD;
export const CHECK_FIELD = '__check__';
export const TABLE_ROWS_PER_PAGE = 50;
export const TABLE_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
export const DEFAULT_ORDER_PARAM = DEFAULT_ORDER_FIELD;

export const getShowPagePath = (path: RoutePath, id: string | number) =>
  pathJoin(PARENT_ROUTE_SLASH, path, String(id), PathParams.SHOW);

export const parseSortItem = (field: GridSortItem['field']): string => {
  switch (field) {
    case UID_FIELD:
      return ID_FIELD;
    default:
      return field;
  }
};

export const undoParseSortItem = (field: GridSortItem['field']): string => {
  switch (field) {
    case ID_FIELD:
      return UID_FIELD;
    default:
      return field;
  }
};

type BaseContent = { id?: number | string } & Record<string, any>;

const REVERSE_PREFIX = 'reverse:';

const buildSearch = (attribute: string, value: string, options?: SearchOptions) => {
  const searchObject = {};

  if (!value) {
    return searchObject;
  }

  if (options?.[attribute]?.uppercased) {
    searchObject[attribute] = String(value).toUpperCase();
  }
  if (options?.[attribute]?.toNumber) {
    searchObject[attribute] = Number(value);
  } else {
    searchObject[attribute] = value;
  }

  if (Object.keys(searchObject).length) {
    return { or: searchObject };
  }

  return searchObject;
};

const getAllowedAttributes = (arr: string[]): string[] => {
  const attributesToExclude = [UID_FIELD, '__typename'];

  return arr.filter(item => !attributesToExclude.includes(item));
};

const sortHandler = (items: GridSortItem[]): string => {
  const item = items[0];

  const field = parseSortItem(item?.field);

  switch (item?.sort) {
    case ASC:
      return field;
    case DESC:
      return REVERSE_PREFIX + field;
    default:
      return DEFAULT_ORDER_FIELD;
  }
};

const extractInitialParams = (searchParams: URLSearchParams): Partial<SearchParams> => {
  return Object.fromEntries(searchParams.entries());
};

const strToInt = (str: string | undefined, defaultValue: number, strictToArray?: number[]): number => {
  if (str && /^\d+$/.test(str)) {
    const intValue = parseInt(str, 10);
    if (strictToArray) {
      return strictToArray.includes(intValue) ? intValue : defaultValue;
    }
    return intValue;
  }
  return defaultValue;
};

const getSortModelByOrder = (orderValue: string): GridSortModel => {
  const reverse = orderValue.startsWith(REVERSE_PREFIX);
  const field = orderValue.slice(reverse ? REVERSE_PREFIX.length : 0);
  return [{ field: undoParseSortItem(field), sort: reverse ? DESC : ASC }];
};

export const useTable = <Content extends BaseContent[]>({
  defaultRowsPerPage = TABLE_ROWS_PER_PAGE,
  defaultOrder = DEFAULT_ORDER_PARAM,
  query: { fetchPolicy, query, where: standardWhere, client },
  dataKey,
  counterKey,
  defaultAttributes = '',
  searchOptions,
}: Props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialParams = extractInitialParams(searchParams);
  const initialPage = strToInt(initialParams.page, 0);
  const initialRowsPerPage = strToInt(initialParams.perPage, defaultRowsPerPage, TABLE_ROWS_PER_PAGE_OPTIONS);
  const initialOrder = initialParams.order ?? defaultOrder;
  const initialSortModel = getSortModelByOrder(initialOrder);
  const initialAttributes = initialParams.attributes || defaultAttributes;
  const initialFilterString = initialParams.filterString || '';
  const isFilterEnabled = initialAttributes && initialFilterString;
  const initialQuerySearch = isFilterEnabled ? initialFilterString : '';
  const initialQueryAttributes = isFilterEnabled ? initialAttributes : '';

  const [page, setPage] = useState<number>(initialPage);
  const [selectAllMode, setSelectAllMode] = useState<boolean>(false);
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [excluded, setExcluded] = useState<GridRowSelectionModel>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(initialRowsPerPage);
  const [filterString, setFilterString] = useState<string>(initialFilterString);
  const [attributes, setAttributes] = useState<string>(initialAttributes);
  const [querySearch, setQuerySearch] = useState<string>(initialQuerySearch);
  const [queryAttributes, setQueryAttributes] = useState<string>(initialQueryAttributes);
  const [order, setOrder] = useState(initialOrder);
  const [isFilterPressed, setIsFilterPressed] = useState<boolean>(false);
  const [content, setContent] = useState<Content>([] as unknown as Content);
  const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel);
  const prevRowsPerPageRef = useRef<number>(initialRowsPerPage);

  const updateSearchParams = (newValues: Partial<SearchParams>, defaultValues: Partial<SearchParams> = {}) => {
    setSearchParams(prevParams => {
      const newSearchParams = Object.fromEntries(prevParams);
      Object.entries(newValues).forEach(([name, value]) => {
        if (value === (defaultValues[name] ?? '')) {
          delete newSearchParams?.[name];
        } else {
          newSearchParams[name] = String(value);
        }
      });

      return newSearchParams;
    });
  };

  const filterHandler = (input: string): void => {
    setIsFilterPressed(true);

    if (!attributes) {
      return;
    }

    updateSearchParams({ attributes, filterString: input });
    setSelected([]);
    setExcluded([]);
    setSelectAllMode(false);
    setPage(0);
    setQueryAttributes(attributes);
    setQuerySearch(input);
    setFilterString(input);
  };

  const resetHandler = (): void => {
    setIsFilterPressed(false);
    setAttributes('');
    setFilterString('');
    setQueryAttributes('');
    setQuerySearch('');
    updateSearchParams({ attributes: '', filterString: '' });
  };

  const where = useMemo(
    () => ({ ...buildSearch(queryAttributes, querySearch, searchOptions), ...standardWhere }),
    [querySearch, queryAttributes],
  );

  const isFiltered = Boolean(querySearch) && Boolean(queryAttributes);

  const [getQuery, { data, loading }] = useLazyQuery(query, {
    fetchPolicy: fetchPolicy ?? Policy.NETWORK_ONLY,
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      order: order,
      where: where,
    },
    client: client,
  });

  const selectAllTotalCount = useMemo(() => data?.[counterKey] - excluded.length || 0, [data?.[counterKey], excluded]);
  const selectedCount = useMemo(
    () => (selectAllMode ? selectAllTotalCount : selected.length),
    [selectAllMode, selectAllTotalCount, selected.length],
  );

  const rowCount = useMemo(() => data?.[counterKey], [data?.[counterKey]]);

  const sortContent = (items: GridSortModel): void => {
    const newItems = items.length ? [...items] : getSortModelByOrder(defaultOrder);
    const newOrder = sortHandler(newItems);

    setOrder(newOrder);
    setSortModel(newItems);
    updateSearchParams({ order: newOrder }, { order: DEFAULT_ORDER_PARAM });
  };

  const cellClickHandler: CellClickHandler = (params, event, details, extendedCellClickHandler = () => void {}) => {
    const checkFieldHandler = () => {
      const isChecked = (event as unknown as ChangeEvent<HTMLInputElement>).target.checked;
      if (!isChecked && selectAllMode) {
        const uniqueItems = Array.from(new Set([...excluded, params.id]));
        setExcluded(uniqueItems);
      } else if (selectAllMode) {
        setExcluded(excluded.filter(id => id !== params.id));
      }
    };

    switch (params.field) {
      case CHECK_FIELD:
        return checkFieldHandler();
      default:
        return extendedCellClickHandler(
          params,
          event as unknown as MuiEvent<MouseEvent<HTMLElement, globalThis.MouseEvent>>,
          details,
        );
    }
  };

  const getFilter = ({ options, excludeOptions = [] }: GetFilterParams): FilterProps => ({
    setFilterString,
    setAttributes,
    attributes,
    filterString,
    options: getAllowedAttributes(options).filter(option => !excludeOptions.includes(option)),
    filterHandler,
    resetHandler,
    isFiltered,
    rowCount,
    isFilterPressed,
  });

  const selectAll = () => {
    setSelectAllMode(true);
    setSelected(data[dataKey].map(item => item.id));
  };
  const unselectAll = () => {
    setSelectAllMode(false);
    setSelected([]);
  };
  const updatePage = () => {
    getQuery();
    unselectAll();
  };

  const bulkObject: BulkObject = useMemo(
    () => ({
      ids: selectAllMode ? excluded : selected,
      isExclude: selectAllMode,
      where: where,
    }),
    [selectAllMode, excluded, selected],
  );

  const paginationModelChangeHandler = (model: GridPaginationModel, details: GridCallbackDetails<any>) => {
    setRowsPerPage(model.pageSize);
    setPage(model.page);
  };

  useEffect(() => {
    if (prevRowsPerPageRef.current !== rowsPerPage) {
      setPage(prevPage => {
        const newPage = Math.floor(prevPage * (prevRowsPerPageRef.current / rowsPerPage));
        prevRowsPerPageRef.current = rowsPerPage;
        return newPage;
      });
      updateSearchParams({ perPage: String(rowsPerPage) }, { perPage: String(defaultRowsPerPage) });
    }
  }, [rowsPerPage]);

  useEffect(() => updateSearchParams({ page: String(page) }, { page: '0' }), [page]);

  useEffect(() => {
    getQuery();
  }, [location.key]);

  useEffect(() => {
    if (data?.[dataKey]) {
      setContent(data[dataKey]);
    }
  }, [data?.[dataKey]]);

  useEffect(() => {
    if (selectAllMode && data?.[dataKey]) {
      const toSelected = Array.from(new Set([...selected, ...data[dataKey].map(item => item.id)])).filter(
        item => !excluded.includes(item),
      );

      setSelected(toSelected);
    } else if (!selectAllMode) {
      setExcluded([]);
    }
  }, [rowsPerPage, selectAllMode, page, data]);

  const selectPageHandler: GridEventListener<'columnHeaderClick'> = (header, e) => {
    if (header.field !== CHECK_FIELD) return;

    if ((e as unknown as ChangeEvent<HTMLInputElement>).target?.checked && selectAllMode) {
      setExcluded(prev => {
        return prev.filter(value => !content.find(row => row.id === value));
      });
    } else if (selectAllMode) {
      setExcluded(prev => Array.from(new Set([...prev, ...content.map(row => row.id!)])));
    }
  };

  const onSelectAllChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void = (event, checked) => {
    checked ? selectAll() : unselectAll();
  };

  const selectAllIndeterminate = selectAllMode && excluded.length !== 0;

  //TODO: refactor to decrease renders
  return {
    page,
    selected,
    setSelected,
    selectedCount,
    cellClickHandler,
    rowsPerPage,
    setOrder,
    content,
    selectAll,
    unselectAll,
    updatePage,
    bulkObject,
    where,
    loading,
    rowCount,
    sortContent,
    attributes,
    getFilter,
    paginationModelChangeHandler,
    sortModel,
    selectPageHandler,
    selectAllChecked: selectAllMode,
    onSelectAllChange,
    selectAllIndeterminate,
  };
};
