import { FC, useEffect, useMemo } from 'react';
import { useMatches } from 'react-router';
import { Link } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';

import { stringToInt } from 'global/formater';
import { GET_BREADCRUMBS } from 'graphql/queries';

import styles from './index.module.scss';
import { BreadcrumbItem, BreadcrumbsMap, BreadcrumbsQueryResult, EntitiesToResolve, MatchesHandle } from './types';

export const Breadcrumbs: FC = () => {
  let matches = useMatches();

  const rawBreadcrumbs: BreadcrumbItem[] = useMemo(
    () =>
      matches
        .filter(match => Boolean((match.handle as MatchesHandle)?.breadcrumb))
        .map(match => (match.handle as MatchesHandle).breadcrumb(match.params)),
    [matches],
  );

  const entitiesToResolve = useMemo(
    () =>
      rawBreadcrumbs.reduce<EntitiesToResolve[]>(
        (acc, { resolveInfo }) => (resolveInfo ? [...acc, { ...resolveInfo, id: stringToInt(resolveInfo.id) }] : acc),
        [],
      ),
    [rawBreadcrumbs],
  );

  const [getBreadcrumbs, { data, loading }] = useLazyQuery<BreadcrumbsQueryResult>(GET_BREADCRUMBS, {
    variables: { entitiesList: entitiesToResolve },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (entitiesToResolve.length) {
      getBreadcrumbs();
    }
  }, [entitiesToResolve]);

  const breadcrumbsMap: BreadcrumbsMap =
    useMemo(
      () =>
        data?.breadcrumbs?.reduce<BreadcrumbsMap>(
          (acc, { entityName, labelText }) => ({ ...acc, [entityName]: labelText }),
          {},
        ),
      [data],
    ) || {};

  const breadcrumbs: BreadcrumbItem[] = useMemo(
    () =>
      rawBreadcrumbs.map<BreadcrumbItem>(({ link, resolveInfo, labelText }) => ({
        link,
        labelText: resolveInfo ? breadcrumbsMap[resolveInfo.entityName] || resolveInfo.id : labelText,
      })),
    [data, rawBreadcrumbs],
  );

  const [breadcrumbsLinks, currentLocationText] = useMemo(
    () => [breadcrumbs.slice(0, -1), breadcrumbs.at(-1)?.labelText],
    [breadcrumbs],
  );

  if (loading || breadcrumbs.length <= 1) {
    return null;
  }

  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      {breadcrumbsLinks.map(({ link, labelText }) => (
        <Typography key={link} className={styles['breadcrumb-label']}>
          <Link to={link} className={styles['breadcrumb-link']}>
            {labelText}
          </Link>
        </Typography>
      ))}
      <Typography className={styles['breadcrumb-label']}>{currentLocationText}</Typography>
    </MuiBreadcrumbs>
  );
};
