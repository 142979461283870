import { FC, useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Button, IconButton, TextField } from '@mui/material';

import { ViewTable } from 'components/tables/view-table';
import { Package, PACKAGE_PLACEHOLDER, SEQUENCE_PACKAGES_COLUMNS } from 'pages/package/forms';

import '../index.scss';

interface Props {
  packages: Package[];
  allPackages?: Package[];
  setPackages: (packages: Package[]) => void;
  disabled: boolean;
}

export const Packages: FC<Props> = ({ packages, allPackages, setPackages, disabled }) => {
  const [currentPackages, setCurrentPackages] = useState(packages);

  useEffect(() => {
    setCurrentPackages(packages);
  }, [packages]);

  const filterPackages = (allPackages: Package[], id: number): Package[] => {
    const idsToExclude = currentPackages.reduce((packageIds: number[], _package: Package) => {
      if (_package.id !== id) {
        packageIds.push(_package.id as number);
      }
      return packageIds;
    }, []);
    return allPackages.filter(_package => !idsToExclude.includes(_package.id as number));
  };

  const setSelected = (key: number, value: Package | null) => {
    if (value) {
      const newPackages = [...currentPackages];
      newPackages[key] = { packageNumber: key + 1, ...value };
      setCurrentPackages(newPackages);
      setPackages(newPackages);
    }
  };

  const addPackage = () => {
    const newPackages = [...currentPackages];
    newPackages.push({ packageNumber: currentPackages.length + 1, ...PACKAGE_PLACEHOLDER });
    setCurrentPackages(newPackages);
    setPackages(newPackages);
  };

  const removePackage = (key: number) => {
    const newPackages = [...currentPackages];
    newPackages.splice(key, 1);
    setCurrentPackages(newPackages);
    setPackages(newPackages);
  };

  return (
    <div className="constructor-packages">
      <div className="label">Packages:</div>
      {allPackages &&
        !disabled &&
        currentPackages.map(({ id, packageNumber }, key) => (
          <div key={key}>
            {!disabled && (
              <div className="package">
                <div className="package-number">{packageNumber}</div>
                <Autocomplete
                  key={key}
                  defaultValue={allPackages.find(_package => _package.id === id)}
                  options={filterPackages(allPackages, id as number)}
                  onChange={(e, value) => setSelected(key, value)}
                  getOptionLabel={option => `${option.uid}: ${option.title}`}
                  renderInput={params => <TextField {...params} variant="standard" />}
                  disabled={disabled}
                  fullWidth
                />
                <IconButton className="delete-package" aria-label="delete" onClick={() => removePackage(key)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>
        ))}
      {!disabled && (
        <Button variant="contained" onClick={addPackage}>
          Add Package
        </Button>
      )}
      {disabled && <ViewTable rows={packages ?? []} columns={SEQUENCE_PACKAGES_COLUMNS} label={''} />}
    </div>
  );
};
