import { FC } from 'react';

import { StandardTextFieldProps } from '@material-ui/core';
import { Grid, MenuItem } from '@mui/material';

import { ClassName, Select } from 'components/mui-custom';
import { DEFAULT_GRID_ITEM_PROPS, setLabel } from 'global/form';
import { PathParams } from 'global/route';

import { EXPERIMENT_TYPE_TEXT, Key as ExperimentKey } from '../forms';
import { UseExperimentForm } from '../hooks/useExperimentForm';

interface Props extends StandardTextFieldProps {
  hook: UseExperimentForm;
  action: PathParams;
  disabled?: boolean;
}

export const ExperimentType: FC<Props> = ({
  hook: { watch, setType, trigger, errors, readOnly, experimentTypesList },
  action,
  disabled = false,
}) => {
  const { type } = watch();

  return (
    <Grid {...DEFAULT_GRID_ITEM_PROPS} className={errors.type?.message ? ClassName.ERROR : ''}>
      <Select
        label={setLabel('Type', action)}
        value={type}
        onChange={setType}
        onBlur={() => trigger(ExperimentKey.TYPE)}
        formHelperTextProps={{ text: errors.type?.message, error: true }}
        error={Boolean(errors.type)}
        disabled={readOnly || disabled}
      >
        {experimentTypesList?.map(({ id, name }) => (
          <MenuItem key={id} value={name}>
            {Object.keys(EXPERIMENT_TYPE_TEXT).includes(name) ? EXPERIMENT_TYPE_TEXT[name] : name}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
