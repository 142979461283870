import { ApolloClient, DefaultOptions, InMemoryCache, InMemoryCacheConfig } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { GetApiClient } from 'app/manager';
import config from 'config';

export enum FetchPolicy {
  CACHE_FIRST = 'cache-first',
  NETWORK_ONLY = 'network-only',
  CACHE_ONLY = 'cache-only',
  NO_CACHE = 'no-cache',
  STANDBY = 'standby',
}

export const cacheOtions: InMemoryCacheConfig = {
  // options here
};

const defaultOptions: DefaultOptions = {
  watchQuery: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const defaultHeaders = { 'x-origin-site': 'Admin' };

export const getCMSClient: GetApiClient = ({ errorHandler }) =>
  new ApolloClient({
    cache: new InMemoryCache(cacheOtions),
    link: onError(errorHandler).concat(
      createUploadLink({
        uri: `${config.env.API_HOST}/graphql`,
        credentials: 'include',
        headers: defaultHeaders,
      }),
    ),
    defaultOptions,
  });

export const getWebClient: GetApiClient = ({ errorHandler }) =>
  new ApolloClient({
    cache: new InMemoryCache(cacheOtions),
    link: onError(errorHandler).concat(
      createUploadLink({
        uri: `${config.env.API_HOST}/web-api-graphql`,
        credentials: 'include',
      }),
    ),
    defaultOptions,
  });
