import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { store } from 'store';
import { Modal, MODAL } from './common-modal';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: MODAL,
  reducers: {
    setModal: (MODAL, action: PayloadAction<Modal>) => ({ ...MODAL, ...action.payload }),
    clearModal: () => MODAL,
  },
});

export const selectModal = (state: ReturnType<typeof store.getState>) => state.modal;

export const modalReducer = modalSlice.reducer;

export const { setModal, clearModal } = modalSlice.actions;
